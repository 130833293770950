export function BankIdIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='40'
                height='41'
                viewBox='0 0 40 41'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12.987 21.2463L14.5743 11.2415C13.949 11.2415 12.8788 11.2415 12.8788 11.2415C12.0851 11.2415 11.063 10.7966 10.7624 9.97886C10.6662 9.70228 10.4377 8.7523 11.7484 7.82637C12.2174 7.5017 12.518 7.14094 12.5782 6.86437C12.6383 6.57577 12.5661 6.32324 12.3617 6.13084C12.0731 5.85426 11.5079 5.69794 10.7864 5.69794C9.57189 5.69794 8.71812 6.39539 8.63394 6.90044C8.57381 7.27322 8.86242 7.57385 9.11494 7.76625C9.87252 8.33143 10.0529 9.14913 9.58392 9.91873C9.10292 10.7124 8.05674 11.2295 6.93841 11.2415C6.93841 11.2415 5.8321 11.2415 5.2068 11.2415C5.0625 12.2155 2.70558 27.1506 2.52521 28.3291H11.8807C11.9649 27.812 12.3978 24.9861 12.987 21.2463Z'
                    fill='#193E4F'
                />
                <path
                    d='M2.02213 31.7919H5.85812C7.49353 31.7919 7.89036 32.6217 7.77011 33.3792C7.67391 33.9925 7.25303 34.4495 6.53153 34.7501C7.44543 35.0988 7.80619 35.64 7.67391 36.4937C7.50556 37.564 6.57963 38.3576 5.3651 38.3576H1L2.02213 31.7919ZM4.55942 34.5096C5.30497 34.5096 5.6537 34.1128 5.72585 33.6438C5.798 33.1387 5.56952 32.79 4.82397 32.79H4.16259L3.89804 34.5096H4.55942ZM4.15057 37.3355C4.92017 37.3355 5.3651 37.0228 5.47332 36.3855C5.5575 35.8324 5.24485 35.5077 4.49929 35.5077H3.75374L3.46514 37.3475H4.15057V37.3355Z'
                    fill='#193E4F'
                />
                <path
                    d='M13.0503 38.4061C12.0522 38.4783 11.5712 38.3701 11.3307 37.9372C10.8016 38.2618 10.2124 38.4302 9.58708 38.4302C8.45672 38.4302 8.0599 37.841 8.16812 37.1916C8.21622 36.8789 8.3966 36.5783 8.6852 36.3258C9.3105 35.7847 10.8497 35.7125 11.451 35.3037C11.4991 34.8467 11.3187 34.6784 10.7535 34.6784C10.0921 34.6784 9.53898 34.8948 8.589 35.5442L8.81748 34.0531C9.63518 33.4638 10.4288 33.1873 11.3427 33.1873C12.5092 33.1873 13.5433 33.6683 13.3509 34.9429L13.1224 36.3859C13.0383 36.891 13.0623 37.0473 13.6275 37.0593L13.0503 38.4061ZM11.3187 36.1334C10.7896 36.4701 9.80353 36.41 9.69531 37.1074C9.64721 37.4321 9.85163 37.6726 10.1763 37.6726C10.489 37.6726 10.8738 37.5403 11.1864 37.3239C11.1624 37.2036 11.1744 37.0834 11.2105 36.8549L11.3187 36.1334Z'
                    fill='#193E4F'
                />
                <path
                    d='M14.9128 33.2714H16.909L16.8008 33.9328C17.4381 33.3917 17.9191 33.1873 18.5444 33.1873C19.6627 33.1873 20.1798 33.8727 19.9994 34.991L19.4823 38.346H17.4862L17.9191 35.5682C18.0033 35.0632 17.8469 34.8227 17.4621 34.8227C17.1495 34.8227 16.8609 34.991 16.5843 35.3638L16.1274 38.334H14.1312L14.9128 33.2714Z'
                    fill='#193E4F'
                />
                <path
                    d='M21.5621 31.7919H23.5583L23.0532 35.0146L24.9652 33.271H27.4304L24.9773 35.4355L26.9494 38.3456H24.4361L22.921 35.9887H22.8969L22.5362 38.3336H20.54L21.5621 31.7919Z'
                    fill='#193E4F'
                />
                <path d='M28.6213 31.7933H30.9181L29.908 38.347H27.6112L28.6213 31.7933Z' fill='#193E4F' />
                <path
                    d='M32.0363 31.7933H35.3192C37.8565 31.7933 38.59 33.6332 38.3495 35.1604C38.121 36.6515 36.9425 38.347 34.7179 38.347H31.0142L32.0363 31.7933ZM34.1648 36.7837C35.2831 36.7837 35.8964 36.2306 36.0767 35.0642C36.209 34.1983 35.9445 33.3446 34.7059 33.3446H34.0926L33.5635 36.7837H34.1648Z'
                    fill='#193E4F'
                />
                <path
                    d='M25.567 2.5H16.007L14.7324 10.5929H16.3558C17.2456 10.5929 18.0874 10.184 18.4481 9.59479C18.5684 9.40239 18.6165 9.23403 18.6165 9.07771C18.6165 8.74101 18.388 8.48848 18.1595 8.32013C17.5342 7.85115 17.402 7.35813 17.402 7.0094C17.402 6.93725 17.402 6.87712 17.414 6.817C17.5463 5.96322 18.7007 5.03729 20.2278 5.03729C21.1418 5.03729 21.8392 5.25374 22.2601 5.65057C22.6329 5.99929 22.7772 6.49232 22.6689 7.0094C22.5367 7.62268 21.9234 8.12773 21.5747 8.38025C20.6487 9.02961 20.769 9.59479 20.8291 9.76314C21.0215 10.2682 21.755 10.5929 22.3202 10.5929H24.7974C24.7974 10.5929 24.7974 10.5929 24.7974 10.6049C28.1644 10.6289 29.9681 12.1802 29.403 15.7877C28.8739 19.1427 26.3005 20.5857 23.2341 20.6097L22.0196 28.3539H23.8113C31.3751 28.3539 37.556 23.4958 38.7705 15.8238C40.2736 6.29992 34.225 2.5 25.567 2.5Z'
                    fill='#193E4F'
                />
            </svg>
        </>
    );
}
