import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSessionContext} from 'src/context/SessionContext';
import {validateEmail, validatePhone} from 'src/services/validation/validation';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {Input} from 'src/components/form/input/Input';
import {Button} from '../form/button/Button';
import {Checkbox} from '../form/checkbox/Checkbox';
import {formatPhoneNumber} from 'src/lib/numbers/format';
import './ToCModal.scss';

export interface ToCModalProps {
    open: boolean;
}

export const ToCModal: React.FC<ToCModalProps> = ({open}) => {
    const {t} = useTranslation<any, undefined>();
    const navigate = useNavigate();
    const {sessionState, updateAccount} = useSessionContext();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [isAccepted, setIsAccepted] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>();
    const [isAcceptedErrorMessage, setIsAcceptedErrorMessage] = useState<string>('');

    const handleEmailInput = (email: string) => {
        setEmail(email);
    };

    const handlePhoneNumberInput = (phoneNumber: string) => {
        const phoneNumberWithOnlyNumbers = formatPhoneNumber(phoneNumber);
        setPhoneNumber(phoneNumberWithOnlyNumbers);
    };

    const handleToSToggle = (checked: boolean) => {
        setIsAccepted(checked);
        checked && setIsAcceptedErrorMessage('');
    };

    const handleSubmit = async () => {
        setIsAcceptedErrorMessage(isAccepted ? '' : t('policy.privacy_policy_modal_error_state'));
        const isValidEmailInput = validateEmail(email) && email.length > 0;
        setIsValidEmail(isValidEmailInput);
        setEmailErrorMessage(isValidEmailInput ? '' : t('confirm.form.email_error'));
        const isValidPhoneNumberInput = validatePhone(phoneNumber) && phoneNumber.length > 0;
        setIsValidPhoneNumber(isValidPhoneNumberInput);
        setPhoneNumberErrorMessage(isValidPhoneNumberInput ? '' : t('confirm.form.phone_error'));

        if (sessionState?.userJwt && isValidEmailInput && isValidPhoneNumberInput && isAccepted) {
            updateAccount(sessionState?.userJwt, {email, mobile: phoneNumber, preferredContact: 'SMS_EMAIL'});
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <ModalPortal
            size='sm'
            open={open}
            onCloseClick={handleCancel}
            aria-label={`${t('policy.privacy_policy_modal_header', {userName: sessionState.user?.givenName})} ${t(
                'policy.privacy_policy_required_description'
            )}`}
        >
            <div id='toc-modal' className='terms-and-conditions-container' data-testid='terms-and-conditions-container'>
                <h1>{t('policy.privacy_policy_modal_header', {userName: sessionState.user?.givenName})}</h1>
                <p>{t('policy.privacy_policy_required_description')}</p>
                <div className='terms-and-conditions-container__actions'>
                    <Input
                        isValid={isValidEmail}
                        type='email'
                        required
                        value={email}
                        label={t('confirm.form.email_placeholder')}
                        ariaLabel={t('confirm.form.email_placeholder')}
                        id='emailInput'
                        onChange={handleEmailInput}
                        errorMessage={emailErrorMessage && emailErrorMessage}
                    />
                    <Input
                        isValid={isValidPhoneNumber}
                        type='tel'
                        required
                        value={phoneNumber}
                        label={t('confirm.form.telephone')}
                        ariaLabel={t('confirm.form.telephone')}
                        id='phoneNumberInput'
                        onChange={handlePhoneNumberInput}
                        errorMessage={phoneNumberErrorMessage ? phoneNumberErrorMessage : undefined}
                    />
                    <div className='terms-and-conditions-container__actions__toggle'>
                        <div className='terms-and-conditions-container__actions__toggle__button'>
                            <Checkbox
                                id='tos-checkbox-switch'
                                onClick={handleToSToggle}
                                ariaLabel={
                                    isAcceptedErrorMessage.length > 0
                                        ? isAcceptedErrorMessage
                                        : t('policy.privacy_policy_accept')
                                }
                                ariaInvalid={isAcceptedErrorMessage.length > 0}
                            />
                        </div>
                        <div>
                            <Trans t={t} i18nKey='policy.privacy_policy_content_links'>
                                I accept MittVaccins
                                {''}
                                <a
                                    target='_blank'
                                    href='/personuppgiftspolicy'
                                    aria-label={t('policy.privacy_policy_link_aria')}
                                >
                                    {t('policy.privacy_policy_short_header')}
                                </a>
                            </Trans>
                        </div>
                    </div>
                    <Button
                        className='terms-and-conditions-container__actions__button'
                        onClick={handleSubmit}
                        variant='filled'
                        ariaLabel={t('button_save.text')}
                    >
                        {t('button_save.text')}
                    </Button>
                </div>
            </div>
        </ModalPortal>
    );
};
