type IconProps = {
    width?: string;
    height?: string;
    ariaHidden?: boolean;
};
export function UserIcon(props: IconProps) {
    return (
        <>
            <svg
                aria-hidden={!!props.ariaHidden}
                focusable='false'
                width={props?.width || '24'}
                height={props?.height || '24'}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z'
                    stroke='currentColor'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.75 18C2.75 15.6528 4.65279 13.75 7 13.75H17C19.3472 13.75 21.25 15.6528 21.25 18V22.5C21.25 22.7761 21.0261 23 20.75 23H19.25C18.9739 23 18.75 22.7761 18.75 22.5V18C18.75 17.0335 17.9665 16.25 17 16.25H7C6.0335 16.25 5.25 17.0335 5.25 18V22.5C5.25 22.7761 5.02614 23 4.75 23H3.25C2.97386 23 2.75 22.7761 2.75 22.5V18Z'
                    fill='currentColor'
                />
            </svg>
        </>
    );
}
