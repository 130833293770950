import {enGB as en, sv} from 'date-fns/locale';
import {getLocale} from '../../services/i18n/locale';
import {formatInTimeZone} from 'date-fns-tz';

const locales = {en, sv};
const timeZone = 'Europe/Stockholm';
export function format(date: Date, dateFormat = 'PP') {
    return formatInTimeZone(date, timeZone, dateFormat, {
        locale: locales[getLocale()]
    });
}
