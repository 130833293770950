export function ListIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='17'
                height='16'
                viewBox='0 0 17 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M2.02099 13.7295C1.60433 13.7295 1.25366 13.5872 0.968992 13.3025C0.684325 13.0178 0.541992 12.6672 0.541992 12.2505C0.541992 11.8338 0.684325 11.4832 0.968992 11.1985C1.25366 10.9138 1.60433 10.7715 2.02099 10.7715C2.43766 10.7715 2.78833 10.9138 3.07299 11.1985C3.35766 11.4832 3.49999 11.8338 3.49999 12.2505C3.49999 12.6672 3.35766 13.0178 3.07299 13.3025C2.78833 13.5872 2.43766 13.7295 2.02099 13.7295ZM4.99999 13.4795V11.0215H16.958V13.4795H4.99999ZM2.02099 9.47948C1.60433 9.47948 1.25366 9.33715 0.968992 9.05248C0.684325 8.76782 0.541992 8.41715 0.541992 8.00048C0.541992 7.58382 0.684325 7.23315 0.968992 6.94848C1.25366 6.66382 1.60433 6.52148 2.02099 6.52148C2.43766 6.52148 2.78833 6.66382 3.07299 6.94848C3.35766 7.23315 3.49999 7.58382 3.49999 8.00048C3.49999 8.41715 3.35766 8.76782 3.07299 9.05248C2.78833 9.33715 2.43766 9.47948 2.02099 9.47948ZM4.99999 9.22948V6.77148H16.958V9.22948H4.99999ZM2.02099 5.22948C1.60433 5.22948 1.25366 5.08715 0.968992 4.80248C0.684325 4.51782 0.541992 4.16715 0.541992 3.75048C0.541992 3.33382 0.684325 2.98315 0.968992 2.69848C1.25366 2.41382 1.60433 2.27148 2.02099 2.27148C2.43766 2.27148 2.78833 2.41382 3.07299 2.69848C3.35766 2.98315 3.49999 3.33382 3.49999 3.75048C3.49999 4.16715 3.35766 4.51782 3.07299 4.80248C2.78833 5.08715 2.43766 5.22948 2.02099 5.22948ZM4.99999 4.97948V2.52148H16.958V4.97948H4.99999Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
