import {AppointmentCategory, AppointmentType} from '../../../../services/api/types/types';

export function minMaxPeopleForType(
    appointmentTypes: Array<AppointmentType>,
    reasonId: number | null = null
): [minPeople: number, maxPeople: number] {
    if (reasonId !== null) {
        appointmentTypes = appointmentTypes.filter(({visitReasonIds}) => visitReasonIds.includes(reasonId));
    }

    if (appointmentTypes.length === 0) {
        return [1, 1];
    }

    const patientsCount = appointmentTypes.map(({patients}) => patients);

    const minPeople = Math.min(...patientsCount);
    const maxPeople = Math.max(...patientsCount);

    return [minPeople, maxPeople];
}

export function getHealthDeclarationId(
    appointmentCategories: Array<AppointmentCategory>,
    appointmentTypeId: number | undefined
) {
    const category = appointmentCategories.find((ac) => {
        return ac.appointmentTypes.some(({id}) => id === appointmentTypeId);
    });

    if (!category) {
        return null;
    }

    return category.healthDeclarationDefinitionId;
}

export function getMinMaxAge(appointmentCategories: Array<AppointmentCategory>, appointmentTypeId: number | undefined) {
    const category = appointmentCategories.find((ac) => {
        return ac.appointmentTypes.some(({id}) => id === appointmentTypeId);
    });

    if (!category) {
        return null;
    }

    return {minAge: category.minAge || null, maxAge: category.maxAge || null};
}

export function getUrlParamReason(reasonParams: Array<string>, availableVisitReasons: Array<number>) {
    const paramReason = reasonParams.length === 1 ? parseInt(reasonParams[0], 10) : null;

    if (paramReason === null) {
        return null;
    }

    return availableVisitReasons.find((id) => paramReason === id) || null;
}
