import {useNavigate} from 'react-router-dom';
import {useSessionContext} from '../../context/SessionContext';
import {HandleHistoryIdentity} from './components/HandleHistoryIdentity';
import {HistoryNavigationContainer} from './components/HistoryNavigationContainer';
import {AgeRestrictionWarningModal} from '../session/AgeRestrictionWarningModal';
import {ToCModal} from '../session/ToCModal';
import {Spinner} from '../layout/loader/Spinner';
import './HistoryMain.scss';

export function HistoryMain() {
    const {sessionState, startSession} = useSessionContext();
    const navigate = useNavigate();
    const hasIdentity = sessionState?.hasActiveSession && !!sessionState?.user;
    const isAgeRestricted = sessionState.isAgeRestricted;
    if (sessionState.isLoading) {
        return (
            <div className='history-container__spinner'>
                <div className='history-container__spinner__icon'>
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <main id='main-history-container' className='history-container' data-testid='main-history-container'>
            <div className='page-container-margin'>
                {!hasIdentity && (
                    <HandleHistoryIdentity
                        onLoginSuccess={async ({jwt, sessionDuration, tokenDuration}) => {
                            startSession(jwt, sessionDuration, tokenDuration);
                        }}
                    />
                )}
                {hasIdentity && isAgeRestricted && (
                    <AgeRestrictionWarningModal open handleAction={() => navigate('/')} />
                )}
                {hasIdentity && !isAgeRestricted && !sessionState.account && (
                    <ToCModal open={hasIdentity && !sessionState.account} />
                )}
                {hasIdentity && !isAgeRestricted && sessionState.account && <HistoryNavigationContainer />}
            </div>
        </main>
    );
}
