import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LatLng} from 'leaflet';
import {ListStatus, UiNoValueActions, UiStringActions, useUiContext} from 'src/context/UiContext';
import {useMapDataContext} from 'src/context/MapDataContext';
import {ClinicObjectSlimWithCapacity} from 'src/services/api/types/types';
import {LoadIndicator} from 'src/components/layout/loader/LoadIndicator';
import {XIcon} from '../../icons/XIcon';
import {MapModal} from '../../layout/map-modal/MapModal';
import {ClinicListItem} from './ClinicListItem';
import {fetchCapacity} from 'src/services/api/api';
import {sortBy} from 'src/lib/sort/sort';
import './style.scss';

interface ListModalProps {
    reasonParams: Array<string>;
    mapCenterPoint: LatLng | null;
}

export function ListModal({reasonParams, mapCenterPoint}: ListModalProps) {
    const {state, dispatch} = useUiContext();
    const {state: mapDataState, getDistanceToClinic} = useMapDataContext();
    const {t} = useTranslation();
    const [sortedClinics, setSortedClinics] = useState<ClinicObjectSlimWithCapacity[]>([]);
    const [isSortingClinics, setIsSortingClinics] = useState(false);
    const focusRef = useRef<HTMLDivElement>(null);
    const noClinicsInView = state.visibleClinics.length === 0;

    const sortedClinicsToRender = useMemo(() => {
        return (
            sortedClinics &&
            sortedClinics.length > 0 &&
            sortedClinics.map((clinic) => {
                return (
                    <ClinicListItem
                        key={clinic.id}
                        reasonParams={reasonParams}
                        clinic={clinic}
                        distanceToClinic={getDistanceToClinic(clinic)}
                    />
                );
            })
        );
    }, [getDistanceToClinic, reasonParams, sortedClinics]);

    const sortedClinicsOnAvailability = useCallback(async () => {
        // first sort on proximity to map center point (or user location if available)
        setIsSortingClinics(true);
        let sortedClinicsOnMap = state.visibleClinics.sort((a, b) => {
            const referenceLocation = mapDataState.userLocation || mapCenterPoint;
            if (referenceLocation) {
                const distanceA = referenceLocation.distanceTo([a.location.lat, a.location.lng]);
                const distanceB = referenceLocation.distanceTo([b.location.lat, b.location.lng]);
                if (distanceA < distanceB) {
                    return -1;
                } else {
                    if (distanceA > distanceB) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            } else {
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
            }
        });

        try {
            // then check capacity if there are clinics and reasons
            if (sortedClinicsOnMap.length > 0 && reasonParams.length > 0) {
                // only check the first 15 clinics to not overload the server
                const firstUpTo15Clinics = sortedClinicsOnMap.slice(
                    0,
                    sortedClinicsOnMap.length >= 2 ? 15 : sortedClinicsOnMap.length
                );
                const clinicsWithAvailability = await Promise.all(
                    firstUpTo15Clinics.map(
                        (clinic: any) =>
                            new Promise((resolve, reject) => {
                                fetchCapacity(parseInt(clinic.id, 10), new AbortController().signal, reasonParams)
                                    .then((capacity) =>
                                        resolve({
                                            ...clinic,
                                            capacity: capacity
                                        })
                                    )
                                    .catch((error) => {
                                        reject(error);
                                    });
                            })
                    )
                );
                const clinicsWithAvailabilitySorted = sortBy(clinicsWithAvailability, [
                    {key: 'capacity.first', direction: 'asc'},
                    {key: 'capacity.within30Days', direction: 'desc'},
                    {key: 'capacity.within7Days', direction: 'desc'},
                    {key: 'hasDropin', direction: 'desc'},
                    {key: 'hasTimebook', direction: 'desc'}
                ]);
                // replace the first 15 clinics with the sorted clinics
                sortedClinicsOnMap.splice(0, firstUpTo15Clinics.length, ...clinicsWithAvailabilitySorted);
            }
        } catch (error) {
            console.error('Error sorting clinics', error);
        } finally {
            setIsSortingClinics(false);
        }
        setSortedClinics(sortedClinicsOnMap);
    }, [state.visibleClinics, mapDataState.userLocation, mapCenterPoint, reasonParams]);

    useEffect(() => {
        // debounce sorting so we don't sort on every render when the map moves
        const debouncedSorting = setTimeout(() => {
            sortedClinicsOnAvailability();
        }, 250);

        return () => {
            clearTimeout(debouncedSorting);
        };
    }, [sortedClinicsOnAvailability]);

    return (
        <MapModal className={`list_modal`}>
            <section className='map_modal__content map_modal__header' ref={focusRef} tabIndex={-1}>
                <div className='list_modal__title'>
                    <h1>{t('list_clinics_heading.heading')}</h1>
                    <span>{t('list_clinics_heading.clinic_count', {clinicCount: state.visibleClinics.length})}</span>
                </div>
                <button
                    aria-label={t('list_clinics_close_button.text')}
                    className='list_modal__close_button'
                    onClick={() => {
                        dispatch({type: UiStringActions.SET_LIST_STATUS, value: ListStatus.HIDDEN_BUT_SHOWN});
                        dispatch({type: UiNoValueActions.CLEAR_STICKY_CLINIC_ID});
                    }}
                >
                    <XIcon />
                </button>
            </section>

            <div className='map_modal__scroll' aria-live='polite'>
                <div className='clinic_list'>
                    {noClinicsInView && (
                        <div className='clinic-list-item clinic_list_item--no-hover'>
                            <p>{t('list_clinics_no_clinics_found.text')}</p>
                        </div>
                    )}
                    {isSortingClinics && (
                        <div className='clinic_list__loader'>
                            <LoadIndicator show />
                        </div>
                    )}
                    {sortedClinicsToRender}
                </div>
            </div>
        </MapModal>
    );
}
