import {FallbackProps} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

export function FallbackError({error, resetErrorBoundary}: FallbackProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onPrimaryAction={resetErrorBoundary} primaryActionLabel={t('button_try_again.text') as string}>
            <p>
                <b>{t('error_generic_title.text')}</b>
                <br />
                {t('error_generic_message.text')}
            </p>
        </ModalPortal>
    );
}
