import {useTranslation} from 'react-i18next';
import {Booking} from './components/Booking';
import {ChangeTimeSlot} from './ChangeTimeSlot';
import {HandleBookingActions, useHandleBookingContext} from '../../../context/HandleBookingContext';
import {SessionActions, useSessionContext} from '../../../context/SessionContext';
import {EditContactInfoModal} from './components/EditContactInfoModal';
import {fetchUnreserveTimeSlot} from '../../../services/api/api';
import {ConfirmUnbookingModal} from './components/ConfirmUnbookingModal';
import {Button} from '../../form/button/Button';
import {UnauthorizedError} from '../../../services/api/implementations/fetch';
import './HandleBookings.scss';

export function HandleBookings() {
    const {t} = useTranslation();
    const {state, dispatch, refreshAllBookings} = useHandleBookingContext();
    const {sessionState, sessionDispatch} = useSessionContext();

    const {bookingsLoaded, bookings, editTimeSlot, editContactInfo, confirmUnbooking, user} = state;

    return (
        <>
            {sessionState.sessionTimedOut && (
                <section aria-live='assertive' aria-atomic>
                    <h1 className='error-container__header'>{t('session.handle_booking_expired_heading')}</h1>
                    <p className='error-container__info-text'>{t('session.handle_booking_expired_content')}</p>
                    <Button className='error-container__button' big onClick={() => window.location.reload()}>
                        <span>{t('session.handle_booking_expired_button')}</span>
                    </Button>
                </section>
            )}

            {!sessionState.sessionTimedOut && user !== null && (
                <>
                    {confirmUnbooking && (
                        <ConfirmUnbookingModal
                            handleCloseModal={() =>
                                dispatch({type: HandleBookingActions.CONFIRM_UNBOOKING, value: null})
                            }
                            onConfirmClick={async () => {
                                try {
                                    await fetchUnreserveTimeSlot(
                                        confirmUnbooking.clinicId,
                                        confirmUnbooking.id,
                                        sessionState.userJwt
                                    );
                                } catch (error) {
                                    if (error instanceof UnauthorizedError) {
                                        sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
                                    }
                                }

                                dispatch({type: HandleBookingActions.CONFIRM_UNBOOKING, value: null});
                                refreshAllBookings(sessionState.userJwt as string);
                                dispatch({
                                    type: HandleBookingActions.SET_SNACKBAR_MESSAGE,
                                    value: t('handle_booking.booking_unbooked_snackbar')
                                });
                            }}
                        />
                    )}
                    {editTimeSlot && (
                        <ChangeTimeSlot
                            onBookingUpdated={() => {
                                dispatch({type: HandleBookingActions.EDIT_TIMESLOT, value: null});
                                refreshAllBookings(sessionState.userJwt as string);
                                dispatch({
                                    type: HandleBookingActions.SET_SNACKBAR_MESSAGE,
                                    value: t('handle_booking.timeslot_updated_snackbar')
                                });
                            }}
                            appointmentTypeId={editTimeSlot.appointmentTypeId}
                            initialTimeSlot={new Date(editTimeSlot.when)}
                            bookingId={editTimeSlot.id}
                            clinicId={editTimeSlot.clinicId}
                        />
                    )}
                    {editContactInfo && (
                        <EditContactInfoModal
                            editBooking={editContactInfo}
                            onBookingUpdated={() => {
                                dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: null});
                                refreshAllBookings(sessionState.userJwt as string);
                                dispatch({
                                    type: HandleBookingActions.SET_SNACKBAR_MESSAGE,
                                    value: t('handle_booking.contact_updated_snackbar')
                                });
                            }}
                            onUpdateError={(error) => {
                                if (error instanceof UnauthorizedError) {
                                    sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
                                }
                            }}
                            handleCloseModal={() => {
                                dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: null});
                            }}
                            userJwt={sessionState.userJwt}
                        />
                    )}
                    {!editTimeSlot && (
                        <div className='bookings-list'>
                            <h1 className='handle-booking__heading'>{t('handle_booking.handle_booking_heading')}</h1>
                            <h2 className='handle-booking__extra-heading'>
                                {t('handle_booking.x_bookings', {count: bookings ? bookings.length : 0})}
                            </h2>
                            <div className='bookings'>
                                {bookingsLoaded && bookings.length === 0 && (
                                    <p className='bookings__no-bookings'>{t('handle_booking.you_have_no_bookings')}</p>
                                )}
                                {bookings.map((booking) => (
                                    <Booking
                                        key={booking.id}
                                        booking={booking}
                                        onEditTimeSlotClick={() => {
                                            dispatch({type: HandleBookingActions.EDIT_TIMESLOT, value: booking});
                                        }}
                                        onEditContactInfoClick={() => {
                                            dispatch({type: HandleBookingActions.EDIT_CONTACT_INFO, value: booking});
                                        }}
                                        onCancelBookingClick={() => {
                                            dispatch({type: HandleBookingActions.CONFIRM_UNBOOKING, value: booking});
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
}
