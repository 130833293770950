import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';
import {useSessionContext} from 'src/context/SessionContext';

export function SessionWarningModal() {
    const {t} = useTranslation();
    const {sessionState, refreshSession} = useSessionContext();
    if (sessionState.timeoutWarning) {
        return (
            <ModalPortal
                open={sessionState.timeoutWarning}
                onPrimaryAction={refreshSession}
                primaryActionLabel={t('error_ok_button.text')}
            >
                <div className='mt-6'>
                    <h1>{t('session.timeout_warning.heading')}</h1>
                    <p>{t('session.timeout_warning.content', {minutes: 10})}</p>
                </div>
            </ModalPortal>
        );
    }
    if (sessionState.tokenTimeoutWarning) {
        return (
            <ModalPortal
                onPrimaryAction={refreshSession}
                primaryActionLabel={t('session.token_timeout_warning.button')}
            >
                <div className='mt-6'>
                    <h1>{t('session.token_timeout_warning.heading')}</h1>
                    <p>{t('session.token_timeout_warning.content')}</p>
                </div>
            </ModalPortal>
        );
    }
    return null;
}
