export function GpsIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12.9333 19.4667L19 5L5 11.5333L11.5333 12.9333L12.9333 19.4667Z'
                    stroke='#E30031'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
}
