import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HealthDeclaration} from '../../../../../services/api/types/types';
import {Button} from '../../../../form/button/Button';
import {getLocaleWithRegionCode} from '../../../../../services/i18n/locale';
import './HealthDeclAnswersModal.scss';

export interface HealthDeclarationAnswers {
    questionId: number;
    answer: 'yes' | 'no' | null;
}

interface HealthDeclarationAnswersModalProps {
    healthDeclaration: HealthDeclaration;
    existingAnswers: Array<HealthDeclarationAnswers>;
    onCloseClick(): void;
    name: string;
}

export function HealthDeclarationAnswersModal({
    healthDeclaration,
    existingAnswers,
    onCloseClick,
    name
}: HealthDeclarationAnswersModalProps) {
    const {t} = useTranslation();
    const [qna, setQna] = useState<Array<{text: string | undefined; answer: string | null}>>([]);
    const localeWithRegion = getLocaleWithRegionCode();

    useEffect(() => {
        const answers = existingAnswers.map((answer: HealthDeclarationAnswers) => {
            const question = healthDeclaration.questions.find((question) => question.id === answer.questionId);
            const text = question?.text.find(({locale}) => localeWithRegion === locale)?.text;
            return {
                text: text,
                answer: answer.answer === 'yes' ? t('button_yes.text') : t('button_no.text')
            };
        });

        setQna(answers);
    }, [existingAnswers, healthDeclaration.questions]);
    return (
        <>
            <h1 className='health-declaration__header'>
                {t('healthdeclaration.header')} <br />
                <span className='health-declaration__header-person'>{name}</span>
            </h1>

            {qna.map((question, index) => {
                return (
                    <div key={`${index}-${question.text}`}>
                        <div className='health-declaration__question'>
                            <p id={`q-label-${question.text}`}>{question.text}</p>
                            <div className='health-declaration__answer' aria-labelledby={`q-label-${index}`}>
                                {question.answer}
                            </div>
                        </div>
                    </div>
                );
            })}

            <div className='health-declaration__question-footer'>
                <Button onClick={() => onCloseClick()} className='btn--big btn--wide'>
                    <span>{t('button_finished.text')}</span>
                </Button>
            </div>
        </>
    );
}
