import {useTranslation} from 'react-i18next';
import {useUiContext} from '../../../context/UiContext';

interface SearchFooterProps {
    paramsCount: number;
    clearParams(): void;
    toggleSearchView(): void;
}

export function SearchFooter({paramsCount, clearParams, toggleSearchView}: SearchFooterProps) {
    const {t} = useTranslation();
    const {state} = useUiContext();
    const {searchExtended} = state;

    return (
        <div className='search_modal__footer__content'>
            <a
                href='/'
                tabIndex={searchExtended ? 0 : -1}
                onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                        e.preventDefault();
                        clearParams();
                    }
                }}
                role='button'
                onClick={(e) => {
                    e.preventDefault();
                    clearParams();
                }}
            >
                {t('clear_filters.text')} ({paramsCount})
            </a>
        </div>
    );
}
