import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Toggle} from '../../form/toggle/Toggle';
import {CountyFilterObject, PlacesFilterObject} from '../../../services/api/types/types';

interface CountyFiltersProps {
    countyFilters: Array<CountyFilterObject>;
    activeFilters: Array<string>;
    onToggleFilter(id: string, place: PlacesFilterObject, checked: boolean): void;
    disableTabNavigation?: boolean;
}

export function CountyFilters({
    countyFilters,
    activeFilters,
    disableTabNavigation = false,
    onToggleFilter
}: CountyFiltersProps) {
    const shouldShowAllFilters = () => {
        const highestActiveIndex = Math.max(
            ...activeFilters.map((activeFilterId) => {
                return countyFilters.findIndex(({id}) => id === activeFilterId);
            })
        );
        return highestActiveIndex > 4;
    };

    const [showAllFilters, setShowAllFilters] = useState(() => {
        return shouldShowAllFilters();
    });

    useEffect(() => {
        if (shouldShowAllFilters()) {
            setShowAllFilters(true);
        }
    }, [activeFilters]);

    const {t} = useTranslation();

    return (
        <div className='checkbox_group'>
            {countyFilters.slice(0, showAllFilters ? undefined : 5).map((v) => {
                return (
                    <Toggle
                        onClick={(checked) => {
                            onToggleFilter(v.id, v, checked);
                        }}
                        checked={activeFilters.includes(v.id)}
                        tabIndex={disableTabNavigation ? -1 : 0}
                        key={v.id}
                        id={`county-${v.id}`}
                        label={v.name}
                    />
                );
            })}

            <span className='checkbox_group__more'>
                <a
                    tabIndex={disableTabNavigation ? -1 : 0}
                    role='button'
                    href='/'
                    onKeyDown={(e) => {
                        if (e.key === ' ' || e.key === 'Enter') {
                            e.preventDefault();
                            setShowAllFilters(!showAllFilters);
                        }
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        setShowAllFilters(!showAllFilters);
                    }}
                >
                    {showAllFilters ? t('vaccinations_show_fewer.text') : t('vaccinations_show_more.text')}
                </a>
            </span>
        </div>
    );
}
