import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

interface SearchErrorProps {
    primaryAction(): void;
    secondaryAction(): void;
}

export function SearchError({primaryAction, secondaryAction}: SearchErrorProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onPrimaryAction={primaryAction} onSecondaryAction={secondaryAction}>
            <p>
                <b>{t('error_search_title.text')}</b>
                <br />
                {t('error_search_message.text')}
            </p>
        </ModalPortal>
    );
}
