import {format} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {BookingStep, useBookingContext} from '../../../../context/BookingContext';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {CertificateIcon} from '../../../icons/CertificateIcon';
import {ConsentIcon} from '../../../icons/ConsentIcon';
import {HealthDeclarationIcon} from '../../../icons/HealthDeclarationIcon';
import {LocationIcon} from '../../../icons/LocationIcon';
import {ReactComponent as IconInfo} from '../../../icons/icon_info.svg';
import {ReactComponent as IconVaccine} from '../../../icons/icon_vaccine.svg';
import {TimeIcon} from '../../../icons/TimeIcon';
import {UserIcon} from '../../../icons/UserIcon';
import {VisitCategory} from '../../../main/components/PickVisitCategory';

export function BookingStepItem({bookingStep}: {bookingStep: BookingStep}) {
    const {t} = useTranslation();

    const {bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {clinic, visitReasons} = dataState;

    const {
        timeSlotState,
        user,
        step,
        steps,
        bookingType,
        selectedPersonsIds,
        reasonState: {appointmentType, reason}
    } = bookingState;

    const isClosedBooking =
        ((bookingType === 'dropIn' || bookingType === 'healthDeclaration') && clinic?.eidDropin) ||
        (bookingType === 'booking' && appointmentType?.eidRequired);
    const sortedSelectedIds = isClosedBooking
        ? selectedPersonsIds?.sort((a, b) => (parseInt(a, 10) || 0) - (parseInt(b, 10) || 0))
        : selectedPersonsIds;

    const selectedNames = sortedSelectedIds.map((id) => {
        if (user?.identity === id) {
            return user.givenName + ' ' + user.familyName;
        }
        const person = user?.relations?.find((p) => p.identity === id);

        return person?.givenName + ' ' + person?.familyName;
    }, []);

    const getReasonLabel = () => {
        const reasonPrefix = reason?.category ? t(`reason_group.${reason.category as VisitCategory}`) : '';
        const visitReasonsJoined = appointmentType?.visitReasonIds
            .map((visitReasonId) => {
                return visitReasons.find(({id}) => parseInt(id, 10) === visitReasonId)?.label;
            })
            .join(', ');

        return `${reasonPrefix} ${visitReasonsJoined}`;
    };

    const userText = () => {
        if (selectedNames && selectedNames.length > 0) {
            return selectedNames.join(', ');
        } else if (Boolean(appointmentType?.eidRequired) === false) {
            return t('stepindicator.peopleSelect_open_booking');
        }

        return t('stepindicator.peopleSelect_count', {
            count: bookingState.reasonState.numberPatients || 0
        });
    };

    const currStepIndex = steps.indexOf(bookingStep);
    const setStepClasses = (bookingStep: BookingStep) => {
        const index = steps.indexOf(bookingStep);
        if (bookingStep === step) {
            return ' current-step';
        }
        if (index > currStepIndex) {
            return ' future-step';
        }
        return '';
    };

    const getStepIndicatorIcon = (step: string) => {
        switch (step) {
            case 'information':
                return <IconInfo aria-hidden />;
            case 'timeSelect':
                return <TimeIcon />;
            case 'peopleSelect':
                return <UserIcon ariaHidden />;
            case 'confirm':
                return <CertificateIcon />;
            case 'healthDeclaration':
                return <HealthDeclarationIcon />;
        }
    };

    if (!steps.includes(bookingStep) || bookingStep === 'completed') {
        return null;
    }

    if (bookingStep === 'initial') {
        return (
            <>
                <li className='stepindicator__list__item'>
                    <LocationIcon />
                    {clinic?.name}
                </li>
                {bookingType === 'booking' && (
                    <li className='stepindicator__list__item'>
                        <IconVaccine aria-hidden />
                        {getReasonLabel()}
                        <br />
                        {t('stepindicator.people_count', {count: bookingState.reasonState.numberPatients || 0})}
                    </li>
                )}
            </>
        );
    }
    if (bookingStep === 'timeSelect') {
        return (
            <li className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                <TimeIcon />
                {timeSlotState.selectedSlot
                    ? format(timeSlotState.selectedSlot, 'ccc dd MMM, HH:mm').replace('.', '')
                    : t('stepindicator.timeSelect')}
            </li>
        );
    }
    if (bookingStep === 'peopleSelect') {
        return (
            <li className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                <UserIcon ariaHidden />
                {userText()}
            </li>
        );
    }
    if (bookingStep === 'consent') {
        return (
            <li className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                <ConsentIcon />
                {t('stepindicator.consent')}
            </li>
        );
    }
    return (
        <li className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
            {getStepIndicatorIcon(bookingStep)} {t(`stepindicator.${bookingStep}`)}
        </li>
    );
}
