import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

interface NetworkErrorProps {
    primaryAction?(): void;
    secondaryAction?(): void;
}

export function NetworkError({primaryAction, secondaryAction}: NetworkErrorProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onPrimaryAction={primaryAction} onSecondaryAction={secondaryAction}>
            <p>
                <b>{t('error_network_title.text')}</b>
                <br />
                {t('error_network_message.text')}
            </p>
        </ModalPortal>
    );
}
