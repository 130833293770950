export interface OpenUser {
    uniqueKey: string;
    identity: string;
    identityValid: boolean;
    age: number | undefined;
    givenName: string;
    gender: 'male' | 'female';
    familyName: string;
}

export function generateOpenUserKey() {
    const timestamp = (+new Date()).toString();
    return `user-${timestamp.substring(timestamp.length - 4)}${Math.floor(Math.random() * 10000)}`;
}

export function makeNewOpenUser(): OpenUser {
    return {
        uniqueKey: generateOpenUserKey(),
        identity: '',
        identityValid: false,
        age: undefined,
        givenName: '',
        familyName: '',
        gender: 'female'
    };
}
