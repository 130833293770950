import {createContext, useContext, useEffect, useReducer} from 'react';
import {HealthDeclarationAnswers} from '../components/clinic/booking/booking-health-declaration/HealthDeclarationModal';
import {BookingType} from '../components/clinic/booking/booking-initial/BookingInitial';
import {AppointmentType, ReasonFilterObject, AllowedAge, ConsentRequest} from '../services/api/types/types';
import {useStateRestore} from 'src/hooks/useStateRestore';

export enum BookingActions {
    SET_LOADING = 'SET_LOADING',
    SET_BOOKING_TYPE = 'SET_BOOKING_TYPE',
    SET_BOOKING_STEP = 'SET_BOOKING_STEP',
    RESTORE_BOOKING_STATE = 'RESTORE_BOOKING_STATE',
    BOOKING_HEALTH_DECLARATION_MODE = 'BOOKING_HEALTH_DECLARATION_MODE',

    SET_CONSENT_STEP = 'SET_CONSENT_STEP',
    SET_CONSENT_REQUESTS = 'SET_CONSENT_REQUESTS',
    USER_CONSENT_NEEDED = 'USER_CONSENT_NEEDED',
    USER_CONSENT_GIVEN = 'USER_CONSENT_GIVEN',

    SET_REASON_DATA = 'SET_REASON_DATA',
    SET_TIMESLOT_DATA = 'SET_TIMESLOT_DATA',
    SET_TIMESLOT_LOADING = 'SET_TIMESLOT_LOADING',
    SET_BOOKING_ID = 'SET_BOOKING_ID',
    CLEAR_BOOKING_ID = 'CLEAR_BOOKING_ID',
    NEXT_STEP = 'NEXT_STEP',
    PREVIOUS_STEP = 'PREVIOUS_STEP',
    RESET_BOOKING = 'RESET_BOOKING',
    SET_USER = 'SET_USER',
    SET_USER_RELATIONS = 'SET_USER_RELATIONS',
    SET_SELECTED_IDS = 'SET_SELECTED_IDS',
    SET_SHOW_PEOPLES_LIST = 'SET_SHOW_PEOPLES_LIST',
    SET_HEALTH_DECLARATION_ANSWERS = 'SET_HEALTH_DECLARATION_ANSWERS',
    SET_TRIP_DESTINATIONS = 'SET_TRIP_DESTINATIONS',
    SET_TRIP_DEPARTURE_DATE = 'SET_TRIP_DEPARTURE_DATE',
    CLEAR_TRIP_INFORMATION = 'CLEAR_TRIP_INFORMATION',
    REMOVE_HEALTH_DECLARATION = 'REMOVE_HEALTH_DECLARATION',
    RESET_BOOKING_ID = 'RESET_BOOKING_ID',
    SET_BOOKING_CODE = 'SET_BOOKING_CODE',
    SET_CONTACT_DETAILS = 'SET_CONTACT_DETAILS'
}

interface ReasonState {
    reason: ReasonFilterObject | null;
    numberPatients: number | null;
    appointmentType: AppointmentType | null;
    healthDeclarationDefinitionId: number | null;
    allowedAge: AllowedAge | null;
}

interface TimeSlotState {
    lockLoading: boolean;
    lockError: Error | null;
    selectedSlot?: Date | null;
    lockedSlot?: Date | null;
}

export interface User {
    identity: string;
    age?: number;
    gender?: 'male' | 'female';
    givenName: string;
    familyName: string;
    email?: string;
    mobile?: string;
    relations?: Array<{
        relationType: string;
        identity: string;
        age?: number;
        gender: 'male' | 'female';
        givenName: string;
        familyName: string;
        email?: string;
        mobile?: string;
    }>;
    otherGuardians?: Array<{children: Array<string>}> | null;
}

export interface PersonAnswers {
    healthDeclarationId: string | null;
    answers: {
        [identity: string]: HealthDeclarationAnswers;
    };
}

export type Destinations = Array<Destination>;

export interface Destination {
    countryId: number | null;
    duration: number | null;
}
export interface ContactDetails {
    email: string | null;
    mobile: string | null;
}

export type BookingStep =
    | 'initial'
    | 'information'
    | 'timeSelect'
    | 'peopleSelect'
    | 'consent'
    | 'healthDeclaration'
    | 'confirm'
    | 'completed';
type bookingSteps = Array<BookingStep>;
type Action = {
    type: BookingActions;
    value?:
        | boolean
        | string
        | string[]
        | number
        | PersonAnswers
        | Date
        | Destinations
        | BookingType
        | BookingStep
        | ReasonState
        | ContactDetails
        | User
        | Partial<TimeSlotState>
        | ConsentRequest[]
        | BookingContextState;
};
export type BookingContextDispatch = (action: Action) => void;

export interface BookingContextStateWithoutDates {
    step: BookingStep;
    bookingType: BookingType;
    bookingId: string | null;
    bookingCode: string | null;
    reasonState: ReasonState;
    selectedPersonsIds: string[];
    user: User | null;
    steps: bookingSteps;
    healthDeclarationAnswers: PersonAnswers;
    trip?: Destinations;
    contactDetails?: ContactDetails | null;
    consentRequests?: Array<ConsentRequest>;
    userConsentNeeded?: boolean;
    userConsentGiven?: boolean;
}

export interface BookingContextState extends BookingContextStateWithoutDates {
    timeSlotState: TimeSlotState;
    tripDepartureDate?: Date | null;
}
type BookingProviderProps = {children: React.ReactNode};

const nextStep = (state: BookingContextState): BookingStep => {
    const {step, steps} = state;
    const currStepIndex = steps.indexOf(step);
    const nextStep = steps[currStepIndex + 1];

    return nextStep || null;
};
const previousStep = (state: BookingContextState): BookingStep => {
    const {step, steps} = state;
    const currStepIndex = steps.indexOf(step);
    const previousStep = steps[currStepIndex - 1];

    return previousStep || null;
};

const getSteps = (state: BookingContextState, allSteps: Array<BookingStep>) => {
    const {bookingType, reasonState} = state;

    if (bookingType === 'dropIn' || bookingType === 'healthDeclaration') {
        return allSteps.filter((step) => {
            if (step === 'timeSelect' || step === 'information') {
                return false;
            }
            if (bookingType === 'healthDeclaration' && step === 'initial') {
                return false;
            }
            return true;
        });
    } else {
        return allSteps.filter((step) => {
            if (step === 'information') {
                return (
                    reasonState.appointmentType?.information[0]?.text ||
                    reasonState.appointmentType?.information[0]?.url
                );
            }
            if (step === 'healthDeclaration') {
                return reasonState.appointmentType?.healthDeclarationRequired;
            }
            return true;
        });
    }
};

const BookingContext = createContext<{bookingState: BookingContextState; dispatch: BookingContextDispatch} | undefined>(
    undefined
);
function BookingReducer(state: BookingContextState, action: Action): BookingContextState {
    switch (action.type) {
        case BookingActions.RESTORE_BOOKING_STATE: {
            return action.value as BookingContextState;
        }
        case BookingActions.SET_BOOKING_STEP: {
            return {...state, step: action.value as BookingStep};
        }
        case BookingActions.NEXT_STEP: {
            return {...state, step: nextStep(state)};
        }
        case BookingActions.PREVIOUS_STEP: {
            return {...state, step: previousStep(state)};
        }
        case BookingActions.SET_BOOKING_TYPE: {
            const newState = {...state, bookingType: action.value as BookingType};
            const newSteps = getSteps(newState, allSteps);

            return {...newState, steps: newSteps};
        }
        case BookingActions.SET_CONSENT_STEP: {
            const hasConsentAge = state.reasonState.appointmentType?.consentAge ? true : false;
            let newSteps = [...state.steps];
            if (action.value && hasConsentAge && !state.steps.includes('consent')) {
                const i = newSteps.indexOf('peopleSelect');
                newSteps.splice(i + 1, 0, 'consent');
            }
            if (!action.value && hasConsentAge && state.steps.includes('consent')) {
                newSteps = newSteps.filter((step) => step !== 'consent');
            }
            return {...state, steps: newSteps};
        }
        case BookingActions.SET_CONSENT_REQUESTS: {
            return {...state, consentRequests: action.value as Array<ConsentRequest>};
        }
        case BookingActions.USER_CONSENT_NEEDED: {
            return {...state, userConsentNeeded: action.value as boolean};
        }
        case BookingActions.USER_CONSENT_GIVEN: {
            return {...state, userConsentGiven: action.value as boolean};
        }
        case BookingActions.SET_REASON_DATA: {
            const newState = {...state, reasonState: action.value as ReasonState};
            const newSteps = getSteps(newState, allSteps);

            return {...newState, steps: newSteps};
        }
        case BookingActions.SET_TIMESLOT_DATA: {
            return {...state, timeSlotState: {...state.timeSlotState, ...(action.value as TimeSlotState)}};
        }
        case BookingActions.SET_TIMESLOT_LOADING: {
            return {...state, timeSlotState: {...state.timeSlotState, lockLoading: action.value as boolean}};
        }
        case BookingActions.SET_BOOKING_ID: {
            return {...state, bookingId: action.value as string};
        }
        case BookingActions.SET_USER: {
            return {...state, user: action.value as User};
        }
        case BookingActions.SET_CONTACT_DETAILS: {
            return {...state, contactDetails: action.value as ContactDetails};
        }
        case BookingActions.SET_SELECTED_IDS: {
            return {...state, selectedPersonsIds: action.value as string[]};
        }
        case BookingActions.CLEAR_BOOKING_ID: {
            return {...state, bookingId: null};
        }
        case BookingActions.SET_BOOKING_CODE: {
            return {...state, bookingCode: action.value as string};
        }
        case BookingActions.SET_HEALTH_DECLARATION_ANSWERS: {
            return {...state, healthDeclarationAnswers: action.value as PersonAnswers};
        }
        case BookingActions.REMOVE_HEALTH_DECLARATION: {
            const newDeclarations = state.healthDeclarationAnswers;
            delete newDeclarations.answers[action.value as string];
            return {...state, healthDeclarationAnswers: newDeclarations};
        }
        case BookingActions.SET_TRIP_DEPARTURE_DATE: {
            return {...state, tripDepartureDate: action.value as Date};
        }
        case BookingActions.SET_TRIP_DESTINATIONS: {
            return {...state, trip: action.value as Destinations};
        }
        case BookingActions.CLEAR_TRIP_INFORMATION: {
            return {...state, tripDepartureDate: null, trip: undefined};
        }
        case BookingActions.BOOKING_HEALTH_DECLARATION_MODE: {
            const newState = {
                ...state,
                step: 'peopleSelect' as BookingStep,
                bookingType: 'healthDeclaration' as BookingType
            };
            const newSteps = getSteps(newState, allSteps);

            return {...newState, steps: newSteps};
        }
        case BookingActions.RESET_BOOKING_ID: {
            return {...state, bookingId: null};
        }
        case BookingActions.RESET_BOOKING: {
            sessionStorage.removeItem('bookingState');
            return {
                step: 'initial',
                bookingType: 'booking',
                bookingId: state.bookingId || null,
                bookingCode: null,
                reasonState: {
                    reason: null,
                    numberPatients: null,
                    appointmentType: null,
                    healthDeclarationDefinitionId: null,
                    allowedAge: null
                },
                timeSlotState: {
                    lockLoading: false,
                    lockError: null,
                    selectedSlot: null,
                    lockedSlot: null
                },
                user: null,
                selectedPersonsIds: [],
                steps: allSteps,
                healthDeclarationAnswers: {
                    healthDeclarationId: null,
                    answers: {}
                },
                tripDepartureDate: null,
                trip: undefined
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const allSteps: Array<BookingStep> = [
    'initial',
    'information',
    'timeSelect',
    'peopleSelect',
    'healthDeclaration',
    'confirm',
    'completed'
];

function BookingProvider({children}: BookingProviderProps) {
    const [bookingState, dispatch] = useReducer(BookingReducer, {
        step: 'initial',
        bookingType: 'booking',
        bookingId: null,
        bookingCode: null,
        reasonState: {
            reason: null,
            numberPatients: null,
            appointmentType: null,
            healthDeclarationDefinitionId: null,
            allowedAge: null
        },
        timeSlotState: {
            lockLoading: false,
            lockError: null,
            selectedSlot: null,
            lockedSlot: null
        },
        user: null,
        selectedPersonsIds: [],
        steps: allSteps,
        healthDeclarationAnswers: {
            healthDeclarationId: null,
            answers: {}
        },
        tripDepartureDate: null,
        trip: undefined
    });

    const {getBookingStateFromCache, updateBookingStateCache} = useStateRestore();
    // Restore booking state from cache on mount
    useEffect(() => {
        const cachedBookingState = getBookingStateFromCache();
        // If the stored booking state is not null and the step is not initial, restore the booking state
        if (cachedBookingState && cachedBookingState.step !== 'initial') {
            // If the stored booking state has an old date, reset the booking state
            if (
                (cachedBookingState.tripDepartureDate && cachedBookingState.tripDepartureDate < new Date()) ||
                (cachedBookingState.timeSlotState.selectedSlot &&
                    cachedBookingState.timeSlotState.selectedSlot < new Date())
            ) {
                dispatch({type: BookingActions.RESET_BOOKING});
            }
            dispatch({type: BookingActions.RESTORE_BOOKING_STATE, value: cachedBookingState});
        }
        if (cachedBookingState && cachedBookingState.step === 'completed') {
            dispatch({type: BookingActions.RESET_BOOKING});
        }
    }, [getBookingStateFromCache]);
    // Update booking state cache on state change
    useEffect(() => {
        if (bookingState.step !== 'initial') {
            updateBookingStateCache(bookingState);
        }
    }, [updateBookingStateCache, bookingState]);

    const value = {bookingState, dispatch};
    return <BookingContext.Provider value={value}>{children}</BookingContext.Provider>;
}

function useBookingContext() {
    const context = useContext(BookingContext);
    if (context === undefined) {
        throw new Error('useBookingContext must be used within a BookingProvider');
    }

    return context;
}

export {BookingProvider, useBookingContext};
