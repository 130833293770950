import {
    startOfWeek as fnsStartOfWeek,
    endOfWeek as fnsEndOfWeek,
    getWeek as fnsGetWeek,
    eachDayOfInterval
    /* eslint-disable-next-line import/no-duplicates */
} from 'date-fns';
/* eslint-disable-next-line import/no-duplicates */
import {enGB as en, sv} from 'date-fns/locale';

import {getLocale} from '../../services/i18n/locale';

const locales = {en, sv};

export function getWeek(date: Date) {
    return fnsGetWeek(date, {
        locale: locales[getLocale()]
    });
}

export function startOfWeek(date: Date) {
    return fnsStartOfWeek(date, {
        locale: locales[getLocale()]
    });
}

export function endOfWeek(date: Date) {
    return fnsEndOfWeek(date, {
        locale: locales[getLocale()]
    });
}

export function weekInterval(day: Date) {
    const weekStart = startOfWeek(day);
    const weekEnd = endOfWeek(day);

    return eachDayOfInterval({
        start: weekStart,
        end: weekEnd
    });
}
