import {useState, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import {
    caregiverCookiePrefix,
    defaultCookieOptions,
    getCaregiverCookieKey
} from '../../../hooks/useCaregiverConsentCookie';
import {fetchCaregivers, getCaregiverCookiePolicyLink} from '../../../services/api/api';
import {CaregiverObject} from '../../../services/api/types/types';
import {Button} from '../../form/button/Button';
import {Checkbox} from '../../form/checkbox/Checkbox';
import {Spinner} from '../../layout/loader/Spinner';
import ModalPortal from '../../layout/modal/ModalPortal';

import './HandleCookiesModal.scss';

interface HandleCookiesModalProps {
    handleCloseModal(): void;
}

type LoadStatus = 'loading' | 'done' | 'error';

export function HandleCookiesModal({handleCloseModal}: HandleCookiesModalProps) {
    const {t} = useTranslation();

    const [caregiversWithCookies, setCaregiversWithCookies] = useState<Array<CaregiverObject>>([]);
    const [caregiverCookies, setCaregiverCookies] = useState<Record<string, string | false>>({});
    const [caregiversStatus, setCaregiversStatus] = useState<LoadStatus>('loading');
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        if (caregiversStatus !== 'loading') {
            return;
        }

        const loadCaregivers = async () => {
            try {
                const allCaregivers = await fetchCaregivers();

                const caregiverConsentIds = Object.keys(cookies)
                    .filter((val) => val.startsWith(caregiverCookiePrefix))
                    .map((val) => {
                        return val.split('.').pop();
                    });

                const caregivers = allCaregivers.filter(({id}) => caregiverConsentIds.includes(id.toString()));
                setCaregiversWithCookies(caregivers);

                const caregiversObject = Object.fromEntries(
                    caregivers.map(({id}) => {
                        const cookieName = getCaregiverCookieKey(id);
                        return [id, cookies[cookieName]];
                    })
                );

                setCaregiverCookies(caregiversObject);
                setCaregiversStatus('done');
            } catch (error) {
                setCaregiversStatus('error');
            }
        };

        loadCaregivers();
    }, [cookies, caregiversStatus]);

    return (
        <ModalPortal onCloseClick={() => handleCloseModal()} size='sm'>
            <section className='handle-cookies'>
                <h2>{t('cookies.handle_modal.heading')}</h2>

                <div className='handle-cookies__header'>
                    <p>{t('cookies.handle_modal.heading_information')}</p>
                </div>

                <div className='handle-cookies__information'>
                    <div className='handle-cookies__information-header'>
                        <h3>{t('cookies.handle_modal.necessary_cookies')}</h3>
                        <span>{t('cookies.handle_modal.always_active')}</span>
                    </div>

                    <p>{t('cookies.handle_modal.necessary_cookies_information')}</p>
                </div>

                <div className='handle-cookies__information'>
                    <div className='handle-cookies__information-header'>
                        <h3>{t('cookies.handle_modal.tracking_cookies_heading')}</h3>
                    </div>

                    <p>{t('cookies.handle_modal.tracking_cookies_information')}</p>
                    {caregiversStatus === 'loading' && (
                        <div className='handle-cookies__load-status handle-cookies__load-error'>
                            <span className='handle-cookies__spinner'>
                                <Spinner />
                            </span>
                        </div>
                    )}

                    {caregiversStatus === 'error' && (
                        <p className='handle-cookies__load-status handle-cookies__load-error'>
                            {t('cookies.handle_modal.cookie_policy_load_error')}
                            <br />
                            <Button onClick={() => setCaregiversStatus('loading')} variant='text'>
                                <span>{t('button_try_again.text')}</span>
                            </Button>
                        </p>
                    )}

                    {caregiversStatus === 'done' && caregiversWithCookies.length === 0 && (
                        <ul className='handle-cookies__cookies-list'>
                            <li>{t('cookies.handle_modal.no_cookies_text')}</li>
                        </ul>
                    )}

                    {caregiversStatus === 'done' && caregiversWithCookies.length > 0 && (
                        <ul className='handle-cookies__cookies-list'>
                            {caregiversWithCookies.map((caregiver) => {
                                const caregiverPdfLink = getCaregiverCookiePolicyLink(caregiver.id);

                                return (
                                    <li key={caregiver.id} className='handle-cookies__cookies-list-item'>
                                        <span>
                                            {caregiver.label} -{' '}
                                            <a target='_blank' rel='noreferrer' href={caregiverPdfLink}>
                                                {t('cookies.handle_modal.read_cookie_policy')}
                                            </a>
                                        </span>
                                        <Checkbox
                                            id={`caregiver-check-${caregiver.id}`}
                                            ariaLabel={t('cookies.handle_modal.aria_caregiver_cookie_consent', {
                                                caregiverName: caregiver.label
                                            })}
                                            checked={
                                                `${caregiverCookies[caregiver.id]}` === caregiver.cookiePolicyVersion
                                            }
                                            onClick={(checked: boolean) => {
                                                const cookieValue = checked ? caregiver.cookiePolicyVersion : false;
                                                setCaregiverCookies({
                                                    ...caregiverCookies,
                                                    [caregiver.id]: cookieValue
                                                });
                                            }}
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                    {caregiversWithCookies.length > 1 && (
                        <div className='handle-cookies__information-footer'>
                            <Button
                                variant='text'
                                onClick={() => {
                                    const clearedCookieEntries = Object.entries(caregiverCookies).map(
                                        ([caregiverId, _]) => [caregiverId, false]
                                    );

                                    setCaregiverCookies(Object.fromEntries(clearedCookieEntries));
                                }}
                                className='handle-cookies__deselect-all-button'
                            >
                                {t('cookies.handle_modal.deselect_all')}
                            </Button>
                        </div>
                    )}
                </div>

                <div className='handle-cookies__footer'>
                    <Button
                        big
                        disabled={caregiversWithCookies.length === 0}
                        className='btn--wide'
                        onClick={() => {
                            Object.entries(caregiverCookies).forEach(([caregiverId, cookieValue]) => {
                                const cookieKey = getCaregiverCookieKey(caregiverId);
                                setCookie(cookieKey, cookieValue, defaultCookieOptions);
                            });

                            handleCloseModal();
                        }}
                    >
                        {t('cookies.handle_modal.confirm_choices')}
                    </Button>
                </div>
            </section>
        </ModalPortal>
    );
}
