/* eslint-disable-next-line import/no-duplicates */
import {startOfDay} from 'date-fns';
/* eslint-disable-next-line import/no-duplicates */
import {enGB as en, sv} from 'date-fns/locale';

import {forwardRef, useMemo} from 'react';
import DatePicker from 'react-datepicker';
import {shift, autoPlacement, offset} from '@floating-ui/dom';
import {useTranslation} from 'react-i18next';
import {format} from 'src/lib/dates/format';
import {getLocale} from 'src/services/i18n/locale';
import {Button} from '../button/Button';
import {Select} from '../select/Select';
import {ReactComponent as IconCalendar} from 'src/components/icons/icon_calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DateSelect.scss';

interface DateSelectProps {
    id?: string | number;
    selectedDate?: Date | null;
    useNative: boolean;
    min?: Date;
    max?: Date;
    placeholder?: string;
    label: string;
    isValid?: boolean;
    errorMessage?: string;
    onChange(date: Date | null): void;
}

const LOCALES = {en, sv};
const DATE_FORMAT_CUSTOM = 'yyyy/MM/dd';
const DATE_FORMAT_NATIVE = 'yyyy-MM-dd';
const DATE_MINIMUM_YEAR = 1900;
const DATE_MAXIMUM_YEAR = new Date().getFullYear() + 50;

export function DateSelect({
    id,
    selectedDate = null,
    useNative,
    min,
    max,
    placeholder,
    label,
    isValid,
    errorMessage,
    onChange
}: DateSelectProps) {
    const {t} = useTranslation();
    const locale = getLocale();

    const minDate = useMemo(() => min || new Date(DATE_MINIMUM_YEAR, 0, 1), [min]);
    const maxDate = useMemo(() => max || new Date(DATE_MAXIMUM_YEAR, 11, 31), [max]);

    const years: string[] = useMemo(() => {
        const result = [];
        for (let i = minDate.getFullYear(); i <= maxDate.getFullYear(); i++) {
            result.push(i.toString());
        }
        return result;
    }, [minDate, maxDate]);

    const monthsLong: string[] = useMemo(
        () => [
            t('datePicker.months.january'),
            t('datePicker.months.february'),
            t('datePicker.months.march'),
            t('datePicker.months.april'),
            t('datePicker.months.may'),
            t('datePicker.months.june'),
            t('datePicker.months.july'),
            t('datePicker.months.august'),
            t('datePicker.months.september'),
            t('datePicker.months.october'),
            t('datePicker.months.november'),
            t('datePicker.months.december')
        ],
        [t]
    );

    const monthsShort: string[] = useMemo(() => [...monthsLong.map((month) => month.slice(0, 3))], [monthsLong]);

    if (useNative) {
        return (
            <div className='date-select-container'>
                <label className={`date-select__native${isValid === false ? ' invalid' : ''}`}>
                    <div className='date-select__native__left-column'>
                        <span className='date-select__native__label'>{label}</span>
                        <input
                            id={`${id}`}
                            aria-label={label}
                            className='date-select__native__input'
                            required
                            type='date'
                            value={selectedDate ? format(selectedDate, DATE_FORMAT_NATIVE) : ''}
                            placeholder={placeholder}
                            min={format(minDate, DATE_FORMAT_NATIVE)}
                            max={format(maxDate, DATE_FORMAT_NATIVE)}
                            onChange={(e) => {
                                if (e.target.value) {
                                    const date = startOfDay(new Date(e.target.value));
                                    if (date < minDate) {
                                        return;
                                    }

                                    if (date > maxDate) {
                                        return;
                                    }

                                    onChange(date);
                                } else {
                                    onChange(selectedDate);
                                }
                            }}
                        />
                    </div>
                    <div className='calendar-icon-container'>
                        <IconCalendar className='calendar-icon' aria-hidden />
                    </div>
                </label>
                {isValid === false && (
                    <span className='date-select-container__error-label' aria-live='assertive'>
                        {errorMessage}
                    </span>
                )}
            </div>
        );
    }

    const DateInputControl = forwardRef<HTMLButtonElement, any>(
        ({value, onClick}: {value: string; onClick(): void}, ref) => {
            return (
                <div className='date-select__container'>
                    <button
                        className={`date-select__button${selectedDate ? ' date-select__button--has-value' : ''}${
                            isValid === false ? ' invalid' : ''
                        }`}
                        aria-label={`${selectedDate ? label : placeholder} ${
                            selectedDate ? format(selectedDate, DATE_FORMAT_CUSTOM) : ''
                        }`}
                        onClick={onClick}
                        ref={ref}
                    >
                        <span className='date-select__button__content' aria-hidden='true'>
                            <span aria-hidden='true' className='date-select__button__content__label'>
                                {selectedDate ? label : placeholder}
                            </span>
                            <span aria-hidden='true'>{selectedDate && format(selectedDate, DATE_FORMAT_CUSTOM)}</span>
                        </span>
                        <IconCalendar className='calendar-icon' aria-hidden='true' />
                    </button>

                    {isValid === false && (
                        <span className='date-select-container__error-label' aria-live='assertive'>
                            {errorMessage}
                        </span>
                    )}
                </div>
            );
        }
    );

    const getCustomHeader = ({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        changeYear,
        changeMonth
    }: {
        date: Date;
        decreaseMonth: () => void;
        increaseMonth: () => void;
        prevMonthButtonDisabled: boolean;
        nextMonthButtonDisabled: boolean;
        changeYear: (year: number) => void;
        changeMonth: (month: number) => void;
    }) => {
        return (
            <div className='date-select__header'>
                {!prevMonthButtonDisabled && (
                    <Button
                        className='react-datepicker__navigation react-datepicker__navigation--previous'
                        aria-label={t('datePicker.previousMonthAriaLabel')}
                        onClick={decreaseMonth}
                    >
                        <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'>
                            {t('datePicker.previousMonthAriaLabel')}
                        </span>
                    </Button>
                )}

                <Select
                    value={monthsShort[date.getMonth()]}
                    valueAriaLabel={monthsLong[date.getMonth()]}
                    ariaLabel={t('datePicker.selectMonthAriaLabel')}
                    options={monthsShort}
                    variant='small'
                    onChange={(value) => changeMonth(monthsShort.indexOf(value))}
                />

                <Select
                    value={date.getFullYear().toString()}
                    options={years}
                    ariaLabel={t('datePicker.selectYearAriaLabel')}
                    variant='small'
                    onChange={(value) => changeYear(+value)}
                />

                {!nextMonthButtonDisabled && (
                    <Button
                        className='react-datepicker__navigation react-datepicker__navigation--next'
                        aria-label={t('datePicker.nextMonthAriaLabel')}
                        onClick={increaseMonth}
                    >
                        <span className='react-datepicker__navigation-icon react-datepicker__navigation-icon--next'>
                            {t('datePicker.nextMonthAriaLabel')}
                        </span>
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div
            id={`${id}`}
            data-testid='custom-date-select'
            className={`date-select${isValid === false ? ' invalid' : ''}`}
        >
            <DatePicker
                showPopperArrow={false}
                locale={LOCALES[locale]}
                maxDate={maxDate}
                minDate={minDate}
                selected={selectedDate}
                onChange={onChange}
                chooseDayAriaLabelPrefix={t('datePicker.chooseDayAriaLabelPrefix')}
                disabledDayAriaLabelPrefix={t('datePicker.disabledDayAriaLabelPrefix')}
                monthAriaLabelPrefix={t('datePicker.monthAriaLabelPrefix')}
                customInput={<DateInputControl />}
                renderCustomHeader={getCustomHeader}
                popperModifiers={[
                    autoPlacement({
                        alignment: 'start',
                        autoAlignment: false
                    }),
                    shift({crossAxis: true})
                ]}
            />
        </div>
    );
}
