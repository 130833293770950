import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Outlet} from 'react-router-dom';
import {useCssWorkarounds} from 'src/hooks/useCssWorkarounds';

export function AboutRoot() {
    const {t} = useTranslation();
    useCssWorkarounds();

    useEffect(() => {
        document.title = t('route_name.about');
        return () => {
            document.title = t('route_name.home');
        };
    }, [t]);

    return <Outlet />;
}
