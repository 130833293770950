import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

interface PositionTimeoutErrorProps {
    onClose(): void;
    retryFunction(): void;
}

export function PositionTimeoutError({onClose, retryFunction}: PositionTimeoutErrorProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onPrimaryAction={retryFunction} onSecondaryAction={onClose}>
            <p>{t('error_position_timeout_message.text')}</p>
        </ModalPortal>
    );
}
