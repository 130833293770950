import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../context/BookingContext';
import {HealthDeclaration, HealthDeclarationQuestion} from '../../../../services/api/types/types';
import {getLocaleWithRegionCode} from '../../../../services/i18n/locale';
import {Button} from '../../../form/button/Button';
import {Radio} from '../../../form/radio/Radio';

export interface HealthDeclarationAnswers {
    [id: number]: boolean | null;
}

interface HealthDeclarationModalProps {
    person: User;
    healthDeclaration: HealthDeclaration;
    existingAnswers?: HealthDeclarationAnswers;
    onChange(dirty: boolean): void;
    onSaveClick(answers: HealthDeclarationAnswers): void;
}

export function HealthDeclarationModal({
    person,
    healthDeclaration,
    existingAnswers = {},
    onChange,
    onSaveClick
}: HealthDeclarationModalProps) {
    const {t} = useTranslation();
    const localeWithRegion = getLocaleWithRegionCode();

    const [formState, setFormState] = useState<HealthDeclarationAnswers>(existingAnswers || {});

    const isChanged =
        JSON.stringify(Object.values(formState).sort()) !== JSON.stringify(Object.values(existingAnswers).sort());
    useEffect(() => {
        onChange(isChanged);
    }, [onChange, isChanged]);

    const isApplicable = ({applicableTo, minAge, maxAge}: HealthDeclarationQuestion) => {
        if (person.age) {
            if (minAge && person.age < minAge) return false;
            if (maxAge && person.age > maxAge) return false;
        }
        if (applicableTo === 'all') {
            return true;
        }
        if (person.gender === 'female' && applicableTo === 'women') {
            return true;
        }
        return person.gender === 'male' && applicableTo === 'men';
    };

    const applicableQuestions = healthDeclaration.questions.filter(isApplicable).map((question) => {
        return {
            ...question,
            followUpQuestions: question.followUpQuestions?.filter(isApplicable)
        };
    });

    const requiredQuestionIds = applicableQuestions.reduce((acc: Array<number>, question) => {
        acc.push(question.id);

        if (question.followUpQuestions && formState[question.id] === true) {
            acc.push(...question.followUpQuestions.map(({id}) => id));
        }
        return acc;
    }, []);

    // Compare required question answers with given answers in formState, check for any null values (not answered)
    const formValid = requiredQuestionIds.map((id) => formState[id] ?? null).includes(null) === false;

    return (
        <>
            <h1 className='health-declaration__header'>
                {t('healthdeclaration.header')} <br />
                <span className='health-declaration__header-person'>
                    {person.givenName} {person.familyName}
                </span>
            </h1>
            <p>{t('healthdeclaration.questions_information_text')}</p>

            {applicableQuestions.map((question) => {
                return (
                    <div key={question.id}>
                        <div className='health-declaration__question'>
                            <p id={`q-label-${question.id}`}>
                                {question.text.find(({locale}) => localeWithRegion === locale)?.text}
                            </p>
                            <div className='radio-group' role='radiogroup' aria-labelledby={`q-label-${question.id}`}>
                                <Radio
                                    checked={formState[question.id] === true}
                                    name={`q-name-${question.id}`}
                                    label={t('button_yes.text')}
                                    onClick={() => {
                                        const newState = {...formState, [question.id]: true};
                                        setFormState(newState);
                                    }}
                                />
                                <Radio
                                    checked={formState[question.id] === false}
                                    name={`q-name-${question.id}`}
                                    label={t('button_no.text')}
                                    onClick={() => {
                                        // Remove any followup questions from state when selecting 'no' on parent
                                        const clearedFollowups = Object.fromEntries(
                                            Object.entries(formState).filter(([key]) => {
                                                return !question.followUpQuestions
                                                    ?.map(({id}) => id)
                                                    .includes(Number(key));
                                            })
                                        );
                                        const newState = {...clearedFollowups, [question.id]: false};
                                        setFormState(newState);
                                    }}
                                />
                            </div>
                        </div>

                        {formState[question.id] === true &&
                            question.followUpQuestions &&
                            question.followUpQuestions.length > 0 &&
                            question.followUpQuestions.map((followupQuestion) => {
                                return (
                                    <div
                                        key={followupQuestion.id}
                                        className='health-declaration__question health-declaration__question--followup'
                                    >
                                        <p id={`qf-label-${followupQuestion.id}`}>
                                            {
                                                followupQuestion.text.find(({locale}) => localeWithRegion === locale)
                                                    ?.text
                                            }
                                        </p>
                                        <div
                                            className='radio-group'
                                            role='radiogroup'
                                            aria-labelledby={`qf-label-${followupQuestion.id}`}
                                        >
                                            <Radio
                                                checked={formState[followupQuestion.id] === true}
                                                name={`qf-name-${followupQuestion.id}`}
                                                label={t('button_yes.text')}
                                                onClick={() =>
                                                    setFormState({...formState, [followupQuestion.id]: true})
                                                }
                                            />
                                            <Radio
                                                checked={formState[followupQuestion.id] === false}
                                                name={`qf-name-${followupQuestion.id}`}
                                                label={t('button_no.text')}
                                                onClick={() =>
                                                    setFormState({...formState, [followupQuestion.id]: false})
                                                }
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                );
            })}

            <div className='health-declaration__question-footer'>
                <Button disabled={!formValid} onClick={() => onSaveClick(formState)} className='btn--big btn--wide'>
                    <span>{t('button_save.text')}</span>
                </Button>
            </div>
        </>
    );
}
