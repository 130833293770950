import {LatLngBounds, Map} from 'leaflet';
import {useEffect} from 'react';
import {useMap} from 'react-leaflet';
import {SWE_BOUNDING_BOX} from '../../../services/geo/constants';

declare class MapWithExtendedArea extends Map {
    setActiveArea(selector: string): void;
}

interface MapActiveAreaProps {
    mapBounds?: LatLngBounds | null;
}

export function MapActiveArea({mapBounds}: MapActiveAreaProps) {
    const map = useMap() as MapWithExtendedArea;

    useEffect(() => {
        map.setActiveArea('map_active_area');
        map.fitBounds(SWE_BOUNDING_BOX);
    }, [map]);

    useEffect(() => {
        if (!mapBounds) {
            return;
        }
        map.flyToBounds(mapBounds, {duration: 0.8, easeLinearity: 0.25, padding: [10, 10]});
    }, [mapBounds, map]);

    return null;
}
