import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {HandleBookingActions, useHandleBookingContext} from '../../../context/HandleBookingContext';
import {SessionActions, useSessionContext} from '../../../context/SessionContext';
import {UiProvider} from '../../../context/UiContext';
import {format} from '../../../lib/dates/format';
import {updateBooking} from '../../../services/api/api';
import {UnauthorizedError} from '../../../services/api/implementations/fetch';
import {Button} from '../../form/button/Button';
import {TimeIcon} from '../../icons/TimeIcon';
import {Spinner} from '../../layout/loader/Spinner';
import {TimeSlotPicker} from '../components/TimeSlotPicker/TimeSlotPicker';

import './ChangeTimeSlot.scss';

interface ChangeTimeSlotProps {
    bookingId: string;
    initialTimeSlot: Date;
    clinicId: number;
    appointmentTypeId: number;
    onBookingUpdated(): void;
}

export function ChangeTimeSlot({
    bookingId,
    initialTimeSlot,
    clinicId,
    appointmentTypeId,
    onBookingUpdated
}: ChangeTimeSlotProps) {
    const {t} = useTranslation();

    const {
        sessionState: {userJwt},
        sessionDispatch
    } = useSessionContext();
    const {dispatch} = useHandleBookingContext();
    const [previousTimeSlot] = useState(initialTimeSlot);
    const [selectedSlot, setSelectedSlot] = useState<Date | null>(initialTimeSlot);

    const [lockLoading, setLockLoading] = useState(false);
    const [reserveError, setReserveError] = useState<Error | null>(null);

    const updateBookingSlot = async () => {
        if (selectedSlot) {
            setLockLoading(true);
            try {
                const updateData = {
                    when: format(selectedSlot, `yyyy-MM-dd'T'HH:mm:ssxxx`)
                };
                await updateBooking(bookingId, clinicId, updateData, userJwt);
                onBookingUpdated();
            } catch (error) {
                if (error instanceof UnauthorizedError) {
                    sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
                }
                setReserveError(error as Error);
            }
            setLockLoading(false);
        }
    };

    return (
        <div className='change-timeslot'>
            <div className='change-timeslot__time-picker'>
                <UiProvider>
                    <TimeSlotPicker
                        appointmentTypeId={appointmentTypeId}
                        clinicId={clinicId}
                        initialSelectedSlot={selectedSlot}
                        onTimeSlotClicked={(timeSlot, selected) => {
                            setSelectedSlot(selected ? null : timeSlot);

                            const changed = selected || +initialTimeSlot !== +timeSlot;
                            dispatch({type: HandleBookingActions.TIMESLOT_CHANGED, value: changed});
                        }}
                        clearLoadError={() => {}}
                        clearReserveError={() => {
                            setReserveError(null);
                            setSelectedSlot(previousTimeSlot);
                        }}
                        reserveError={reserveError}
                        lockLoading={lockLoading}
                    />
                </UiProvider>
            </div>
            <div className='change-timeslot__sidebar'>
                <div className='change-timeslot__sidebar-content'>
                    <h2>{t('handle_booking.selected_time')}</h2>
                    <p className='change-timeslot__sidebar-item'>
                        <TimeIcon />
                        {selectedSlot
                            ? format(selectedSlot, 'ccc dd MMM, HH:mm').replace('.', '')
                            : t('stepindicator.timeSelect')}
                    </p>
                </div>
                <div className='change-timeslot__sidebar-footer'>
                    {lockLoading ? (
                        <div className='timeslot__spinner'>
                            <Spinner />
                        </div>
                    ) : (
                        <Button
                            disabled={
                                lockLoading || selectedSlot === null || +(selectedSlot || 0) === +previousTimeSlot
                            }
                            big
                            className='btn--wide'
                            onClick={() => {
                                updateBookingSlot();
                            }}
                        >
                            <span>{t('button_save.text')}</span>
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
