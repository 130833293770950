export function XIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3.95711 2.89645C4.15237 2.70118 4.46895 2.70118 4.66421 2.89645L8.02306 6.2553L11.3819 2.89645C11.5772 2.70118 11.8938 2.70118 12.089 2.89645L13.1497 3.95711C13.3449 4.15237 13.3449 4.46895 13.1497 4.66421L9.79083 8.02306L13.1495 11.3817C13.3448 11.577 13.3448 11.8936 13.1495 12.0888L12.0888 13.1495C11.8936 13.3448 11.577 13.3448 11.3817 13.1495L8.02306 9.79083L4.6644 13.1495C4.46914 13.3448 4.15255 13.3448 3.95729 13.1495L2.89663 12.0888C2.70137 11.8936 2.70137 11.577 2.89663 11.3817L6.2553 8.02306L2.89645 4.66421C2.70118 4.46895 2.70118 4.15237 2.89645 3.95711L3.95711 2.89645Z'
                    fill='currentColor'
                />
            </svg>
        </>
    );
}
