import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../form/button/Button';
import {PolicyArticle} from './policy-article/PolicyArticle';

import './CookiePolicy.scss';
import {HandleCookiesModal} from '../cookies/HandleCookiesModal/HandleCookiesModal';

export function CookiePolicy() {
    useEffect(() => window.scrollTo(0, 0), []);
    const {t} = useTranslation();
    const [showHandleCookies, setShowHandleCookies] = useState(false);

    useEffect(() => {
        document.title = t('route_name.cookie');
        return () => {
            document.title = t('route_name.home');
        };
    }, [t]);

    return (
        <>
            <PolicyArticle
                heading={t('policy.cookie_policy_heading')}
                article={t('policy.cookie_policy', {returnObjects: true})}
            />
            <p className='cookie-article'>
                <Button
                    onClick={() => {
                        setShowHandleCookies(true);
                    }}
                    className='cookie-article__handle-button'
                    variant='outlined'
                >
                    {t('policy.cookie_policy_handle_button')}
                </Button>
            </p>
            {showHandleCookies && <HandleCookiesModal handleCloseModal={() => setShowHandleCookies(false)} />}
        </>
    );
}
