import {ClinicJSON} from 'src/services/api/types/types';

export const gTagManager = () => {
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    const gtag: any = function () {
        window.dataLayer.push(arguments);
    };

    const initiate = (clinic: ClinicJSON) => {
        // Set default consent to 'denied' as a placeholder
        // Determine actual values based on your own requirements
        gtag('consent', 'default', {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied'
        });
        // Set the Google Tag Manager ID
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            j.id = 'gtm-script';
            f.parentNode?.insertBefore(j, f);
        })(window, document, 'script' as const, 'dataLayer' as const, clinic.googleAnalyticsTag);
    };

    // Update the consent based on the user's choice
    const updateConsent = (hasConsent: boolean) => {
        if (hasConsent) {
            gtag('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted',
                analytics_storage: 'granted'
            });
        } else if (!hasConsent) {
            gtag('consent', 'update', {
                ad_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied',
                analytics_storage: 'denied'
            });
        }
        // Set the linker parameter to accept incoming links to enable cross-domain tracking
        gtag('set', 'linker', {
            accept_incoming: hasConsent
        });
    };

    const removeScript = () => {
        const script = document.getElementById('gtm-script');
        if (script) {
            script.remove();
        }
    };

    return {gtag, initiate, updateConsent, removeScript};
};
