import {Button} from '../../form/button/Button';
import {Trans, useTranslation} from 'react-i18next';
import {EIdPollData, EidPollError, pollEId} from '../../../services/api/implementations/eidapi';
import {useInterval} from '../../../hooks/useInterval';
import {BankIdIcon} from '../../icons/BankIdIcon';
import {FrejaIcon} from '../../icons/FrejaIcon';
import {useEffect, useRef, useState} from 'react';
import {Spinner} from '../../layout/loader/Spinner';
import {SessionActions, useSessionContext} from '../../../context/SessionContext';

import './modal.scss';

interface eIdOnDeviceProps {
    initData: any;
    closeModalAfterComplete(): void;
    onSelectOtherDevice(): void;
    selectedType: string;
    setMessage(type: string): void;
    showError(): void;
    onLoginSuccess(data: EIdPollData): void;
}

function browserAppScheme() {
    const baseUrl = `${window.location.host}`;
    const path = `${window.location.pathname}`;
    let userAgent = navigator.userAgent;
    const isiOS = /iPad|iPhone|iPod/.test(userAgent);
    // No redirect for non-iOS devices, Android will open last opened app
    if (!isiOS) {
        return 'null';
    }

    if (
        (/Chrome/.test(userAgent) || /CriOS/.test(userAgent)) &&
        !/Chromium/.test(userAgent) &&
        !/SamsungBrowser/.test(userAgent)
    ) {
        // Chrome iOS opens last opened tab, no url needed for redirect otherwise it will open a new tab.
        return `googlechrome://`;
    } else if (/Firefox/.test(userAgent) || /FxiOS/.test(userAgent)) {
        // Firefox iOS
        return `firefox://open-url?url=${baseUrl}${path}`;
    } else if (/Safari/.test(userAgent)) {
        // Safari iOS add hash to url to prevent safari from auto refreshing
        return `${encodeURIComponent(`https://${baseUrl}${path}#main`)}`;
    }
    // No match, default to no app redirect
    return 'null';
}

export function EIdOnDevice({
    initData,
    closeModalAfterComplete,
    onSelectOtherDevice,
    selectedType,
    setMessage,
    showError,
    onLoginSuccess
}: eIdOnDeviceProps) {
    const {t} = useTranslation();
    const [isPolling, setIsPolling] = useState(false);
    const [pollDelay, setPollDelay] = useState<number | null>(3000);
    const [provider, setProvider] = useState<string>('');
    const {sessionDispatch} = useSessionContext();
    const [pollingMessage, setPollingMessage] = useState<string>('');
    const eIdName = selectedType === 'BankID' ? 'BankID' : 'Freja';
    const isRequestingRef = useRef(false);

    const handleOpenEid = () => {
        if (selectedType === 'BankID') {
            OpenBankId();
        } else if (selectedType === 'Freja eID+') {
            OpenFrejaEId();
        } else if (selectedType === 'MockID') {
            setProvider('mock');
        }
    };

    const OpenBankId = () => {
        window.open(`bankid:///?autostarttoken=${initData.autoStartToken}&redirect=${browserAppScheme()}`, '_self');
    };
    const OpenFrejaEId = () => {
        window.open(
            `frejaeid://bindUserToTransaction?transactionReference=${
                initData.autoStartToken
            }&originAppScheme=${browserAppScheme()}`,
            '_self'
        );
    };

    const selectedTypeIcon = () => {
        switch (selectedType) {
            case 'BankID':
                return <BankIdIcon />;
            case 'Freja eID+':
                return <FrejaIcon />;
        }
    };

    useEffect(() => {
        sessionDispatch({type: SessionActions.ALLOW_OTHER_DEVICE_NAVIGATION, value: true});
        return () => {
            sessionDispatch({type: SessionActions.ALLOW_OTHER_DEVICE_NAVIGATION, value: false});
        };
    }, [sessionDispatch]);

    useEffect(() => {
        !initData?.pollInterval || initData?.pollInterval === 0
            ? setPollDelay(null)
            : setPollDelay(initData.pollInterval);
    }, [initData]);

    useEffect(() => {
        if (selectedType === 'BankID') {
            setProvider('bankid');
        } else if (selectedType === 'Freja eID+') {
            setProvider('freja');
        } else if (selectedType === 'MockID') {
            setProvider('mock');
        }
    }, [selectedType]);

    useInterval(async () => {
        try {
            if (isRequestingRef.current) {
                return;
            }
            isRequestingRef.current = true;
            const statusData = await pollEId(provider, initData);

            setMessage(statusData.status);
            setPollingMessage(statusData.status);

            if (statusData.status === 'USER_SIGN') {
                setIsPolling(true);
            }
            if (statusData.status === 'COMPLETE') {
                setIsPolling(false);
                closeModalAfterComplete();
                onLoginSuccess(statusData);
            }
            if (!statusData.continuePoll) {
                setIsPolling(false);
                setPollDelay(null);
                showError();
            }
        } catch (e) {
            console.log(e);
            if (e instanceof EidPollError) {
                setIsPolling(false);
                setMessage('GENERIC_ERROR');
                setPollDelay(null);
                showError();
            } else {
                console.log('Default error', e);
            }
        } finally {
            isRequestingRef.current = false;
        }
    }, pollDelay);

    return (
        <div>
            <div
                className='eid-modal__same-device'
                aria-label={`${selectedType} ${t('people_select.same_device.on_this_device')}`}
            >
                <h1>
                    {selectedType} <span>{t('people_select.same_device.on_this_device')}</span>
                </h1>
                {!isPolling ? (
                    <Button className='eid-modal__same-device-button' onClick={() => handleOpenEid()}>
                        {t('people_select.same_device.open')} {selectedType} {selectedTypeIcon()}
                    </Button>
                ) : (
                    <div className='eid-modal__status'>
                        <p>
                            <Trans>
                                e_id_error.{eIdName}.{pollingMessage}
                            </Trans>
                        </p>
                        <div className='eid-modal__spinner'>
                            <Spinner />
                        </div>
                    </div>
                )}
            </div>
            {!isPolling && (
                <footer className='eid-modal__footer eid-modal__footer--same-device'>
                    <span>{t('people_select.same_device.footer.header', {selectedType})}</span>
                    <br />
                    <button className='eid-modal__footer-link' onClick={() => onSelectOtherDevice()}>
                        {t('people_select.same_device.footer.button', {selectedType})}
                    </button>
                </footer>
            )}
        </div>
    );
}
