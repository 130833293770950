import React from 'react';

import './style.scss';

interface MapModalProps {
    children: React.ReactNode;
    className?: string;
    id?: string;
}

export function MapModal({children, className, id}: MapModalProps) {
    return (
        <div id={id || undefined} className={`map_modal${className ? ` ${className}` : ''}`}>
            {children}
        </div>
    );
}
