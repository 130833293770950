import {useEffect} from 'react';

import {BookingActions, useBookingContext} from '../../../../context/BookingContext';

import './BookingTime.scss';
import {format} from '../../../../lib/dates/format';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {useTranslation} from 'react-i18next';
import {TimeSelectNextButton} from '../../components/BookingTime/TimeSelectNextButton';
import {Spinner} from '../../../layout/loader/Spinner';

import 'react-datepicker/dist/react-datepicker.css';

import {UiProvider} from '../../../../context/UiContext';
import {TimeSlotPicker} from '../../components/TimeSlotPicker/TimeSlotPicker';

export function BookingTime() {
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {t} = useTranslation();

    const {selectedSlot, lockLoading, lockError} = bookingState.timeSlotState;

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <>
            <UiProvider>
                <main className={`main-content booking-time-content`} id='main'>
                    <TimeSlotPicker
                        appointmentTypeId={bookingState.reasonState.appointmentType?.id as number}
                        clinicId={dataState.clinic?.id as number}
                        initialSelectedSlot={selectedSlot || null}
                        reserveError={lockError}
                        lockLoading={lockLoading}
                        clearReserveError={() =>
                            dispatch({
                                type: BookingActions.SET_TIMESLOT_DATA,
                                value: {lockError: null, selectedSlot: null}
                            })
                        }
                        clearLoadError={() =>
                            dispatch({type: BookingActions.SET_TIMESLOT_DATA, value: {lockError: null}})
                        }
                        onTimeSlotClicked={(timeSlot: Date, selected: boolean) => {
                            if (selected) {
                                dispatch({
                                    type: BookingActions.SET_TIMESLOT_DATA,
                                    value: {
                                        selectedSlot: null
                                    }
                                });
                            } else {
                                dispatch({
                                    type: BookingActions.SET_TIMESLOT_DATA,
                                    value: {
                                        selectedSlot: timeSlot
                                    }
                                });
                            }
                        }}
                    />
                </main>
                <div
                    className='booking-time-content__mobile-nav'
                    role='region'
                    aria-label={t('timeSelect.aria_next_step_region')}
                >
                    <span>
                        {selectedSlot && (
                            <>
                                <span className='screen-reader-only'>
                                    {t('timeSelect.aria_selected_time_slot', {timeSlot: format(selectedSlot, 'Pp')})}
                                </span>
                                <span aria-hidden>{format(selectedSlot, 'ccc dd MMM, HH:mm').replace('.', '')}</span>
                            </>
                        )}
                    </span>

                    {lockLoading ? (
                        <div className='timeslot__spinner'>
                            <Spinner />
                        </div>
                    ) : (
                        <TimeSelectNextButton />
                    )}
                </div>
            </UiProvider>
        </>
    );
}
