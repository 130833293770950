import {LatLngBounds} from 'leaflet';
import {ClinicObjectSlim} from '../api/types/types';

export const isLocationWithinBoundingbox = (boundingbox: LatLngBounds, clinic: ClinicObjectSlim) => {
    return boundingbox.contains([clinic.location.lat, clinic.location.lng]);
};

export function commonElem(firstArr: Array<unknown>, secondArr: Array<unknown>) {
    const map = new Map();
    firstArr.forEach((item) => map.set(item, true));

    return secondArr.filter((item) => map.has(item));
}
