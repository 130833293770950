import {createContext, useCallback, useContext, useReducer} from 'react';
import {
    addVaccination,
    editVaccination,
    fetchHistory,
    fetchVaccineCategories,
    removeManuallyAddedVaccination
} from 'src/services/api/api';
import {
    HistoryResponse,
    UserJSON,
    UserRelation,
    ManualVaccinationPayload,
    VaccineCategoryResponse
} from 'src/services/api/types/types';

export enum VaccineCategoryActions {
    SET_VACCINE_CATEGORIES = 'SET_VACCINE_CATEGORIES'
}
export enum HistoryDataActions {
    SET_HISTORY = 'SET_HISTORY'
}
export enum HistoryEditActions {
    EDIT_MANUALLY_ADDED_VACCINATION = 'EDIT_MANUALLY_ADDED_VACCINATION'
}
export enum HistoryRemoveActions {
    DELETE_MANUALLY_ADDED_VACCINATION = 'DELETE_MANUALLY_ADDED_VACCINATION'
}
export enum HistoryLoadingActions {
    SET_LOADING_DATA = 'SET_LOADING_DATA'
}

export enum ClearDataActions {
    CLEAR_HISTORY = 'CLEAR_HISTORY'
}
export enum HistoryErrorActions {
    SET_FETCH_HISTORY_ERROR = 'SET_FETCH_HISTORY_ERROR'
}

type Action =
    | DataActions
    | VaccineDataActions
    | ClearActions
    | ErrorActions
    | LoadingActions
    | EditActions
    | DeleteActions;

type VaccineDataActions = {type: VaccineCategoryActions; value: VaccineCategoryResponse[]};
type DataActions = {type: HistoryDataActions; value: PersonHistory};
type LoadingActions = {type: HistoryLoadingActions; value: boolean};
type EditActions = {
    type: HistoryEditActions;
    value: {user: UserRelation | UserWithOptionalId; vaccinationId: string; updatedVaccination: HistoryResponse};
};
type DeleteActions = {
    type: HistoryRemoveActions;
    value: {user: UserRelation | UserWithOptionalId; vaccinationId: string};
};
type ClearActions = {type: ClearDataActions; value: []};
type ErrorActions = {type: HistoryErrorActions; value: string};

type Dispatch = (action: Action) => void;
export type PersonHistory = {
    id?: string;
    identity: string;
    history: Array<HistoryResponse>;
};
type State = {
    isLoadingData?: boolean;
    historyData: Array<PersonHistory>;
    vaccineCategories: Array<VaccineCategoryResponse>;
    fetchHistoryError?: string | null;
};
type DataProviderProps = {children: React.ReactNode};

interface UserWithOptionalId extends UserJSON {
    id?: string;
}

const getUserVaccinationHistoryIndices = (history: PersonHistory[], identity: string, vaccinationId: string) => {
    const userIndex = history.findIndex((personHistory: PersonHistory) => personHistory?.identity === identity);
    if (userIndex !== -1) {
        const vaccinationIndex = history[userIndex].history.findIndex((vaccination: HistoryResponse) => {
            return vaccination.id === vaccinationId;
        });
        return {userIndex, vaccinationIndex};
    } else {
        return {userIndex: -1, vaccinationIndex: -1};
    }
};

const HistoryDataContext = createContext<
    | {
          dataState: State;
          getVaccineCategories(): void;
          getUserHistory({jwt, user}: {jwt: string; user: UserJSON | UserRelation}): void;
          clearHistory(): void;
          addManualVaccination({
              jwt,
              user,
              payload
          }: {
              jwt: string;
              user: UserRelation | UserWithOptionalId;
              payload: ManualVaccinationPayload;
          }): Promise<HistoryResponse>;
          editManualVaccination({
              jwt,
              user,
              selectedVaccination,
              payload
          }: {
              jwt: string;
              user: UserRelation | UserWithOptionalId;
              selectedVaccination: HistoryResponse;
              payload: ManualVaccinationPayload;
          }): Promise<HistoryResponse>;
          deleteManuallyAddedVaccination({
              jwt,
              user,
              vaccination
          }: {
              jwt: string;
              user: UserRelation | UserWithOptionalId;
              vaccination: HistoryResponse;
          }): Promise<void>;
          dispatch: Dispatch;
      }
    | undefined
>(undefined);

function DataReducer(state: State, action: Action): State {
    switch (action.type) {
        case HistoryDataActions.SET_HISTORY: {
            const existingHistoryEntryIndex = state.historyData.findIndex(
                (history) => history.identity === action.value.identity
            );

            let updatedHistory = [...state.historyData];

            if (existingHistoryEntryIndex !== -1) {
                updatedHistory = updatedHistory.filter((history) => history.identity !== action.value.identity);
                updatedHistory.push(action.value);
            } else if (action.value) {
                updatedHistory.push(action.value);
            }

            return {...state, historyData: updatedHistory};
        }
        case VaccineCategoryActions.SET_VACCINE_CATEGORIES: {
            return {...state, vaccineCategories: action.value};
        }
        case HistoryLoadingActions.SET_LOADING_DATA: {
            return {...state, isLoadingData: action.value as boolean};
        }
        case ClearDataActions.CLEAR_HISTORY: {
            return {...state, historyData: []};
        }
        case HistoryEditActions.EDIT_MANUALLY_ADDED_VACCINATION: {
            const {userIndex, vaccinationIndex} = getUserVaccinationHistoryIndices(
                state.historyData,
                action.value.user.identity,
                action.value.vaccinationId
            );

            if (userIndex !== -1 && vaccinationIndex !== -1) {
                const updatedHistory = [...state.historyData];
                updatedHistory[userIndex] = {
                    ...updatedHistory[userIndex],
                    history: updatedHistory[userIndex].history.map((item, index) =>
                        index === vaccinationIndex ? action.value.updatedVaccination : item
                    )
                };
                return {...state, historyData: updatedHistory};
            } else {
                return {...state};
            }
        }
        case HistoryRemoveActions.DELETE_MANUALLY_ADDED_VACCINATION: {
            const {userIndex, vaccinationIndex} = getUserVaccinationHistoryIndices(
                state.historyData,
                action.value.user.identity,
                action.value.vaccinationId
            );

            if (userIndex !== -1) {
                const updatedHistory = [...state.historyData];
                updatedHistory[userIndex] = {
                    ...updatedHistory[userIndex],
                    history: [
                        ...updatedHistory[userIndex].history.slice(0, vaccinationIndex),
                        ...updatedHistory[userIndex].history.slice(vaccinationIndex + 1)
                    ]
                };
                return {...state, historyData: updatedHistory};
            } else {
                return {...state};
            }
        }
        case HistoryErrorActions.SET_FETCH_HISTORY_ERROR: {
            return {...state, fetchHistoryError: action.value as string};
        }
        default: {
            throw new Error(`Unhandled action type: ${action || ''}`);
        }
    }
}

function HistoryDataProvider({children}: DataProviderProps) {
    const [dataState, dispatch] = useReducer(DataReducer, {
        historyData: [],
        vaccineCategories: [],
        isLoadingData: false
    });

    const getVaccineCategories = useCallback(async () => {
        try {
            const categories = await fetchVaccineCategories();
            dispatch({type: VaccineCategoryActions.SET_VACCINE_CATEGORIES, value: categories});
        } catch (error) {}
    }, []);

    const getUserHistory = useCallback(async ({jwt, user}: {jwt: string; user: UserRelation | UserWithOptionalId}) => {
        dispatch({type: HistoryLoadingActions.SET_LOADING_DATA, value: true});
        try {
            const historyResponse = await fetchHistory(jwt, user?.id);
            if (historyResponse.length > 0) {
                const personHistory: PersonHistory = {
                    id: user.id,
                    identity: user.identity,
                    history: historyResponse
                };
                dispatch({type: HistoryDataActions.SET_HISTORY, value: personHistory});
            }
        } catch (error) {
        } finally {
            dispatch({type: HistoryLoadingActions.SET_LOADING_DATA, value: false});
        }
    }, []);

    const clearHistory = () => {
        dispatch({type: ClearDataActions.CLEAR_HISTORY, value: []});
    };

    const addManualVaccination = useCallback(
        async ({
            jwt,
            user,
            payload
        }: {
            jwt: string;
            user: UserRelation | UserWithOptionalId;
            payload: ManualVaccinationPayload;
        }) => {
            try {
                const response = await addVaccination(jwt, user?.id || 'me', payload);
                await getUserHistory({jwt, user});
                return response;
            } catch (error) {
                throw new Error();
            }
        },
        [getUserHistory]
    );

    const editManualVaccination = useCallback(
        async ({
            jwt,
            user,
            selectedVaccination,
            payload
        }: {
            jwt: string;
            user: UserRelation | UserWithOptionalId;
            selectedVaccination: HistoryResponse;
            payload: ManualVaccinationPayload;
        }) => {
            try {
                const response = await editVaccination(jwt, user?.id || 'me', selectedVaccination.id, payload);

                if (selectedVaccination.when !== response.when) {
                    await getUserHistory({jwt, user});
                } else {
                    dispatch({
                        type: HistoryEditActions.EDIT_MANUALLY_ADDED_VACCINATION,
                        value: {user: user, vaccinationId: selectedVaccination.id, updatedVaccination: response}
                    });
                }

                return response;
            } catch (error) {
                throw new Error();
            }
        },
        [getUserHistory]
    );

    const deleteManuallyAddedVaccination = useCallback(
        async ({
            jwt,
            user,
            vaccination
        }: {
            jwt: string;
            user: UserRelation | UserWithOptionalId;
            vaccination: HistoryResponse;
        }) => {
            try {
                await removeManuallyAddedVaccination(jwt, vaccination.id, user?.id || 'me');
                dispatch({
                    type: HistoryRemoveActions.DELETE_MANUALLY_ADDED_VACCINATION,
                    value: {user: user, vaccinationId: vaccination.id}
                });
            } catch (error) {
                throw new Error();
            }
        },
        []
    );

    const value = {
        dataState,
        getUserHistory,
        getVaccineCategories,
        clearHistory,
        addManualVaccination,
        editManualVaccination,
        deleteManuallyAddedVaccination,
        dispatch
    };
    return <HistoryDataContext.Provider value={value}>{children}</HistoryDataContext.Provider>;
}

function useHistoryDataContext() {
    const context = useContext(HistoryDataContext);
    if (context === undefined) {
        throw new Error('useHistoryDataContext must be used within a HistoryDataProvider');
    }
    return context;
}

export {HistoryDataProvider, useHistoryDataContext};
