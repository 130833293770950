import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';
import {useEffect, useState} from 'react';
import {Checkbox} from '../../../form/checkbox/Checkbox';
import {Button} from '../../../form/button/Button';
import {Radio} from '../../../form/radio/Radio';
import {Input} from '../../../form/input/Input';
import {validateEmail, validatePhone} from '../../../../services/validation/validation';
import {ConsentRequest} from '../../../../services/api/types/types';

import './BookingConsent.scss';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';

export function BookingConsent() {
    const {t} = useTranslation();

    useEffect(() => window.scrollTo(0, 0), []);

    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();

    const {user, reasonState, consentRequests = [], userConsentNeeded = false, userConsentGiven = false} = bookingState;

    const {visitReasons} = dataState;

    const [consentGiven, setConsentGiven] = useState(userConsentGiven);
    const [localConsentRequests, setLocalConsentRequests] = useState<Array<ConsentRequest>>(consentRequests);
    const [consentRequestsValid, setConsentRequestsValid] = useState(false);

    const selectedNames = localConsentRequests.map((request) => request.displayName);

    const getConsentHeader = () => {
        if (selectedNames.length === 0) {
            return t('booking-consent.user.consent_no_name');
        }

        return t('booking-consent.user.consent', {person: selectedNames.join(', ')});
    };

    const handleConsentRequest = <CRKey extends keyof ConsentRequest>(
        i: number,
        type: CRKey,
        value: ConsentRequest[CRKey]
    ) => {
        const newConsentRequests = localConsentRequests.map((request) => ({...request}));
        newConsentRequests[i][type] = value;
        setLocalConsentRequests(newConsentRequests);
        dispatch({type: BookingActions.SET_CONSENT_REQUESTS, value: newConsentRequests});
    };

    const goToNextStep = () => {
        dispatch({type: BookingActions.USER_CONSENT_GIVEN, value: consentGiven});
        dispatch({type: BookingActions.SET_CONSENT_REQUESTS, value: localConsentRequests});
        dispatch({type: BookingActions.NEXT_STEP});
    };

    const getGuardianString = (i: number) => {
        if (userConsentNeeded === false && userConsentGiven === false) {
            return t('booking-consent.guardian.text_guardians', {
                guardianNumber: localConsentRequests?.length === 1 ? '' : i + 1
            });
        }

        return selectedNames.length > 0
            ? t('booking-consent.guardian.text_with_name', {person: localConsentRequests[i].displayName})
            : t('booking-consent.guardian.text_guardians', {
                  guardianNumber: localConsentRequests?.length === 1 ? '' : i + 1
              });
    };

    useEffect(() => {
        const userConsentValid = userConsentNeeded === false || (userConsentNeeded && consentGiven);
        setConsentRequestsValid(
            userConsentValid &&
                localConsentRequests.every((request) => {
                    return (
                        request.digital === false ||
                        (request.digital === true &&
                            (request.email.length > 0 || request.mobile.length > 0) &&
                            validateEmail(request.email) &&
                            validatePhone(request.mobile))
                    );
                })
        );
    }, [consentGiven, localConsentRequests, userConsentNeeded]);

    const appointmentTypeLabel = reasonState.appointmentType?.visitReasonIds
        .map((visitReasonId) => {
            return visitReasons.find(({id}) => parseInt(id, 10) === visitReasonId)?.label;
        })
        .join(', ');

    return (
        <main className='main-content main-content--consent' id='main'>
            <h1 className='main-content--information__header no-hyphens'>{t('booking-consent.header_guardian')}</h1>
            <p className='booking-consent__subheader'>{t('booking-consent.subheader')}</p>
            <p className='booking-consent__subheader'>
                {t('booking-consent.infoheader', {appointmentType: appointmentTypeLabel})}
            </p>

            {userConsentNeeded && (
                <section className='booking-consent__section'>
                    <h2 className='booking-consent__user-consent'>{getConsentHeader()}</h2>
                    <Checkbox
                        id='user-consent'
                        label={t('booking-consent.user.label', {userName: user?.givenName + ' ' + user?.familyName})}
                        checked={consentGiven}
                        onClick={(checked) => setConsentGiven(checked)}
                    />
                </section>
            )}

            {localConsentRequests?.map((request, i) => (
                <section key={i} className='booking-consent__section'>
                    <h2 className='booking-consent__user-consent'>{getGuardianString(i)}</h2>
                    <div className={`radio-with-input ${request.digital ? 'border-bottom' : ''} `}>
                        <Radio
                            name={`consent-${i}`}
                            checked={request.digital}
                            borderless
                            label={t('booking-consent.guardian.digital-consent')}
                            onClick={() => {
                                handleConsentRequest(i, 'digital', true);
                            }}
                        />

                        {request.digital && (
                            <>
                                <p className='radio-input-information'>{t('booking-consent.guardian.input-info')}</p>
                                <div className='booking-info-form__fields'>
                                    <Input
                                        type='tel'
                                        value={request.mobile}
                                        isValid={validatePhone(request.mobile)}
                                        id={`tel-${i}`}
                                        label={t('confirm.form.telephone')}
                                        onChange={(val) => {
                                            handleConsentRequest(i, 'mobile', val);
                                        }}
                                    />

                                    {validatePhone(request.mobile) === false && (
                                        <p className='input-error'>{t('confirm.form.phone_error')}</p>
                                    )}

                                    <Input
                                        type='email'
                                        value={request.email}
                                        isValid={validateEmail(request.email)}
                                        id={`email-${i}`}
                                        label={t('confirm.form.email_placeholder')}
                                        onChange={(val) => {
                                            handleConsentRequest(i, 'email', val);
                                        }}
                                    />

                                    {validateEmail(request.email) === false && (
                                        <p className='input-error'>{t('confirm.form.email_error')}</p>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <Radio
                        name={`consent-${i}`}
                        checked={request.digital === false}
                        borderless
                        label={t('booking-consent.guardian.analog-consent')}
                        onClick={() => {
                            handleConsentRequest(i, 'digital', false);
                        }}
                    />
                </section>
            ))}

            <div className={'booking-consent__button-container'}>
                <Button
                    big
                    disabled={(userConsentNeeded && consentGiven === false) || !consentRequestsValid}
                    onClick={() => goToNextStep()}
                >
                    <span>{t('button_next.text')}</span>
                </Button>
            </div>
        </main>
    );
}
