import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {HistoryResponse} from 'src/services/api/types/types';
import {ArrowIcon} from 'src/components/icons/ArrowIcon';
import {ReactComponent as IconAlarmOn} from 'src/components/icons/icon_alarm_on.svg';
import './VaccinationListItem.scss';

type AppProps = {
    vaccination: HistoryResponse;
    onSelected: (vaccination: HistoryResponse) => void;
    isSelected: boolean;
};
export function VaccinationListItem({vaccination, onSelected, isSelected}: AppProps) {
    const {t} = useTranslation();

    const vaccinationDate = useMemo(() => {
        const dateOptions: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        return new Date(vaccination.when).toLocaleDateString('sv-SE', dateOptions);
    }, [vaccination.when]);

    const handleOnSelected = (e: any) => {
        e.preventDefault();
        onSelected(vaccination);
    };

    const handleKeyboard = (e: any) => {
        if (e.key === 'Enter') {
            onSelected(vaccination);
        }
    };

    const getVaccineHeader = () => {
        if (vaccination.vaccine.categoryName === '-' && vaccination.vaccine.name) {
            return vaccination.vaccine.name;
        }
        return vaccination.vaccine.categoryName || vaccination.vaccine.name;
    };

    return (
        <>
            <div
                role='button'
                tabIndex={0}
                className={`vaccination-box ${isSelected && 'vaccination-box--selected'}`}
                data-testid='vaccine-test-component'
                onClick={handleOnSelected}
                onKeyDown={handleKeyboard}
                aria-expanded={isSelected}
                aria-owns={`vaccine-details-${vaccination.id}`}
            >
                <div className='vaccination-box__content'>
                    <div className='vaccination-box__content__left'>
                        <div className='vaccination-box__content__left__top-row'>
                            <h1 className='vaccination-box__content__left__top-row__header'>{getVaccineHeader()}</h1>
                            <label className='vaccination-box__content__left__top-row__date'>{vaccinationDate}</label>
                        </div>
                        <div className='vaccination-box__content__left__bottom-row'>
                            <div className='vaccination-box__content__left__bottom-row__vaccine-left-column'>
                                <label className='vaccination-box__content__left__bottom-row__vaccine-left-column__label'>
                                    {vaccination.vaccine.dose && vaccination.vaccine.dose !== -1
                                        ? t('vaccine.doseNr', {doseNr: vaccination.vaccine.dose})
                                        : t('vaccine.doseNrNotSpecified')}
                                </label>
                                {vaccination.source === 'USER_ADDED' && (
                                    <label className='vaccination-box__content__left__bottom-row__vaccine-left-column__label'>
                                        {t('vaccine.isUserAddedText')}
                                    </label>
                                )}
                                {vaccination.source === 'REGISTERED_HISTORY' && (
                                    <label className='vaccination-box__content__left__bottom-row__vaccine-left-column__label'>
                                        {t('vaccine.isRegisteredHistoryText', {clinicName: vaccination.clinic.name})}
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='vaccination-box__content__right'>
                        <label className='vaccination-box__content__right__date'>{vaccinationDate}</label>
                        <div className='vaccination-box__content__right__icon'>
                            <ArrowIcon width='1.75rem' height='1.75rem' ariaHidden />
                        </div>
                    </div>
                </div>
                {vaccination.notification && (
                    <div className='vaccination-box__notification'>
                        <div className='vaccination-box__notification__left'>
                            <p>{t('vaccine.nextDoseNotificationText')}</p>
                            <h4>{vaccination.notification.when}</h4>
                        </div>
                        <div className='vaccination-box__notification__right'>
                            <IconAlarmOn width='1.75rem' height='1.75rem' aria-hidden />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
