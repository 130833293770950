export function TimeIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5Z'
                    stroke='currentColor'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M12.75 5.54999C13.0261 5.54999 13.25 5.77385 13.25 6.04999V11.156L16.8489 12.9555C17.0959 13.079 17.196 13.3793 17.0725 13.6263L16.4017 14.9679C16.2782 15.2149 15.9778 15.315 15.7309 15.1915L11.441 13.0466C11.0175 12.8349 10.75 12.402 10.75 11.9286V6.04999C10.75 5.77385 10.9739 5.54999 11.25 5.54999H12.75Z'
                    fill='currentColor'
                />
            </svg>
        </>
    );
}
