export function EditIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 20.6001H21'
                    stroke='#D12C3B'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M14.5 6L17 8.5'
                    stroke='#D12C3B'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M3.75 16.25L2.25 20.75L6.75 20L20.25 5.75L17.25 2.75L3.75 16.25Z'
                    stroke='#E30031'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
}
