interface ApiConfig {
    baseUrl: string;
    apiKey: string;
}

const config: ApiConfig = {
    baseUrl: process.env.REACT_APP_CLINIC_API_ENDPOINT || '',
    apiKey: process.env.REACT_APP_CLINIC_API_KEY || ''
};

export interface EIdInitData {
    orderRef: string;
    autoStartToken: string;
    transactionId: string;
    pollInterval: number;
    qrRefreshInterval: number;
}

type EIdStatus =
    | 'COMPLETE'
    | 'STARTED'
    | 'USER_REQ'
    | 'NO_CLIENT'
    | 'OUTSTANDING_TRANSACTION'
    | 'ERROR'
    | 'USER_SIGN'
    | 'RETRY'
    | 'EXPIRED_TRANSACTION'
    | 'USER_CANCEL'
    | 'CLIENT_ERR'
    | 'CANCELLED'
    | 'START_FAILED'
    | 'ALREADY_IN_PROGRESS';

export interface EIdPollData {
    status: EIdStatus;
    continuePoll: boolean;
    jwt: string;
    sessionDuration: number;
    tokenDuration: number;
}

interface EIdRefreshData {
    jwt: string;
    remainingSessionDuration: number;
    tokenDuration: number;
}

export type EIdProvider = 'bankid' | 'freja' | 'mock';

export class EidPollError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'EidPollError';
    }
}

export const initEIdSession = async (provider: EIdProvider) => {
    const {baseUrl, apiKey} = config;

    if (provider === 'mock') {
        return {
            autoStartToken: 'autostart-1234',
            orderRef: 'orderRef-1234',
            transactionId: 'transactionId-1234',
            pollInterval: 500,
            qrRefreshInterval: 3000
        } as EIdInitData;
    }

    const initResponse = await fetch(`${baseUrl}/v1/eid/init`, {
        method: 'post',
        body: JSON.stringify({
            provider
        }),
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    });

    if (!initResponse.ok) {
        console.error(await initResponse.json());
        throw new Error(`Unable to initialize eid session. Status: ${initResponse.status}`);
    }

    const initData = (await initResponse.json()) as EIdInitData;
    return initData;
};

export const getQrCodeUrl = (transactionId: string) => {
    const {baseUrl} = config;
    return `${baseUrl}/v1/eid/qr/${transactionId}?t=${+new Date()}`;
};

export const pollEId = async (provider: string, initData: EIdInitData) => {
    const {baseUrl, apiKey} = config;

    if (provider === 'mock') {
        return {
            continuePoll: false,
            status: 'COMPLETE',
            jwt: 'mock',
            sessionDuration: 600, // 10 minutes
            tokenDuration: 120 // 2 minutes
        } as EIdPollData;
    }

    const pollResponse = await fetch(`${baseUrl}/v1/eid/poll`, {
        method: 'post',
        body: JSON.stringify({
            transactionId: initData.transactionId,
            orderRef: initData.orderRef,
            provider: provider
        }),
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    });

    if (!pollResponse.ok) {
        console.error(await pollResponse.json());
        throw new EidPollError(`Unable to poll eid session. Status: ${pollResponse.status}`);
    }

    const pollData = (await pollResponse.json()) as EIdPollData;
    return pollData;
};

export const refreshEidSession = async (userJwt: string) => {
    const {baseUrl, apiKey} = config;

    if (userJwt.startsWith('mock')) {
        return {
            jwt: `mock-${Math.floor(Math.random() * 10000)}`,
            remainingSessionDuration: 300, // 5 minutes,
            tokenDuration: 120 // 2 minutes
        } as EIdRefreshData;
    }

    const refreshResponse = await fetch(`${baseUrl}/v1/eid/refresh`, {
        method: 'post',
        headers: {
            'x-api-key': apiKey,
            'x-user-token': userJwt,
            'Content-Type': 'application/json'
        }
    });

    if (!refreshResponse.ok) {
        throw new Error(`Unable to refresh eid session. Status: ${refreshResponse.status}`);
    }

    return (await refreshResponse.json()) as EIdRefreshData;
};
