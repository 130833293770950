import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PeopleList} from './views/PeopleList';
import {useSessionContext} from '../../../../context/SessionContext';
import {useBookingContext} from '../../../../context/BookingContext';
import {PeopleForm} from './views/PeopleForm';
import {useBookingSessionContext} from '../../../../context/BookingSessionContext';
import {EidAuthenticate} from '../../../authentication/EidAuthenticate';
import './PeopleMain.scss';

export function PeopleMain() {
    const {t} = useTranslation();
    const {
        sessionState: {userJwt}
    } = useSessionContext();

    const {startBookingSession} = useBookingSessionContext();

    const {bookingState} = useBookingContext();
    const isOpenBooking = Boolean(bookingState.reasonState.appointmentType?.eidRequired) === false;

    useEffect(() => window.scrollTo(0, 0), []);

    if (isOpenBooking) {
        return <PeopleForm />;
    }

    return (
        <>
            {userJwt !== null ? (
                <PeopleList />
            ) : (
                <main className='main-content' id='main'>
                    <h1 className='people__header'>{t('people_select.identify_yourself')}</h1>
                    <p className='people-main-container-text'>{t('people_select.subheader')}</p>
                    <p className='people-main-container-text'>{t('people_select.additional_subheader')}</p>
                    <EidAuthenticate
                        onLoginSuccess={({jwt, sessionDuration, tokenDuration}) => {
                            startBookingSession(jwt, sessionDuration, tokenDuration);
                        }}
                    />
                </main>
            )}
        </>
    );
}
