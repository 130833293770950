import {PolicyLinks} from '../../policy/links/PolicyLinks';
import './MapPolicyMenu.scss';

export function MapPolicyMenu() {
    return (
        <div className='map-policy-menu'>
            <PolicyLinks />
        </div>
    );
}
