export function CheckIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M13.182 3.18198C12.9867 2.98672 12.6701 2.98672 12.4749 3.18198L6.46447 9.19239L3.98959 6.71751C3.79433 6.52225 3.47775 6.52225 3.28249 6.71751L1.86827 8.13173C1.67301 8.32699 1.67301 8.64357 1.86827 8.83883L4.6967 11.6673L6.11091 13.0815C6.30617 13.2767 6.62276 13.2767 6.81802 13.0815L8.23223 11.6673L14.5962 5.3033C14.7915 5.10804 14.7915 4.79146 14.5962 4.59619L13.182 3.18198Z'
                    fill='white'
                />
            </svg>
        </>
    );
}
