export function CertificateIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='currentColor'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M3 3C3 1.89543 3.89543 1 5 1H18C19.1046 1 20 1.89543 20 3V9.23387L17.5 12.0161V4C17.5 3.72386 17.2761 3.5 17 3.5H6C5.72386 3.5 5.5 3.72386 5.5 4V20C5.5 20.2761 5.72386 20.5 6 20.5H12.1111L14.3333 23H5C3.89543 23 3 22.1046 3 21V3Z'
                    fill='currentColor'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M23.387 11.5514C23.5951 11.733 23.6165 12.0488 23.435 12.2569L16.3964 20.3219C16.159 20.5939 15.8156 20.75 15.4546 20.75C15.0936 20.75 14.7502 20.5939 14.5128 20.3219L11.5651 16.9444C11.3835 16.7363 11.405 16.4205 11.613 16.2389L12.7432 15.2526C12.9512 15.071 13.2671 15.0925 13.4487 15.3005L15.4546 17.599L21.5514 10.613C21.733 10.405 22.0488 10.3835 22.2569 10.5651L23.387 11.5514Z'
                    fill='currentColor'
                />
                <rect x='7' y='5' width='9' height='2.5' rx='0.5' fill='currentColor' />
                <rect x='7' y='9' width='9' height='2.5' rx='0.5' fill='currentColor' />
                <rect x='7' y='13' width='4' height='2.5' rx='0.5' fill='currentColor' />
            </svg>
        </>
    );
}
