import {KeyboardEvent, createRef, useRef} from 'react';
import './TabMenu.scss';

export type Tab = {
    id: string;
    label: string;
    ariaLabel?: string;
};

interface TabMenuProps {
    tabs: Tab[];
    activeTab: string;
    onTabChange(tabId: string): void;
    dataTestId?: string;
    firstFocusId?: string;
}

export const TabMenu = ({tabs, activeTab, onTabChange, dataTestId, firstFocusId}: TabMenuProps) => {
    let selectedMenuItem = useRef(0);
    let tabValues = useRef(
        tabs.map((tab) => ({
            id: tab.id,
            label: tab.label,
            ref: createRef<HTMLButtonElement>(),
            ariaLabel: tab.ariaLabel || ''
        }))
    );

    const handleKeyPress = (event: KeyboardEvent<HTMLButtonElement>) => {
        const tabCount = tabs.length - 1;
        if (event.key === 'ArrowLeft') {
            const last = tabCount;

            const next = selectedMenuItem.current - 1;
            if (next < 0) {
                handleNextTab(last, tabCount, tabCount);
                return;
            }
            handleNextTab(last, next, 0);
        }
        if (event.key === 'ArrowRight') {
            const first = 0;
            const next = selectedMenuItem.current === tabCount ? 0 : selectedMenuItem.current + 1;
            handleNextTab(first, next, tabCount);
        }
    };

    const handleNextTab = (firstTabInRound: number, nextTab: number, lastTabInRound: number) => {
        const tabToSelect: number = selectedMenuItem.current === lastTabInRound ? firstTabInRound : nextTab;
        selectedMenuItem.current = tabToSelect;
        tabValues.current[tabToSelect].ref.current?.focus();
    };

    return (
        <div className='tab-menu'>
            {tabValues.current &&
                tabValues.current.length > 0 &&
                tabValues.current.map((tab, index) => (
                    <button
                        key={tab.id}
                        id={index === 0 && firstFocusId ? firstFocusId : undefined}
                        ref={tab.ref}
                        tabIndex={activeTab === tab.id ? 0 : -1}
                        onKeyDown={handleKeyPress}
                        data-testid={dataTestId}
                        className={activeTab === tab.id ? 'active' : ''}
                        onClick={() => onTabChange(tab.id)}
                        aria-label={tab.ariaLabel || ''}
                    >
                        <h3 className={`tab-menu__item${activeTab === tab.id ? '--selected' : ''}`}>{tab.label}</h3>
                    </button>
                ))}
        </div>
    );
};
