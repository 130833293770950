export function ChevronLeftIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M11 13.5L5.5 8L11 2.5' stroke='currentColor' strokeOpacity='1' strokeWidth='2.5' />
            </svg>
        </>
    );
}
