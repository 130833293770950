import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {HandleBookingHeader} from '../components/clinic/handle-booking/components/HandleBookingHeader';
import {useCssWorkarounds} from '../hooks/useCssWorkarounds';
import {useClearTrackingCookies} from '../hooks/useClearTrackingCookies';

export function HandleBookingRoot() {
    const {t} = useTranslation();
    useCssWorkarounds();
    useClearTrackingCookies();

    useEffect(() => {
        document.title = t('route_name.handle_booking');
        return () => {
            document.title = t('route_name.home');
        };
    }, [t]);

    return (
        <div className='handle-container' aria-busy='false'>
            <HandleBookingHeader />
            <Outlet />
        </div>
    );
}
