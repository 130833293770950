export interface sortByCriteria {
    key: string;
    direction: 'asc' | 'desc';
}

/**
 * Sort an array of objects by multiple criteria
 * @param arrayToSort  Array of objects to sort
 * @param sortByCriteria  Array of objects with key and direction
 *
 * @example
 * const arrayToSort = [
 *   {name: 'John', age: 25},
 *   {name: 'Alice', age: 30},
 *   {name: 'Bob', age: 20}
 * ];
 *
 * it('should sort the array by name in ascending order', () => {
 *    const sortByCriteria: sortByCriteria[] = [{key: 'name', direction: 'asc'}];
 *    const sortedArray = sortBy(arrayToSort, sortByCriteria);
 *
 *   expect(sortedArray).toEqual([
 *     {name: 'Alice', age: 30},
 *     {name: 'Bob', age: 20},
 *     {name: 'John', age: 25}
 *   ]);
 * });
 *
 * @returns Sorted array
 */
export const sortBy = (arrayToSort: any, sortByCriteria: sortByCriteria[]) => {
    let sortedArray = [];
    if (sortByCriteria.length === 0 || sortByCriteria === null) {
        return arrayToSort;
    }

    sortedArray = arrayToSort.sort((a: any, b: any) => {
        let sortValue = 0;
        sortByCriteria &&
            sortByCriteria.length > 0 &&
            sortByCriteria.forEach((criteria) => {
                const keys = criteria.key.split('.');
                const aKey = keys.reduce((obj, key) => obj && obj[key], a);
                const bKey = keys.reduce((obj, key) => obj && obj[key], b);
                if (sortValue === 0) {
                    if (criteria.direction === 'asc') {
                        if (aKey !== undefined && aKey < bKey && bKey !== undefined) {
                            sortValue = -1;
                        } else if (aKey !== undefined && aKey > bKey && bKey !== undefined) {
                            sortValue = 1;
                        } else if (aKey === undefined && bKey !== undefined) {
                            sortValue = 1;
                        } else if (aKey !== undefined && bKey === undefined) {
                            sortValue = -1;
                        } else if (aKey === bKey) {
                            sortValue = 0;
                        }
                    } else {
                        if (aKey !== undefined && aKey > bKey && bKey !== undefined) {
                            sortValue = -1;
                        } else if (aKey !== undefined && aKey < bKey && bKey !== undefined) {
                            sortValue = 1;
                        } else if (aKey !== undefined && bKey === undefined) {
                            sortValue = -1;
                        } else if (aKey === undefined && bKey && bKey !== undefined) {
                            sortValue = 1;
                        } else if (aKey === bKey) {
                            sortValue = 0;
                        }
                    }
                }
            });
        return sortValue;
    });
    return sortedArray;
};
