import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

interface EmptyResultErrorProps {
    primaryAction(): void;
}

export function EmptyResultError({primaryAction}: EmptyResultErrorProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onPrimaryAction={primaryAction} primaryActionLabel={t('error_ok_button.text')}>
            <p>{t('error_empty_result_message.text')}</p>
        </ModalPortal>
    );
}
