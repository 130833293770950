import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../form/button/Button';

export function PolicyFooter() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <>
            <div className='policy-container__footer'>
                <Button big onClick={() => navigate('/')}>
                    <span>{t('button_to_startpage.text')}</span>
                </Button>
            </div>
        </>
    );
}
