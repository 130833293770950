import {LatLng, LeafletEvent} from 'leaflet';
import {useEffect} from 'react';
import {useMap} from 'react-leaflet';
import {UiStringActions, useUiContext} from '../../../context/UiContext';

interface MapPanListenerProps {
    onMoveEnd(centerPoint: LatLng): void;
}

export function MapPanListener({onMoveEnd}: MapPanListenerProps) {
    const map = useMap();
    const {dispatch} = useUiContext();

    useEffect(() => {
        const popupClose = () => dispatch({type: UiStringActions.SET_POPUP_OPEN_ID, value: null});

        map.on('popupclose', popupClose);

        return () => void map.off('popupClose', popupClose);
    }, [dispatch, map]);

    useEffect(() => {
        let debounceOnMoveEnd: NodeJS.Timeout;
        const panListener = (e: LeafletEvent) => {
            clearTimeout(debounceOnMoveEnd);
            debounceOnMoveEnd = setTimeout(() => {
                onMoveEnd(map.getCenter());
            }, 100);
        };
        map.on('moveend', panListener);

        return () => {
            map.off('moveend', panListener);
        };
    }, [map, onMoveEnd]);

    return null;
}
