import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchHealthDeclaration} from '../../../../../services/api/api';
import {ConsentRequestObject, HealthDeclaration} from '../../../../../services/api/types/types';
import {Button} from '../../../../form/button/Button';
import ModalPortal from '../../../../layout/modal/ModalPortal';
import {HealthDeclarationAnswersModal} from '../healthDeclModal/HealthDeclAnswersModal';
import {RadioToggle} from '../toggle/radioToggle';

interface ConsentRequestProps {
    consentRequest: ConsentRequestObject;
    handleConsentAnswer(id: string, bool: boolean): void;
}

export function ConsentRequest({consentRequest, handleConsentAnswer}: ConsentRequestProps) {
    const {id, child, for: forReasons, healthDecl = null} = consentRequest;

    const {t} = useTranslation();
    const [modalStatus, setModalStatus] = useState<'open' | 'closed' | 'healthDeclaration'>('closed');
    const [healthDeclaration, setHealthDeclaration] = useState<HealthDeclaration | null>(null);

    useEffect(() => {
        if (healthDeclaration !== null || healthDecl === null) {
            return;
        }

        const loadHealthDeclaration = async () => {
            const healthDeclaration = await fetchHealthDeclaration(healthDecl.definitionId as number);
            setHealthDeclaration(healthDeclaration);
        };

        loadHealthDeclaration();
    }, [healthDecl, healthDeclaration]);

    return (
        <div key={id} className='consent-content__card'>
            <p className='consent-content__card__patient_name'>{`${child.givenName} ${child.familyName}`}</p>
            <p className='consent-content__card__for'>
                {t('consent.handle.consent_for_visit_reason', {visitReason: forReasons})}
            </p>

            {healthDecl !== null && (
                <Button
                    variant='text'
                    className='btn btn-text consent-content__section__button'
                    onClick={() => setModalStatus('healthDeclaration')}
                >
                    <span>{t('consent.button.show_health_declaration')}</span>
                </Button>
            )}

            <p className='consent-content__card__radio_header'>{t('consent.toggle_header')}</p>
            <RadioToggle id={id} onChange={(bool) => handleConsentAnswer(id, bool)} />

            {modalStatus === 'healthDeclaration' && (
                <ModalPortal onCloseClick={() => setModalStatus('closed')}>
                    {healthDeclaration && healthDecl !== null && (
                        <HealthDeclarationAnswersModal
                            healthDeclaration={healthDeclaration}
                            existingAnswers={healthDecl.answers}
                            name={`${child.givenName} ${child.familyName}`}
                            onCloseClick={() => setModalStatus('closed')}
                        />
                    )}
                </ModalPortal>
            )}
        </div>
    );
}
