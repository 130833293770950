import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {fetchSingleBooking} from 'src/services/api/api';
import {ForbiddenError, NotFoundError} from 'src/services/api/implementations/fetch';
import {BookingResponse} from 'src/services/api/types/types';
import {validateBookingCode, validatePhone} from 'src/services/validation/validation';
import {Button} from 'src/components/form/button/Button';
import {Input} from 'src/components/form/input/Input';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import './BookingCodeModal.scss';

interface BookingCodeModalProps {
    onBookingSuccess(booking: BookingResponse): void;
    handleCloseModal(): void;
}

export function BookingCodeModal({onBookingSuccess, handleCloseModal}: BookingCodeModalProps) {
    const {t} = useTranslation();
    const [phone, setPhone] = useState('');
    const [bookingCode, setBookingCode] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isBookingCodeValid, setIsBookingCodeValid] = useState(true);

    const [isBookingForbidden, setIsBookingForbidden] = useState(false);
    const [isBookingNotFound, setIsBookingNotFound] = useState(false);

    const formValid = validateBookingCode(bookingCode) && phone.length > 0 && validatePhone(phone);

    const findOpenBooking = async () => {
        try {
            const booking = await fetchSingleBooking(bookingCode, phone);
            onBookingSuccess(booking);
        } catch (error) {
            if (error instanceof ForbiddenError) {
                setIsBookingForbidden(true);
            }
            if (error instanceof NotFoundError) {
                setIsBookingNotFound(true);
            }
        }
    };

    const formContent = (
        <div>
            <h1>{t('handle_booking.identify_yourself')}</h1>
            <p>{t('handle_booking.identify_yourself_explanation')}</p>
            <div className='booking-code-form'>
                <div className='booking-code-form__fields'>
                    <Input
                        isValid={isPhoneValid}
                        type='tel'
                        id='phone'
                        required
                        label={t('confirm.form.telephone')}
                        onKeyDown={(e) => {
                            if (formValid && e.key === 'Enter') {
                                findOpenBooking();
                            }
                        }}
                        onChange={(val) => {
                            setIsPhoneValid(val.length < 10 || validatePhone(val));
                            setPhone(val);
                        }}
                    />
                    {!isPhoneValid && <p className='input-error'>{t('handle_booking.error.phone_error')}</p>}
                    <Input
                        isValid={isBookingCodeValid}
                        type='text'
                        id='booking-code'
                        label={t('handle_booking.booking_code')}
                        onKeyDown={(e) => {
                            if (formValid && e.key === 'Enter') {
                                findOpenBooking();
                            }
                        }}
                        onChange={(val) => {
                            setIsBookingCodeValid(val.length < 7 || validateBookingCode(val));
                            setBookingCode(val);
                        }}
                    />
                    {!isBookingCodeValid && (
                        <p className='input-error'>{t('handle_booking.error.booking_code_error')}</p>
                    )}

                    <div className='booking-code-form__footer'>
                        <p>{t('handle_booking.contact_clinic')}</p>
                    </div>
                </div>
            </div>
            <div className='booking-code-modal__footer'>
                <p>
                    <Button disabled={formValid === false} big className='btn--wide' onClick={findOpenBooking}>
                        <span>{t('button_next.text')}</span>
                    </Button>
                </p>
            </div>
        </div>
    );

    if (isBookingForbidden) {
        return (
            <ModalPortal onCloseClick={() => handleCloseModal()}>
                <div className='booking-code-modal'>
                    <h1>{t('handle_booking.change_identity_method')}</h1>
                    <p>{t('handle_booking.change_identity_method_explanation')}</p>
                    <div className='booking-code-modal__footer'>
                        <p>
                            <Button big className='btn--wide' onClick={() => handleCloseModal()}>
                                <span>{t('healthdeclaration.button_change')}</span>
                            </Button>
                        </p>
                    </div>
                </div>
            </ModalPortal>
        );
    }

    if (isBookingNotFound) {
        return (
            <ModalPortal onCloseClick={() => handleCloseModal()}>
                <div className='booking-code-modal'>
                    <ModalPortal
                        onPrimaryAction={() => setIsBookingNotFound(false)}
                        primaryActionLabel={t('error_ok_button.text')}
                    >
                        <p>
                            <b>{t('handle_booking.error.booking_not_found_heading')}</b>
                            <br />
                            {t('handle_booking.error.booking_not_found_content')}
                        </p>
                    </ModalPortal>
                    {formContent}
                </div>
            </ModalPortal>
        );
    }

    return (
        <ModalPortal onCloseClick={() => handleCloseModal()}>
            <div className='booking-code-modal'>{formContent}</div>
        </ModalPortal>
    );
}
