import {useTranslation} from 'react-i18next';
import {useUiContext} from '../../../context/UiContext';
import {CaregiverObject, ClinicFilterObject, PlacesFilterObject} from '../../../services/api/types/types';
import {XIcon} from '../../icons/XIcon';
import './style.scss';

interface PillsProps {
    places: Array<PlacesFilterObject>;
    caregivers: Array<CaregiverObject>;
    clinics: Array<ClinicFilterObject>;
    removeCareGiver(careGiverId: string): void;
    removePlace(placeId: string): void;
    removeClinic(clinicId: string): void;
}

export function Pills({places, clinics, caregivers, removeCareGiver, removeClinic, removePlace}: PillsProps) {
    const {t} = useTranslation();
    const {state} = useUiContext();
    const {searchExtended, smallDevice} = state;

    return (
        <div className='pills-container'>
            {places.map((place) => {
                const fullLabel = `${place.name}\u00a0(${t('search_group_place.text')})`;
                return (
                    place.category !== 'county' && (
                        <div className='pill' key={place.id}>
                            <p>
                                <span>{fullLabel}</span>
                                <button
                                    tabIndex={!searchExtended && smallDevice ? -1 : 0}
                                    aria-label={t('search_pill_remove_filter.text', {label: fullLabel})}
                                    onClick={() => removePlace(place.id)}
                                >
                                    <XIcon />
                                </button>
                            </p>
                        </div>
                    )
                );
            })}
            {caregivers.reverse().map(({id, label}) => {
                const fullLabel = `${label} (${t('search_group_caregiver.text')})`;
                return (
                    <div className='pill' key={id}>
                        <p>
                            <span>{fullLabel}</span>
                            <button
                                tabIndex={!searchExtended && smallDevice ? -1 : 0}
                                aria-label={t('search_pill_remove_filter.text', {label: fullLabel})}
                                onClick={(e) => {
                                    e.preventDefault();
                                    removeCareGiver(id?.toString());
                                }}
                            >
                                <XIcon />
                            </button>
                        </p>
                    </div>
                );
            })}
            {clinics.reverse().map(({id, name}) => {
                const fullLabel = `${name} (${t('search_group_clinic.text')})`;
                return (
                    <div className='pill' key={id}>
                        <p>
                            <span>{fullLabel}</span>
                            <button
                                tabIndex={!searchExtended && smallDevice ? -1 : 0}
                                aria-label={t('search_pill_remove_filter.text', {label: fullLabel})}
                                onClick={(e) => {
                                    e.preventDefault();
                                    removeClinic(id);
                                }}
                            >
                                <XIcon />
                            </button>
                        </p>
                    </div>
                );
            })}
        </div>
    );
}
