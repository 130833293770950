import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCssWorkarounds} from '../hooks/useCssWorkarounds';
import {useClearTrackingCookies} from '../hooks/useClearTrackingCookies';

export function AccountRoot() {
    const {t} = useTranslation();
    useCssWorkarounds();
    useClearTrackingCookies();
    useEffect(() => {
        document.title = t('route_name.account');
        return () => {
            document.title = t('route_name.home');
        };
    }, [t]);

    return <Outlet />;
}
