import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext, User} from 'src/context/BookingContext';
import {useBookingSessionContext} from 'src/context/BookingSessionContext';
import {useClinicDetailsContext} from 'src/context/ClinicDetailsContext';
import {fetchCountries, fetchDefaultHealthDeclaration, fetchHealthDeclaration} from 'src/services/api/api';
import {Country, HealthDeclaration, UserJSON} from 'src/services/api/types/types';
import {useTravelDurations} from './hooks/useTravelDurations';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {PlusIcon} from 'src/components/icons/PlusIcon';
import {EditIcon} from 'src/components/icons/EditIcon';
import {HealthDeclarationModal} from './HealthDeclarationModal';
import {TravelModal} from './TravelModal';
import './BookingHealthDeclaration.scss';

function formatIdentity(identity: string) {
    const arr = identity.split('');
    arr.splice(8, 0, '-');
    return arr.join('');
}

export function BookingHealthDeclaration() {
    const {t} = useTranslation();

    const {dataState} = useClinicDetailsContext();
    const {dispatch, bookingState} = useBookingContext();
    const {travelDurations} = useTravelDurations();
    const {refreshBookingSession} = useBookingSessionContext();

    const {reasonState, healthDeclarationAnswers, trip, tripDepartureDate, user, bookingType, selectedPersonsIds} =
        bookingState;

    useEffect(() => window.scrollTo(0, 0), []);

    const [healthDeclaration, setHealthDeclaration] = useState<HealthDeclaration | null>(null);
    const [countries, setCountries] = useState<Array<Country>>([]);

    const [loadStatus, setLoadStatus] = useState<'loading' | 'done' | 'error'>('loading');

    useEffect(() => {
        const load = async () => {
            if (
                !dataState.clinic?.id ||
                (bookingType === 'booking' && reasonState.healthDeclarationDefinitionId === null)
            ) {
                return;
            }

            try {
                const [hd, countries] = await Promise.all([
                    bookingType === 'booking'
                        ? await fetchHealthDeclaration(reasonState.healthDeclarationDefinitionId as number)
                        : await fetchDefaultHealthDeclaration(dataState.clinic.id),
                    await fetchCountries()
                ]);

                setHealthDeclaration(hd);
                setCountries(countries);
                setLoadStatus('done');
            } catch (error) {
                console.log(error);
                setLoadStatus('error');
            }
        };

        load();
    }, [reasonState.healthDeclarationDefinitionId, dataState.clinic?.id, bookingType]);

    const [modalStatus, setModalStatus] = useState<'closed' | 'travel' | 'healthDeclaration'>('closed');
    const [selectedPerson, setSelectedPerson] = useState<User | null>(null);

    const [answersChanged, setAnswersChanged] = useState(false);
    const [showWarning, setShowWarning] = useState(false);

    const healthDeclarationValid = selectedPersonsIds?.length === Object.keys(healthDeclarationAnswers.answers).length;
    const hasTravelInformation = trip !== undefined && tripDepartureDate !== null;

    const selectedPeople = [user as UserJSON, ...(user?.relations || [])].filter(({identity}) =>
        selectedPersonsIds?.includes(identity)
    );
    const sortedPeople =
        bookingType === 'booking'
            ? selectedPeople.sort((a, b) => (parseInt(a.identity, 10) || 0) - parseInt(b.identity, 10))
            : selectedPeople;

    const openQuestionModal = (person: User) => {
        setSelectedPerson(person);
        setModalStatus('healthDeclaration');
    };
    const closeModal = () => {
        setModalStatus('closed');
        setSelectedPerson(null);
    };

    const travelSubheading = hasTravelInformation
        ? t('healthdeclaration.destination_edit_section_header')
        : t('healthdeclaration.destination_add_section_header');
    const travelLinkText = hasTravelInformation
        ? t('healthdeclaration.destination_edit_section_link_text')
        : t('healthdeclaration.destination_add_section_link_text');

    if (loadStatus === 'error') {
        return (
            <>
                <main className={`main-content health-declaration`} id='main'>
                    <div>
                        <h1 className='main-content__header'>{t('healthdeclaration.load_error_header')}</h1>
                        <p className='main-content--information__text'>
                            {t('healthdeclaration.load_error_content', {clinicName: dataState?.clinic?.name})}
                            {dataState?.clinic?.phone && (
                                <>
                                    {t('people_select.people_list.info_tel')}{' '}
                                    <a
                                        href={`tel:${dataState.clinic.phone}`}
                                        aria-label={t('people_select.phonenumber', {
                                            phonenumber: dataState.clinic.phone
                                        })}
                                    >
                                        {dataState.clinic.phone}
                                    </a>
                                </>
                            )}
                            .
                        </p>
                    </div>
                </main>
            </>
        );
    }

    return (
        <>
            <main className={`main-content health-declaration`} id='main'>
                <div>
                    <h1 className='main-content__header'>{t('healthdeclaration.header')}</h1>
                    <p className='main-content__sub-header wrap-html'>{t('healthdeclaration.information_text')}</p>
                </div>
                <div className='health-declaration__people-section'>
                    {sortedPeople.map((user) => {
                        return (
                            <div className='health-declaration__person' key={`person-${user.identity}`}>
                                <p className='health-declaration__person-info'>
                                    <span className='screen-reader-only'>
                                        {t('healthdeclaration.aria.person_information', {
                                            person: `${user.givenName} ${user.familyName}`,
                                            identity: formatIdentity(user.identity)
                                        })}
                                    </span>
                                    <span aria-hidden className='health-declaration__person-name'>
                                        {user.givenName} {user.familyName}
                                    </span>
                                    <span aria-hidden>{user.identity}</span>
                                </p>
                                <button
                                    aria-label={
                                        !healthDeclarationAnswers.answers[user.identity]
                                            ? t('healthdeclaration.aria.fill_for_person', {
                                                  person: `${user.givenName} ${user.familyName}`
                                              })
                                            : t('healthdeclaration.aria.change_for_person', {
                                                  person: `${user.givenName} ${user.familyName}`
                                              })
                                    }
                                    disabled={loadStatus === 'loading'}
                                    onClick={() => openQuestionModal(user)}
                                    className={`btn btn--primary health-declaration__btn${
                                        !healthDeclarationAnswers.answers[user.identity] ? '' : ' btn--tertiary'
                                    }`}
                                >
                                    <span>
                                        {!healthDeclarationAnswers.answers[user.identity]
                                            ? t('healthdeclaration.button_fill')
                                            : t('healthdeclaration.button_change')}
                                    </span>
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div className='health-declaration__travel-section'>
                    <h2 className='health-declaration__sub-heading'>{travelSubheading}</h2>
                    {hasTravelInformation && (
                        <ul className='health-declaration__destination-list'>
                            {trip.map((destination, index) => {
                                const countryName = countries.find(({id}) => destination.countryId === id)?.name;
                                const durationLabel = travelDurations.find(
                                    ({id}) => destination.duration === id
                                )?.label;

                                return (
                                    <li key={index}>
                                        {t('healthdeclaration.destination_list_item', {
                                            destination: countryName,
                                            duration: durationLabel
                                        })}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <p>
                        <a
                            href='/'
                            onClick={(e) => {
                                e.preventDefault();
                                setModalStatus('travel');
                            }}
                            className='health-declaration__add-travel-link'
                        >
                            {hasTravelInformation ? <EditIcon /> : <PlusIcon />}
                            {travelLinkText}
                        </a>
                    </p>
                </div>
                <div className='health-declaration__next-section'>
                    <button
                        disabled={healthDeclarationValid === false}
                        className='btn btn--primary btn--big health-declaration__next-button'
                        onClick={() => {
                            refreshBookingSession(bookingState.bookingId);
                            dispatch({type: BookingActions.NEXT_STEP});
                        }}
                    >
                        <span>{t('button_next.text')}</span>
                    </button>
                </div>
            </main>

            {modalStatus === 'healthDeclaration' && healthDeclaration !== null && selectedPerson !== null && (
                <ModalPortal
                    onCloseClick={() => {
                        if (answersChanged) {
                            setShowWarning(true);
                            return;
                        }
                        closeModal();
                    }}
                >
                    {showWarning && (
                        <ModalPortal
                            primaryActionLabel={t('button_yes.text')}
                            onPrimaryAction={() => {
                                setShowWarning(false);
                                setAnswersChanged(false);
                                closeModal();
                            }}
                            secondaryActionLabel={t('button_no.text')}
                            onSecondaryAction={() => setShowWarning(false)}
                        >
                            <p>
                                <b>{t('healthdeclaration.close_warning_header')}</b>
                                <br />
                                {t('healthdeclaration.close_warning_content')}
                            </p>
                        </ModalPortal>
                    )}
                    <HealthDeclarationModal
                        healthDeclaration={healthDeclaration}
                        existingAnswers={healthDeclarationAnswers.answers[selectedPerson.identity]}
                        person={selectedPerson}
                        onChange={(isChanged) => setAnswersChanged(isChanged)}
                        onSaveClick={(formAnswers) => {
                            setShowWarning(false);
                            setAnswersChanged(false);

                            dispatch({
                                type: BookingActions.SET_HEALTH_DECLARATION_ANSWERS,
                                value: {
                                    healthDeclarationId: healthDeclaration.id,
                                    answers: {
                                        ...healthDeclarationAnswers.answers,
                                        [selectedPerson.identity]: formAnswers
                                    }
                                }
                            });

                            closeModal();
                        }}
                    />
                </ModalPortal>
            )}

            {modalStatus === 'travel' && (
                <ModalPortal
                    onCloseClick={() => {
                        if (answersChanged) {
                            setShowWarning(true);
                            return;
                        }
                        closeModal();
                    }}
                >
                    {showWarning && (
                        <ModalPortal
                            primaryActionLabel={t('button_yes.text')}
                            onPrimaryAction={() => {
                                setShowWarning(false);
                                setAnswersChanged(false);
                                closeModal();
                            }}
                            secondaryActionLabel={t('button_no.text')}
                            onSecondaryAction={() => setShowWarning(false)}
                        >
                            <p>
                                <b>{t('healthdeclaration.close_warning_header')}</b>
                                <br />
                                {t('healthdeclaration.close_warning_content')}
                            </p>
                        </ModalPortal>
                    )}
                    <TravelModal
                        countries={countries}
                        initialDestinations={trip}
                        initialDepartureDate={tripDepartureDate}
                        onChange={(isChanged) => setAnswersChanged(isChanged)}
                        onSaveClick={({departureDate, destinations}) => {
                            dispatch({type: BookingActions.SET_TRIP_DEPARTURE_DATE, value: departureDate});
                            dispatch({type: BookingActions.SET_TRIP_DESTINATIONS, value: destinations});
                            closeModal();
                        }}
                    />
                </ModalPortal>
            )}
        </>
    );
}
