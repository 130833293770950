import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ArrowIcon} from '../../../icons/ArrowIcon';
import {BookingActions, BookingStep, useBookingContext} from '../../../../context/BookingContext';
import {BookingSessionActions, useBookingSessionContext} from '../../../../context/BookingSessionContext';
import {useSessionContext} from '../../../../context/SessionContext';
import ModalPortal from 'src/components/layout/modal/ModalPortal';

export function BookingHeader() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {bookingSessionDispatch} = useBookingSessionContext();
    const {sessionState} = useSessionContext();

    const {bookingType, timeSlotState, step, steps} = bookingState;

    const {allowOtherDeviceNavigation} = sessionState;

    const [confirmResetBooking, setConfirmResetBooking] = useState(false);

    const handleBack = () => {
        dispatch({type: BookingActions.PREVIOUS_STEP});
    };

    const handleCancel = () => {
        setConfirmResetBooking(true);
    };

    useEffect(() => {
        if (!timeSlotState.selectedSlot || (step === 'peopleSelect' && allowOtherDeviceNavigation)) {
            return;
        }
        const handler = (event: Event) => {
            event.preventDefault();
            // @ts-ignore Chrome needs this to be set for now
            event.returnValue = '';
            return 'Leave text';
        };
        window.addEventListener('beforeunload', handler, {capture: true});
        return () => {
            window.removeEventListener('beforeunload', handler, {capture: true});
        };
    }, [allowOtherDeviceNavigation, step, timeSlotState.selectedSlot]);

    const backableSteps: Array<BookingStep> =
        bookingType === 'booking'
            ? ['timeSelect', 'peopleSelect', 'consent', 'healthDeclaration', 'confirm']
            : ['healthDeclaration', 'confirm'];
    const backIsEnabled = backableSteps.includes(step);
    const cancelIsEnabled = bookingType !== 'healthDeclaration' && steps.indexOf(step) > 0;

    return (
        <header className={!backIsEnabled ? 'header-nav row-reverse' : 'header-nav'}>
            {backIsEnabled && (
                <button className='header-nav__back-button primary' onClick={() => handleBack()}>
                    <span>
                        <ArrowIcon />
                        {t('clinic_header.back')}
                    </span>
                </button>
            )}
            {cancelIsEnabled && (
                <button className='primary' onClick={() => handleCancel()}>
                    <span>{t('clinic_header.cancel')}</span>
                </button>
            )}

            {confirmResetBooking && (
                <ModalPortal
                    size='sm'
                    primaryActionLabel={
                        bookingType === 'booking'
                            ? t('timeSelect.confirm_abort_button')
                            : t('timeSelect.confirm_dropin_abort_button')
                    }
                    onPrimaryAction={() => {
                        if (bookingType === 'healthDeclaration') {
                            window.location.reload();
                        } else {
                            dispatch({type: BookingActions.RESET_BOOKING});
                            bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
                        }
                    }}
                    onSecondaryAction={() => setConfirmResetBooking(false)}
                    onCloseClick={() => setConfirmResetBooking(false)}
                >
                    <p>
                        <b>{t('timeSelect.confirm_abort_header')}</b>
                        <br />
                        {t('timeSelect.confirm_abort_text')}
                    </p>
                </ModalPortal>
            )}
        </header>
    );
}
