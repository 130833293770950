import {useCallback} from 'react';
import {BookingContextState, BookingContextStateWithoutDates} from 'src/context/BookingContext';
import {HandleBookingContextState} from 'src/context/HandleBookingContext';

/**
 * Hook to restore booking state from session storage
 */

interface TimeSlotState {
    lockLoading: boolean;
    lockError: Error | null;
    selectedSlot?: string | null;
    lockedSlot?: string | null;
}

interface BookingContextStateNormalized extends BookingContextStateWithoutDates {
    timeSlotState: TimeSlotState;
    tripDepartureDate?: string | null;
}

export function useStateRestore() {
    // ------------------- Clinic Booking Flow ------------------- //
    const getBookingStateFromCache = useCallback(() => {
        const storedBookingStateString = sessionStorage.getItem('bookingState');
        const storedBookingState: BookingContextStateNormalized = storedBookingStateString
            ? JSON.parse(storedBookingStateString)
            : null;

        // Change normalized booking state to have Date objects instead of strings
        const storedBookingStateWithDateFormat: BookingContextState | null = storedBookingState && {
            ...storedBookingState,
            tripDepartureDate: storedBookingState.tripDepartureDate
                ? new Date(storedBookingState.tripDepartureDate)
                : null,
            timeSlotState: {
                ...storedBookingState?.timeSlotState,
                selectedSlot: storedBookingState?.timeSlotState?.selectedSlot
                    ? new Date(storedBookingState?.timeSlotState?.selectedSlot)
                    : null,
                lockedSlot: storedBookingState?.timeSlotState?.lockedSlot
                    ? new Date(storedBookingState?.timeSlotState?.lockedSlot)
                    : null
            }
        };
        return storedBookingStateWithDateFormat;
    }, []);

    const updateBookingStateCache = useCallback((bookingState: BookingContextState) => {
        sessionStorage.setItem('bookingState', JSON.stringify(bookingState));
    }, []);

    const removeBookingStateCache = useCallback(() => {
        sessionStorage.removeItem('bookingState');
    }, []);

    // ------------------- Handle Existing Booking Flow ------------------- //
    const getHandleBookingStateFromCache = useCallback(() => {
        const storedBookingStateString = sessionStorage.getItem('handleBookingState');
        const storedBookingState: HandleBookingContextState = storedBookingStateString
            ? JSON.parse(storedBookingStateString)
            : null;
        return storedBookingState;
    }, []);

    const updateHandleBookingStateCache = useCallback((handleBookingState: HandleBookingContextState) => {
        sessionStorage.setItem('handleBookingState', JSON.stringify(handleBookingState));
    }, []);

    const removeHandleBookingStateCache = useCallback(() => {
        sessionStorage.removeItem('handleBookingState');
    }, []);

    // ------------------- Signed in Eid Session ------------------- //

    const getUserJwtFromCache = useCallback(() => {
        return sessionStorage.getItem('userJwt');
    }, []);

    const getUserJwtExpireAtFromCache = useCallback(() => {
        return sessionStorage.getItem('userJwtExpireAt');
    }, []);

    const updateUserJwtCache = useCallback((jwt: string) => {
        sessionStorage.setItem('userJwt', jwt);
    }, []);

    const updateUserJwtExpireAtCache = useCallback((userJwtExpiration: string) => {
        sessionStorage.setItem('userJwtExpireAt', userJwtExpiration);
    }, []);

    const clearSessionStorage = useCallback(() => {
        sessionStorage.clear();
    }, []);

    return {
        getBookingStateFromCache,
        updateBookingStateCache,
        removeBookingStateCache,
        getHandleBookingStateFromCache,
        updateHandleBookingStateCache,
        removeHandleBookingStateCache,
        getUserJwtFromCache,
        getUserJwtExpireAtFromCache,
        updateUserJwtCache,
        updateUserJwtExpireAtCache,
        clearSessionStorage
    };
}
