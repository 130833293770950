import './BookingCompleted.scss';
import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';
import {CircleCheckmark} from '../../../icons/CircleCheckmark';
import {Button} from '../../../form/button/Button';
import {WorldIcon} from '../../../icons/WorldIcon';
import {useEffect} from 'react';
import {BookingSessionActions, useBookingSessionContext} from '../../../../context/BookingSessionContext';

export function BookingCompleted() {
    const {t} = useTranslation();
    const {dispatch} = useBookingContext();
    const {bookingSessionDispatch} = useBookingSessionContext();

    useEffect(() => window.scrollTo(0, 0), []);

    const handleNavigation = () => {
        dispatch({type: BookingActions.RESET_BOOKING_ID});
        dispatch({type: BookingActions.RESET_BOOKING});
        bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
        dispatch({type: BookingActions.SET_BOOKING_STEP, value: 'initial'});
    };

    return (
        <main className='main-content completed' id='main'>
            <CircleCheckmark />
            <h1 className='header'>{t('completed.header')}</h1>
            <p className='subheader'>{t('completed.subheader')}</p>
            <Button big variant='outlined' onClick={() => handleNavigation()}>
                <span>{t('completed.new_booking_button')}</span>
            </Button>

            <div className='link-section'>
                <button className='button-link' onClick={() => handleNavigation()}>
                    <WorldIcon />
                    <span>{t('completed.go_to_clinic')}</span>
                </button>
            </div>
        </main>
    );
}
