import {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {format} from 'src/lib/dates/format';
import {useSessionContext} from 'src/context/SessionContext';
import {useHistoryDataContext} from 'src/context/HistoryDataContext';
import {useUiContext} from 'src/context/UiContext';
import {HistoryResponse, UserJSON, UserRelation} from 'src/services/api/types/types';
import {VaccinationItemDetails} from './vaccine-details/VaccinationItemDetails';
import {VaccinationList} from './vaccination-list/VaccinationList';
import {Spinner} from 'src/components/layout/loader/Spinner';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {Button} from 'src/components/form/button/Button';
import {ReactComponent as IconPlus} from 'src/components/icons/icon_plus.svg';
import {ManualVaccinationModal} from './ManualVaccinationModal';
import {VaccinationFormData} from './ManualVaccinationForm';
import {TabMenu, Tab} from '../../form/tab-list/TabMenu';

import './HistoryNavigationContainer.scss';
import {SnackBar} from 'src/components/layout/SnackBar/SnackBar';

export function HistoryNavigationContainer() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {sessionState} = useSessionContext();
    const {state} = useUiContext();
    const {
        dataState,
        getUserHistory,
        getVaccineCategories,
        addManualVaccination,
        editManualVaccination,
        deleteManuallyAddedVaccination
    } = useHistoryDataContext();

    const [showAddManualVaccinationModal, setShowAddManualVaccinationModal] = useState<boolean>(false);
    const [showEditManualVaccinationModal, setShowEditManualVaccinationModal] = useState<boolean>(false);
    const [showRemoveVaccinationModal, setShowRemoveVaccinationModal] = useState<boolean>(false);
    const [showNetworkErrorModal, setShowNetworkErrorModal] = useState<boolean>(false);
    const [selectedVaccination, setSelectedVaccination] = useState<HistoryResponse | null>(null);
    const [vaccinationActionTarget, setVaccinationActionTarget] = useState<HistoryResponse | null>(null);
    const [selectedPerson, setSelectedPerson] = useState<UserRelation | UserJSON | null>(null);
    const [showAddedVaccinationToast, setShowAddedVaccinationToast] = useState<boolean>(false);

    const isMobile = ['compact', 'medium'].includes(state.breakPointInterval);
    const isDesktop = ['large', 'expanded'].includes(state.breakPointInterval);

    const relatedVaccinations = useMemo(() => {
        if (selectedVaccination) {
            let filterFunction: (vaccination_: HistoryResponse) => boolean;

            if (selectedVaccination.vaccine.categoryName !== '-') {
                filterFunction = (vaccination_: HistoryResponse) =>
                    vaccination_.vaccine.categoryName === selectedVaccination.vaccine.categoryName;
            } else {
                filterFunction = (vaccination_: HistoryResponse) =>
                    vaccination_.vaccine.name === selectedVaccination.vaccine.name;
            }

            return dataState.historyData
                .filter((personHistory) => personHistory.identity === selectedPerson?.identity)
                .map((personHistory) => personHistory.history)
                .flat()
                .filter(filterFunction);
        } else {
            return [];
        }
    }, [dataState.historyData, selectedVaccination]);

    useEffect(() => {
        if (sessionState.user && sessionState?.userJwt && !selectedPerson) {
            setSelectedPerson(sessionState.user);
            const personHistory = dataState?.historyData.find(
                (history) => history.identity === sessionState.user?.identity
            );
            if (!personHistory) {
                getVaccineCategories();
                getUserHistory({jwt: sessionState.userJwt, user: sessionState.user});
            }
        }
    }, [
        sessionState.user,
        sessionState.userJwt,
        dataState.historyData,
        selectedPerson,
        getUserHistory,
        getVaccineCategories
    ]);

    const handleOpenEditVaccinationModal = (vaccination: HistoryResponse) => {
        setVaccinationActionTarget(vaccination);
        setShowEditManualVaccinationModal(true);
    };

    const handleOpenRemoveVaccinationModal = (vaccination: HistoryResponse) => {
        setVaccinationActionTarget(vaccination);
        setShowRemoveVaccinationModal(true);
    };

    const handleSelectPerson = (selectedPerson: UserRelation | UserJSON | null) => {
        setSelectedVaccination(null);
        setVaccinationActionTarget(null);
        setSelectedPerson(selectedPerson);
        const personHistory = dataState.historyData.find((history) => history.identity === selectedPerson?.identity);
        if (!personHistory && sessionState.userJwt && selectedPerson) {
            getUserHistory({jwt: sessionState.userJwt, user: selectedPerson});
        }
    };

    const handleSelectVaccination = (vaccination: HistoryResponse) => {
        setSelectedVaccination(vaccination);

        if (isMobile) {
            window.scrollTo(0, 0);
        }
    };

    const manualVaccinationPayload = useCallback(
        (form: VaccinationFormData) => {
            if (!form.vaccinationDate) {
                throw new Error();
            }

            return {
                when: format(form.vaccinationDate, 'yyyy-MM-dd'),
                vaccine: {
                    name: form.vaccine,
                    categoryName: form.vaccineCategory,
                    dose: +form.dose || -1
                },
                ...(form.notificationDate && {
                    notification: {
                        when: format(form.notificationDate, 'yyyy-MM-dd'),
                        type: sessionState.account?.preferredContact || 'SMS_EMAIL'
                    }
                })
            };
        },
        [sessionState.account?.preferredContact]
    );

    const handleAddManualVaccination = async (form: VaccinationFormData) => {
        if (!sessionState.userJwt || !selectedPerson) {
            return;
        }

        try {
            const response = await addManualVaccination({
                jwt: sessionState.userJwt,
                user: selectedPerson,
                payload: manualVaccinationPayload(form)
            });

            setSelectedVaccination(response);
            setShowAddManualVaccinationModal(false);
            setShowAddedVaccinationToast(true);
        } catch (error) {
            setShowNetworkErrorModal(true);
        }
    };

    const handleEditManualVaccination = async (form: VaccinationFormData) => {
        if (!sessionState.userJwt || !selectedPerson || !vaccinationActionTarget || !form.vaccinationDate) {
            return;
        }

        try {
            const response = await editManualVaccination({
                jwt: sessionState.userJwt,
                user: selectedPerson,
                selectedVaccination: vaccinationActionTarget,
                payload: manualVaccinationPayload(form)
            });

            if (!isMobile) {
                setSelectedVaccination(response);
            }

            setVaccinationActionTarget(null);
            setShowEditManualVaccinationModal(false);
        } catch (error) {
            setShowNetworkErrorModal(true);
        }
    };

    const handleRemoveVaccination = async () => {
        if (sessionState?.userJwt && vaccinationActionTarget && selectedPerson) {
            try {
                await deleteManuallyAddedVaccination({
                    jwt: sessionState.userJwt,
                    user: selectedPerson,
                    vaccination: vaccinationActionTarget
                });
            } catch (error) {
                setShowNetworkErrorModal(true);
            } finally {
                if (selectedVaccination?.id === vaccinationActionTarget.id) {
                    setSelectedVaccination(null);
                }

                setVaccinationActionTarget(null);
                setShowRemoveVaccinationModal(false);
            }
        }
    };

    const getPersonRelations = () => {
        const relations: Tab[] | null = [];
        if (sessionState.user) {
            const tabUser = {
                id: sessionState.user.identity,
                label: sessionState.user.givenName,
                ariaLabel: t('vaccine.vaccine_list_aria_label', {name: sessionState.user.givenName})
            };
            relations.push(tabUser);
            sessionState.user.relations?.forEach((relation: UserRelation) => {
                if (relation.relationType === 'child') {
                    const tabRelation = {
                        id: relation.identity,
                        label: relation.givenName,
                        ariaLabel: t('vaccine.vaccine_list_aria_label', {name: relation.givenName})
                    };
                    relations.push(tabRelation);
                }
            });
        }

        return (
            <TabMenu
                firstFocusId='history-container-first-focus'
                tabs={relations}
                activeTab={selectedPerson?.identity || ''}
                dataTestId='history-navigation-container-relations-item'
                onTabChange={(tabId: string) => {
                    const selectedPerson =
                        sessionState.user?.identity === tabId
                            ? sessionState.user
                            : sessionState.user?.relations?.find(
                                  (relation: UserRelation) => relation.identity === tabId
                              );

                    handleSelectPerson(selectedPerson || null);
                }}
            />
        );
    };

    return (
        <>
            <div
                aria-label={t('vaccine.vaccine_list')}
                role='region'
                id='vaccination-history-content'
                className='history-navigation-container'
            >
                {showAddedVaccinationToast && (
                    <SnackBar
                        duration={3000}
                        content={t('history.vaccination_added')}
                        onExited={() => {
                            setShowAddedVaccinationToast(false);
                        }}
                    />
                )}
                {selectedPerson && !(selectedVaccination && isMobile) && (
                    <>
                        <h1 className='history-navigation-container__header'>{t('history.heading')}</h1>
                        <div className='history-navigation-container__relations'>{getPersonRelations()}</div>
                        <div className='history-navigation-container__add-vaccination'>
                            <Button
                                id='add-vaccination-button'
                                className='history-navigation-container__add-vaccination__button'
                                thin
                                onClick={() => setShowAddManualVaccinationModal(true)}
                            >
                                <IconPlus />
                            </Button>
                            <label
                                className='history-navigation-container__add-vaccination__label'
                                htmlFor='add-vaccination-button'
                            >
                                {t('history.add_button_text')}
                            </label>
                        </div>
                    </>
                )}

                {dataState.isLoadingData || !selectedPerson ? (
                    <div className='history-navigation-container__spinner'>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {selectedPerson && !(selectedVaccination && isMobile) && (
                            <div className='history-navigation-container__left-column'>
                                <VaccinationList
                                    history={
                                        dataState?.historyData?.find(
                                            (personHistory) => personHistory.identity === selectedPerson.identity
                                        )?.history || []
                                    }
                                    selectedVaccination={selectedVaccination}
                                    onSelectedVaccination={handleSelectVaccination}
                                />
                            </div>
                        )}

                        {selectedVaccination && isDesktop && (
                            <div className='history-navigation-container__vaccine-details-container'>
                                <div className='history-navigation-container__vaccine-details-container--sticky'>
                                    <VaccinationItemDetails
                                        vaccination={selectedVaccination}
                                        onEditVaccination={handleOpenEditVaccinationModal}
                                        onRemoveVaccination={handleOpenRemoveVaccinationModal}
                                    />
                                </div>
                            </div>
                        )}

                        {selectedVaccination && isMobile && (
                            <div className='history-navigation-container__vaccine-details__modal'>
                                <div className='history-navigation-container__vaccine-details__modal__navbar'>
                                    <button onClick={() => navigate('/')}>
                                        <img
                                            className='navbar__logo'
                                            src='/mittvaccin_logo.svg'
                                            alt={t('logo_alt.text')}
                                        />
                                    </button>

                                    <button
                                        className='history-navigation-container__vaccine-details__modal__navbar__button'
                                        onClick={() => setSelectedVaccination(null)}
                                    >
                                        {t('button_close.text')}
                                    </button>
                                </div>
                                <VaccinationItemDetails
                                    vaccination={
                                        relatedVaccinations.find(
                                            (vaccination) => vaccination.id === selectedVaccination.id
                                        ) || relatedVaccinations[0]
                                    }
                                    relatedVaccinations={relatedVaccinations}
                                    onEditVaccination={handleOpenEditVaccinationModal}
                                    onRemoveVaccination={handleOpenRemoveVaccinationModal}
                                />
                            </div>
                        )}
                    </>
                )}

                <ManualVaccinationModal
                    open={showAddManualVaccinationModal || showEditManualVaccinationModal}
                    selectedVaccination={showEditManualVaccinationModal ? vaccinationActionTarget : undefined}
                    onClose={() =>
                        showEditManualVaccinationModal
                            ? setShowEditManualVaccinationModal(false)
                            : setShowAddManualVaccinationModal(false)
                    }
                    onSubmit={showEditManualVaccinationModal ? handleEditManualVaccination : handleAddManualVaccination}
                />

                <ModalPortal
                    open={showNetworkErrorModal}
                    headerTitle={t('error_network_title.text')}
                    onPrimaryAction={() => {
                        setShowNetworkErrorModal(false);
                    }}
                    primaryActionLabel={t('button_close.text')}
                >
                    <p>{t('error_network_message.text')}</p>
                </ModalPortal>

                <ModalPortal
                    open={showRemoveVaccinationModal}
                    size='sm'
                    headerTitle={t('vaccinations.actions.remove') || ''}
                    onCloseClick={() => setShowRemoveVaccinationModal(false)}
                    onPrimaryAction={() => handleRemoveVaccination()}
                    primaryActionLabel={t('button_remove.text') || ''}
                    onSecondaryAction={() => setShowRemoveVaccinationModal(false)}
                    secondaryActionLabel={t('button_cancel.text') || ''}
                >
                    <p>{t('vaccinations.actions.remove_confirmation_text')}</p>
                </ModalPortal>
            </div>
        </>
    );
}
