import {useTranslation} from 'react-i18next';
import {useHandleBookingContext} from '../../../../context/HandleBookingContext';
import {AppointmentType, BookingResponse, ConsentStatus} from '../../../../services/api/types/types';

import './ConsentStatusList.scss';

interface ConsentStatusListProps {
    booking: BookingResponse;
    appointmentType: AppointmentType;
}

export function ConsentStatusList({booking, appointmentType}: ConsentStatusListProps) {
    const {t} = useTranslation();

    const {state} = useHandleBookingContext();

    const translations: Record<ConsentStatus, string> = {
        DENIED: t('handle_booking.consent_status_denied'),
        GIVEN: t('handle_booking.consent_status_given'),
        NOT_GIVEN: t('handle_booking.consent_status_not_given')
    };

    const consentPatients = booking.patients.filter(({consents}) => consents && consents.length > 0);
    const userIsGuardian = (state.user?.age as number) > (appointmentType?.consentAge as number);

    return (
        <div className='consent-status'>
            {consentPatients.map(({familyName, givenName, consents = []}, index) => {
                return (
                    <div className='consent-status__person' key={index}>
                        <b className='consent-status__name'>
                            {consentPatients.length === 1 && consents.length > 1
                                ? t('handle_booking.consent_by_both_guardians_for_x', {givenName, familyName})
                                : t('handle_booking.consent_for_x', {givenName, familyName})}
                        </b>
                        {consents.map((consent, index) => {
                            if (userIsGuardian && index === 0 && consent === 'GIVEN') {
                                return (
                                    <span key={index}>
                                        <b className='consent-status__name'>
                                            {t('handle_booking.guardian_x', {index: 1})}
                                        </b>
                                        {t('handle_booking.consent_status_given_by_you')}
                                    </span>
                                );
                            }

                            return (
                                <span key={index}>
                                    <b className='consent-status__name'>
                                        {t('handle_booking.guardian_x', {index: index + 1})}
                                    </b>
                                    {translations[consent]}
                                </span>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
}
