import {useTranslation} from 'react-i18next';
import {Radio} from '../../../../form/radio/Radio';
import {useState} from 'react';

interface RadioToggleProps {
    onChange(bool: boolean): void;
    id: string;
}

export function RadioToggle({onChange, id}: RadioToggleProps) {
    const {t} = useTranslation();
    const [isApproved, setIsApproved] = useState<boolean | undefined>(undefined);
    return (
        <div className='radio-group' role='radiogroup'>
            <Radio
                checked={isApproved === true}
                name={`consent-${id}`}
                label={t('consent.approve')}
                onClick={() => {
                    setIsApproved(true);
                    onChange(true);
                }}
            />
            <Radio
                checked={isApproved === false}
                name={`consent-${id}`}
                label={t('consent.deny')}
                onClick={() => {
                    setIsApproved(false);
                    onChange(false);
                }}
            />
        </div>
    );
}
