import {addSeconds} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {format} from '../../../../lib/dates/format';
import {Button} from '../../../form/button/Button';

import './SessionWarningModal.scss';

interface SessionWarningModalProps {
    onClose(): void;
}

export function OpenSessionWarningModal({onClose}: SessionWarningModalProps) {
    const {t} = useTranslation();
    const timeoutTime = format(addSeconds(new Date(), 300), 'HH:mm');

    return (
        <div className='session-warning-modal' aria-atomic aria-live='assertive'>
            <h1>{t('session.x_minutes_remaining', {minutes: 5})}</h1>
            <div className='session-warning-modal__content'>
                <p>{t('session.open_session_warning_content', {timeoutTime})}</p>
            </div>

            <Button
                big
                className='btn--wide session-warning-modal__button'
                onClick={() => {
                    onClose();
                }}
            >
                <span>{t('error_ok_button.text')}</span>
            </Button>
        </div>
    );
}
