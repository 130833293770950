import {useTranslation} from 'react-i18next';

interface TravelDuration {
    id: number;
    label: string;
}

export function useTravelDurations() {
    const {t} = useTranslation();
    const travelDurations: Array<TravelDuration> = [
        {
            id: 1,
            label: t('travelModal.travel_duration.less_than_three_weeks')
        },
        {
            id: 2,
            label: t('travelModal.travel_duration.three_weeks_to_three_months')
        },
        {
            id: 3,
            label: t('travelModal.travel_duration.more_than_three_months')
        }
    ];

    return {
        travelDurations
    };
}
