import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Marker} from 'react-leaflet';
import {Icon, LatLngExpression} from 'leaflet';
import {ClinicObjectSlim} from 'src/services/api/types/types';
import {UiStringActions, useUiContext} from 'src/context/UiContext';
import {useMapDataContext} from 'src/context/MapDataContext';
import {ClinicPopup} from 'src/components/map/clinic-popup/ClinicPopup';

interface ClinicMarkerProps {
    name: string;
    position: LatLngExpression;
    clinic: ClinicObjectSlim;
    reasonQuery: string;
    zoomLevel: number;
}

export function ClinicMarker({name, position, clinic, reasonQuery, zoomLevel}: ClinicMarkerProps) {
    const {t} = useTranslation();
    const {state, dispatch} = useUiContext();
    const hoverActive = state.hoveredClinicId === clinic.id || state.stickyClinicId === clinic.id;

    const extraSize = hoverActive ? 6 : 0;
    const baseIconSize = 2 + zoomLevel * 2.5 + extraSize;
    const baseShadowSize = 2 * 1.22 + zoomLevel * 2.5 + extraSize;
    const baseAnchor = 1 + extraSize / 2 + (zoomLevel * 2.5) / 2;

    const {getDistanceToClinic} = useMapDataContext();
    const clinicPopupToRender = useMemo(
        () => (
            <ClinicPopup
                reasonQuery={reasonQuery}
                clinic={clinic}
                isOpenId={state.popupOpenId}
                distanceToClinic={getDistanceToClinic(clinic)}
            />
        ),
        [clinic, getDistanceToClinic, reasonQuery, state.popupOpenId]
    );

    return (
        <Marker
            alt={t(`map_marker.text`, {name}) as string}
            icon={
                new Icon({
                    className: `clinic_map_marker${hoverActive ? ' clinic_map_marker--hovered' : ''}`,
                    iconUrl: `${hoverActive ? '/map_pin_small_dark@2x.png' : '/map_pin_small@2x.png'}`,
                    shadowUrl: '/map_pin_small_shadow@2x.png',
                    iconSize: [baseIconSize, baseIconSize * 1.166],
                    shadowSize: [baseShadowSize, baseShadowSize * 1.159],
                    iconAnchor: [baseAnchor, baseIconSize + extraSize],
                    shadowAnchor: [baseAnchor, baseShadowSize + extraSize - (zoomLevel < 9.2 ? 0 : zoomLevel / 4)],
                    popupAnchor: [0, -32]
                })
            }
            position={position}
            eventHandlers={{
                popupopen: () => dispatch({type: UiStringActions.SET_POPUP_OPEN_ID, value: clinic.id}),
                popupclose: () => dispatch({type: UiStringActions.SET_POPUP_OPEN_ID, value: null})
            }}
        >
            {clinicPopupToRender}
        </Marker>
    );
}
