import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useBookingContext} from '../../../context/BookingContext';
import {useClinicDetailsContext} from '../../../context/ClinicDetailsContext';

export function InitialHeader() {
    const {t} = useTranslation();

    const {
        dataState: {careGivers, clinic}
    } = useClinicDetailsContext();
    const {bookingState} = useBookingContext();

    const navigate = useNavigate();

    if (clinic?.externalBookingUrl) {
        window.location.href = clinic.externalBookingUrl;
    }

    const caregiverName = careGivers.find((caregiver) => caregiver.id === clinic?.principalCareGiver)?.label;

    return (
        <header className='header-nav'>
            {clinic?.logoUrl && <img src={clinic?.logoUrl} height='32' alt={`${clinic?.name} logo`} />}
            {!clinic?.logoUrl && <h3 className='region-name'>{caregiverName}</h3>}

            {clinic?.externalBookingUrl && <div>{t('clinic_details.redirect_msg')}</div>}

            {!clinic?.externalBookingUrl && (
                <div className='header-nav__links'>
                    {clinic && bookingState.bookingType !== 'healthDeclaration' && (
                        <button
                            onClick={() => {
                                navigate(`/hantera`, {
                                    state: {
                                        clinicId: clinic.id
                                    }
                                });
                            }}
                            className='primary'
                        >
                            {t('clinic_details.handle_booking')}
                        </button>
                    )}
                </div>
            )}
        </header>
    );
}
