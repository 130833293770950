import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useBookingContext, BookingActions} from '../../../../context/BookingContext';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {useSessionContext} from '../../../../context/SessionContext';
import {useInterval} from '../../../../hooks/useInterval';
import {fetchPerson} from '../../../../services/api/api';
import {Button} from '../../../form/button/Button';
import {AuthenticatedPeopleList} from '../../booking/people-select/components/AuthenticatedPeople';

export function DropInPeopleList() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {sessionState, refreshSession} = useSessionContext();
    const [displayError, setDisplayError] = useState(false);
    const [delay, setDelay] = useState<number | null>(560);

    useEffect(() => window.scrollTo(0, 0), []);

    const {user, selectedPersonsIds, bookingType} = bookingState;

    const {clinic} = dataState;

    const {userJwt} = sessionState;

    const {relations = []} = user || {};

    const {eidDropinSelfOnly = false} = clinic || {};

    const userHasYoungChildren =
        relations.filter(({relationType, age}) => {
            return relationType === 'child' && (age as number) < 18;
        }).length > 0;
    const showConsentInfo =
        user !== null && ((eidDropinSelfOnly === false && userHasYoungChildren) || (user.age as number) < 18);

    const selectPerson = async (id: string) => {
        if (selectedPersonsIds.includes(id)) {
            dispatch({type: BookingActions.REMOVE_HEALTH_DECLARATION, value: id});
            dispatch({type: BookingActions.SET_SELECTED_IDS, value: selectedPersonsIds.filter((p) => p !== id)});
        } else {
            dispatch({type: BookingActions.SET_SELECTED_IDS, value: [...selectedPersonsIds, id]});
        }
    };

    const handleNext = () => {
        refreshSession();
        dispatch({type: BookingActions.NEXT_STEP});
    };

    useInterval(async () => {
        try {
            const userData = await fetchPerson(userJwt as string);
            !userData && setDisplayError(true);
            dispatch({type: BookingActions.SET_USER, value: userData});
            setDelay(null);
        } catch (e) {
            console.log(e);
            setDisplayError(true);
            setDelay(null);
        }
    }, delay);

    return (
        <main className='main-content' id='main'>
            <h1 className='people__header'>
                {displayError
                    ? t(`people_select.people_list.errors.header`)
                    : t(`people_select.people_list.header.${bookingType}`)}
            </h1>
            {clinic && (
                <>
                    {user && showConsentInfo && (
                        <p className='people__text wrap-html'>{t('people_select.people_list.consent_info')}</p>
                    )}
                    <p className='people__text wrap-html'>
                        {displayError
                            ? t(`people_select.people_list.errors.info`, {clinicName: clinic.name})
                            : t(`people_select.people_list.info.${bookingType}`, {clinicName: clinic.name})}
                        {clinic.phone ? (
                            <>
                                {t('people_select.people_list.info_tel')}{' '}
                                <a
                                    href={`tel:${clinic.phone}`}
                                    aria-label={t('people_select.phonenumber', {phonenumber: clinic.phone})}
                                >
                                    {clinic.phone}
                                </a>
                            </>
                        ) : (
                            '.'
                        )}
                    </p>
                </>
            )}

            {!displayError && (
                <div className='people__list-section'>
                    <AuthenticatedPeopleList
                        user={user}
                        loading={!user}
                        selectedPersonsIds={selectedPersonsIds}
                        selfBookingOnly={eidDropinSelfOnly}
                        selectPerson={selectPerson}
                    />
                    <Button disabled={selectedPersonsIds.length === 0} big onClick={() => handleNext()}>
                        <span>{t('people_select.people_list.next')}</span>
                    </Button>
                </div>
            )}
        </main>
    );
}
