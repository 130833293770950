import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../form/button/Button';
import {SnackBar} from '../../../layout/SnackBar/SnackBar';

import './ShareConsentLink.scss';

export function ShareConsentLink() {
    const {t} = useTranslation();
    const [didCopy, setDidCopy] = useState(false);
    const consentUrl = `${process.env.REACT_APP_LANDING_PAGE_URL}/samtycke`;

    return (
        <div className='share-consent-modal'>
            <h1>{t('handle_booking.send_consent_request')}</h1>
            <p>{t('handle_booking.consent_request.on_location_or_digitally')}</p>
            <p>{t('handle_booking.consent_request.send_request_by_sharing_link')}</p>
            <div className='share-consent-modal__footer'>
                <h2>{t('handle_booking.consent_request.share_link')}</h2>
                <div className='share-consent-modal__share-link'>
                    <p>{consentUrl}</p>
                    <Button
                        onClick={async () => {
                            try {
                                await navigator.clipboard.writeText(consentUrl);
                                setDidCopy(true);
                            } catch (error) {}
                        }}
                        variant='text'
                        color='primary'
                    >
                        <span>{t('button_copy.text')}</span>
                    </Button>
                </div>
            </div>

            {didCopy && (
                <SnackBar
                    content={t('handle_booking.consent_request.link_copied')}
                    onExited={() => setDidCopy(false)}
                />
            )}
        </div>
    );
}
