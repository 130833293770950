import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Navigate, Outlet, RouterProvider} from 'react-router-dom';

import './scss/index.scss';

import './i18n';
import {Root} from './routes/root';
import {Main} from './components/main/Main';
import {AboutRoot} from './routes/AboutRoot';
import {AboutMain} from './components/about/AboutMain';
import {ClinicRoot} from './routes/ClinicRoot';
import {ClinicDetails} from './components/clinic/ClinicDetails';
import {HandleBookingRoot} from './routes/HandleBookingRoot';
import {HandleBookingMain} from './components/clinic/handle-booking/HandleBookingMain';
import {ConsentRoot} from './routes/ConsentRoot';
import {ConsentMain} from './components/clinic/consent/ConsentMain';
import {PolicyRoot} from './routes/PolicyRoot';
import {PrivacyPolicy} from './components/policy/PrivacyPolicy';
import {CookiePolicy} from './components/policy/CookiePolicy';
import {AccessibilityStatement} from './components/policy/AccessibilityStatement';
import {Suspense} from 'react';
import {HistoryRoot} from './routes/HistoryRoot';
import {HistoryMain} from './components/history/HistoryMain';
import {SessionProvider} from './context/SessionContext';
import {HistoryDataProvider} from './context/HistoryDataContext';
import {HandleBookingProvider} from './context/HandleBookingContext';
import {AccountRoot} from './routes/AccountRoot';
import {AccountMain} from './components/account/AccountMain';
import {SessionWarningModal} from './components/session/SessionWarningModal';
import {Navbar} from './components/main/navbar/Navbar';
import {UiProvider} from './context/UiContext';

const NavBarWrapper = () => {
    return (
        <>
            <Navbar />
            <Outlet />
        </>
    );
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <NavBarWrapper />,
        children: [
            {
                path: '/',
                element: <Root />,
                errorElement: <Root />,
                children: [
                    {
                        path: '/hitta/:search?',
                        element: <Main />
                    },
                    {
                        path: '/',
                        element: <Main />
                    }
                ]
            },
            {
                path: '/konto',
                element: <AccountRoot />,
                children: [
                    {
                        path: '/konto',
                        element: <AccountMain />
                    }
                ]
            },
            {
                path: '/mottagning/:clinicId',
                element: <ClinicRoot />,
                children: [
                    {
                        path: '/mottagning/:clinicId',
                        element: <ClinicDetails />
                    },
                    {
                        path: '/mottagning/:clinicId/halsodeklaration',
                        element: <ClinicDetails healthDeclarationMode />
                    }
                ]
            },
            {
                path: '/hantera',
                element: <HandleBookingRoot />,
                children: [
                    {
                        path: '/hantera/:bookingId',
                        element: <HandleBookingMain />
                    },
                    {
                        path: '/hantera',
                        element: <HandleBookingMain />
                    }
                ]
            },
            {
                path: '/samtycke',
                element: <ConsentRoot />,
                children: [
                    {
                        path: '/samtycke/:bookingId',
                        element: <ConsentMain />
                    },
                    {
                        path: '/samtycke',
                        element: <ConsentMain />
                    }
                ]
            },
            {
                path: '/personuppgiftspolicy',
                element: <PolicyRoot />,
                children: [
                    {
                        path: '/personuppgiftspolicy',
                        element: <PrivacyPolicy />
                    }
                ]
            },
            {
                path: '/cookie-policy',
                element: <PolicyRoot />,

                children: [
                    {
                        path: '/cookie-policy',
                        element: <CookiePolicy />
                    }
                ]
            },
            {
                path: '/accessibility-statement',
                element: <PolicyRoot />,

                children: [
                    {
                        path: '/accessibility-statement',
                        element: <AccessibilityStatement />
                    }
                ]
            },
            {
                path: '/vaccinationshistorik',
                element: <HistoryRoot />,
                children: [
                    {
                        path: '/vaccinationshistorik',
                        element: <HistoryMain />
                    }
                ]
            },
            {
                path: '/om-oss',
                element: <AboutRoot />,
                children: [
                    {
                        path: '/om-oss',
                        element: <AboutMain />
                    }
                ]
            },
            {
                path: '/*',
                element: <Navigate to='/' />
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Suspense>
        <SessionProvider>
            <HandleBookingProvider>
                <HistoryDataProvider>
                    <UiProvider>
                        <SessionWarningModal />
                        <RouterProvider router={router} />
                    </UiProvider>
                </HistoryDataProvider>
            </HandleBookingProvider>
        </SessionProvider>
    </Suspense>
);
