import {useEffect} from 'react';
import {HistoryInfoItem} from '../error-messages/HistoryInfoItem';
import {VaccinationListItem} from './VaccinationListItem';
import {HistoryResponse} from '../../../../services/api/types/types';
import {useTranslation} from 'react-i18next';
import './VaccinationList.scss';

export type HistoryNavigationContainerProps = {
    history: HistoryResponse[] | [];
    selectedVaccination: HistoryResponse | null;
    onSelectedVaccination: (vaccination: HistoryResponse) => void;
};
export function VaccinationList({
    history,
    selectedVaccination,
    onSelectedVaccination
}: HistoryNavigationContainerProps) {
    const {t} = useTranslation();

    const onSelected = (vaccination: HistoryResponse) => {
        onSelectedVaccination(vaccination);
    };

    useEffect(() => {
        const selectedVaccinationElement = document.getElementById(`list-item-${selectedVaccination?.id}`);
        selectedVaccinationElement?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        selectedVaccinationElement?.focus({preventScroll: true});
    }, [selectedVaccination]);

    const getVaccinationsItems = () => {
        if (history.length === 0) {
            return <HistoryInfoItem messageType='EMPTY_HISTORY' />;
        }
        const vaccinationItems = history.map((vaccination, index, array) => {
            const isSelected = selectedVaccination?.id === vaccination.id;
            if (index === 0) {
                return [
                    <h1
                        className='vaccine-list__date-header'
                        key={`date-key-${vaccination.when}`}
                        aria-label={t('vaccine.vaccine_list_year', {
                            year: new Date(vaccination.when).toLocaleDateString('se-SE', {year: 'numeric'})
                        })}
                    >
                        {new Date(vaccination.when).toLocaleDateString('se-SE', {year: 'numeric'})}
                    </h1>,
                    <li id={`list-item-${vaccination.id}`} key={`list-item-${vaccination.id}`}>
                        <VaccinationListItem
                            vaccination={vaccination}
                            onSelected={onSelected}
                            isSelected={isSelected}
                        />
                    </li>
                ];
            } else if (new Date(vaccination.when).getFullYear() !== new Date(array[index - 1].when).getFullYear()) {
                return [
                    <h1
                        className='vaccine-list__date-header'
                        key={`date-key-${vaccination.when}`}
                        aria-label={t('vaccine.vaccine_list_year', {
                            year: new Date(vaccination.when).toLocaleDateString('se-SE', {year: 'numeric'})
                        })}
                    >
                        {new Date(vaccination.when).toLocaleDateString('se-SE', {year: 'numeric'})}
                    </h1>,
                    <li id={`list-item-${vaccination.id}`} key={`list-item-${vaccination.id}`}>
                        <VaccinationListItem
                            vaccination={vaccination}
                            onSelected={onSelected}
                            isSelected={isSelected}
                        />
                    </li>
                ];
            } else {
                return (
                    <li id={`list-item-${vaccination.id}`} key={`list-item-${vaccination.id}`}>
                        <VaccinationListItem
                            vaccination={vaccination}
                            onSelected={onSelected}
                            isSelected={isSelected}
                        />
                    </li>
                );
            }
        });
        return (
            <ul data-testid='vaccination-history-list' className='vaccine-list' aria-label={t('vaccine.vaccine_list')}>
                {vaccinationItems}
                <div className='mt-3'>
                    <HistoryInfoItem messageType='INCOMPLETE_HISTORY' />
                </div>
            </ul>
        );
    };

    return (
        <>
            <div className='vaccine-list'>{getVaccinationsItems()}</div>
        </>
    );
}
