import {useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {defaultClearCookieOptions} from './useCaregiverConsentCookie';

export function useClearTrackingCookies() {
    // the removeCookie function is third in the returned array
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, _, removeCookie] = useCookies();

    useEffect(() => {
        Object.keys(cookies)
            .filter((val) => {
                const allowedCookiePrefixes = ['mvj-', 'mv-'];

                return (
                    allowedCookiePrefixes.some((allowedPrefix) => {
                        return val.startsWith(allowedPrefix);
                    }) === false
                );
            })
            .forEach((clearableCookie) => {
                removeCookie(clearableCookie, defaultClearCookieOptions);
            });

        // This hook should only run once, since cookies set after first render should be preserved
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
