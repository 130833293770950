import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {updateBooking} from 'src/services/api/api';
import {BookingPatchRequest, BookingResponse} from 'src/services/api/types/types';
import {validateEmail, validatePhone} from 'src/services/validation/validation';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {Button} from 'src/components/form/button/Button';
import {Input} from 'src/components/form/input/Input';
import './EditContactInfoModal.scss';

interface EditContactInfoModalProps {
    editBooking: BookingResponse;
    userJwt?: string | null;
    onUpdateError?(error: unknown): void;
    onBookingUpdated(updatedData: Partial<BookingPatchRequest>): void;
    handleCloseModal(): void;
}

// Ensure only numbers first, then length
const validPhone = (phone: string) => {
    if (new RegExp(/^[\d]+$/).test(phone) === false) {
        return false;
    }
    if (phone.length > 10) {
        return false;
    }

    return true;
};

export function EditContactInfoModal({
    editBooking,
    onUpdateError,
    onBookingUpdated,
    handleCloseModal,
    userJwt = null
}: EditContactInfoModalProps) {
    const {t} = useTranslation();

    const {id: bookingId, clinicId, contactInfo} = editBooking;

    const initialContactInfo = {
        marketing: contactInfo.marketing,
        preferredContact: contactInfo.preferredContact
    };

    const [phone, setPhone] = useState(contactInfo.mobile);
    const [email, setEmail] = useState(contactInfo.email);
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);

    const [showNetworkError, setShowNetworkError] = useState(false);

    const formValid = validateEmail(email) && validatePhone(phone) && phone.length > 0;

    const updateContactInfo = async () => {
        setIsEmailValid(validateEmail(email));
        setIsPhoneValid(phone.length === 10 && validPhone(phone));

        if (!formValid) {
            return;
        }

        try {
            const updateData = {
                contactInfo: {
                    ...initialContactInfo,
                    mobile: phone,
                    ...(email !== '' && {email})
                }
            } as Partial<BookingPatchRequest>;

            await updateBooking(bookingId, clinicId, updateData, userJwt);
            onBookingUpdated(updateData);
        } catch (error) {
            onUpdateError && onUpdateError(error);
            setShowNetworkError(true);
        }
    };

    return (
        <ModalPortal onCloseClick={() => handleCloseModal()}>
            {showNetworkError && (
                <ModalPortal
                    primaryActionLabel={t('error_ok_button.text')}
                    onPrimaryAction={() => setShowNetworkError(false)}
                >
                    <p>
                        <b>{t('error_network_title.text')}</b>
                        <br />
                        {t('error_network_message.text')}
                    </p>
                </ModalPortal>
            )}

            <div className='booking-info-modal'>
                <h1>{t('handle_booking.change_contact_info')}</h1>
                <div className='booking-info-form'>
                    <div className='booking-info-form__fields'>
                        <Input
                            isValid={isPhoneValid}
                            type='tel'
                            id='phone'
                            required
                            value={phone}
                            label={t('confirm.form.telephone')}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateContactInfo();
                                }
                            }}
                            onChange={(val) => {
                                setIsPhoneValid(val.length === 0 || validPhone(val));
                                setPhone(val);
                            }}
                        />
                        {!isPhoneValid && <p className='input-error'>{t('handle_booking.error.phone_error')}</p>}
                        <Input
                            isValid={isEmailValid}
                            type='text'
                            id='email'
                            label={t('confirm.form.email')}
                            value={email}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    updateContactInfo();
                                }
                            }}
                            onChange={(val) => {
                                setIsEmailValid(val.length >= 0);
                                setEmail(val);
                            }}
                        />
                        {!isEmailValid && <p className='input-error'>{t('confirm.form.email_error')}</p>}
                    </div>
                </div>
                <div className='booking-info-modal__footer'>
                    <p>
                        <Button
                            disabled={contactInfo.mobile === phone && contactInfo.email === email}
                            big
                            className='btn--wide'
                            onClick={() => {
                                updateContactInfo();
                            }}
                        >
                            <span>{t('button_save.text')}</span>
                        </Button>
                    </p>
                </div>
            </div>
        </ModalPortal>
    );
}
