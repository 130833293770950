import {useBookingContext} from '../../../../context/BookingContext';
import {BookingHeader} from '../../booking/booking-header/bookingHeader';
import {InitialHeader} from '../../inital/initialHeader';

export function ClinicHeader() {
    const {bookingState} = useBookingContext();

    const stepComponent = () => {
        if (bookingState.step === 'completed') {
            return null;
        }

        if (bookingState.steps.indexOf(bookingState.step) === 0) {
            return <InitialHeader />;
        }

        return <BookingHeader />;
    };

    return <>{stepComponent()}</>;
}
