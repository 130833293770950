import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {BookingActions, User, useBookingContext} from 'src/context/BookingContext';
import {useClinicDetailsContext} from 'src/context/ClinicDetailsContext';
import {BookingSessionActions, useBookingSessionContext} from 'src/context/BookingSessionContext';
import {useSessionContext} from 'src/context/SessionContext';
import {Button} from 'src/components/form/button/Button';
import {useInterval} from 'src/hooks/useInterval';
import {ConsentRequest} from 'src/services/api/types/types';
import {fetchPerson} from 'src/services/api/api';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {AuthenticatedPeopleList} from '../components/AuthenticatedPeople';
import './PeopleList.scss';

export function PeopleList() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {bookingSessionDispatch, refreshBookingSession} = useBookingSessionContext();
    const {sessionState} = useSessionContext();
    const [displayError, setDisplayError] = useState(false);
    const [delay, setDelay] = useState<number | null>(560);
    const [confirmResetBooking, setConfirmResetBooking] = useState(false);
    const [localConsentRequests, setLocalConsentRequests] = useState<Array<ConsentRequest>>(
        bookingState.consentRequests || []
    );

    const {
        bookingType,
        reasonState: {numberPatients, allowedAge, appointmentType},
        user,
        selectedPersonsIds
    } = bookingState;

    const {clinic} = dataState;

    const {userJwt} = sessionState;
    const {relations = []} = user || {};

    const {eidSelfOnly = false, consentAge = null} = appointmentType || {};

    const userHasYoungChildren =
        relations.filter(({relationType, age}) => {
            return relationType === 'child' && (age as number) < 18;
        }).length > 0;
    const showConsentInfo =
        user !== null &&
        consentAge === null &&
        ((eidSelfOnly === false && userHasYoungChildren) || (user.age as number) < 18);

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        const consentObjects: Array<ConsentRequest> = [];
        const consentAge = appointmentType?.consentAge;

        if (consentAge) {
            const consentStepNeeded = selectedPersonsIds.some((id) => {
                const person = user?.relations?.find(({identity}) => identity === id);
                const age = person?.age || user?.age || 1000;
                return age < consentAge;
            });

            if (consentStepNeeded) {
                dispatch({type: BookingActions.USER_CONSENT_NEEDED, value: (user?.age || 0) >= consentAge});
                const filteredGuardians = user?.otherGuardians?.filter(({children}) =>
                    children.some((id) => selectedPersonsIds.includes(id))
                );

                filteredGuardians?.forEach((_) => {
                    const selectedNames = _.children
                        .filter((id) => selectedPersonsIds.includes(id))
                        .map((id) => {
                            const person =
                                user?.relations?.find((r) => r.identity === id) || (user?.identity === id && user);
                            return person && person?.givenName + ' ' + person?.familyName;
                        })
                        .filter((name) => name)
                        .join(', ');

                    consentObjects.push({
                        id: _.children,
                        displayName: selectedNames,
                        digital: undefined,
                        email: '',
                        mobile: ''
                    });
                });
            }

            setLocalConsentRequests(consentObjects);
            dispatch({type: BookingActions.SET_CONSENT_STEP, value: consentStepNeeded});
        }
    }, [selectedPersonsIds]);

    // Implement logic to add consentRequest when selecting people instead of in the consentRequest step
    const selectPerson = async (id: string) => {
        if (selectedPersonsIds.includes(id)) {
            dispatch({type: BookingActions.REMOVE_HEALTH_DECLARATION, value: id});
            dispatch({type: BookingActions.SET_SELECTED_IDS, value: selectedPersonsIds.filter((p) => p !== id)});
        } else {
            dispatch({type: BookingActions.SET_SELECTED_IDS, value: [...selectedPersonsIds, id]});
        }
    };

    const isDisabled = () => {
        return selectedPersonsIds.length !== numberPatients;
    };

    const showAmountError = () => {
        if (numberPatients && user?.relations) {
            return 1 + user.relations.length < numberPatients;
        }
        return false;
    };

    const showAgeError = () => {
        if (allowedAge && (allowedAge.minAge !== null || allowedAge.maxAge !== null) && user) {
            return (
                !isWithinAgeRange(user.age || 0) ||
                (user.relations && user.relations.some((r) => !isWithinAgeRange(r.age || 0)))
            );
        }
        return false;
    };

    const isDisabledUser = (user: User) => {
        return (
            (selectedPersonsIds.length === numberPatients && !selectedPersonsIds.includes(user.identity)) ||
            !isWithinAgeRange(user.age || 0)
        );
    };

    const isWithinAgeRange = (age: number) => {
        const minAge = allowedAge?.minAge || 0;
        const maxAge = allowedAge?.maxAge || 1000;

        return minAge <= age && maxAge >= age;
    };

    const getAgeErrorTranslationKey = () => {
        if (allowedAge?.minAge && allowedAge.maxAge) {
            return 'people_select.people_list.errors.age_out_of_range_between';
        } else if (allowedAge?.minAge) {
            return 'people_select.people_list.errors.age_out_of_range_over';
        } else if (allowedAge?.maxAge) {
            return 'people_select.people_list.errors.age_out_of_range_under';
        }

        return 'people_select.people_list.errors.age_out_of_range';
    };

    const handleNext = () => {
        refreshBookingSession(bookingState.bookingId);
        dispatch({type: BookingActions.SET_CONSENT_REQUESTS, value: localConsentRequests});
        dispatch({type: BookingActions.NEXT_STEP});
    };

    useInterval(async () => {
        try {
            const userData = await fetchPerson(userJwt as string);
            !userData && setDisplayError(true);
            dispatch({type: BookingActions.SET_USER, value: userData});
            setDelay(null);
        } catch (e) {
            console.log(e);
            setDisplayError(true);
            setDelay(null);
        }
    }, delay);

    return (
        <>
            <main className='main-content' id='main'>
                <h1 className='people__header'>
                    {displayError
                        ? t(`people_select.people_list.errors.header`)
                        : t(`people_select.people_list.header.${bookingType}`)}
                </h1>
                {!displayError && (
                    <p className='people__text wrap-html'>
                        {t('people_select.people_list.subheader', {count: numberPatients || 0})}
                    </p>
                )}
                {user && showConsentInfo && (
                    <p className='people__text wrap-html'>{t('people_select.people_list.consent_info')}</p>
                )}
                {clinic && (
                    <p className='people__text wrap-html'>
                        <>
                            {displayError
                                ? t(`people_select.people_list.errors.info`, {clinicName: clinic.name})
                                : t(`people_select.people_list.info.booking`, {clinicName: clinic.name})}
                            {clinic.phone ? (
                                <>
                                    {t('people_select.people_list.info_tel')}{' '}
                                    <a
                                        href={`tel:${clinic.phone}`}
                                        aria-label={t('people_select.phonenumber', {phonenumber: clinic.phone})}
                                    >
                                        {clinic.phone}
                                    </a>
                                </>
                            ) : (
                                '.'
                            )}
                        </>
                    </p>
                )}
                {showAmountError() && !displayError && (
                    <div className='people__error'>
                        <p>
                            {t('people_select.people_list.errors.amount_not_correct')}
                            &nbsp;
                            <button className='primary' onClick={() => setConfirmResetBooking(true)}>
                                <span>{t('people_select.people_list.links.to_start_page')}</span>
                            </button>
                        </p>
                    </div>
                )}
                {showAgeError() && (
                    <div className='people__error'>
                        <p>
                            {t(getAgeErrorTranslationKey(), {minAge: allowedAge?.minAge, maxAge: allowedAge?.maxAge})}
                            &nbsp;
                            <button className='primary' onClick={() => setConfirmResetBooking(true)}>
                                <span>{t('people_select.people_list.links.to_start_page')}</span>
                            </button>
                        </p>
                    </div>
                )}

                {!displayError && (
                    <div className='people__list-section'>
                        <AuthenticatedPeopleList
                            user={user}
                            loading={!user}
                            selectedPersonsIds={selectedPersonsIds}
                            selfBookingOnly={appointmentType?.eidSelfOnly === true}
                            selectPerson={selectPerson}
                            validateUser={isDisabledUser}
                        />
                        <Button
                            disabled={isDisabled()}
                            big
                            onClick={() => {
                                handleNext();
                            }}
                        >
                            <span>{t('people_select.people_list.next')}</span>
                        </Button>
                    </div>
                )}
                {confirmResetBooking && (
                    <ModalPortal
                        primaryActionLabel={
                            bookingType === 'booking'
                                ? t('timeSelect.confirm_abort_button')
                                : t('timeSelect.confirm_dropin_abort_button')
                        }
                        onPrimaryAction={() => {
                            dispatch({type: BookingActions.RESET_BOOKING});
                            bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
                        }}
                        onSecondaryAction={() => setConfirmResetBooking(false)}
                    >
                        <p>
                            <b>{t('timeSelect.confirm_abort_header')}</b>
                            <br />
                            {t('timeSelect.confirm_abort_text')}
                        </p>
                    </ModalPortal>
                )}
            </main>
        </>
    );
}
