import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {OpenUser} from '../../../../../lib/user/openUser';
import {postValidatePnr} from '../../../../../services/api/api';
import {Button} from '../../../../form/button/Button';
import {Input} from '../../../../form/input/Input';

interface PatientFormProps {
    index: number;
    patient: OpenUser;
    identities: Array<string>;
    ageRange?: [minAge: number | null, maxAge: number | null];
    onRemoveClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    onChange(updatedPatient: OpenUser): void;
    onNetworkError(): void;
}

export function PatientForm({
    index,
    identities,
    patient,
    onChange,
    onRemoveClick,
    onNetworkError,
    ageRange = [null, null]
}: PatientFormProps) {
    const {t} = useTranslation();
    const [givenNameValid, setGivenNameValid] = useState(true);
    const [familyNameValid, setFamilyNameValid] = useState(true);
    const [pnrValid, setPnrValid] = useState(true);
    const [ageValid, setAgeValid] = useState(true);

    const [minAge, maxAge] = ageRange;

    const ageError = () => {
        if (minAge && maxAge) {
            return t('people_select.people_form.identity_age_range_error', {minAge, maxAge});
        } else if (minAge) {
            return t('people_select.people_form.identity_age_over_error', {minAge});
        } else if (maxAge) {
            return t('people_select.people_form.identity_age_under_error', {maxAge});
        }

        return '';
    };

    const duplicateIdentities =
        patient.identity !== '' && identities.filter((identity) => identity === patient.identity).length > 1;

    const pnrTimeout = useRef<NodeJS.Timeout>();
    const pnrValidation = async (pnr: string) => {
        clearTimeout(pnrTimeout.current);

        if (pnr.length === 0) {
            const updatedPatient = {
                ...patient,
                identity: pnr,
                identityValid: false
            };

            setPnrValid(true);
            onChange(updatedPatient);

            return;
        }

        // Debounced pnr validation
        pnrTimeout.current = setTimeout(async () => {
            try {
                const validationResults = await postValidatePnr(pnr);
                const {age} = validationResults;
                const ageValid = age >= (minAge || 0) && age <= (maxAge || 1000);

                const updatedPatient = {
                    ...patient,
                    gender: validationResults.gender,
                    age: validationResults.age,
                    identity: pnr,
                    identityValid: ageValid
                };

                setPnrValid(true);
                setAgeValid(ageValid);
                onChange(updatedPatient);
            } catch (error) {
                if ((error as Error).name === 'InvalidIdentityError') {
                    setPnrValid(false);
                    setAgeValid(true);
                } else {
                    setPnrValid(true);
                    setAgeValid(true);
                    onNetworkError();
                }

                const updatedPatient = {
                    ...patient,
                    identity: pnr,
                    identityValid: false
                };
                onChange(updatedPatient);
            }
        }, 500);
    };

    return (
        <div className='form-container'>
            <div className='people-form__header'>
                <h2>{t('people_select.people_form.person_header', {personNumber: index + 1})}</h2>
                {onRemoveClick && (
                    <Button
                        thin
                        onClick={(e) => onRemoveClick(e)}
                        className='people-form__remove-button'
                        variant='text'
                        color='primary'
                    >
                        <span>{t('button_remove.text')}</span>
                    </Button>
                )}
            </div>
            <div className='people-form__name-container'>
                <div className='people-form__name-container--name-input'>
                    <Input
                        onChange={(val) => {
                            const updatedPatient = {
                                ...patient,
                                givenName: val
                            };

                            setGivenNameValid(val.length > 0);
                            onChange(updatedPatient);
                        }}
                        onBlur={(val) => setGivenNameValid(val.length > 0)}
                        isValid={givenNameValid}
                        type='text'
                        id={`first-name-${index}`}
                        value={patient.givenName}
                        required
                        label={t('people_select.people_form.givenName_label')}
                    />
                    {!givenNameValid && (
                        <p className='input-error'>{t('people_select.people_form.givenName_validation_error')}</p>
                    )}
                </div>
                <div className='people-form__name-container--name-input'>
                    <Input
                        onChange={(val) => {
                            const updatedPatient = {
                                ...patient,
                                familyName: val
                            };

                            setFamilyNameValid(val.length > 0);
                            onChange(updatedPatient);
                        }}
                        onBlur={(val) => setFamilyNameValid(val.length > 0)}
                        isValid={familyNameValid}
                        type='text'
                        id={`last-name-${index}`}
                        value={patient.familyName}
                        required
                        label={t('people_select.people_form.familyName_label')}
                    />
                    {!familyNameValid && (
                        <p className='input-error'>{t('people_select.people_form.familyName_validation_error')}</p>
                    )}
                </div>
            </div>
            <div className='form-content'>
                <Input
                    onChange={async (val) => {
                        const updatedPatient = {
                            ...patient,
                            identity: val,
                            identityValid: false
                        };
                        onChange(updatedPatient);
                        pnrValidation(val);
                    }}
                    isValid={pnrValid && !duplicateIdentities}
                    type='text'
                    defaultValue={patient.identity}
                    id={`pnr-${index}`}
                    ariaLabel={t('people_select.people_form.identity_aria_label')}
                    placeholder={t('people_select.people_form.identity_placeholder')}
                    required
                    label={t('people_select.people_form.identity_label')}
                />
                {!pnrValid && <p className='input-error'>{t('people_select.people_form.identity_validation_error')}</p>}
                {!ageValid && <p className='input-error'>{ageError()}</p>}
                {pnrValid && duplicateIdentities && (
                    <p className='input-error'>{t('people_select.people_form.identity_duplicate_error')}</p>
                )}
            </div>
        </div>
    );
}
