import {useState} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {PlacesFilterObject} from '../services/api/types/types';

export interface SearchFilterMap {
    reason: Array<string>;
    other: Array<string>;
    place: Array<string>;
    caregiver: Array<string>;
    clinic: Array<string>;
}

export interface PlaceFilterMap {
    [id: string]: PlacesFilterObject;
}

function filtersToQuery(filters: SearchFilterMap) {
    const reasons = filters.reason.length > 0 ? [`reason=${filters.reason}`] : [];
    const other = filters.other.length > 0 ? [`other=${filters.other}`] : [];
    const place = filters.place.length > 0 ? [`place=${filters.place}`] : [];
    const caregiver = filters.caregiver.length > 0 ? [`caregiver=${filters.caregiver}`] : [];
    const clinic = filters.clinic.length > 0 ? [`clinic=${filters.clinic}`] : [];

    return [...reasons, ...other, ...place, ...caregiver, ...clinic].join('&');
}

export function useSearchFilters() {
    const [urlSearchParams] = useSearchParams();
    const [searchParams, setSearchParams] = useState<SearchFilterMap>({
        reason: urlSearchParams.get('reason')?.split(',') || [],
        other: urlSearchParams.get('other')?.split(',') || [],
        place: urlSearchParams.get('place')?.split(',') || [],
        caregiver: urlSearchParams.get('caregiver')?.split(',') || [],
        clinic: urlSearchParams.get('clinic')?.split(',') || []
    });
    const [placeFilters, setPlaceFilters] = useState<PlaceFilterMap>({});

    const navigate = useNavigate();

    const setParamsAndLocation = (newSearchParams: SearchFilterMap) => {
        setSearchParams(newSearchParams);
        updateBrowserLocation(newSearchParams);
    };

    const updateBrowserLocation = (filters: SearchFilterMap) => {
        const query = filtersToQuery(filters);

        if (query.length > 0) {
            return navigate(`/hitta?${query}`);
        } else {
            return navigate('/');
        }
    };

    const addFilter = (type: keyof SearchFilterMap, id: string) => {
        // Avoid adding duplicates of the same filter
        const filterArr = searchParams[type].filter((filterId) => filterId !== id);
        filterArr.push(id);

        const newSearchParams = {
            ...searchParams,
            [type]: filterArr
        };
        setParamsAndLocation(newSearchParams);
    };

    const removeFilter = (type: keyof SearchFilterMap, id: string) => {
        const filterArr = searchParams[type].filter((filterId) => filterId !== id);

        const newSearchParams = {
            ...searchParams,
            [type]: filterArr
        };
        setParamsAndLocation(newSearchParams);
    };

    const setFilter = (type: keyof SearchFilterMap, ids: Array<string>) => {
        const newSearchParams = {
            ...searchParams,
            [type]: ids
        };
        setParamsAndLocation(newSearchParams);
    };

    return {
        searchParams,
        placeFilters,
        setPlaceFilters,
        searchParamsCount() {
            const {reason, other, place, caregiver, clinic} = searchParams;

            return reason.length + other.length + place.length + caregiver.length + clinic.length;
        },
        clearParams() {
            const clearedSearchParams = {
                reason: [],
                tags: [],
                other: [],
                place: [],
                caregiver: [],
                clinic: []
            };
            setParamsAndLocation(clearedSearchParams);
        },
        clearParamsByType(type: keyof SearchFilterMap) {
            const newSearchParams = {...searchParams};
            newSearchParams[type] = [];

            setParamsAndLocation(newSearchParams);
        },
        addFilter,
        removeFilter,
        setFilter,
        addPlaceFilter(id: string, {name, category, boundingBox}: PlacesFilterObject) {
            const newFilters = {...placeFilters};

            newFilters[id] = {
                id,
                name,
                boundingBox,
                category
            };

            setPlaceFilters(newFilters);
            addFilter('place', id);
        },
        removePlaceFilter(id: string) {
            const newFilters = {...placeFilters};

            delete newFilters[id];

            setPlaceFilters(newFilters);
            removeFilter('place', id);
        },
        setOthers(other: Array<string>) {
            const newSearchParams = {
                ...searchParams,
                other: other
            };
            setParamsAndLocation(newSearchParams);
        },
        setBoundingBox(places: Array<string>) {
            const newSearchParams = {
                ...searchParams,
                bb: places
            };
            setParamsAndLocation(newSearchParams);
        }
    };
}
