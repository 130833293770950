import './Radio.scss';
import {RadioControl} from './RadioControl';

interface RadioProps {
    label: string;
    name: string;
    onClick(checked: boolean): void;
    tabIndex?: 0 | -1;
    checked?: boolean;
    borderless?: boolean;
}

export function Radio({label, name, onClick, checked = false, tabIndex = 0, borderless}: RadioProps) {
    return (
        <>
            <div className='radio-input'>
                <label
                    className={`radio-input__label${checked ? ' radio-input__label--active' : ''}${
                        borderless ? ' borderless' : ''
                    }`}
                >
                    <RadioControl
                        checked={checked}
                        className={borderless ? 'borderless' : undefined}
                        name={name}
                        ariaLabel={label}
                        onClick={(checked) => onClick(checked)}
                    />
                    <span className='radio-input__text' aria-hidden={true}>
                        {label}
                    </span>
                </label>
            </div>
        </>
    );
}
