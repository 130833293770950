import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useBookingContext, BookingActions} from '../../../../context/BookingContext';
import {useBookingSessionContext, BookingSessionActions} from '../../../../context/BookingSessionContext';
import {Button} from '../../../form/button/Button';
import {CircleCheckmark} from '../../../icons/CircleCheckmark';

export function DropInCompleted() {
    const {t} = useTranslation();
    const {dispatch} = useBookingContext();
    const {bookingSessionDispatch} = useBookingSessionContext();
    const {
        bookingState: {bookingType}
    } = useBookingContext();

    useEffect(() => window.scrollTo(0, 0), []);

    const handleNavigation = () => {
        dispatch({type: BookingActions.RESET_BOOKING});
        bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
        dispatch({type: BookingActions.SET_BOOKING_STEP, value: 'initial'});
    };

    return (
        <main className='main-content completed' id='main'>
            <CircleCheckmark />
            <h1 className='header'>
                {bookingType === 'healthDeclaration'
                    ? t('completed.healthDeclaration_header')
                    : t('completed.dropin_header')}
            </h1>
            <p className='subheader'>
                {bookingType === 'healthDeclaration'
                    ? t('completed.healthDeclaration_subheader')
                    : t('completed.dropin_subheader')}
            </p>
            {bookingType !== 'healthDeclaration' && (
                <Button big variant='outlined' onClick={() => handleNavigation()}>
                    <span>{t('list_clinics_to_clinic.text')}</span>
                </Button>
            )}
        </main>
    );
}
