import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSessionContext} from '../../../../context/SessionContext';
import {useAppState} from '../../../../hooks/useAppState';
import {Button} from '../../../form/button/Button';
import {PolicyLinks} from '../../../policy/links/PolicyLinks';
import {ReactComponent as IconClose} from 'src/components/icons/icon_close.svg';
import {ReactComponent as IconLock} from 'src/components/icons/icon_lock.svg';
import {ReactComponent as IconUser} from 'src/components/icons/icon_user.svg';
import './MobileMenu.scss';

interface MobileMenuProps {
    onCloseClick(): void;
}

export function MobileMenu({onCloseClick}: MobileMenuProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {signOut} = useAppState();
    const location = useLocation();
    const {sessionState} = useSessionContext();

    const handleNavigation = (navigateToPath: string) => {
        const currentLocation = location.pathname;
        if (currentLocation === navigateToPath) {
            onCloseClick();
        } else {
            onCloseClick();
            navigate(navigateToPath);
        }
    };

    return (
        <div className='map-mobile-menu'>
            <div className='map-mobile-menu__header'>
                <Button variant='text' onClick={onCloseClick} className='map-mobile-menu__close-button' thin>
                    <IconClose width='1rem' height='1rem' />
                </Button>
            </div>
            <div className='map-mobile-menu__content'>
                <ul className='map-mobile-menu__main-links'>
                    <li>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleNavigation('/');
                            }}
                            href='/'
                        >
                            {t('handle_booking.book_vaccinations')}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleNavigation('/hantera');
                            }}
                            href='/hantera'
                        >
                            {t('handle_booking.handle_booking_heading')}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleNavigation('/vaccinationshistorik');
                            }}
                            href='/vaccinationshistorik'
                        >
                            {t('history.heading')}
                        </a>
                    </li>
                    <li>
                        <a
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleNavigation('/om-oss');
                            }}
                            href='/om-oss'
                        >
                            {t('about.heading')}
                        </a>
                    </li>
                </ul>
                {sessionState.hasActiveSession && (
                    <ul className='map-mobile-menu__main-bottom-links'>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleNavigation('/konto');
                                }}
                                href='/konto'
                                className='map-mobile-menu__main-bottom-links__icon'
                            >
                                <IconUser /> {t('account.header')}
                            </a>
                        </li>
                        <li>
                            <a
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    signOut();
                                }}
                                href='/'
                                className='map-mobile-menu__main-bottom-links__icon'
                            >
                                <IconLock /> {t('general.signOut')}
                            </a>
                        </li>
                    </ul>
                )}
                <div className='map-mobile-menu__policy-links'>
                    <PolicyLinks />
                </div>
            </div>
        </div>
    );
}
