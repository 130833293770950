import {useTranslation} from 'react-i18next';
import {useUiContext} from '../../../context/UiContext';
import {Button} from '../../form/button/Button';

interface MobileSearchFooterProps {
    paramsCount: number;
    clearParams(): void;
    toggleSearchView(): void;
}

export function MobileSearchFooter({paramsCount, clearParams, toggleSearchView}: MobileSearchFooterProps) {
    const {t} = useTranslation();
    const {state} = useUiContext();
    const {searchExtended} = state;

    return (
        <div className={'search_modal__footer__content footer__flex'}>
            <a
                href='/'
                tabIndex={searchExtended ? 0 : -1}
                onKeyDown={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                        e.preventDefault();
                        clearParams();
                    }
                }}
                role='button'
                onClick={(e) => {
                    e.preventDefault();
                    clearParams();
                }}
            >
                {t('clear_filters.text')} ({paramsCount})
            </a>
            <Button onClick={() => toggleSearchView()}>
                <span>{t('button_finished.text')}</span>
            </Button>
        </div>
    );
}
