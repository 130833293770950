import {useEffect, useRef} from 'react';
import './Input.scss';

interface InputProps {
    label: string;
    id: string;
    tabIndex?: number;
    ariaLabel?: string;
    disabled?: boolean;
    focusOnMount?: boolean;
    onChange(value: string): void;
    onBlur?(value: string): void;
    onKeyDown?(key: React.KeyboardEvent): void;
    type: string;
    value?: string;
    defaultValue?: string;
    placeholder?: string;
    required?: boolean;
    isValid?: boolean;
    errorMessage?: string;
}

export function Input({
    label,
    id,
    tabIndex,
    ariaLabel,
    disabled,
    focusOnMount,
    onChange,
    type,
    value,
    defaultValue,
    required,
    onBlur,
    onKeyDown,
    placeholder,
    isValid,
    errorMessage
}: InputProps) {
    const classNames = () => {
        const classes = ['input'];
        if (!isValid) {
            classes.push('invalid');
        }
        return classes.join(' ');
    };

    const inputRef = useRef<HTMLInputElement>(null);
    const labelRef = useRef<HTMLLabelElement>(null);

    useEffect(() => {
        if (inputRef.current?.value) {
            labelRef.current?.classList.add('has-content');
        } else {
            labelRef.current?.classList.remove('has-content');
        }
    }, [inputRef.current?.value]);

    useEffect(() => {
        if (focusOnMount) {
            inputRef.current?.focus();
        }
    }, [focusOnMount]);

    return (
        <span className='input-container' aria-live='polite'>
            <label
                aria-hidden={errorMessage ? 'true' : 'false'}
                ref={labelRef}
                htmlFor={`input-${id}`}
                className={'input-with-label'}
            >
                {label}
            </label>
            <input
                ref={inputRef}
                onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                id={`input-${id}`}
                type={type}
                value={value}
                defaultValue={defaultValue}
                required={required}
                placeholder={placeholder}
                tabIndex={tabIndex}
                className={classNames()}
                onBlur={(e) => onBlur && onBlur(e.target.value)}
                onChange={(e) => onChange(e.target.value)}
                autoComplete='off'
                disabled={disabled}
                aria-label={ariaLabel ? ariaLabel : ''}
                aria-disabled={disabled ? 'true' : 'false'}
                aria-invalid={isValid ? 'false' : 'true'}
                aria-errormessage={`input-${id}-error-message`}
            />
            <label
                aria-label={errorMessage}
                id={`input-${id}-error-message`}
                htmlFor={`input-${id}`}
                className='input-error-label'
            >
                {errorMessage}
            </label>
        </span>
    );
}
