/* eslint-disable-next-line import/no-duplicates */
import {startOfDay} from 'date-fns';
/* eslint-disable-next-line import/no-duplicates */
import {enGB as en, sv} from 'date-fns/locale';

import {forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import {ChevronDownIcon} from '../../../icons/ChevronDownIcon';
import {useTranslation} from 'react-i18next';
import {format} from '../../../../lib/dates/format';
import {useUiContext} from '../../../../context/UiContext';

import './DateInput.scss';
import {getLocale} from '../../../../services/i18n/locale';

interface DateInputProps {
    min?: Date;
    max?: Date;
    selectedDate: Date;
    onChange(date: Date | null): void;
}

const locales = {en, sv};

export function DateInput({min, max, onChange, selectedDate}: DateInputProps) {
    const {t} = useTranslation();
    const {state} = useUiContext();
    const locale = getLocale();

    if (state.smallDevice) {
        return (
            <input
                aria-label={t('timeSelect.aria_select_date')}
                type='date'
                className='date-input-native'
                value={format(selectedDate, 'yyyy-MM-dd')}
                min={min ? format(min, 'yyyy-MM-dd') : ''}
                onChange={(e) => {
                    if (e.target.value) {
                        const date = startOfDay(new Date(e.target.value));
                        onChange(date);
                    } else {
                        onChange(selectedDate);
                    }
                }}
            />
        );
    }

    const DateInputButton = forwardRef<HTMLButtonElement, any>(
        ({value, onClick}: {value: string; onClick(): void}, ref) => {
            return (
                <button
                    aria-label={t('timeSelect.aria_select_date')}
                    className='date-input-button'
                    onClick={onClick}
                    ref={ref}
                >
                    <span>
                        {value}
                        <ChevronDownIcon />
                    </span>
                </button>
            );
        }
    );

    return (
        <DatePicker
            showPopperArrow={false}
            locale={locales[locale]}
            maxDate={max}
            minDate={min}
            selected={selectedDate}
            onChange={onChange}
            chooseDayAriaLabelPrefix={t('datePicker.chooseDayAriaLabelPrefix')}
            disabledDayAriaLabelPrefix={t('datePicker.disabledDayAriaLabelPrefix')}
            nextMonthAriaLabel={t('datePicker.nextMonthAriaLabel')}
            previousMonthAriaLabel={t('datePicker.previousMonthAriaLabel')}
            monthAriaLabelPrefix={t('datePicker.monthAriaLabelPrefix')}
            dateFormat={'MMMM yyyy'}
            customInput={<DateInputButton />}
        />
    );
}
