import {useTranslation} from 'react-i18next';
import {useBookingContext} from '../../../../context/BookingContext';
import {Button} from '../../../form/button/Button';
import {addSeconds} from 'date-fns';
import {format} from '../../../../lib/dates/format';

import './SessionWarningModal.scss';

interface SessionWarningModalProps {
    refreshCallback(): void;
}

export function SessionWarningModal({refreshCallback}: SessionWarningModalProps) {
    const {t} = useTranslation();

    const {bookingState} = useBookingContext();
    const {bookingType} = bookingState;
    const timeoutTime = format(addSeconds(new Date(), 600), 'HH:mm');

    return (
        <div className='session-warning-modal' aria-atomic aria-live='assertive'>
            <h1>{t('session.x_minutes_remaining', {minutes: 10})}</h1>
            <div className='session-warning-modal__content'>
                <p>{t(`session.${bookingType}.warning_content`, {timeoutTime})}</p>
            </div>

            <Button
                big
                className='btn--wide session-warning-modal__button'
                onClick={() => {
                    refreshCallback();
                }}
            >
                <span>{t('error_ok_button.text')}</span>
            </Button>
        </div>
    );
}
