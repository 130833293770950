/* eslint-disable-next-line import/no-duplicates */
import {startOfDay} from 'date-fns';
/* eslint-disable-next-line import/no-duplicates */
import {enGB as en, sv} from 'date-fns/locale';

import {forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {format} from '../../../../../lib/dates/format';
import {getLocale} from '../../../../../services/i18n/locale';
import {ChevronDownIcon} from '../../../../icons/ChevronDownIcon';
import './DepartureDateInput.scss';

interface DepartureDateInputProps {
    selectedDate?: Date | null;
    useNative: boolean;
    min?: Date;
    max?: Date;
    onChange(date: Date | null): void;
}
const locales = {en, sv};

export function DepartureDateInput({selectedDate = null, useNative, min, max, onChange}: DepartureDateInputProps) {
    const {t} = useTranslation();
    const locale = getLocale();

    if (useNative) {
        return (
            <>
                <div className='departure-date__native'>
                    <label htmlFor='departure-date__native-input' className='departure-date__native-label'>
                        {t('travelModal.departure_date')}
                    </label>
                    <input
                        id='departure-date__native-input'
                        aria-label={t('travelModal.departure_date')}
                        className='departure-date__native-input'
                        required
                        type='date'
                        value={selectedDate ? format(selectedDate, 'yyyy-MM-dd') : ''}
                        min={min ? format(min, 'yyyy-MM-dd') : ''}
                        onChange={(e) => {
                            if (e.target.value) {
                                const date = startOfDay(new Date(e.target.value));

                                if (min && date < min) {
                                    return;
                                }

                                if (max && date > max) {
                                    return;
                                }

                                onChange(date);
                            } else {
                                onChange(selectedDate);
                            }
                        }}
                    />
                </div>
            </>
        );
    }

    const DateInputControl = forwardRef<HTMLButtonElement, any>(
        ({value, onClick}: {value: string; onClick(): void}, ref) => {
            return (
                <button
                    className={`departure-date__button${selectedDate ? ' departure-date__button--with-value' : ''}`}
                    aria-label={t('travelModal.departure_date')}
                    onClick={onClick}
                    ref={ref}
                >
                    <span className='departure-date__button-content'>
                        <span className='departure-date__button-label'>{t('travelModal.departure_date')}</span>
                        <span>{selectedDate && format(selectedDate, 'dd MMM yyyy')}</span>
                    </span>
                    <ChevronDownIcon />
                </button>
            );
        }
    );

    return (
        <div className='departure-date'>
            <DatePicker
                showPopperArrow={false}
                onCalendarOpen={() => {
                    // Timing issue, need to wait for the datepicker to render before scrolling to it
                    setTimeout(() => {
                        const datePicker = document.querySelector('.react-datepicker') as HTMLDivElement;
                        datePicker?.scrollIntoView();
                    }, 0);
                }}
                locale={locales[locale]}
                maxDate={max}
                minDate={min}
                selected={selectedDate}
                onChange={onChange}
                dateFormat={'MMMM yyyy'}
                chooseDayAriaLabelPrefix={t('datePicker.chooseDayAriaLabelPrefix')}
                disabledDayAriaLabelPrefix={t('datePicker.disabledDayAriaLabelPrefix')}
                nextMonthAriaLabel={t('datePicker.nextMonthAriaLabel')}
                previousMonthAriaLabel={t('datePicker.previousMonthAriaLabel')}
                monthAriaLabelPrefix={t('datePicker.monthAriaLabelPrefix')}
                customInput={<DateInputControl />}
                popperPlacement='bottom-start'
                popperModifiers={[
                    {
                        name: 'offset',
                        options: {
                            offset: [-16, -30]
                        },
                        fn: (state) => state
                    }
                ]}
            />
        </div>
    );
}
