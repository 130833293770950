import {useTranslation} from 'react-i18next';
import {EIdPollData} from '../../../services/api/implementations/eidapi';
import {EidAuthenticate} from '../../authentication/EidAuthenticate';
import './HandleHistoryIdentity.scss';

interface HandleIdentifyProps {
    onLoginSuccess(result: EIdPollData): void;
}

export function HandleHistoryIdentity({onLoginSuccess}: HandleIdentifyProps) {
    const {t} = useTranslation();

    return (
        <div
            id='history-sign-in'
            role='region'
            aria-label={`${t('history.login_required_message')} ${t('people_select.choose_method')}`}
            className='handle-history-identify'
            data-testid='authentication-module'
        >
            <h1 className='handle-history-identify__heading'>{t('history.heading')}</h1>
            <p className='handle-history-identify__sub-heading'>{t('history.login_required_message')}</p>
            <h2 className='handle-history-identify__identity-heading'>{t('people_select.choose_method')}</h2>
            <EidAuthenticate onLoginSuccess={onLoginSuccess} />
        </div>
    );
}
