import {useState} from 'react';
import {CheckIcon} from '../../icons/CheckIcon';
import './Checkbox.scss';

interface CheckboxProps {
    id: string;
    label?: string;
    ariaLabel?: string;
    jsx?: JSX.Element | null;
    onClick(checked: boolean): void;
    tabIndex?: 0 | -1;
    checked?: boolean;
    noCheckbox?: boolean;
    required?: boolean;
    ariaInvalid?: boolean;
    ariaErrormessage?: string;
}

export function Checkbox({
    label,
    ariaLabel,
    jsx = null,
    id,
    onClick,
    checked: checkedProp = false,
    tabIndex = 0,
    noCheckbox,
    required,
    ariaInvalid
}: CheckboxProps) {
    const [checked, setChecked] = useState(checkedProp);

    const handleCheck = () => {
        onClick(!checked);
        setChecked(!checked);
    };

    return (
        <>
            <div className='checkbox-container'>
                <input
                    tabIndex={tabIndex}
                    id={`checkbox-${id}`}
                    aria-label={ariaLabel || label}
                    aria-invalid={ariaInvalid}
                    onChange={(e) => {
                        handleCheck();
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleCheck();
                        }
                    }}
                    required={required}
                    checked={checked}
                    type='checkbox'
                />
                <label htmlFor={`checkbox-${id}`} aria-labelledby={`checkbox-${id}`} className={`checkbox_box`}>
                    {!noCheckbox && (
                        <span className={`checkbox  ${ariaInvalid ? 'checkbox--error' : ''}`}>
                            {checked && <CheckIcon />}
                        </span>
                    )}
                    <span className={`checkbox__label`} aria-hidden={true}>
                        {jsx !== null ? jsx : label}
                    </span>
                </label>
            </div>
        </>
    );
}
