import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {ManualVaccinationForm, VaccinationFormData} from './ManualVaccinationForm';
import './ManualVaccinationModal.scss';
import {HistoryResponse} from 'src/services/api/types/types';
import {Button} from 'src/components/form/button/Button';

interface ManualVaccinationModalProps {
    open: boolean;
    selectedVaccination?: HistoryResponse | null;
    onClose(): void;
    onSubmit(form: VaccinationFormData): void;
}

export function ManualVaccinationModal({open, selectedVaccination, onClose, onSubmit}: ManualVaccinationModalProps) {
    const {t} = useTranslation();

    const [showFormModal, setShowFormModal] = useState<boolean>(open);
    const [showActionModal, setShowActionModal] = useState<boolean>(false);

    const [prefilledForm, setPrefilledForm] = useState<VaccinationFormData>();

    useEffect(() => {
        setShowFormModal(open);

        if (open) {
            setShowActionModal(false);
        }

        if (open && selectedVaccination) {
            setPrefilledForm({
                vaccineCategory: selectedVaccination.vaccine.categoryName || '',
                vaccine: selectedVaccination.vaccine.name,
                dose:
                    selectedVaccination.vaccine.dose === undefined || selectedVaccination.vaccine.dose === -1
                        ? t('vaccination_form.dose_option_unknown')
                        : selectedVaccination.vaccine.dose.toString(),
                vaccinationDate: new Date(selectedVaccination.when),
                notificationDate: selectedVaccination.notification?.when
                    ? new Date(selectedVaccination.notification.when)
                    : null
            });
        }
    }, [open, selectedVaccination, t]);

    const handleCloseFormModal = () => {
        setShowActionModal(true);
    };

    const handleCloseActionModal = () => {
        setShowActionModal(false);
    };

    const handleSubmitForm = (form: VaccinationFormData) => {
        setPrefilledForm(undefined);
        onSubmit(form);
    };

    const handleCancelForm = () => {
        setPrefilledForm(undefined);
        onClose();
    };

    return (
        <>
            <ModalPortal
                open={showFormModal && showActionModal}
                onCloseClick={handleCloseActionModal}
                shouldRestoreFocus={true}
                shouldFadeOut={showActionModal}
                headerTitle={
                    <h2 className='manual-vaccination-action-modal-header'>
                        {t('vaccination_form.confirm_cancel_header')}
                    </h2>
                }
                ariaLabel={t('vaccination_form.confirm_cancel_header')}
            >
                <>
                    <p>{t('vaccination_form.confirm_cancel_text')}</p>
                    <Button
                        className='manual-vaccination-action-modal-header__action-button'
                        variant='filled'
                        ariaLabel={t('vaccination_form.confirm_cancel_button_text')}
                        onClick={handleCancelForm}
                    >
                        {t('vaccination_form.confirm_cancel_button_text')}
                    </Button>
                </>
            </ModalPortal>
            <ModalPortal
                open={showFormModal && !showActionModal}
                onCloseClick={handleCloseFormModal}
                shouldRestoreFocus={true}
                shouldFadeOut={false}
                ariaLabel={selectedVaccination ? t('button_change.text') : t('history.add_button_text')}
                headerTitle={
                    <h1 className='manual-vaccination-modal-header' aria-live='polite'>
                        {selectedVaccination ? t('button_change.text') : t('history.add_button_text')}
                    </h1>
                }
            >
                <>
                    {!prefilledForm && (
                        <p className='manual-vaccination-modal-subtitle'>{t('vaccination_form.subtitle')}</p>
                    )}
                    <ManualVaccinationForm prefilledForm={prefilledForm} onSubmitForm={handleSubmitForm} />
                </>
            </ModalPortal>
        </>
    );
}
