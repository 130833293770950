import {Icon, LatLngExpression} from 'leaflet';
import {useTranslation} from 'react-i18next';
import {Marker} from 'react-leaflet';

interface PositionMarkerProps {
    position: LatLngExpression;
}

export function PositionMarker({position}: PositionMarkerProps) {
    const {t} = useTranslation();

    return (
        <Marker
            alt={t(`map_position_marker.text`) as string}
            position={position}
            icon={
                new Icon({
                    className: 'position_map_marker',
                    iconUrl: '/location.png',
                    iconSize: [58, 58]
                })
            }
        />
    );
}
