import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';

interface PositionPermissionErrorProps {
    onClose(): void;
}

export function PositionPermissionError({onClose}: PositionPermissionErrorProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onSecondaryAction={onClose}>
            <p>{t('error_position_permission_message.text')}</p>
        </ModalPortal>
    );
}
