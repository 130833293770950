import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../../form/button/Button';
import './ConfirmRemoveAccount.scss';

interface ConfirmRemoveAccountProps {
    onConfirmCloseAccount: () => void;
}

export function ConfirmRemoveAccount({onConfirmCloseAccount}: ConfirmRemoveAccountProps) {
    const {t} = useTranslation<any, undefined>();
    return (
        <>
            <div className='confirm-remove-account__text-content' data-testid='confirm-remove-account'>
                <Trans t={t} i18nKey='account.remove_account_explanation'>
                    <h1>Avsluta konto</h1>
                    <p>
                        Om du avslutar ditt konto kommer bl.a. dina manuellt tillagda vaccinationer, påminnelser och
                        familjemedlemmar raderas. Eventuella bokningar du gjort hos någon av vara anslutna mottagningar
                        samt journalförd information kommer inte raderas.
                    </p>
                    <h3>Vad kommer tas bort?</h3>
                    <ul>
                        <li>Manuellt tillagda vaccinationer för dig och dina barn</li>
                        <li>Egentillagda påminnelser</li>
                        <li>Kontaktuppgifter</li>
                    </ul>
                    <h3>Vad kommer inte tas bort?</h3>
                    <ul>
                        <li>Vaccinationer journalförda av vårdgivare</li>
                        <li>Påminnelser från vårdgivare</li>
                    </ul>
                </Trans>
            </div>

            <Button
                className='confirm-remove-account__remove-button'
                onClick={onConfirmCloseAccount}
                variant='outlined'
                big
                ariaLabel={t('account.remove_account')}
            >
                {t('account.remove_account')}
            </Button>
        </>
    );
}
