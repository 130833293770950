import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EIdPollData} from '../../../../services/api/implementations/eidapi';
import {BookingResponse} from '../../../../services/api/types/types';
import {BookingCodeModal} from './BookingCodeModal';
import {EidAuthenticate} from '../../../authentication/EidAuthenticate';

interface HandleBookingIdentifyProps {
    onLoginSuccess(result: EIdPollData): void;
    onBookingSuccess(booking: BookingResponse): void;
}

export function HandleBookingIdentity({onLoginSuccess, onBookingSuccess}: HandleBookingIdentifyProps) {
    const {t} = useTranslation();
    const [showBookingCodeModal, setShowBookingCodeModal] = useState(false);

    return (
        <div id='handle-booking-identity' className='handle-booking__identify'>
            <h1 className='handle-booking__heading'>{t('handle_booking.handle_booking_heading')}</h1>
            <p className='handle-booking__sub-heading'>{t('handle_booking.handle_booking_sub_heading')}</p>

            <h2 className='handle-booking__identify__heading'>{t('people_select.choose_method')}</h2>
            <div className='handle-booking__identify-booking-code'>
                <div className='login__section__buttons'>
                    <button className={'login__section__buttons__button'} onClick={() => setShowBookingCodeModal(true)}>
                        <span>{t('booking.information.bookingcode')}</span>
                    </button>
                </div>
            </div>
            <EidAuthenticate onLoginSuccess={onLoginSuccess} />

            {showBookingCodeModal && (
                <BookingCodeModal
                    onBookingSuccess={onBookingSuccess}
                    handleCloseModal={() => setShowBookingCodeModal(false)}
                />
            )}
        </div>
    );
}
