import './style.scss';

type ButtonVariant = 'filled' | 'outlined' | 'text' | 'lighter';
type ButtonColor = 'primary' | 'secondary' | 'tertiary';

interface ButtonProps {
    disabled?: boolean;
    ariaLabel?: string;
    variant?: ButtonVariant;
    color?: ButtonColor;
    onDark?: boolean;
    onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    tabIndex?: 0 | -1;
    className?: string;
    id?: string;
    children: React.ReactNode;
    big?: boolean;
    thin?: boolean;
}

export function Button({
    children,
    ariaLabel,
    tabIndex = 0,
    disabled = false,
    variant = 'filled',
    color = 'primary',
    onClick = () => {},
    onDark = false,
    className,
    id = undefined,
    big = false,
    thin = false
}: ButtonProps) {
    const classNames = () => {
        const classes = ['btn'];
        if (variant) {
            classes.push(`btn--${variant}`);
        }
        if (color) {
            classes.push(`btn--${color}`);
        }
        if (onDark) {
            classes.push('btn--on-dark');
        }
        if (big) {
            classes.push('btn--big');
        }
        if (thin) {
            classes.push('btn--thin');
        }
        if (className) {
            classes.push(className);
        }

        return classes.join(' ');
    };

    return (
        <button
            id={id}
            tabIndex={tabIndex}
            className={classNames()}
            aria-label={ariaLabel}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
