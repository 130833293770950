import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {HandleBookingActions, useHandleBookingContext} from 'src/context/HandleBookingContext';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import './HandleBookingHeader.scss';

type LocationState = {
    clinicId: string;
};

interface Path {
    pathname: string;
    search: string;
    hash: string;
}
interface Location<State = LocationState> extends Path {
    state: State;
    key: string;
}

export function HandleBookingHeader() {
    const {t} = useTranslation();
    const location: Location = useLocation();
    const navigate = useNavigate();
    const {state, dispatch} = useHandleBookingContext();
    const [confirmClose, setConfirmClose] = useState(false);
    const [redirectHome, setRedirectHome] = useState(false);

    const handleClose = () => {
        setConfirmClose(false);
        dispatch({type: HandleBookingActions.EDIT_TIMESLOT, value: null});
        if (redirectHome) {
            setRedirectHome(false);
            navigate('/');
        }
    };

    useEffect(() => {
        if (confirmClose && state.editTimeSlot === null) {
            setConfirmClose(false);
        }
    }, [confirmClose, state.editTimeSlot]);

    const getRedirectHandler = () => {
        dispatch({type: HandleBookingActions.CLEAR_CONTEXT, value: null});
        if (location.state) {
            if (location.state.clinicId === 'map') {
                navigate(`/`);
            } else {
                navigate(`/mottagning/${location.state.clinicId}`);
            }
        } else {
            navigate(`/`);
        }
    };

    return (
        <header className='handle-header-nav'>
            <button
                onClick={() => {
                    dispatch({type: HandleBookingActions.CLEAR_CONTEXT, value: null});
                    if (state.timeSlotChanged) {
                        setRedirectHome(true);
                        setConfirmClose(true);
                    } else {
                        handleClose();
                        navigate('/');
                    }
                }}
            >
                <img className='logo' src='/mittvaccin_logo.svg' alt={t('logo_alt.text')} />
            </button>

            {confirmClose && (
                <ModalPortal
                    onSecondaryAction={() => setConfirmClose(false)}
                    secondaryActionLabel={t('handle_booking.pick_new_time')}
                    onPrimaryAction={() => handleClose()}
                    primaryActionLabel={t('handle_booking.dont_change_time')}
                >
                    <p>
                        <b>{t('handle_booking.time_change_confirm_heading')}</b>
                        <br />
                        {t('handle_booking.time_change_confirm_content')}
                    </p>
                </ModalPortal>
            )}

            {state.editTimeSlot !== null && (
                <button
                    className='handle-button'
                    onClick={() => {
                        if (state.timeSlotChanged) {
                            setConfirmClose(true);
                        } else {
                            handleClose();
                        }
                    }}
                >
                    <span>{t('button_cancel.text')}</span>
                </button>
            )}

            {state.editTimeSlot === null && (
                <button className='handle-button' onClick={getRedirectHandler}>
                    <span>{t('button_close.text')}</span>
                </button>
            )}
        </header>
    );
}
