import './BookingStepIndicator.scss';
import {useTranslation} from 'react-i18next';
import {useBookingContext} from '../../../../context/BookingContext';
import {Spinner} from '../../../layout/loader/Spinner';
import {TimeSelectNextButton} from '../../components/BookingTime/TimeSelectNextButton';
import {BookingType} from '../booking-initial/BookingInitial';
import {BookingStepItem} from './BookingStepItem';

export function BookingStepIndicator() {
    const {t} = useTranslation();
    const {bookingState} = useBookingContext();

    const {step, timeSlotState, bookingType} = bookingState;

    return (
        <aside
            className={`aside-content aside-content--booking-indicator${
                step === 'timeSelect' ? ' aside-content--time-select' : ''
            }`}
        >
            <div className='aside-content__container step-width'>
                <h2 className='aside-padding'>{t(`clinic_aside.heading.${bookingType as BookingType}`)}</h2>
                <ul className='stepindicator__list'>
                    {bookingState.steps.map((step, i) => {
                        return <BookingStepItem bookingStep={step} key={i} />;
                    })}
                </ul>
                {step === 'timeSelect' && (
                    <div className='stepindicator__footer'>
                        {timeSlotState.lockLoading ? (
                            <div className='timeslot__spinner'>
                                <Spinner />
                            </div>
                        ) : (
                            <TimeSelectNextButton className='btn--wide' />
                        )}
                    </div>
                )}
            </div>
        </aside>
    );
}
