import {useEffect, useState} from 'react';
import {CheckIcon} from '../../icons/CheckIcon';
import './style.scss';

interface ToggleProps {
    id: string;
    label: string;
    onClick(checked: boolean): void;
    tabIndex?: 0 | -1;
    checked?: boolean;
    noCheckbox?: boolean;
}

export function Toggle({label, id, onClick, checked: checkedProp = false, tabIndex = 0, noCheckbox}: ToggleProps) {
    const [checked, setChecked] = useState(checkedProp);
    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    return (
        <>
            <div className='toggle_box_container'>
                <input
                    tabIndex={tabIndex}
                    id={`toggle-${id}`}
                    aria-label={label}
                    onClick={(e) => {
                        const checked = e.currentTarget.checked;
                        onClick(checked);
                    }}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onClick(!checked);
                        }
                    }}
                    checked={checked}
                    type='checkbox'
                />
                <label htmlFor={`toggle-${id}`} aria-labelledby={`toggle-${id}`} className='toggle_box'>
                    <span className='checkbox__label' aria-hidden={true}>
                        {label}
                    </span>
                    {!noCheckbox && <span className='checkbox'>{checked && <CheckIcon />}</span>}
                </label>
            </div>
        </>
    );
}
