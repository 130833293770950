export function FrejaIcon() {
    const randomIdSuffix = Math.floor(Math.random() * 10000);
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='70'
                height='18'
                viewBox='0 0 70 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath={`url(#clip0_3232_344932_${randomIdSuffix})`}>
                    <path
                        d='M50.762 16.4396L52.7299 12.1542C52.7483 12.1175 52.7483 12.0807 52.7483 12.0439V0.916729C52.7483 0.714417 52.5828 0.548889 52.3805 0.548889H46.3479C46.2375 0.548889 46.1272 0.622457 46.0904 0.732809L45.0421 3.27091C44.9685 3.45483 45.0972 3.65714 45.2996 3.65714H48.978C49.1803 3.65714 49.3458 3.82267 49.3458 4.02498V12.0071C49.3458 13.0371 48.6469 14.067 47.3595 14.1774C46.1088 14.2693 44.803 13.3497 44.0305 12.6876C43.9018 12.5405 43.6443 12.5773 43.5707 12.7612L42.4856 15.0234C42.4304 15.1154 42.4488 15.2441 42.5224 15.3177L42.5591 15.3545C44.1409 16.7339 45.226 17.4511 47.3595 17.4511C48.7756 17.4511 49.6952 17.1385 50.6516 16.5315C50.7068 16.5315 50.7436 16.4764 50.762 16.4396Z'
                        fill='#3E3A93'
                    />
                    <path
                        d='M11.7893 3.43644L12.6905 1.06387C12.7824 0.806377 12.6169 0.548889 12.3594 0.548889H1.58171C1.43458 0.548889 1.30583 0.640849 1.25066 0.787985L0.018392 3.80428C0.018392 3.80428 0 3.87784 0 3.91463V16.9178C0 17.304 0.165528 17.4695 0.36784 17.4695H3.2186C3.42091 17.4695 3.58644 17.304 3.58644 17.1017V10.9772C3.58644 10.7748 3.75197 10.6093 3.95428 10.6093H8.69942C8.84656 10.6093 8.99369 10.5174 9.04887 10.3702L10.0053 8.01605C10.0972 7.77695 9.93169 7.51946 9.65581 7.51946H3.95428C3.75197 7.51946 3.58644 7.35393 3.58644 7.15162V4.04337C3.58644 3.84106 3.75197 3.67553 3.95428 3.67553H11.4582C11.6054 3.67553 11.7341 3.58357 11.7893 3.43644Z'
                        fill='#3E3A93'
                    />
                    <path
                        d='M41.7866 3.45481L42.8166 1.08224C42.9269 0.843141 42.743 0.567261 42.4855 0.567261H32.4251C32.278 0.567261 32.1308 0.659221 32.0757 0.806357L30.7698 4.04335C30.7514 4.08013 30.7514 4.13531 30.7514 4.17209V17.1017C30.7514 17.304 30.917 17.4695 31.1193 17.4695H38.5129C38.66 17.4695 38.7887 17.3776 38.8623 17.2488L39.8371 14.8762C39.9291 14.6371 39.7635 14.3613 39.4876 14.3613H34.5402C34.3379 14.3613 34.1724 14.1957 34.1724 13.9934V10.8852C34.1724 10.6829 34.3379 10.5173 34.5402 10.5173H38.7152C38.8623 10.5173 38.9911 10.4254 39.0462 10.2966L40.021 7.94246C40.113 7.70336 39.9474 7.42748 39.69 7.42748H34.5402C34.3379 7.42748 34.1724 7.26195 34.1724 7.05964V4.04335C34.1724 3.84104 34.3379 3.67551 34.5402 3.67551H41.474C41.5843 3.67551 41.7131 3.58355 41.7866 3.45481Z'
                        fill='#3E3A93'
                    />
                    <path
                        d='M27.5328 16.3476L24.7372 12.191C24.6085 12.0071 24.6821 11.768 24.866 11.6576C26.7052 10.6461 27.7167 8.82525 27.7167 6.4159C27.7167 2.68232 25.197 0.530457 20.8197 0.530457H15.4493C15.3021 0.530457 15.155 0.622417 15.0998 0.769553L14.0699 3.56514C14.0515 3.60192 14.0515 3.6571 14.0515 3.69388V17.1017C14.0515 17.304 14.217 17.4695 14.4193 17.4695H17.0862C17.2885 17.4695 17.454 17.304 17.454 17.1017V12.9267C17.454 12.7244 17.6195 12.5588 17.8218 12.5588H20.8014C20.9301 12.5588 21.0404 12.5588 21.1692 12.5588C21.2979 12.5588 21.4267 12.6324 21.4819 12.7428L24.2407 17.2856C24.3142 17.3959 24.4246 17.4695 24.5533 17.4695H27.2202C27.4225 17.4695 27.588 17.304 27.588 17.1017V16.5683C27.588 16.4947 27.5696 16.4212 27.5328 16.3476ZM20.8014 9.45058H17.8218C17.6195 9.45058 17.454 9.28505 17.454 9.08274V4.04333C17.454 3.84102 17.6195 3.67549 17.8218 3.67549H20.8014C23.2107 3.67549 24.4246 4.63188 24.4246 6.50786C24.443 8.45741 23.2107 9.45058 20.8014 9.45058Z'
                        fill='#3E3A93'
                    />
                    <path
                        d='M69.9632 14.067L64.0778 0.787965C64.0226 0.659221 63.8938 0.567261 63.7467 0.567261H63.7099H60.9511C60.804 0.567261 60.6752 0.659221 60.6201 0.787965L53.4656 16.9545C53.3552 17.1936 53.5391 17.4695 53.7966 17.4695H56.5738C56.7209 17.4695 56.8497 17.3776 56.9049 17.2488L58.2659 14.0302C58.321 13.9015 58.4498 13.8095 58.5969 13.8095H65.8986C66.0273 13.8095 66.1193 13.8647 66.1928 13.975L67.4803 17.1201L67.5538 17.2856L67.609 17.396C67.6458 17.4511 67.6642 17.4695 67.701 17.4879C67.7378 17.4879 67.7745 17.4879 67.8297 17.4879H68.4551C68.6022 17.4879 68.7309 17.396 68.7861 17.2672L69.9448 14.3981C70.0184 14.2693 70.0184 14.159 69.9632 14.067ZM64.2617 10.7013H60.2154C59.9579 10.7013 59.774 10.4254 59.8844 10.1863L61.9075 5.44114C62.0362 5.14687 62.4593 5.14687 62.588 5.44114L64.6111 10.1863C64.7031 10.4438 64.5376 10.7013 64.2617 10.7013Z'
                        fill='#3E3A93'
                    />
                    <path
                        fill={`url(#paint0_linear_3232_344932_${randomIdSuffix})`}
                        opacity='0.8'
                        d='M12.3594 0.548889H1.58169C1.43456 0.548889 1.30581 0.640849 1.25064 0.787985L0.0735474 3.67553H3.88069C3.89909 3.67553 3.93587 3.67553 3.95426 3.67553H11.4582C11.6053 3.67553 11.7525 3.58357 11.8077 3.43643L12.6905 1.06387C12.7824 0.806377 12.6169 0.548889 12.3594 0.548889Z'
                    />
                    <path
                        fill={`url(#paint1_linear_3232_344932_${randomIdSuffix})`}
                        opacity='0.8'
                        d='M42.4671 0.548889H32.4251C32.278 0.548889 32.1308 0.640849 32.0757 0.787985L30.917 3.67553H34.0988C34.1172 3.67553 34.154 3.67553 34.1723 3.67553H41.4372C41.5843 3.67553 41.7315 3.58357 41.7866 3.43643L42.8166 1.06387C42.9086 0.806377 42.7246 0.548889 42.4671 0.548889Z'
                    />
                    <path
                        fill={`url(#paint2_linear_3232_344932_${randomIdSuffix})`}
                        opacity='0.8'
                        d='M20.8014 0.548889H15.4493C15.3021 0.548889 15.155 0.640849 15.0998 0.787985L14.0699 3.58357C14.0515 3.62035 14.0515 3.63875 14.0515 3.67553H17.7851C17.8035 3.67553 17.8035 3.67553 17.8218 3.67553H20.8014C23.2107 3.67553 24.4246 4.63192 24.4246 6.5079C24.4246 8.45745 23.2107 9.45062 20.8014 9.45062H17.8218C17.7851 9.45062 17.7483 9.45062 17.7115 9.43223H14.0515V12.5589H19.4036C19.9737 12.5589 20.5806 12.5405 21.1692 12.5037C22.5854 12.4117 24.0016 12.1726 25.1051 11.5289C26.9075 10.4806 27.6984 8.42067 27.6984 6.41594C27.6984 2.70075 25.197 0.548889 20.8014 0.548889Z'
                    />
                    <path
                        fill={`url(#paint3_linear_3232_344932_${randomIdSuffix})`}
                        opacity='0.8'
                        d='M49.0515 3.67553H52.7667V0.916729C52.7667 0.714417 52.6012 0.548889 52.3989 0.548889H46.3479C46.2375 0.548889 46.1272 0.622457 46.0904 0.732809L45.0421 3.27091C44.9685 3.45483 45.0972 3.65714 45.2996 3.65714H48.978C48.9963 3.67553 49.0331 3.67553 49.0515 3.67553Z'
                    />
                    <path
                        fill={`url(#paint4_linear_3232_344932_${randomIdSuffix})`}
                        opacity='0.8'
                        d='M56.9049 17.2304L56.9784 17.0649L63.949 0.622437C63.8754 0.585653 63.8019 0.567261 63.7099 0.567261H60.9511C60.804 0.567261 60.6752 0.659221 60.6201 0.787965L53.4656 16.9545C53.3552 17.1936 53.5391 17.4695 53.7966 17.4695H56.5738C56.7026 17.4695 56.8129 17.3959 56.8865 17.2856L56.9049 17.2304Z'
                    />
                </g>
                <defs>
                    <linearGradient
                        id={`paint0_linear_3232_344932_${randomIdSuffix}`}
                        x1='2.51397'
                        y1='6.11933'
                        x2='10.2918'
                        y2='-1.65856'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0.4372' stopColor='#6C6CEA' stopOpacity='0.4' />
                        <stop offset='0.5416' stopColor='#6767E1' stopOpacity='0.5113' />
                        <stop offset='0.7089' stopColor='#5B5ACA' stopOpacity='0.6897' />
                        <stop offset='0.9171' stopColor='#4744A4' stopOpacity='0.9116' />
                        <stop offset='1' stopColor='#3E3A93' />
                    </linearGradient>
                    <linearGradient
                        id={`paint1_linear_3232_344932_${randomIdSuffix}`}
                        x1='33.1384'
                        y1='5.9083'
                        x2='40.5557'
                        y2='-1.50896'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0.2002' stopColor='#6C6CEA' stopOpacity='0.4' />
                        <stop offset='0.3506' stopColor='#6767E1' stopOpacity='0.5128' />
                        <stop offset='0.5978' stopColor='#5A59C8' stopOpacity='0.6983' />
                        <stop offset='0.9095' stopColor='#4541A0' stopOpacity='0.9321' />
                        <stop offset='1' stopColor='#3E3A93' />
                    </linearGradient>
                    <linearGradient
                        id={`paint2_linear_3232_344932_${randomIdSuffix}`}
                        x1='16.0093'
                        y1='0.179909'
                        x2='23.2022'
                        y2='12.6383'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0.1036' stopColor='#6C6CEA' stopOpacity='0.4' />
                        <stop offset='0.3689' stopColor='#5E5DCF' stopOpacity='0.5776' />
                        <stop offset='1' stopColor='#3E3A93' />
                    </linearGradient>
                    <linearGradient
                        id={`paint3_linear_3232_344932_${randomIdSuffix}`}
                        x1='45.0065'
                        y1='2.11995'
                        x2='52.7689'
                        y2='2.11995'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0.146' stopColor='#6C6CEA' stopOpacity='0.4' />
                        <stop offset='0.3749' stopColor='#6A6AE7' stopOpacity='0.5608' />
                        <stop offset='0.5465' stopColor='#6565DD' stopOpacity='0.6814' />
                        <stop offset='0.6995' stopColor='#5D5CCD' stopOpacity='0.7889' />
                        <stop offset='0.8416' stopColor='#514EB7' stopOpacity='0.8887' />
                        <stop offset='0.9749' stopColor='#413E99' stopOpacity='0.9824' />
                        <stop offset='1' stopColor='#3E3A93' />
                    </linearGradient>
                    <linearGradient
                        id={`paint4_linear_3232_344932_${randomIdSuffix}`}
                        x1='53.4171'
                        y1='9.01282'
                        x2='63.9442'
                        y2='9.01282'
                        gradientUnits='userSpaceOnUse'
                    >
                        <stop offset='0' stopColor='#6C6CEA' stopOpacity='0.4' />
                        <stop offset='0.5572' stopColor='#4F4DB4' stopOpacity='0.756' />
                        <stop offset='0.939' stopColor='#3E3A93' />
                    </linearGradient>
                    <clipPath id={`clip0_3232_344932_${randomIdSuffix}`}>
                        <rect width='70' height='16.9023' fill='white' transform='translate(0 0.548889)' />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
