import {useBookingContext} from '../../../../context/BookingContext';
import {BookingInformation} from '../../booking/booking-information/BookingInformation';
import {BookingInitial} from '../../booking/booking-initial/BookingInitial';
import {BookingTime} from '../../booking/booking-time/BookingTime';
import {BookingHealthDeclaration} from '../../booking/booking-health-declaration/BookingHealthDeclaration';
import {PeopleMain} from '../../booking/people-select/PeopleMain';
import {BookingConfirm} from '../../booking/booking-confirm/BookingConfirm';
import {BookingCompleted} from '../../booking/booking-completed/BookingCompleted';
import {BookingConsent} from '../../booking/booking-consent/BookingConsent';
import {DropInPeople} from '../../dropin/dropin-people/DropInPeople';
import {DropInConfirm} from '../../dropin/dropin-confirm/DropInConfirm';
import {DropInCompleted} from '../../dropin/dropin-completed/DropInCompleted';

export function ClinicMain() {
    const {bookingState} = useBookingContext();
    const {step, bookingType} = bookingState;

    const stepComponent = () => {
        if (bookingType === 'booking') {
            switch (step) {
                case 'initial':
                    return <BookingInitial />;
                case 'information':
                    return <BookingInformation />;
                case 'timeSelect':
                    return <BookingTime />;
                case 'peopleSelect':
                    return <PeopleMain />;
                case 'consent':
                    return <BookingConsent />;
                case 'healthDeclaration':
                    return <BookingHealthDeclaration />;
                case 'confirm':
                    return <BookingConfirm />;
                case 'completed':
                    return <BookingCompleted />;
            }
        } else if (bookingType === 'dropIn' || bookingType === 'healthDeclaration') {
            switch (step) {
                case 'initial':
                    return <BookingInitial />;
                case 'peopleSelect':
                    return <DropInPeople />;
                case 'healthDeclaration':
                    return <BookingHealthDeclaration />;
                case 'confirm':
                    return <DropInConfirm />;
                case 'completed':
                    return <DropInCompleted />;
            }
        }
    };

    return <>{stepComponent()}</>;
}
