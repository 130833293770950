import {useState} from 'react';
import {BankIdIcon} from '../../icons/BankIdIcon';
import {EIdOnDevice} from './EIdOnDevice';
import {EIdOtherDevice} from './EIdOtherDevice';
import {FrejaIcon} from '../../icons/FrejaIcon';
import {Trans, useTranslation} from 'react-i18next';
import {Button} from '../../form/button/Button';
import ModalPortal from '../../layout/modal/ModalPortal';
import {EIdInitData, EIdPollData} from '../../../services/api/implementations/eidapi';
import {useStateRestore} from 'src/hooks/useStateRestore';
import './modal.scss';

interface EidModalProps {
    closeModal(): void;
    initData: EIdInitData;
    selectedType: string;
    displayError: boolean;
    onLoginSuccess(data: EIdPollData): void;
}

export function EidModal({closeModal, initData, selectedType, displayError, onLoginSuccess}: EidModalProps) {
    const {t} = useTranslation();
    const [onThisDevice, setOnThisDevice] = useState(window.screen.width <= 820 ? true : false);
    const [errorKey, setErrorKey] = useState<null | string>('GENERIC_ERROR');
    const [showError, setShowError] = useState(displayError);
    const {clearSessionStorage} = useStateRestore();

    const selectedTypeIcon = () => {
        switch (selectedType) {
            case 'BankID':
                return <BankIdIcon />;
            case 'Freja eID+':
                return <FrejaIcon />;
        }
    };

    const eIdName = selectedType === 'BankID' ? 'BankID' : 'Freja';
    const handleCloseModal = () => {
        clearSessionStorage();
        setShowError(false);
        closeModal();
    };

    const getAriaLabel = () => {
        if (showError) {
            return t('people_select.same_device.error_header');
        }
        if (onThisDevice) {
            return `${selectedType} ${t('people_select.same_device.on_this_device')}`;
        }
        return `${selectedType} ${t('people_select.other_device.on_other_device')}`;
    };

    return (
        <>
            <ModalPortal
                open={onThisDevice && !showError}
                headerIcon={selectedTypeIcon()}
                onCloseClick={() => handleCloseModal()}
                shouldFadeOut={false}
            >
                <div className='eid-modal'>
                    <EIdOnDevice
                        onLoginSuccess={onLoginSuccess}
                        selectedType={selectedType}
                        initData={initData}
                        setMessage={(type: string) => setErrorKey(type)}
                        showError={() => setShowError(true)}
                        closeModalAfterComplete={() => handleCloseModal()}
                        onSelectOtherDevice={() => setOnThisDevice(false)}
                    />
                </div>
            </ModalPortal>
            <ModalPortal
                open={!onThisDevice && !showError}
                headerIcon={selectedTypeIcon()}
                onCloseClick={() => handleCloseModal()}
                shouldFadeOut={false}
            >
                <div className='eid-modal'>
                    <EIdOtherDevice
                        onLoginSuccess={onLoginSuccess}
                        selectedType={selectedType}
                        initData={initData}
                        setMessage={(type: string) => setErrorKey(type)}
                        showError={() => setShowError(true)}
                        onSelectSameDevice={() => setOnThisDevice(true)}
                        closeModalAfterComplete={() => handleCloseModal()}
                    />
                </div>
            </ModalPortal>
            <ModalPortal
                open={showError}
                headerIcon={selectedTypeIcon()}
                onCloseClick={() => handleCloseModal()}
                shouldFadeOut={false}
            >
                <div className='eid-modal__error'>
                    <h1>{t('people_select.same_device.error_header')}</h1>
                    <p>
                        <Trans>
                            e_id_error.{eIdName}.{errorKey}
                        </Trans>
                    </p>
                    <div className='eid-modal__error-footer'>
                        <Button
                            className='btn--wide btn--big'
                            aria-label={t('people_select.same_device.error_button')}
                            onClick={() => handleCloseModal()}
                        >
                            <span>{t('people_select.same_device.error_button')}</span>
                        </Button>
                    </div>
                </div>
            </ModalPortal>
        </>
    );
}
