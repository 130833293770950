export function MinusIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect
                    x='20'
                    y='10.75'
                    width='2.5'
                    height='16'
                    rx='0.5'
                    transform='rotate(90 20 10.75)'
                    fill='currentColor'
                />
            </svg>
        </>
    );
}
