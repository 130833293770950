import {LoadingError} from '../../errors/LoadingError';
import {NetworkError} from '../../errors/NetworkError';
import {SearchError} from '../../errors/SearchError';
import {PositionTimeoutError} from '../../errors/PositionTimeoutError';
import {EmptyResultError} from '../../errors/EmptyResultError';
import {PositionPermissionError} from '../../errors/PositionPermissionError';

export type ErrorType =
    | 'loading'
    | 'search'
    | 'empty_result'
    | 'network'
    | 'location_timeout'
    | 'location_permission'
    | null;
export type UnionErrorType = ErrorType | [ErrorType, () => void];

interface ErrorDialogProps {
    error: UnionErrorType;
    clear(): void;
}

export function ErrorDialog({error, clear}: ErrorDialogProps) {
    const type = Array.isArray(error) ? error[0] : error;
    const retryFunction = Array.isArray(error) ? error[1] : () => {};

    switch (type) {
        case 'loading':
            return <LoadingError primaryAction={() => window.location.reload()} />;
        case 'location_permission':
            return <PositionPermissionError onClose={() => clear()} />;
        case 'location_timeout':
            return (
                <PositionTimeoutError
                    retryFunction={() => {
                        clear();
                        retryFunction();
                    }}
                    onClose={() => clear()}
                />
            );
        case 'search':
            return <SearchError primaryAction={() => clear()} secondaryAction={() => clear()} />;
        case 'empty_result':
            return <EmptyResultError primaryAction={() => clear()} />;
        case 'network':
            return (
                <NetworkError
                    primaryAction={() => {
                        clear();
                        retryFunction();
                    }}
                    secondaryAction={() => clear()}
                />
            );
        default:
            return <NetworkError primaryAction={() => clear()} />;
    }
}
