import {useTranslation} from 'react-i18next';
import {Radio} from '../../form/radio/Radio';

import './PickVisitCategory.scss';

export type VisitCategory = 'vaccination' | 'travel' | 'other';

interface PickVisitCategoryProps {
    selectedCategory: VisitCategory | null;
    onChange(visitCategory: VisitCategory): void;
}

export function PickVisitCategory({onChange, selectedCategory}: PickVisitCategoryProps) {
    const {t} = useTranslation();
    return (
        <fieldset className='pick-visit-category'>
            <legend className='off-screen'>{t('vaccinations_heading.text')}</legend>
            <h2 className='pick-visit-category__heading'>{t('vaccinations_heading.text')}</h2>

            <div className='pick-visit-category__radio-control'>
                <Radio
                    onClick={() => onChange('vaccination')}
                    checked={selectedCategory === 'vaccination'}
                    tabIndex={0}
                    name='visit-category'
                    label={'Vaccination'}
                />
            </div>

            <div className='pick-visit-category__radio-control'>
                <Radio
                    onClick={() => onChange('travel')}
                    checked={selectedCategory === 'travel'}
                    tabIndex={0}
                    name='visit-category'
                    label={'Rådgivning och resevaccination'}
                />
            </div>

            <div className='pick-visit-category__radio-control'>
                <Radio
                    onClick={() => onChange('other')}
                    checked={selectedCategory === 'other'}
                    tabIndex={0}
                    name='visit-category'
                    label={'Övriga tjänster'}
                />
            </div>
        </fieldset>
    );
}
