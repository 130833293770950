import {useEffect} from 'react';
import {LayerGroup, useMap} from 'react-leaflet';
import {UiClinicActions, useUiContext} from '../../../context/UiContext';
import {ClinicObjectSlim} from '../../../services/api/types/types';

import {ClinicMarker} from '../marker/ClinicMarker';

interface VisibleClinicsLayerProps {
    clinics: Array<ClinicObjectSlim>;
    reasonQuery: string;
}

export function VisibleClinicsLayer({clinics, reasonQuery}: VisibleClinicsLayerProps) {
    const map = useMap();
    const {state, dispatch} = useUiContext();

    useEffect(() => {
        let debouncedUpdateVisibles: NodeJS.Timeout;
        const updateVisibles = () => {
            clearTimeout(debouncedUpdateVisibles);
            debouncedUpdateVisibles = setTimeout(() => {
                dispatch({
                    type: UiClinicActions.SET_VISIBLE_CLINICS,
                    value: clinics.filter(({location}) => map.getBounds().contains(location))
                });
            }, 100);
        };

        map.on('moveend', updateVisibles);
        updateVisibles();

        return () => {
            clearTimeout(debouncedUpdateVisibles);
            map.off('moveend', updateVisibles);
        };
    }, [clinics, dispatch, map]);

    return (
        <LayerGroup>
            {state.visibleClinics.length > 0 &&
                state.visibleClinics.map((clinic) => {
                    return (
                        <ClinicMarker
                            reasonQuery={reasonQuery}
                            name={clinic.name}
                            key={clinic.id}
                            clinic={clinic}
                            position={clinic.location}
                            zoomLevel={map.getZoom()}
                        />
                    );
                })}
        </LayerGroup>
    );
}
