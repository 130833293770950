import {useEffect, useState} from 'react';
import './Booking.scss';
import {useTranslation} from 'react-i18next';
import {EditIcon} from '../../../icons/EditIcon';
import {UserIcon} from '../../../icons/UserIcon';
import {LocationIcon} from '../../../icons/LocationIcon';
import {TimeIcon} from '../../../icons/TimeIcon';
import {PhoneIcon} from '../../../icons/PhoneIcon';
import {fetchAppointmentType, fetchClinicById} from '../../../../services/api/api';
import {format} from '../../../../lib/dates/format';
import {BookingSkeleton} from '../../../layout/skeleton/booking-skeleton/BookingSkeleton';
import {AppointmentType, BookingResponse, ClinicJSON} from '../../../../services/api/types/types';
import {Button} from '../../../form/button/Button';
import {ConsentIcon} from '../../../icons/ConsentIcon';
import {ConsentStatusList} from './ConsentStatusList';
import ModalPortal from '../../../layout/modal/ModalPortal';
import {ShareConsentLink} from './ShareConsentLink';
import {useHandleBookingContext} from '../../../../context/HandleBookingContext';
import {useSessionContext} from '../../../../context/SessionContext';

interface BookingProps {
    booking: BookingResponse;
    openBooking?: boolean;
    onEditTimeSlotClick(booking: BookingResponse): void;
    onEditContactInfoClick(booking: BookingResponse): void;
    onCancelBookingClick(): void;
}

export function Booking({
    booking,
    openBooking,
    onEditTimeSlotClick,
    onEditContactInfoClick,
    onCancelBookingClick
}: BookingProps) {
    const {t} = useTranslation();
    const [clinic, setClinic] = useState<ClinicJSON>();
    const [appointmentType, setAppointmentType] = useState<AppointmentType>();

    const [showConsentModal, setShowConsentModal] = useState(false);
    const {
        state: {user}
    } = useHandleBookingContext();
    const {
        sessionState: {hasActiveSession}
    } = useSessionContext();

    const hasConsentStatus = booking.patients.filter(({consents = []}) => consents.length > 0).length > 0;

    // Either there is an active session and user, or no session
    const sessionLoaded = (hasActiveSession && user !== null) || hasActiveSession === false;

    const getClinic = async (clinicId: number) => {
        const clinic = await fetchClinicById(clinicId);
        if (clinic) {
            setClinic(clinic);
        }
    };

    const getAppointmentType = async (clinicId: number, appointmentTypeId: number) => {
        const appointmentType = await fetchAppointmentType(clinicId, appointmentTypeId);
        if (appointmentType) {
            setAppointmentType(appointmentType);
        }
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const contactDetails = () => {
        return booking.contactInfo.email
            ? booking.contactInfo.mobile + ', ' + booking.contactInfo.email
            : booking.contactInfo.mobile;
    };

    useEffect(() => {
        setTimeout(() => {
            getClinic(booking.clinicId);
            getAppointmentType(booking.clinicId, booking.appointmentTypeId);
        });
    }, [booking.clinicId, booking.appointmentTypeId]);

    return (
        <>
            {showConsentModal && (
                <ModalPortal onCloseClick={() => setShowConsentModal(false)}>
                    <ShareConsentLink />
                </ModalPortal>
            )}

            {(!clinic || !appointmentType || !sessionLoaded) && <BookingSkeleton />}
            {clinic && appointmentType && sessionLoaded && (
                <div key={booking.id} className='booking-item'>
                    <div>
                        <h2 className='booking-item__title'>{appointmentType.name}</h2>
                        <ul className='booking-item__list'>
                            {!openBooking && (
                                <li className='booking-item__list__item'>
                                    <div className='booking-item__list__item-content'>
                                        <EditIcon />
                                        <span>
                                            <b>{t('booking.information.bookingcode')}</b>
                                            <br />
                                            <span>{booking.bookingCode}</span>
                                        </span>
                                    </div>
                                </li>
                            )}
                            <li className='booking-item__list__item booking-item__list__item--location'>
                                <div className='booking-item__list__item-content'>
                                    <LocationIcon />
                                    <span>
                                        <b>{clinic.name}</b>
                                        <br />
                                        <span>
                                            {clinic.address}, {clinic.city}
                                        </span>
                                    </span>
                                </div>
                                {clinic.logoUrl && (
                                    <div className='booking-item__clinic-logo'>
                                        <img src={clinic.logoUrl} alt={'image'} aria-hidden />
                                    </div>
                                )}
                            </li>
                            <li className='booking-item__list__item'>
                                <div className='booking-item__list__item-content'>
                                    <TimeIcon />
                                    <span>
                                        {booking.when
                                            ? capitalizeFirstLetter(
                                                  format(new Date(booking.when), 'ccc dd MMM, HH:mm').replace('.', '')
                                              )
                                            : t('stepindicator.timeSelect')}
                                    </span>
                                </div>
                                {clinic.canRebook && (
                                    <Button
                                        variant='text'
                                        color='primary'
                                        onClick={() => onEditTimeSlotClick(booking)}
                                        ariaLabel={t('handle_booking.aria.change_time_slot')}
                                        className='booking-item__change-button'
                                    >
                                        <span>{t('button_change.text')}</span>
                                    </Button>
                                )}
                            </li>
                            <li className='booking-item__list__item'>
                                <div className='booking-item__list__item-content'>
                                    <UserIcon ariaHidden />
                                    <span className='people'>
                                        {booking.patients.map(({givenName, familyName, identity}) => {
                                            return (
                                                <span key={identity}>
                                                    <b className='name'>{`${givenName} ${familyName}`}</b>
                                                    {identity}
                                                </span>
                                            );
                                        })}
                                    </span>
                                </div>
                            </li>
                            {hasConsentStatus && (
                                <li className='booking-item__list__item booking-item__list__item--consent'>
                                    <div className='booking-item__list__item-content booking-item__list__item-content'>
                                        <ConsentIcon />
                                        <ConsentStatusList appointmentType={appointmentType} booking={booking} />
                                    </div>

                                    <Button
                                        onClick={() => setShowConsentModal(true)}
                                        variant='text'
                                        color='primary'
                                        className='booking-item__change-button'
                                    >
                                        <span>{t('handle_booking.send_consent_request')}</span>
                                    </Button>
                                </li>
                            )}
                            <li className='booking-item__list__item'>
                                <div className='booking-item__list__item-content'>
                                    <PhoneIcon />
                                    <span>
                                        <b>{t('handle_booking.contact_info')}</b>
                                        <br />
                                        <span>{contactDetails()}</span>
                                    </span>
                                </div>

                                {clinic.canRebook && (
                                    <Button
                                        variant='text'
                                        color='primary'
                                        onClick={() => onEditContactInfoClick(booking)}
                                        ariaLabel={t('handle_booking.aria.change_contact_info')}
                                        className='booking-item__change-button'
                                    >
                                        <span>{t('button_change.text')}</span>
                                    </Button>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className='booking-item__footer'>
                        {!clinic.canCancel && (
                            <>
                                <p className='cancel_header'>{t('handle_booking.footer.header')}</p>
                                <p>
                                    {t('handle_booking.footer.subheader')}{' '}
                                    <a href={`tel:${clinic.phone}`}>{clinic.phone}</a>
                                </p>
                            </>
                        )}
                        {clinic.canCancel && (
                            <>
                                <Button
                                    onClick={() => onCancelBookingClick()}
                                    className='booking-item__cancel-button'
                                    variant='text'
                                    color='primary'
                                >
                                    <span>{t('handle_booking.unbook_visit')}</span>
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
