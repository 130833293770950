import {useEffect, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {EIdInitData, EIdPollData, getQrCodeUrl, pollEId} from '../../../services/api/implementations/eidapi';
import {useInterval} from '../../../hooks/useInterval';
import {Spinner} from '../../layout/loader/Spinner';

import './modal.scss';

interface eIdOtherDeviceProps {
    initData: EIdInitData;
    onSelectSameDevice(): void;
    closeModalAfterComplete(): void;
    selectedType: string;
    setMessage(type: string): void;
    showError(): void;
    onLoginSuccess(data: EIdPollData): void;
}

export function EIdOtherDevice({
    initData,
    onSelectSameDevice,
    closeModalAfterComplete,
    selectedType,
    setMessage,
    showError,
    onLoginSuccess
}: eIdOtherDeviceProps) {
    const {t} = useTranslation();
    const [qrUrl, setQrUrl] = useState<string | null>(null);
    const [pollDelay, setPollDelay] = useState<number | null>(3000);
    const [qrDelay, setQrDelay] = useState<number | null>(500);
    const [provider, setProvider] = useState<string>('');
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const [pollingMessage, setPollingMessage] = useState<string>('');
    const eIdName = selectedType === 'BankID' ? 'BankID' : 'Freja';
    const isRequestingRef = useRef(false);

    useEffect(() => {
        !initData?.pollInterval || initData?.pollInterval === 0
            ? setPollDelay(null)
            : setPollDelay(initData.pollInterval);
    }, [initData]);

    useEffect(() => {
        if (selectedType === 'BankID') {
            setProvider('bankid');
        } else if (selectedType === 'Freja eID+') {
            setProvider('freja');
        } else if (selectedType === 'MockID') {
            setProvider('mock');
        }
    }, [selectedType]);

    useInterval(() => {
        setQrUrl(getQrCodeUrl(initData?.transactionId));
        !initData?.qrRefreshInterval || initData?.qrRefreshInterval === 0
            ? setQrDelay(null)
            : setQrDelay(initData.qrRefreshInterval);
    }, qrDelay);

    useInterval(async () => {
        if (isRequestingRef.current) {
            return;
        }
        try {
            isRequestingRef.current = true;
            const statusData = await pollEId(provider, initData);
            setMessage(statusData.status);
            setPollingMessage(statusData.status);

            if (statusData.status === 'USER_SIGN') {
                setIsPolling(true);
            }
            if (statusData.status === 'COMPLETE') {
                closeModalAfterComplete();
                setIsPolling(false);

                onLoginSuccess(statusData);
            }
            if (!statusData.continuePoll) {
                setPollDelay(null);
                showError();
                setIsPolling(false);
            }
        } catch (e) {
            console.log(e);
            setIsPolling(false);
            setMessage('GENERIC_ERROR');
            setPollDelay(null);
            showError();
        } finally {
            sessionStorage.removeItem('eidInitData');
            sessionStorage.removeItem('eidProvider');
            isRequestingRef.current = false;
        }
    }, pollDelay);

    return (
        <div
            className='eid-modal__other-device'
            aria-label={`${selectedType} ${t('people_select.other_device.on_other_device')}`}
        >
            <h1>
                {selectedType} <span>{t('people_select.other_device.on_other_device')}</span>
            </h1>
            {!isPolling ? (
                <>
                    <div className='eid-modal__qr-code'>
                        {qrUrl ? (
                            <img
                                src={qrUrl}
                                aria-label={t('people_select.other_device.qr_code_image')}
                                alt='qr-kod'
                                height={180}
                            />
                        ) : (
                            <Spinner />
                        )}
                    </div>
                    <div className='eid-modal__instructions'>
                        <ul
                            className='eid-modal__instructions-list'
                            aria-label={t('people_select.other_device.list_label')}
                        >
                            <li>{t('people_select.other_device.step_1', {selectedType})}</li>
                            <li>{t('people_select.other_device.step_2', {selectedType})}</li>
                            <li>{t('people_select.other_device.step_3')}</li>
                            <li>{t('people_select.other_device.step_4')}</li>
                        </ul>
                    </div>
                </>
            ) : (
                <div className='eid-modal__status'>
                    <p>
                        <Trans>
                            e_id_error.{eIdName}.{pollingMessage}
                        </Trans>
                    </p>
                    <div className='eid-modal__spinner'>
                        <Spinner />
                    </div>
                </div>
            )}
            {!isPolling && (
                <footer className='eid-modal__footer'>
                    <button className='eid-modal__footer-link' onClick={() => onSelectSameDevice()}>
                        {t('people_select.other_device.footer.button', {selectedType})}
                    </button>
                </footer>
            )}
        </div>
    );
}
