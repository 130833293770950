import {createContext, useContext, useEffect, useReducer, useRef} from 'react';
import {fetchRefreshTimeSlot, fetchUnreserveTimeSlot} from '../services/api/api';
import {useBookingContext} from './BookingContext';
import {useClinicDetailsContext} from './ClinicDetailsContext';
import {SessionActions, useSessionContext} from './SessionContext';

export enum BookingSessionActions {
    SET_SHOW_TIMEOUT_WARNING = 'SET_SHOW_TIMEOUT_WARNING',
    SET_SHOW_OPEN_BOOKING_TIMEOUT_WARNING = 'SET_SHOW_OPEN_BOOKING_TIMEOUT_WARNING',
    CLEAR_USER_SESSION = 'CLEAR_USER_SESSION'
}

type Action = {type: BookingSessionActions; value?: boolean | string | null};
type Dispatch = (action: Action) => void;
type State = {
    showTimeoutWarning: boolean;
    showOpenBookingTimeoutWarning: boolean;
};
type BookingSessionProviderProps = {children: React.ReactNode};

const BookingSessionContext = createContext<
    | {
          bookingSessionState: State;
          bookingSessionDispatch: Dispatch;
          refreshBookingSession(bookingId?: string | null): Promise<void>;
          startBookingSession(jwt: string, sessionTimeoutSeconds: number, tokenDuration: number): void;
          startOpenSession(sessionTimeoutSeconds: number): void;
      }
    | undefined
>(undefined);

function BookingSessionReducer(state: State, action: Action): State {
    switch (action.type) {
        case BookingSessionActions.SET_SHOW_TIMEOUT_WARNING: {
            return {...state, showTimeoutWarning: action.value as boolean};
        }
        case BookingSessionActions.SET_SHOW_OPEN_BOOKING_TIMEOUT_WARNING: {
            return {...state, showOpenBookingTimeoutWarning: action.value as boolean};
        }
        case BookingSessionActions.CLEAR_USER_SESSION: {
            return {
                showTimeoutWarning: false,
                showOpenBookingTimeoutWarning: false
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function BookingSessionProvider({children}: BookingSessionProviderProps) {
    const [bookingSessionState, bookingSessionDispatch] = useReducer(BookingSessionReducer, {
        showTimeoutWarning: false,
        showOpenBookingTimeoutWarning: false
    });

    const {bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {sessionState, sessionDispatch, startSession, refreshSession} = useSessionContext();

    const openSessionWarningTimer = useRef<NodeJS.Timeout>();

    // Since booking-state might change during open booking after timeout has started,
    // save bookingId as refs to get a fresh value when the setTimeout triggers
    const bookingIdRef = useRef<string | null>(null);

    useEffect(() => {
        bookingIdRef.current = bookingState.bookingId;
    }, [bookingState.bookingId]);

    useEffect(() => {
        // Show booking-session timeout warning when sessionState updates
        if (sessionState.timeoutWarning === true) {
            bookingSessionDispatch({type: BookingSessionActions.SET_SHOW_TIMEOUT_WARNING, value: true});
        }
    }, [sessionState.timeoutWarning]);

    useEffect(() => {
        // Unreserve potential booking when session times out
        const unreserveOnTimeout = async (clinicId: number, bookingId: string) => {
            try {
                await fetchUnreserveTimeSlot(clinicId, bookingId);
            } catch (error) {
                // Don't worry about this, slot is probably already unlocked
            }
        };

        if (
            sessionState.userJwt !== null &&
            sessionState.sessionTimedOut === true &&
            bookingState.bookingId &&
            dataState.clinic?.id
        ) {
            unreserveOnTimeout(dataState.clinic?.id, bookingState.bookingId);
        }
    }, [bookingState.bookingId, dataState.clinic?.id, sessionState.sessionTimedOut, sessionState.userJwt]);

    const refreshBookingSession = async (bookingId: string | null = null) => {
        bookingSessionDispatch({type: BookingSessionActions.SET_SHOW_TIMEOUT_WARNING, value: false});
        if (sessionState.userJwt === null || sessionState.hasActiveSession === false) {
            return;
        }

        try {
            const updatedJwt = await refreshSession();

            if (updatedJwt !== null && bookingId && dataState.clinic?.id) {
                await fetchRefreshTimeSlot(updatedJwt, dataState.clinic?.id, bookingId);
            }
        } catch (error) {
            sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
        }
    };

    const startBookingSession = (jwt: string, sessionTimeoutSeconds: number, tokenDuration: number) => {
        startSession(jwt, sessionTimeoutSeconds, tokenDuration);
    };

    const startOpenSession = (sessionTimeoutSeconds: number) => {
        // Warning when 5 minutes remain during open booking
        const sessionTimeoutMs = (sessionTimeoutSeconds - 300) * 1000;

        clearTimeout(openSessionWarningTimer.current);
        openSessionWarningTimer.current = setTimeout(() => {
            const bookingId = bookingIdRef.current;

            if (!bookingId) {
                return;
            }

            bookingSessionDispatch({type: BookingSessionActions.SET_SHOW_OPEN_BOOKING_TIMEOUT_WARNING, value: true});
        }, sessionTimeoutMs);
    };

    const value = {
        bookingSessionState,
        bookingSessionDispatch,
        startBookingSession,
        startOpenSession,
        refreshBookingSession
    };
    return <BookingSessionContext.Provider value={value}>{children}</BookingSessionContext.Provider>;
}

function useBookingSessionContext() {
    const context = useContext(BookingSessionContext);
    if (context === undefined) {
        throw new Error('useBookingSessionContext must be used within a UiProvider');
    }

    return context;
}

export {BookingSessionProvider, useBookingSessionContext};
