import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReasonFilterObject} from '../../../services/api/types/types';
import {Toggle} from '../../form/toggle/Toggle';

interface ReasonFiltersProps {
    reasonFilters: Array<ReasonFilterObject>;
    activeFilters: Array<string>;
    selectedCategory: string;
    onToggleFilter(id: string, checked: boolean): void;
    disableTabNavigation?: boolean;
}

const shouldShowAllFilters = (reasonFilters: Array<ReasonFilterObject>, activeFilters: Array<string>) => {
    const highestActiveIndex = Math.max(
        ...activeFilters.map((activeFilterId) => {
            return reasonFilters.findIndex(({id}) => id === activeFilterId);
        })
    );
    return highestActiveIndex > 4;
};

export function ReasonFilters({
    selectedCategory,
    reasonFilters,
    activeFilters,
    disableTabNavigation = false,
    onToggleFilter
}: ReasonFiltersProps) {
    const {t} = useTranslation();

    const [showAllFilters, setShowAllFilters] = useState(() => {
        return shouldShowAllFilters(reasonFilters, activeFilters);
    });

    useEffect(() => {
        if (shouldShowAllFilters(reasonFilters, activeFilters)) {
            setShowAllFilters(true);
        }
    }, [activeFilters, selectedCategory]);

    return (
        <div className='checkbox_group'>
            {reasonFilters.slice(0, showAllFilters ? undefined : 5).map((v) => {
                return (
                    <Toggle
                        onClick={(checked) => {
                            onToggleFilter(v.id, checked);
                        }}
                        checked={activeFilters.includes(v.id)}
                        tabIndex={disableTabNavigation ? -1 : 0}
                        key={v.id}
                        id={`reason-${v.id}`}
                        label={v.label}
                    />
                );
            })}

            {reasonFilters.length > 4 && (
                <span className='checkbox_group__more'>
                    <a
                        tabIndex={disableTabNavigation ? -1 : 0}
                        role='button'
                        href='/'
                        onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.preventDefault();
                                setShowAllFilters(!showAllFilters);
                            }
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            setShowAllFilters(!showAllFilters);
                        }}
                    >
                        {showAllFilters ? t('vaccinations_show_fewer.text') : t('vaccinations_show_more.text')}
                    </a>
                </span>
            )}
        </div>
    );
}
