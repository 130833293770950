import {useTranslation} from 'react-i18next';
import {HistoryResponse} from 'src/services/api/types/types';
import {VaccinationItemDetailsCard} from './VaccinationItemDetailsCard';

import './VaccinationItemDetails.scss';
import {useUiContext} from '../../../../context/UiContext';
import {useMemo} from 'react';

type VaccinationItemDetailsProps = {
    vaccination: HistoryResponse;
    relatedVaccinations?: HistoryResponse[];
    onRemoveVaccination(vaccination: HistoryResponse): void;
    onEditVaccination(vaccination: HistoryResponse): void;
};

export function VaccinationItemDetails({
    vaccination,
    relatedVaccinations,
    onRemoveVaccination,
    onEditVaccination
}: VaccinationItemDetailsProps) {
    const {t} = useTranslation();
    const {breakPointInterval} = useUiContext().state;

    const isMobile = ['compact', 'medium'].includes(breakPointInterval);

    const detailsHeader = useMemo(() => {
        if (vaccination.vaccine.categoryName === '-' && vaccination.vaccine.name) {
            return vaccination.vaccine.name;
        }
        return vaccination.vaccine.categoryName || vaccination.vaccine.name;
    }, [vaccination.vaccine.categoryName, vaccination.vaccine.name]);

    return (
        <div className='vaccination-item-details'>
            {isMobile && <h2 className='vaccination-item-details__header'>{detailsHeader}</h2>}
            <VaccinationItemDetailsCard
                vaccination={vaccination}
                onRemoveVaccination={() => onRemoveVaccination(vaccination)}
                onEditVaccination={() => onEditVaccination(vaccination)}
            />
            {isMobile && relatedVaccinations && relatedVaccinations.length > 1 && (
                <>
                    <h2 className='vaccination-item-details__related-vaccination-header'>
                        {t('history.related_vaccinations')}
                    </h2>
                    {relatedVaccinations
                        .filter((vaccination_) => vaccination_.id !== vaccination.id)
                        .map((vaccination_) => (
                            <VaccinationItemDetailsCard
                                className='vaccination-item-details__card'
                                key={`${vaccination_.id}`}
                                vaccination={vaccination_}
                                onEditVaccination={() => onEditVaccination(vaccination_)}
                                onRemoveVaccination={() => onRemoveVaccination(vaccination_)}
                            />
                        ))}
                </>
            )}
        </div>
    );
}
