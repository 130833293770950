import {useTranslation} from 'react-i18next';
import './AboutMain.scss';

export function AboutMain() {
    const {t} = useTranslation();
    return (
        <main id='about-container' className='about-container page-container-margin'>
            <div className='about-container__intro'>
                <h1 className='about-container__heading'>{t('about.heading')}</h1>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_1')}</p>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_2')}</p>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_3')}</p>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_4')}</p>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_5')}</p>
                <p className='about-container__paragraph'>{t('about.mitt_vaccin_info_6')}</p>
            </div>
            <div className='about-container__bottom-links'>
                <a href='/fragor-svar/' target='_blank' rel='noreferrer' title={t('about.q_and_a_link_title')}>
                    {t('about.q_and_a_link')}
                </a>
                <a href='/kontakta-oss/' target='_blank' rel='noreferrer' title={t('about.contact_us_link_title')}>
                    {t('about.contact_us_link')}
                </a>

                <a href='/personuppgiftspolicy' title={t('policy.privacy_policy_link_title')}>
                    {t('policy.privacy_policy_link')}
                </a>
                <a href='/cookie-policy' title={t('policy.cookie_policy_link_title')}>
                    {t('policy.cookie_policy_link')}
                </a>
                <a href='/accessibility-statement' title={t('policy.accessibility_statement_link_aria')}>
                    {t('policy.accessibility_statement_link')}
                </a>
            </div>
        </main>
    );
}
