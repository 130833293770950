import {addDays} from 'date-fns';
import {useEffect} from 'react';
import {useCookies} from 'react-cookie';

export const caregiverCookiePrefix = 'mv-caregiver-consent.';

export const getCaregiverCookieKey = (careGiverId: number | string) => {
    return `mv-caregiver-consent.${careGiverId}`;
};

export const defaultCookieOptions = {
    domain: '.mittvaccin.se',
    path: '/',
    expires: addDays(new Date(), 30),
    secure: true
};

export const defaultClearCookieOptions = {
    domain: '.mittvaccin.se',
    path: '/'
};

export function useCaregiverConsentCookie(careGiverId: number, cookiePolicyVersion: string) {
    const [cookies, setCookie, removeCookie] = useCookies();

    // Clear the caregiver consent cookie if the cookie policy version has changed since the cookie was set
    useEffect(() => {
        if (!careGiverId || !cookiePolicyVersion) {
            return;
        }

        const caregiverCookie = cookies[getCaregiverCookieKey(careGiverId)];
        if (caregiverCookie && caregiverCookie.toString() !== cookiePolicyVersion) {
            removeCookie(getCaregiverCookieKey(careGiverId), defaultClearCookieOptions);
        }
    }, [careGiverId, cookiePolicyVersion, removeCookie]);

    if (!careGiverId) {
        return {};
    }

    const caregiverCookieKey = getCaregiverCookieKey(careGiverId);
    const setCookieConsent = (val: boolean) => {
        const cookieValue = val ? cookiePolicyVersion : false;
        setCookie(caregiverCookieKey, cookieValue, defaultCookieOptions);
    };

    return {
        hasConsent: `${cookies[caregiverCookieKey]}` === cookiePolicyVersion,
        hasChosen: cookies[caregiverCookieKey] !== undefined,
        setCookieConsent
    };
}
