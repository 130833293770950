import './PolicyArticle.scss';

interface PolicyArticleProps {
    heading: string;
    article: Array<{
        heading?: string;
        content: Array<string | {subheading: string; text?: string}>;
    }>;
}

export function PolicyArticle({heading, article}: PolicyArticleProps) {
    return (
        <div className='policy-article'>
            <h1>{heading}</h1>

            {article.map(({content, heading = null}, index) => {
                return (
                    <div key={index} className='policy-article__section'>
                        {heading && <h2>{heading}</h2>}

                        {content.map((val, index) => {
                            if (typeof val === 'object') {
                                const {subheading, text = ''} = val;
                                return (
                                    <p key={index}>
                                        <b>{subheading}</b> <br />
                                        {text}
                                    </p>
                                );
                            }

                            return <p key={index}>{val}</p>;
                        })}
                    </div>
                );
            })}
        </div>
    );
}
