import i18n from '../../i18n';

export function setLocale(locale: 'sv' | 'en') {
    if (['sv', 'en'].includes(locale) === false) {
        throw new Error(`Unsupported language: ${locale}`);
    }
    i18n.changeLanguage(locale);
}

export function getLocale() {
    return i18n.language as 'sv' | 'en';
}

export function getLocaleWithRegionCode() {
    const localeMap = {
        sv: 'sv-se',
        en: 'en-gb'
    };

    return localeMap[getLocale()];
}
