import {useTranslation} from 'react-i18next';
import {getCaregiverCookiePolicyLink} from '../../../services/api/api';
import {CaregiverObject} from '../../../services/api/types/types';
import {Button} from '../../form/button/Button';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import './CookieConsentModal.scss';

interface CookieConsentModalProps {
    hasChosen: boolean;
    caregiver: CaregiverObject;
    setCookieConsent(val: boolean): void;
}

interface ApiConfig {
    baseUrl: string;
    apiKey: string;
}

const config: ApiConfig = {
    baseUrl: process.env.REACT_APP_CLINIC_API_ENDPOINT || '',
    apiKey: process.env.REACT_APP_CLINIC_API_KEY || ''
};

const caregiverIdToPing = process.env.NODE_ENV === 'development' ? 251 : 47; // 47 is Doktor24 and 251 is Bontouch Grodan Test when testing locally

export function CookieConsentModal({hasChosen, caregiver, setCookieConsent}: CookieConsentModalProps) {
    const {t} = useTranslation();

    if (hasChosen) {
        return null;
    }
    const caregiverPdfLink = getCaregiverCookiePolicyLink(caregiver.id);

    // TEMPORARY FUNCTION TO TRACK DOCTOR24 COOKIE CONSENT
    // This function is temporary and should be removed once the concent feature is validated
    const handleDoktor24CookieConsent = async ({caregiverId, approved}: {caregiverId: number; approved: boolean}) => {
        if (!config.baseUrl || !config.apiKey) {
            return;
        }

        if (caregiverId === caregiverIdToPing) {
            try {
                const {baseUrl, apiKey} = config;
                await fetch(`${baseUrl}/tmp/consent/${approved ? 'approve' : 'deny'}`, {
                    method: 'GET',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json'
                    }
                });
            } catch (error) {
                console.error('failed ping endpoint', error);
            }
        }
    };

    return (
        <ModalPortal placementDesktop='bottom' size='sm'>
            <div className='cookie-modal'>
                <section className='cookie-modal__content'>
                    <h2 className='cookie-modal__heading'>{t('cookies.consent_modal.heading')}</h2>
                    <p>
                        {t('cookies.consent_modal.caregiver_information', {caregiverName: caregiver.label})}{' '}
                        <a href={caregiverPdfLink} target='_blank' rel='noreferrer'>
                            {t('cookies.consent_modal.caregiver_read_more')}
                        </a>
                        .
                    </p>
                    <p>{t('cookies.consent_modal.required_cookies_information')}</p>
                    <p>
                        {t('cookies.consent_modal.mittvaccin_information')}{' '}
                        <a href='/cookie-policy' target='_blank'>
                            {t('cookies.consent_modal.mittvaccin_read_more')}
                        </a>
                    </p>
                </section>
                <div className='cookie-modal__buttons'>
                    <Button
                        onClick={() => {
                            handleDoktor24CookieConsent({caregiverId: caregiver.id, approved: false});
                            setCookieConsent(false);
                        }}
                        color='tertiary'
                    >
                        <span>{t('cookies.consent_modal.decline_all')}</span>
                    </Button>
                    <Button
                        onClick={() => {
                            handleDoktor24CookieConsent({caregiverId: caregiver.id, approved: true});
                            setCookieConsent(true);
                        }}
                        variant='filled'
                        color='primary'
                    >
                        <span>{t('cookies.consent_modal.accept')}</span>
                    </Button>
                </div>
            </div>
        </ModalPortal>
    );
}
