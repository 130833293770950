export function LocationIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.5 10C18.5 14.6348 14.8385 18.3122 11.972 20.1389C10.7336 19.3476 9.17544 18.0217 7.89184 16.3263C6.45644 14.4304 5.5 12.2573 5.5 10C5.5 7.55264 6.49479 6.00961 7.74099 5.02124C9.07491 3.96331 10.7654 3.5 12 3.5C13.3002 3.5 14.9847 3.92474 16.2843 4.92851C17.4825 5.85407 18.5 7.37271 18.5 10ZM21 10C21 16.221 15.9782 20.6844 12.826 22.5479C12.3115 22.8521 11.6806 22.8678 11.162 22.5708C8.00908 20.765 3 15.939 3 10C3 3.5 8.5 1 12 1C15.5 1 21 3.2 21 10ZM13.5 10C13.5 10.8284 12.8284 11.5 12 11.5C11.1716 11.5 10.5 10.8284 10.5 10C10.5 9.17157 11.1716 8.5 12 8.5C12.8284 8.5 13.5 9.17157 13.5 10ZM16 10C16 12.2091 14.2091 14 12 14C9.79086 14 8 12.2091 8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
