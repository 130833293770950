import {useTranslation} from 'react-i18next';
import {useUiContext} from '../../../context/UiContext';
import {ChevronDownIcon} from '../../icons/ChevronDownIcon';
import {ChevronUpIcon} from '../../icons/ChevronUpIcon';
import './SearchHeader.scss';

interface SearchHeaderProps {
    toggleSearchViewClick(): void;
}

export function SearchHeader({toggleSearchViewClick}: SearchHeaderProps) {
    const {state} = useUiContext();
    const {searchExtended} = state;

    const {t} = useTranslation();

    return (
        <div className='search-header-container'>
            <label htmlFor='search'>
                <h1 id='main-search-heading' className='search-header-container__header'>
                    {t('search_heading.text')}
                </h1>
            </label>
            <button
                aria-label={searchExtended ? t('vaccinations_hide_search.text') : t('vaccinations_show_search.text')}
                className='search_modal_toggle_button'
                onClick={() => toggleSearchViewClick()}
            >
                {searchExtended ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </button>
        </div>
    );
}
