import {useEffect, useState} from 'react';

export function useMatchMedia(selector: string) {
    const [match, setMatch] = useState(false);

    useEffect(() => {
        const mql = window.matchMedia(selector);
        setMatch(mql.matches);

        const setMode = (e: MediaQueryListEvent) => {
            setMatch(e.matches);
        };

        mql.addEventListener('change', setMode);

        return () => mql.removeEventListener('change', setMode);
    }, [selector]);

    return match;
}
