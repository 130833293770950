import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SearchIcon} from '../../icons/SearchIcon';
import {fetchSearch} from '../../../services/api/api';
import {
    CaregiverObject,
    ClinicObjectSlim,
    PlacesFilterObject,
    ReasonFilterObject
} from '../../../services/api/types/types';
import {XIcon} from '../../icons/XIcon';
import {PlaceFilterMap, SearchFilterMap} from '../../../hooks/useSearchFilters';
import {Pills} from '../../main/pills/pills';
import {SearchResults} from './SearchResults';
import {SearchHeader} from 'src/components/main/components/SearchHeader';
import './Search.scss';

export type Status = 'initial' | 'loading' | 'done' | 'error';

export interface GroupedResults {
    visitReasons: Group;
    places: PlaceGroup;
    careGivers: Group;
    clinics: ClinicGroup;
}

interface ClinicGroup {
    label: string;
    items: Array<ResultItem>;
}

interface Group {
    label: string;
    items: Array<ResultItem>;
}

interface PlaceGroup {
    label: string;
    items: Array<PlaceResultItem>;
}

interface ResultItem {
    id: string;
    type: keyof SearchFilterMap;
    label: string;
}

interface PlaceResultItem extends ResultItem {
    place: PlacesFilterObject;
}

interface SearchFiltersProps {
    reasonFilters: Array<ReasonFilterObject>;
    careGiverFilters: Array<CaregiverObject>;
    clinicFilters: Array<ClinicObjectSlim>;
    placeFilters: PlaceFilterMap;
    searchParams: SearchFilterMap;
    onClick(): void;
    onFocusUpdate(value: boolean): void;
    onResultsExpanded(value: boolean): void;
    onToggleFilter(type: keyof SearchFilterMap, id: string, active: boolean): void;
    onAddPlaceFilter(id: string, place: PlacesFilterObject): void;
    onRemovePlaceFilter(id: string): void;
    disableTabNavigation?: boolean;
}

export function Search({
    reasonFilters,
    careGiverFilters,
    placeFilters,
    clinicFilters,
    searchParams,
    disableTabNavigation = false,
    onClick,
    onFocusUpdate,
    onResultsExpanded,
    onToggleFilter,
    onAddPlaceFilter,
    onRemovePlaceFilter
}: SearchFiltersProps) {
    const {t} = useTranslation();
    const searchInputRef = useRef<HTMLInputElement>(null);

    const emptyGroupedResults = useCallback(() => {
        return {
            visitReasons: {
                label: t('search_group_visit_reason.text'),
                items: []
            },
            places: {
                label: t('search_group_place.text'),
                items: []
            },
            careGivers: {
                label: t('search_group_caregiver.text'),
                items: []
            },
            clinics: {
                label: t('search_group_clinic.text'),
                items: []
            }
        } as GroupedResults;
    }, [t]);

    const [groupedResults, setGroupedResults] = useState<GroupedResults>(emptyGroupedResults());
    const [query, setQuery] = useState('');
    const [focused, _setFocused] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    const focusRef = useRef(focused);
    const setFocused = (value: boolean) => {
        focusRef.current = value;
        _setFocused(value);
    };

    const [status, setStatus] = useState<Status>('initial');
    const [hasPlaceLoadError, setHasPlaceLoadError] = useState<boolean>(false);

    const hasQuery = useCallback(() => query.length > 2, [query]);

    const queryResults = useCallback(
        async (abortSignal: AbortSignal) => {
            if (query.length < 2) {
                return;
            }
            setHasPlaceLoadError(false);
            setGroupedResults(emptyGroupedResults());

            let results = emptyGroupedResults();

            results.visitReasons.items = reasonFilters
                .filter(({label}) => label.toLowerCase().includes(query.toLowerCase()))
                .slice(0, 12)
                .map(({id, label}) => {
                    return {
                        id,
                        label,
                        type: 'reason'
                    } satisfies ResultItem;
                });

            results.careGivers.items = careGiverFilters
                .filter(({label}) => label.toLowerCase().includes(query.toLowerCase()))
                .slice(0, 12)
                .map(({id, label}) => {
                    return {
                        id: id.toString(),
                        label,
                        type: 'caregiver'
                    } satisfies ResultItem;
                });

            results.clinics.items = clinicFilters
                .filter(({name}) => name.toLowerCase().includes(query.toLowerCase()))
                .slice(0, 12)
                .map(({id, name}) => {
                    return {
                        id,
                        label: name,
                        type: 'clinic'
                    } satisfies ResultItem;
                });

            try {
                const placeResults = await fetchSearch(query, abortSignal);

                results.places.items = placeResults.map((place) => {
                    return {
                        id: place.id,
                        label: place.name,
                        type: 'place',
                        place
                    } satisfies PlaceResultItem;
                });

                const visitReasonsSlotsOpen =
                    results.visitReasons.items.length > 3 ? 0 : 3 - results.visitReasons.items.length;

                const careGiversSlotsOpen =
                    results.careGivers.items.length > 3 ? 0 : 3 - results.careGivers.items.length;

                const placesSlotsOpen = results.places.items.length > 3 ? 0 : 3 - results.places.items.length;
                const clinicsSlotsOpen = results.clinics.items.length > 3 ? 0 : 3 - results.clinics.items.length;
                const slotsOpen = visitReasonsSlotsOpen + careGiversSlotsOpen + placesSlotsOpen + clinicsSlotsOpen;

                let normalizedResults = emptyGroupedResults();
                normalizedResults.visitReasons.items.push(...results.visitReasons.items.slice(0, 3));
                normalizedResults.careGivers.items.push(...results.careGivers.items.slice(0, 3));
                normalizedResults.places.items.push(...results.places.items.slice(0, 3));
                normalizedResults.clinics.items.push(...results.clinics.items.slice(0, 3));
                if (slotsOpen > 0) {
                    let remainingSlots = slotsOpen;
                    for (let i = 0; i < slotsOpen; i++) {
                        if (visitReasonsSlotsOpen === 0 && remainingSlots > 0) {
                            const nextVisitReason = results.visitReasons.items[3 + i];
                            !!nextVisitReason &&
                                normalizedResults.visitReasons.items.push(nextVisitReason) &&
                                remainingSlots--;
                        }
                        if (careGiversSlotsOpen === 0 && remainingSlots > 0) {
                            const nextCareGiver = results.careGivers.items[3 + i];
                            !!nextCareGiver &&
                                normalizedResults.careGivers.items.push(nextCareGiver) &&
                                remainingSlots--;
                        }
                        if (placesSlotsOpen === 0 && remainingSlots > 0) {
                            const nextPlace = results.places.items[3 + i];
                            !!nextPlace && normalizedResults.places.items.push(nextPlace) && remainingSlots--;
                        }
                        if (clinicsSlotsOpen === 0 && remainingSlots > 0) {
                            const nextClinic = results.clinics.items[3 + i];
                            !!nextClinic && normalizedResults.clinics.items.push(nextClinic) && remainingSlots--;
                        }
                    }
                }
                results = normalizedResults;
            } catch (error) {
                const e = error as Error;
                if (e.name === 'AbortError') {
                    setStatus('loading');
                    return;
                }
                setHasPlaceLoadError(true);
            }

            setGroupedResults(results);
            setStatus('done');
        },
        [query, emptyGroupedResults, reasonFilters, careGiverFilters, clinicFilters]
    );

    const clearQuery = () => {
        setQuery('');
        setFocused(false);
    };

    useEffect(() => {
        setStatus('loading');
        const abortSignal = new AbortController();

        const debouncedQuery = setTimeout(() => {
            queryResults(abortSignal.signal);
        }, 250);

        return () => {
            abortSignal.abort();
            clearTimeout(debouncedQuery);
        };
    }, [queryResults, hasQuery]);

    useEffect(() => {
        if (isExpanded === hasQuery()) {
            return;
        }

        setIsExpanded(hasQuery());
        onResultsExpanded(hasQuery());
    }, [status, hasQuery, onResultsExpanded, isExpanded]);

    // Used to hide native keyboard on mobile when touch on a result
    const blurSearchFocus = useCallback(() => {
        if (searchInputRef.current) {
            setFocused(false);
            searchInputRef.current.blur();
        }
    }, [searchInputRef]);

    return (
        <>
            <section className='search-header-container'>
                <SearchHeader toggleSearchViewClick={onClick} />
            </section>
            <form className={`search_box_form ${focused ? 'search_box_form--focused' : ''}`} role='search'>
                <div className='search_box__input-container'>
                    <label className={`search_box ${focused ? ' has-focus' : ''}`}>
                        <SearchIcon />
                        <input
                            ref={searchInputRef}
                            autoComplete='off'
                            tabIndex={disableTabNavigation ? -1 : 0}
                            onFocus={() => {
                                if (focusRef.current === false) {
                                    setFocused(true);
                                    onFocusUpdate(true);
                                }
                            }}
                            enterKeyHint='search'
                            onBlur={() => {
                                if (focusRef.current === true) {
                                    setFocused(false);
                                    onFocusUpdate(false);
                                }
                            }}
                            type='text'
                            id='search'
                            placeholder={t('search_input.placeholder')}
                            value={query}
                            onChange={(e) => {
                                setQuery(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    blurSearchFocus();
                                    e.preventDefault();
                                }
                            }}
                        />
                        {hasQuery() && (
                            <button
                                onFocus={() => {
                                    setFocused(true);
                                }}
                                className='svg-white search_box__clear-button'
                                aria-label={t('search_input.clear')}
                                onClick={() => {
                                    clearQuery();
                                }}
                            >
                                <XIcon />
                            </button>
                        )}
                    </label>
                </div>
                {hasQuery() && (
                    <SearchResults
                        id='search-modal-search-results'
                        groupedResults={groupedResults}
                        searchParams={searchParams}
                        onToggleFilter={onToggleFilter}
                        clearQuery={clearQuery}
                        disableTabNavigation={disableTabNavigation}
                        onAddPlaceFilter={onAddPlaceFilter}
                        hasPlaceLoadError={hasPlaceLoadError}
                        status={status}
                    />
                )}
                {!hasQuery() &&
                    (searchParams.clinic.length > 0 ||
                        searchParams.caregiver.length > 0 ||
                        searchParams.place.length > 0) && (
                        <Pills
                            places={searchParams.place.map((placeId) => {
                                return (
                                    placeFilters[placeId] || {
                                        id: placeId,
                                        name: t('search_item_unknown.text'),
                                        category: 'unknown'
                                    }
                                );
                            })}
                            clinics={searchParams.clinic.map((clinicId) => {
                                const clinic = clinicFilters.find(({id}) => id === clinicId);
                                return clinic
                                    ? {
                                          id: clinic.id,
                                          name: clinic.name,
                                          category: 'clinic'
                                      }
                                    : {
                                          id: clinicId,
                                          name: t('search_item_unknown.text'),
                                          category: 'unknown'
                                      };
                            })}
                            caregivers={
                                searchParams.caregiver.map((careGiverId) => {
                                    return (
                                        careGiverFilters.find(({id}) => id.toString() === careGiverId) || {
                                            id: careGiverId,
                                            label: t('search_item_unknown.text'),
                                            category: 'unknown'
                                        }
                                    );
                                }) as Array<CaregiverObject>
                            }
                            removePlace={(placeId) => onRemovePlaceFilter(placeId)}
                            removeCareGiver={(careGiverId) => onToggleFilter('caregiver', careGiverId, false)}
                            removeClinic={(clinicId) => onToggleFilter('clinic', clinicId, false)}
                        />
                    )}
            </form>
        </>
    );
}
