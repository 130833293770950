import {createContext, useCallback, useContext, useEffect, useReducer, useRef} from 'react';
import {fetchAccount, fetchPerson, postAccount, removeAccount} from '../services/api/api';
import {refreshEidSession} from '../services/api/implementations/eidapi';
import {UserMinimalJSON, UserJSON, AccountJSON} from '../services/api/types/types';
import {debug} from 'src/services/debug/debug';
import {useStateRestore} from 'src/hooks/useStateRestore';

export enum SessionActions {
    SET_HAS_ACTIVE_SESSION = 'SET_HAS_ACTIVE_SESSION',
    SET_TIMEOUT_WARNING = 'SET_TIMEOUT_WARNING',
    SET_SESSION_TIMED_OUT = 'SET_SESSION_TIMED_OUT',
    SET_SESSION_TOKEN_TIMED_OUT_WARNING = 'SET_SESSION_TOKEN_TIMED_OUT_WARNING',
    SET_USER_JWT = 'SET_USER_JWT',
    SET_USER = 'SET_USER',
    SET_USER_ERROR = 'SET_USER_ERROR',
    CLEAR_USER_SESSION = 'CLEAR_USER_SESSION',
    ALLOW_OTHER_DEVICE_NAVIGATION = 'ALLOW_OTHER_DEVICE_NAVIGATION',
    SET_ACCOUNT = 'SET_ACCOUNT',
    SET_ACCOUNT_AGE_RESTRICTION = 'SET_ACCOUNT_AGE_RESTRICTION',
    LOADING = 'LOADING',
    LOADING_ACCOUNT = 'LOADING_ACCOUNT',
    REMOVING_ACCOUNT = 'REMOVING_ACCOUNT',
    SAVING_ACCOUNT = 'SAVING_ACCOUNT'
}

type Action = {type: SessionActions; value?: boolean | string | UserMinimalJSON | AccountJSON | null};
type Dispatch = (action: Action) => void;

type State = {
    hasActiveSession: boolean;
    timeoutWarning: boolean;
    sessionTimedOut: boolean;
    tokenTimeoutWarning: boolean;
    allowOtherDeviceNavigation: boolean;
    userJwt: string | null;
    user: UserJSON | null;
    userError?: string | null;
    account: AccountJSON | null;
    isAgeRestricted?: boolean;
    isLoading: boolean;
    isRemovingAccount: boolean;
    isLoadingAccount: boolean;
    isSavingAccount: boolean;
};
type SessionProviderProps = {children: React.ReactNode};
type JWT = string;

export const SessionContext = createContext<
    | {
          sessionState: State;
          sessionDispatch: Dispatch;
          refreshSession(): Promise<JWT | null>;
          startSession(userJwt: string, sessionTimeoutSeconds: number, tokenDurationSeconds: number): void;
          clearSession({redirectToHome}: {redirectToHome: boolean}): void;
          updateAccount(userJwt: string, account: AccountJSON): void;
          removeUserAccount(userJwt: string): void;
      }
    | undefined
>(undefined);
function SessionReducer(state: State, action: Action): State {
    switch (action.type) {
        case SessionActions.LOADING: {
            return {...state, isLoading: action.value as boolean};
        }
        case SessionActions.SET_HAS_ACTIVE_SESSION: {
            return {...state, hasActiveSession: action.value as boolean};
        }
        case SessionActions.SET_TIMEOUT_WARNING: {
            // Don't alter session warning if session is no longer active
            if (state.hasActiveSession === false) {
                return {...state};
            }
            return {...state, timeoutWarning: action.value as boolean};
        }
        case SessionActions.SET_SESSION_TIMED_OUT: {
            // Don't alter session timeout if session is no longer active
            if (state.hasActiveSession === false) {
                return {...state};
            }
            return {
                ...state,
                sessionTimedOut: action.value as boolean,
                hasActiveSession: false,
                timeoutWarning: false,
                userJwt: null,
                user: null,
                account: null
            };
        }
        case SessionActions.SET_SESSION_TOKEN_TIMED_OUT_WARNING: {
            return {...state, tokenTimeoutWarning: action.value as boolean};
        }
        case SessionActions.ALLOW_OTHER_DEVICE_NAVIGATION: {
            return {...state, allowOtherDeviceNavigation: action.value as boolean};
        }
        case SessionActions.LOADING_ACCOUNT: {
            return {...state, isLoadingAccount: action.value as boolean};
        }
        case SessionActions.SAVING_ACCOUNT: {
            return {...state, isSavingAccount: action.value as boolean};
        }
        case SessionActions.REMOVING_ACCOUNT: {
            return {...state, isRemovingAccount: action.value as boolean};
        }
        case SessionActions.SET_ACCOUNT: {
            return {...state, account: action.value as AccountJSON};
        }
        case SessionActions.SET_ACCOUNT_AGE_RESTRICTION: {
            return {...state, isAgeRestricted: action.value as boolean};
        }
        case SessionActions.SET_USER_JWT: {
            return {...state, userJwt: action.value as string};
        }
        case SessionActions.SET_USER: {
            return {...state, user: action.value as UserJSON};
        }
        case SessionActions.SET_USER_ERROR: {
            return {...state, userError: action.value as string};
        }
        case SessionActions.CLEAR_USER_SESSION: {
            return {
                sessionTimedOut: false,
                hasActiveSession: false,
                timeoutWarning: false,
                tokenTimeoutWarning: false,
                allowOtherDeviceNavigation: false,
                userJwt: null,
                user: null,
                account: null,
                isLoading: false,
                isRemovingAccount: false,
                isLoadingAccount: false,
                isSavingAccount: false,
                isAgeRestricted: false
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function SessionProvider({children}: SessionProviderProps) {
    const [sessionState, sessionDispatch] = useReducer(SessionReducer, {
        hasActiveSession: false,
        timeoutWarning: false,
        sessionTimedOut: false,
        tokenTimeoutWarning: false,
        allowOtherDeviceNavigation: false,
        userJwt: null,
        user: null,
        account: null,
        isLoading: false,
        isRemovingAccount: false,
        isLoadingAccount: false,
        isSavingAccount: false,
        isAgeRestricted: false
    });
    let lastActionTime = useRef(new Date().getTime()); // Time of last user action, used for access token refresh
    const jwtRef = useRef(sessionState.userJwt); // ref to keep track of the jwt within timeout
    const hasActiveSessionRef = useRef(sessionState.hasActiveSession); // ref to keep track of the session within timeout
    const currentSessionTimer = useRef<NodeJS.Timeout>(); // this is the maximum allowed length of the session
    const currentSessionWarningTimer = useRef<NodeJS.Timeout>(); // this is the warning that the session is about to expire
    const currentAccessTokenTimer = useRef<NodeJS.Timeout>(); // this is the maximum allowed length of the access token
    const currentAccessTokenWarningTimer = useRef<NodeJS.Timeout>(); // this is the warning that the access token is about to expire
    const {
        clearSessionStorage,
        getUserJwtFromCache,
        getUserJwtExpireAtFromCache,
        updateUserJwtCache,
        updateUserJwtExpireAtCache
    } = useStateRestore();

    const clearSession = useCallback(
        ({redirectToHome}: {redirectToHome: boolean}) => {
            hasActiveSessionRef.current = false;
            jwtRef.current = null;
            clearSessionStorage();
            window.removeEventListener('mousemove', updateLastActionTime);
            window.removeEventListener('click', updateLastActionTime);
            window.removeEventListener('keydown', updateLastActionTime);
            window.removeEventListener('touchstart', updateLastActionTime);
            sessionDispatch({type: SessionActions.CLEAR_USER_SESSION});
            if (redirectToHome) {
                window.location.href = '/';
            }
        },
        [jwtRef, hasActiveSessionRef, clearSessionStorage]
    );

    const refreshSession = useCallback(async () => {
        sessionDispatch({type: SessionActions.SET_TIMEOUT_WARNING, value: false});
        sessionDispatch({type: SessionActions.SET_SESSION_TOKEN_TIMED_OUT_WARNING, value: false});

        try {
            // Check if there is a jwt in sessionStorage with valid TTL
            const prevJwt = getUserJwtFromCache();
            if (prevJwt) {
                clearSessionStorage();
                !jwtRef.current && sessionDispatch({type: SessionActions.LOADING, value: true});
                jwtRef.current = prevJwt;
            }

            if (jwtRef.current === null) {
                clearSession({redirectToHome: false});
                return null;
            }

            const newSession = await refreshEidSession(jwtRef.current);
            sessionDispatch({type: SessionActions.SET_USER_JWT, value: newSession.jwt});
            sessionDispatch({type: SessionActions.SET_HAS_ACTIVE_SESSION, value: true});
            const accessTokenTimeoutMs = newSession.tokenDuration * 1000;
            const sessionTimeoutMs = newSession.remainingSessionDuration * 1000;
            jwtRef.current = newSession.jwt;
            hasActiveSessionRef.current = true;

            // Store the jwt in sessionStorage with TTL
            updateUserJwtCache(newSession.jwt);
            const accessTokenExpireAt = new Date().getTime() + accessTokenTimeoutMs;
            updateUserJwtExpireAtCache(accessTokenExpireAt.toString());

            // Update the user and account if they don't exist
            if (!sessionState.user || !sessionState.account) {
                try {
                    const authUser = await fetchPerson(newSession.jwt);
                    sessionDispatch({type: SessionActions.SET_USER, value: authUser});
                } catch (error: any) {
                    sessionDispatch({type: SessionActions.SET_USER_ERROR, value: 'Could not fetch user'});
                }
                try {
                    sessionDispatch({type: SessionActions.LOADING_ACCOUNT, value: true});
                    const account = await fetchAccount(newSession.jwt);
                    sessionDispatch({type: SessionActions.SET_ACCOUNT, value: account});
                } catch (error: any) {
                    if (error.errorCode === 'AGE_RESTRICTION') {
                        sessionDispatch({type: SessionActions.SET_ACCOUNT_AGE_RESTRICTION, value: true});
                    } else {
                        sessionDispatch({type: SessionActions.SET_ACCOUNT, value: null});
                        sessionDispatch({type: SessionActions.SET_ACCOUNT_AGE_RESTRICTION, value: false});
                    }
                } finally {
                    sessionDispatch({type: SessionActions.LOADING, value: false});
                    sessionDispatch({type: SessionActions.LOADING_ACCOUNT, value: false});
                }
            }
            // --- Update session timers --- //
            // Warning after 8 minutes when accessTokenTimer is 10 minutes
            clearTimeout(currentAccessTokenWarningTimer.current);
            currentAccessTokenWarningTimer.current = setTimeout(() => {
                if (
                    lastActionTime.current + (accessTokenTimeoutMs - accessTokenTimeoutMs * (2 / 10)) <
                    new Date().getTime()
                ) {
                    sessionDispatch({type: SessionActions.SET_SESSION_TOKEN_TIMED_OUT_WARNING, value: true});
                } else {
                    // Refresh the session if the user has been active
                    refreshSession();
                }
            }, accessTokenTimeoutMs - accessTokenTimeoutMs * (2 / 10));

            // Sign out the user after 10 minutes if no refresh has been made
            clearTimeout(currentAccessTokenTimer.current);
            currentAccessTokenTimer.current = setTimeout(async () => {
                clearSession({redirectToHome: true});
            }, accessTokenTimeoutMs);

            // Warning after 50 minutes when sessionTimeout is 1 hour, that the user will be signed out at 1h
            clearTimeout(currentSessionWarningTimer.current);
            currentSessionWarningTimer.current = setTimeout(() => {
                sessionDispatch({type: SessionActions.SET_TIMEOUT_WARNING, value: true});
            }, sessionTimeoutMs - sessionTimeoutMs * (1 / 6));

            // Clear the session after 1 hour, this will sign out the user
            clearTimeout(currentSessionTimer.current);
            currentSessionTimer.current = setTimeout(async () => {
                clearSession({redirectToHome: true});
            }, sessionTimeoutMs);
            sessionDispatch({type: SessionActions.LOADING, value: false});

            return newSession.jwt;
        } catch (error) {
            sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
            sessionDispatch({type: SessionActions.SET_SESSION_TOKEN_TIMED_OUT_WARNING, value: false});
            clearTimeout(currentSessionTimer.current);
            clearTimeout(currentAccessTokenWarningTimer.current);
            clearTimeout(currentAccessTokenTimer.current);
            jwtRef.current = null;
            hasActiveSessionRef.current = false;
            return null;
        } finally {
            sessionDispatch({type: SessionActions.LOADING_ACCOUNT, value: false});
        }
    }, [
        jwtRef,
        hasActiveSessionRef,
        lastActionTime,
        currentAccessTokenTimer,
        currentAccessTokenWarningTimer,
        clearSession,
        sessionState.user,
        sessionState.account,
        clearSessionStorage,
        getUserJwtFromCache,
        updateUserJwtCache,
        updateUserJwtExpireAtCache
    ]);

    const startSession = useCallback(
        async (userJwt: string, sessionTimeoutSeconds: number, tokenDurationSeconds: number) => {
            sessionDispatch({type: SessionActions.LOADING, value: true});
            sessionDispatch({type: SessionActions.SET_HAS_ACTIVE_SESSION, value: true});
            sessionDispatch({type: SessionActions.SET_USER_JWT, value: userJwt});
            const accessTokenTimeoutMs = tokenDurationSeconds * 1000;
            const sessionTimeoutMs = sessionTimeoutSeconds * 1000;
            hasActiveSessionRef.current = true;
            jwtRef.current = userJwt;

            // Update the session storage with the new jwt
            const prevJwt = getUserJwtFromCache();
            if (prevJwt) {
                clearSessionStorage();
            }
            updateUserJwtCache(userJwt);
            const accessTokenExpireAt = new Date().getTime() + accessTokenTimeoutMs;
            updateUserJwtExpireAtCache(accessTokenExpireAt.toString());

            try {
                const authUser = await fetchPerson(userJwt);
                sessionDispatch({type: SessionActions.SET_USER, value: authUser});
            } catch (error: any) {
                sessionDispatch({type: SessionActions.SET_USER_ERROR, value: 'Could not fetch user'});
            }
            try {
                sessionDispatch({type: SessionActions.LOADING_ACCOUNT, value: true});
                const account = await fetchAccount(userJwt);
                sessionDispatch({type: SessionActions.SET_ACCOUNT, value: account});
            } catch (error: any) {
                if (error.errorCode === 'AGE_RESTRICTION') {
                    sessionDispatch({type: SessionActions.SET_ACCOUNT_AGE_RESTRICTION, value: true});
                } else {
                    sessionDispatch({type: SessionActions.SET_ACCOUNT, value: null});
                    sessionDispatch({type: SessionActions.SET_ACCOUNT_AGE_RESTRICTION, value: false});
                }
            } finally {
                sessionDispatch({type: SessionActions.LOADING_ACCOUNT, value: false});
            }

            // Event listeners to keep track of user activity / inactivity
            window.addEventListener('click', updateLastActionTime);
            window.addEventListener('keydown', updateLastActionTime);
            window.addEventListener('touchstart', updateLastActionTime);

            // --- Update session timers --- //
            // Warning after 8 minutes when accessTokenTimer is 10 minutes
            clearTimeout(currentAccessTokenWarningTimer.current);
            currentAccessTokenWarningTimer.current = setTimeout(async () => {
                if (
                    lastActionTime.current + (accessTokenTimeoutMs - accessTokenTimeoutMs * (2 / 10)) <
                    new Date().getTime()
                ) {
                    sessionDispatch({type: SessionActions.SET_SESSION_TOKEN_TIMED_OUT_WARNING, value: true});
                } else {
                    // Refresh the session if the user has been active
                    refreshSession();
                }
            }, accessTokenTimeoutMs - accessTokenTimeoutMs * (2 / 10));

            // Sign out the user after 10 minutes if no refresh has been made
            clearTimeout(currentAccessTokenTimer.current);
            currentAccessTokenTimer.current = setTimeout(async () => {
                clearSession({redirectToHome: true});
            }, accessTokenTimeoutMs);

            // Warning after 50 minutes when sessionTimeout is 1 hour, that the user will be signed out at 1h
            clearTimeout(currentSessionWarningTimer.current);
            currentSessionWarningTimer.current = setTimeout(() => {
                sessionDispatch({type: SessionActions.SET_TIMEOUT_WARNING, value: true});
            }, sessionTimeoutMs - sessionTimeoutMs * (1 / 6));

            // Clear the session after 1 hour, this will sign out the user
            clearTimeout(currentSessionTimer.current);
            currentSessionTimer.current = setTimeout(async () => {
                clearSession({redirectToHome: true});
            }, sessionTimeoutMs);
            sessionDispatch({type: SessionActions.LOADING, value: false});
        },
        [
            jwtRef,
            hasActiveSessionRef,
            lastActionTime,
            currentAccessTokenTimer,
            currentAccessTokenWarningTimer,
            clearSession,
            refreshSession,
            getUserJwtFromCache,
            updateUserJwtCache,
            updateUserJwtExpireAtCache,
            clearSessionStorage
        ]
    );

    const updateAccount = async (userJwt: string, account: AccountJSON) => {
        sessionDispatch({type: SessionActions.SAVING_ACCOUNT, value: true});
        try {
            const response = await postAccount(userJwt, account);
            if (response.ok || debug.debugActive) {
                sessionDispatch({type: SessionActions.SET_ACCOUNT, value: account});
            } else {
                sessionDispatch({type: SessionActions.SET_ACCOUNT, value: null});
            }
        } catch (error) {
            sessionDispatch({type: SessionActions.SET_ACCOUNT, value: null});
        } finally {
            sessionDispatch({type: SessionActions.SAVING_ACCOUNT, value: false});
        }
    };

    const removeUserAccount = async (userJwt: string) => {
        sessionDispatch({type: SessionActions.REMOVING_ACCOUNT, value: true});
        try {
            const response = await removeAccount(userJwt);
            if (response.ok) {
                clearSession({redirectToHome: true});
            } else {
                sessionDispatch({type: SessionActions.SET_USER_ERROR, value: 'Could not remove user account'});
            }
        } catch (error) {
            sessionDispatch({type: SessionActions.SET_USER_ERROR, value: 'Could not remove user account'});
        } finally {
            sessionDispatch({type: SessionActions.REMOVING_ACCOUNT, value: false});
        }
    };
    // Check if there is a jwt in sessionStorage with valid TTL and start the session
    useEffect(() => {
        const userJwt = getUserJwtFromCache();
        const accessTokenExpireAt = getUserJwtExpireAtFromCache();

        if (!jwtRef.current && userJwt && accessTokenExpireAt) {
            const accessTokenTimeoutInSeconds = (parseInt(accessTokenExpireAt) - new Date().getTime()) / 1000;
            accessTokenTimeoutInSeconds > 0 && refreshSession();
            accessTokenTimeoutInSeconds <= 0 && clearSessionStorage();
        }
    }, [refreshSession, getUserJwtFromCache, getUserJwtExpireAtFromCache, clearSessionStorage]);

    const updateLastActionTime = () => {
        lastActionTime.current = new Date().getTime();
    };

    const value = {
        sessionState,
        sessionDispatch,
        startSession,
        refreshSession,
        clearSession,
        updateAccount,
        removeUserAccount
    };
    return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}

function useSessionContext() {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error('useSessionContext must be used within a UiProvider');
    }

    return context;
}

export {SessionProvider, useSessionContext};
