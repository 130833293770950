export function EmailIcon() {
    return (
        <>
            <svg
                width='24'
                height='24'
                focusable='false'
                aria-hidden='true'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M4.8 4H19.2C20.19 4 21 4.84375 21 5.875V17.125C21 18.1563 20.19 19 19.2 19H4.8C3.81 19 3 18.1563 3 17.125V5.875C3 4.84375 3.81 4 4.8 4Z'
                    stroke='#E30031'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M20 6L12 12L4 6'
                    stroke='#E30031'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
}
