import {useEffect, useState} from 'react';
import {initEIdSession, EIdInitData, EIdProvider, EIdPollData} from '../../services/api/implementations/eidapi';
import {EidModal} from './components/EidModal';
import {BankIdIcon} from '../icons/BankIdIcon';
import {FrejaIcon} from '../icons/FrejaIcon';
import {debug} from '../../services/debug/debug';
import {useAppState} from 'src/hooks/useAppState';
import './EidAuthenticate.scss';

interface EidAuthenticateProps {
    onLoginSuccess(data: EIdPollData): void;
}

export function EidAuthenticate({onLoginSuccess}: EidAuthenticateProps) {
    const [activeType, setActiveType] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [initData, setInitData] = useState<EIdInitData | null>(null);
    const [displayError, setDisplayError] = useState(false);
    const {clearAppData} = useAppState();

    const providerLabels: Record<EIdProvider, string> = {
        bankid: 'BankID',
        freja: 'Freja eID+',
        mock: 'MockID'
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const initData = sessionStorage.getItem('eidInitData');
        const provider = sessionStorage.getItem('eidProvider');

        if (initData && provider) {
            setInitData(JSON.parse(initData));
            setActiveType(provider);
            setShowModal(true);
        }
        return () => {
            sessionStorage.removeItem('eidInitData');
            sessionStorage.removeItem('eidProvider');
        };
    }, []);

    const openModal = async (provider: EIdProvider) => {
        setActiveType(providerLabels[provider]);
        sessionStorage.setItem('eidProvider', providerLabels[provider]);

        try {
            const initData = await initEIdSession(provider);
            sessionStorage.setItem('eidInitData', JSON.stringify(initData));
            setInitData(initData);
        } catch (error) {
            console.log(error);
            setDisplayError(true);
            clearAppData();
            sessionStorage.removeItem('eidInitData');
            sessionStorage.removeItem('eidProvider');
        }
        setShowModal(true);
    };
    const stripEndOfUrlHash = () => {
        const urlPath = `${window.location.origin}${window.location.pathname}`;
        window.history.pushState({}, '', urlPath);
    };

    const closeModal = () => {
        setActiveType('');
        setShowModal(false);
        setDisplayError(false);
    };

    return (
        <>
            <section className='login__section'>
                <div className='login__section__buttons'>
                    <button
                        id='bankid-button'
                        className={`login__section__buttons__button bank-id ${activeType === 'BankID' ? 'active' : ''}`}
                        onClick={() => openModal('bankid')}
                    >
                        <span>BankID</span>
                        <BankIdIcon />
                    </button>
                    <button
                        className={`login__section__buttons__button freja ${
                            activeType === 'Freja eID+' ? 'active' : ''
                        }`}
                        onClick={() => openModal('freja')}
                    >
                        <span>Freja eID+</span>
                        <FrejaIcon />
                    </button>
                    {debug.debugActive && (
                        <button
                            className={`login__section__buttons__button freja ${
                                activeType === 'Mockid' ? 'active' : ''
                            }`}
                            onClick={() => openModal('mock')}
                        >
                            <span>MockID</span>
                            <span style={{color: '#e30031', lineHeight: '40px', textAlign: 'right'}}>ᖍ(ツ)ᖌ</span>
                        </button>
                    )}
                </div>
            </section>
            {showModal && initData !== null && (
                <EidModal
                    initData={initData}
                    onLoginSuccess={(data) => {
                        onLoginSuccess(data);
                        stripEndOfUrlHash();
                    }}
                    displayError={displayError}
                    closeModal={() => closeModal()}
                    selectedType={activeType}
                />
            )}
        </>
    );
}
