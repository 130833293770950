interface RadioControlProps {
    name: string;
    ariaLabel: string;
    onClick(checked: boolean): void;
    checked: boolean;
    className?: string;
}

export function RadioControl({ariaLabel, checked, className, name, onClick}: RadioControlProps) {
    return (
        <input
            className={`radio-input__control ${className}`}
            name={name}
            aria-label={ariaLabel}
            onClick={(e) => onClick(e.currentTarget.checked)}
            onChange={(e) => onClick(e.currentTarget.checked)}
            checked={checked}
            type='radio'
        />
    );
}
