export function ChevronUpIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='M13.5 11L8 5.5L2.5 11' stroke='#E30031' strokeWidth='2.5' />
            </svg>
        </>
    );
}
