import {useTranslation} from 'react-i18next';
import {useMapDataContext} from '../../../context/MapDataContext';
import {useUiContext} from '../../../context/UiContext';
import {SearchFilterMap} from '../../../hooks/useSearchFilters';
import {VisitCategory} from '../components/PickVisitCategory';
import {ReasonFilters} from './ReasonFilters';

interface PickVisitReasonsProps {
    selectedCategory: VisitCategory;
    searchParams: SearchFilterMap;
    addReasonFilter(id: string): void;
    removeReasonFilter(id: string): void;
}

export function PickVisitReasons({
    searchParams,
    selectedCategory,
    addReasonFilter,
    removeReasonFilter
}: PickVisitReasonsProps) {
    const {t} = useTranslation();

    const {
        state: {searchExtended}
    } = useUiContext();
    const {
        state: {visitReasons}
    } = useMapDataContext();

    const headings = {
        travel: t('vaccinations_travel_heading.text'),
        other: t('vaccinations_other_heading.text'),
        vaccination: t('vaccinations_vaccination_heading.text')
    };
    const disableTabNavigation = searchExtended === false;
    const reasonFiltersByCategory = visitReasons.filter(({category}) => category === selectedCategory);

    return (
        <fieldset className='map_modal__content map_modal__content--borderless'>
            <legend className='off-screen'>{headings[selectedCategory]}</legend>
            <h2>{headings[selectedCategory]}</h2>

            <ReasonFilters
                selectedCategory={selectedCategory}
                reasonFilters={reasonFiltersByCategory}
                activeFilters={searchParams.reason}
                disableTabNavigation={disableTabNavigation}
                onToggleFilter={(id, checked) => {
                    checked ? addReasonFilter(id) : removeReasonFilter(id);
                }}
            />
        </fieldset>
    );
}
