import {useTranslation} from 'react-i18next';
import {Button} from '../../../form/button/Button';
import ModalPortal from '../../../layout/modal/ModalPortal';

import './ConfirmUnbookingModal.scss';

interface ConfirmUnbookingModalProps {
    onConfirmClick(): void;
    handleCloseModal(): void;
}

export function ConfirmUnbookingModal({onConfirmClick, handleCloseModal}: ConfirmUnbookingModalProps) {
    const {t} = useTranslation();

    return (
        <ModalPortal onCloseClick={handleCloseModal}>
            <div className='confirm-unbooking-modal'>
                <h1>{t('handle_booking.unbooking_heading')}</h1>
                <p>{t('handle_booking.unbooking_content')}</p>
                <p className='confirm-unbooking-modal__buttons'>
                    <Button className='confirm-unbooking-modal__confirm-button' big onClick={() => onConfirmClick()}>
                        <span>{t('handle_booking.unbooking_confirm')}</span>
                    </Button>
                </p>
            </div>
        </ModalPortal>
    );
}
