export function WorldIcon() {
    return (
        <>
            <svg
                width='24'
                height='24'
                aria-hidden='true'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12.006 22.2033C10.5952 22.2033 9.26941 21.9358 8.02861 21.4009C6.78781 20.8659 5.70744 20.1384 4.78751 19.2185C3.86758 18.2986 3.14013 17.2182 2.60516 15.9773C2.07021 14.7363 1.80273 13.4104 1.80273 11.9995C1.80273 10.5886 2.07021 9.26289 2.60516 8.0223C3.14013 6.78172 3.86758 5.70147 4.78751 4.78155C5.70744 3.86162 6.78786 3.13417 8.02876 2.5992C9.26968 2.06424 10.5956 1.79675 12.0065 1.79675C13.4174 1.79675 14.7421 2.06424 15.9807 2.5992C17.2193 3.13417 18.2986 3.86162 19.2185 4.78155C20.1384 5.70147 20.8659 6.78189 21.4008 8.0228C21.9358 9.26372 22.2033 10.5896 22.2033 12.0005C22.2033 13.4114 21.9358 14.7372 21.4008 15.9778C20.8659 17.2183 20.1384 18.2986 19.2185 19.2185C18.2986 20.1384 17.2188 20.8659 15.9793 21.4009C14.7398 21.9358 13.4153 22.2033 12.006 22.2033ZM11.012 19.8783V17.9343C10.4659 17.9343 9.99907 17.7404 9.61133 17.3526C9.22358 16.9649 9.02971 16.4987 9.02971 15.9542V14.9642L4.27773 10.212C4.22773 10.508 4.1819 10.804 4.14023 11.1C4.09857 11.396 4.07773 11.6929 4.07773 11.9907C4.07773 13.9923 4.73425 15.7458 6.04728 17.2512C7.36033 18.7566 9.01523 19.6323 11.012 19.8783ZM17.8522 17.3522C18.1855 16.9895 18.4825 16.5977 18.7432 16.1767C19.0039 15.7556 19.2218 15.317 19.3968 14.8606C19.5718 14.4043 19.7041 13.9358 19.7938 13.4551C19.8834 12.9744 19.9283 12.4854 19.9283 11.9881C19.9283 10.3713 19.4801 8.89469 18.5837 7.55835C17.6873 6.222 16.4868 5.25781 14.9821 4.66578V5.05383C14.9821 5.59889 14.7863 6.06551 14.3946 6.45368C14.0029 6.84183 13.535 7.0359 12.9908 7.0359H11.012V9.01618C11.012 9.29671 10.9161 9.53281 10.7245 9.72448C10.5328 9.91614 10.2967 10.012 10.0162 10.012H8.03588V11.9881H13.9856C14.2666 11.9881 14.5029 12.0839 14.6946 12.2756C14.8863 12.4672 14.9821 12.7033 14.9821 12.9836V15.9522H15.9641C16.3975 15.9522 16.7875 16.0814 17.1341 16.3397C17.4808 16.598 17.7201 16.9355 17.8522 17.3522Z'
                    fill='#E30031'
                />
            </svg>
        </>
    );
}
