import {useEffect} from 'react';
import {useSessionContext} from 'src/context/SessionContext';
import {HandleBookingIdentity} from './components/HandleBookingIdentity';
import {BookingResponse} from 'src/services/api/types/types';
import {HandleBookings} from './HandleBookings';
import {HandleOpenBooking} from './HandleOpenBooking';
import {HandleBookingActions, useHandleBookingContext} from 'src/context/HandleBookingContext';
import {SnackBar} from '../../layout/SnackBar/SnackBar';
import {Spinner} from 'src/components/layout/loader/Spinner';
import './HandleBookingMain.scss';

export function HandleBookingMain() {
    const {sessionState, startSession} = useSessionContext();
    const {dispatch, state, updateBookingUser, refreshAllBookings} = useHandleBookingContext();

    const {openBooking, snackBarMessage} = state;

    const hasIdentity = openBooking !== null || sessionState.hasActiveSession;

    useEffect(() => {
        if (sessionState.userJwt) {
            refreshAllBookings(sessionState.userJwt);
        }
    }, [sessionState.userJwt, refreshAllBookings]);

    useEffect(() => {
        if (sessionState.user) {
            updateBookingUser(); // Update booking context with user data from session
        }
    }, [sessionState.user, updateBookingUser]);

    if (sessionState.isLoading) {
        return (
            <div className='handle-container__spinner'>
                <div className='handle-container__spinner__icon'>
                    <Spinner />
                </div>
            </div>
        );
    }
    return (
        <>
            <main className='handle-booking' id='main'>
                <>
                    {!hasIdentity && (
                        <HandleBookingIdentity
                            onBookingSuccess={(booking: BookingResponse) => {
                                dispatch({type: HandleBookingActions.SET_OPEN_BOOKING, value: booking});
                            }}
                            onLoginSuccess={async ({jwt, sessionDuration, tokenDuration}) => {
                                startSession(jwt, sessionDuration, tokenDuration);
                            }}
                        />
                    )}
                    {hasIdentity && (
                        <>
                            {openBooking && !sessionState.hasActiveSession && (
                                <HandleOpenBooking booking={openBooking} />
                            )}
                            {sessionState.hasActiveSession && <HandleBookings />}
                        </>
                    )}
                </>

                {snackBarMessage !== null && (
                    <SnackBar
                        content={snackBarMessage}
                        onExited={() => dispatch({type: HandleBookingActions.SET_SNACKBAR_MESSAGE, value: null})}
                    />
                )}
            </main>
        </>
    );
}
