import './style.scss';
import {useTranslation} from 'react-i18next';
import {ClinicAside} from './components/ClinicAside/ClinicAside';
import {ClinicMain} from './components/ClinicMain/ClinicMain';
import {DataActions, useClinicDetailsContext} from '../../context/ClinicDetailsContext';
import {BookingActions, useBookingContext} from '../../context/BookingContext';
import {ClinicHeader} from './components/ClinicHeader/ClinicHeader';
import {Button} from '../form/button/Button';
import ModalPortal from '../layout/modal/ModalPortal';
import {SessionWarningModal} from './components/Session/SessionWarningModal';
import {useEffect, useState} from 'react';
import {OpenSessionWarningModal} from './components/Session/OpenSessionWarningModal';
import {BookingSessionActions, useBookingSessionContext} from '../../context/BookingSessionContext';
import {useSessionContext} from '../../context/SessionContext';
import {PolicyLinks} from '../policy/links/PolicyLinks';
import {CookieConsentModal} from '../cookies/CookieConsent/CookieConsentModal';
import {useCaregiverConsentCookie} from '../../hooks/useCaregiverConsentCookie';
import {ClinicLoading} from './components/ClinicLoading/ClinicLoading';
import {ClinicError} from './components/ClinicError/ClinicError';
import {gTagManager} from 'src/lib/gTagManager/gTagManager';

interface ClinicDetailsProps {
    healthDeclarationMode?: boolean;
}

export class NoHealthDeclarationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NoHealthDeclarationError';
    }
}

export function ClinicDetails({healthDeclarationMode = false}: ClinicDetailsProps) {
    const {t} = useTranslation();

    const {
        dataState: {loading, clinic, error, careGivers},
        dispatch: clinicDispatch
    } = useClinicDetailsContext();
    const {
        bookingState: {step, bookingId, bookingType},
        dispatch
    } = useBookingContext();
    const {bookingSessionState, refreshBookingSession, bookingSessionDispatch} = useBookingSessionContext();
    const {sessionState} = useSessionContext();
    const [showTermsModal, setShowTermsModal] = useState(false);

    const caregiver = careGivers.find(({id}) => clinic && id === clinic.careGiver);
    const {hasConsent, hasChosen, setCookieConsent} = useCaregiverConsentCookie(
        caregiver?.id as number,
        caregiver?.cookiePolicyVersion as string
    );

    const stepClasses = step ? ` ${step}` : '';

    const ClinicFooter = () => {
        return (
            <footer className='container__footer'>
                {clinic && showTermsModal && (
                    <ModalPortal onCloseClick={() => setShowTermsModal(false)}>
                        <div className='policy-modal'>
                            <h1 className='policy-modal__header'>{t('confirm.policy.header')}</h1>
                            <p className='policy-modal__content wrap-html'>{clinic.termsText}</p>
                        </div>
                    </ModalPortal>
                )}
                <div>
                    <a href={process.env.REACT_APP_LANDING_PAGE_URL} target='_blank' rel='noreferrer'>
                        <img src='/mittvaccin_logo.svg' alt='Mitt vaccin' />
                        <span className='screen-reader-only'>{t('general.opens_in_new_window')}</span>
                    </a>
                </div>
                <div className='container__footer__links'>
                    <p>
                        <PolicyLinks />
                        {clinic && (
                            <a
                                href='/'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowTermsModal(true);
                                }}
                            >
                                {t('clinic_footer.clinic_privacy_policy')}
                            </a>
                        )}
                    </p>
                </div>
            </footer>
        );
    };
    useEffect(() => {
        if (loading || clinic === null) {
            return;
        }
        const descriptionElement = document.querySelector('meta[name=description]');
        if (descriptionElement && clinic?.welcomeText) {
            descriptionElement.setAttribute('content', clinic.welcomeText.replace(/(\r\n|\n|\r)/gm, ' '));
        }
        const titleElement = document.querySelector('title');
        if (titleElement) {
            titleElement.text = `${clinic.name} - MittVaccin.se`;
        }
    }, [clinic, loading]);

    useEffect(() => {
        if (sessionState.hasActiveSession && sessionState.sessionTimedOut) {
            window.scrollTo(0, 0);
        }
    }, [sessionState.hasActiveSession, sessionState.sessionTimedOut]);

    useEffect(() => {
        if (healthDeclarationMode) {
            dispatch({type: BookingActions.BOOKING_HEALTH_DECLARATION_MODE});
        }
    }, [dispatch, healthDeclarationMode]);

    useEffect(() => {
        if (!clinic || error !== null) {
            return;
        }

        if (healthDeclarationMode && !clinic.hasHealthDeclarationInbox) {
            clinicDispatch({
                type: DataActions.SET_ERROR,
                value: new NoHealthDeclarationError('NoHealthDeclarationError')
            });
        }
    }, [clinic, clinicDispatch, dispatch, error, healthDeclarationMode]);

    // Google Tag Manager
    useEffect(() => {
        if (clinic === null || !clinic.googleAnalyticsTag) {
            return;
        }
        if (!hasConsent || document.querySelector('#gtm-script') !== null) {
            return;
        }
        gTagManager().initiate(clinic);
        gTagManager().updateConsent(hasConsent);

        return () => {
            gTagManager().removeScript();
        };
    }, [clinic, hasConsent]);

    if (sessionState.hasActiveSession && sessionState.sessionTimedOut) {
        return (
            <div className='container error-container'>
                <main className='main-content error-container__content' id='main'>
                    <section aria-live='assertive' aria-atomic>
                        <h1 className='error-container__header'>{t(`session.${bookingType}.expired_heading`)}</h1>
                        <p className='error-container__info-text'>{t(`session.${bookingType}.expired_content`)}</p>
                        <Button className='error-container__button' big onClick={() => window.location.reload()}>
                            <span>{t(`session.${bookingType}.expired_button`)}</span>
                        </Button>
                    </section>
                </main>
                <ClinicFooter />
            </div>
        );
    }

    return (
        <div className={`container${stepClasses}`} aria-busy={loading}>
            {!clinic?.externalBookingUrl && sessionState.hasActiveSession && bookingSessionState.showTimeoutWarning && (
                <ModalPortal>
                    <SessionWarningModal
                        refreshCallback={() => {
                            refreshBookingSession(bookingId);
                        }}
                    />
                </ModalPortal>
            )}
            {!clinic?.externalBookingUrl && bookingId && bookingSessionState.showOpenBookingTimeoutWarning && (
                <ModalPortal>
                    <OpenSessionWarningModal
                        onClose={() => {
                            bookingSessionDispatch({
                                type: BookingSessionActions.SET_SHOW_OPEN_BOOKING_TIMEOUT_WARNING,
                                value: false
                            });
                        }}
                    />
                </ModalPortal>
            )}
            {!clinic?.externalBookingUrl &&
                caregiver &&
                caregiver.cookiePolicyVersion &&
                clinic?.googleAnalyticsTag &&
                setCookieConsent !== undefined && (
                    <CookieConsentModal
                        caregiver={caregiver}
                        hasChosen={hasChosen}
                        setCookieConsent={setCookieConsent}
                    />
                )}
            <ClinicHeader />
            {error && <ClinicError bookingType={bookingType} error={error} />}
            {loading && <ClinicLoading />}
            {!loading && !error && (
                <>
                    <ClinicMain />
                    <ClinicAside />
                </>
            )}
            {!loading && <ClinicFooter />}
        </div>
    );
}
