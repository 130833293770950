import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '../../../../form/button/Button';
import {fetchConsentRequests, updateConsentRequests} from '../../../../../services/api/api';
import {ConsentRequestAnswer, ConsentRequestObject} from '../../../../../services/api/types/types';
import {SessionActions, useSessionContext} from '../../../../../context/SessionContext';
import {ConsentCompleted} from '../completed/ConsentCompleted';
import {ConsentRequest} from '../consentRequest/ConsentRequest';
import {UnauthorizedError} from '../../../../../services/api/implementations/fetch';
import './HandleConsent.scss';

export function HandleConsent() {
    const {t} = useTranslation();
    const [consentRequests, setConsentRequests] = useState<Array<ConsentRequestObject>>([]);
    const [requestsStatus, setRequestsStatus] = useState<Array<ConsentRequestAnswer>>([]);
    const {sessionState, sessionDispatch} = useSessionContext();
    const [hasSelectedConsents, setHasSelectedConsents] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [consentRequestsCompleted, setConsentRequestsCompleted] = useState(false);

    const getConsents = async (jwt: string) => {
        const consents = await fetchConsentRequests(jwt);
        const filteredConsents = consents.filter((consent) => consent.status === 'NOT_GIVEN');
        setConsentRequests(filteredConsents);
        setIsLoading(false);
    };

    const handleConsentAnswer = (id: string, bool: boolean) => {
        const answers = requestsStatus;

        if (answers) {
            const answer = answers.find((answer) => answer.id === id);
            if (answer) {
                answer.answer = bool ? 'GIVEN' : 'DENIED';
                setRequestsStatus(answers);
            }
            requestsStatus.some((request) => request.answer === 'NOT_GIVEN')
                ? setHasSelectedConsents(false)
                : setHasSelectedConsents(true);
        }
    };

    const handleSubmit = () => {
        Promise.all(
            requestsStatus.map(async (request) => {
                if (sessionState.userJwt) {
                    try {
                        await updateConsentRequests(sessionState.userJwt, request);
                        setConsentRequestsCompleted(true);
                    } catch (error) {
                        if (error instanceof UnauthorizedError) {
                            sessionDispatch({type: SessionActions.SET_SESSION_TIMED_OUT, value: true});
                        }
                    }
                }
            })
        );
    };

    useEffect(() => {
        if (sessionState.userJwt !== null && !sessionState.sessionTimedOut) {
            getConsents(sessionState.userJwt);
        }
    }, [sessionState.userJwt, sessionState.sessionTimedOut]);

    useEffect(() => {
        const statuses = consentRequests?.map((request) => {
            return {
                id: request.id,
                answer: request.status
            };
        });

        if (statuses) {
            setRequestsStatus(statuses);
        }
    }, [consentRequests]);

    if (consentRequestsCompleted) {
        return <ConsentCompleted />;
    }
    if (!sessionState.sessionTimedOut && sessionState.userJwt !== null) {
        return (
            <>
                <div className='consent-content'>
                    {!isLoading && consentRequests.length === 0 && (
                        <>
                            <h1 className='consent-content__heading'>{t('consent.handle.header_no_consents')}</h1>
                            <p className='consent-content__sub-heading'>{t('consent.handle.sub_header_no_consents')}</p>
                        </>
                    )}
                    {!isLoading && consentRequests.length > 0 && (
                        <>
                            <h1 className='consent-content__heading'>{t('consent.handle.header')}</h1>
                            <p className='consent-content__sub-heading'>{t('consent.handle.sub_header')}</p>

                            {consentRequests?.map((request, index) => {
                                return (
                                    <ConsentRequest
                                        handleConsentAnswer={handleConsentAnswer}
                                        key={index}
                                        consentRequest={request}
                                    />
                                );
                            })}
                            <div className='consent-content__button-container'>
                                <Button big disabled={!hasSelectedConsents} onClick={() => handleSubmit()}>
                                    {t('consent.button.send')}
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    }
    return null;
}
