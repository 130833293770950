import {useBookingContext} from '../../../../context/BookingContext';
import {useMatchMedia} from '../../../../hooks/useMatchMedia';
import {BookingInitialAside} from '../../booking/booking-initial/BookingInitialAside';
import {BookingStepIndicator} from '../../booking/booking-step-indicator/BookingStepIndicator';
import {BookingInformation} from '../BookingInformation/BookingInformation';

export function ClinicAside() {
    const {bookingState} = useBookingContext();

    const mediumDisplay = useMatchMedia('(max-width: 51.25rem)');

    const stepComponent = () => {
        switch (bookingState.step) {
            case 'initial':
                return <BookingInitialAside />;
            case 'confirm':
                return <BookingInformation />;
            case 'completed':
                return <BookingInformation />;
            default:
                return <BookingStepIndicator />;
        }
    };

    return (
        <>
            {(!mediumDisplay || bookingState.step === 'initial' || bookingState.step === 'completed') &&
                stepComponent()}
        </>
    );
}
