import {useTranslation} from 'react-i18next';
import {CircleCheckmark} from '../../../../icons/CircleCheckmark';

import './ConsentCompleted.scss';

export function ConsentCompleted() {
    const {t} = useTranslation();

    return (
        <section className='consent-completed'>
            <div className='consent-completed__content'>
                <CircleCheckmark />
                <h1 className='header'>{t('consent.complete.heading')}</h1>
                <p className='subheader'>{t('consent.complete.subheading')}</p>
            </div>
        </section>
    );
}
