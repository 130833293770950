import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PolicyArticle} from './policy-article/PolicyArticle';

export function PrivacyPolicy() {
    useEffect(() => window.scrollTo(0, 0), []);
    const {t} = useTranslation();

    useEffect(() => {
        document.title = t('route_name.privacy');
        return () => {
            document.title = t('route_name.home');
        };
    }, [t]);

    return (
        <>
            <PolicyArticle
                heading={t('policy.privacy_policy_heading')}
                article={t('policy.privacy_policy', {returnObjects: true})}
            />
        </>
    );
}
