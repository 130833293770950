import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {validateEmail, validatePhone} from 'src/services/validation/validation';
import {useSessionContext} from 'src/context/SessionContext';
import {Input} from 'src/components/form/input/Input';
import {Button} from 'src/components/form/button/Button';
import {Checkbox} from 'src/components/form/checkbox/Checkbox';
import {formatPhoneNumber} from 'src/lib/numbers/format';
import {RemoveAccountModal} from './remove-account/RemoveAccountModal';
import {PreferredContact} from 'src/services/api/types/types';
import './Account.scss';

export function Account() {
    const {t} = useTranslation();
    const {sessionState, updateAccount} = useSessionContext();
    const [email, setEmail] = useState(sessionState?.account?.email || '');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>();
    const [isEditing, setIsEditing] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(sessionState?.account?.mobile || '');
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState<string>();
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
    const [preferredContact, setPreferredContact] = useState<PreferredContact | ''>(
        sessionState?.account?.preferredContact || 'SMS_EMAIL'
    );
    const [isValidPreferredContact, setIsValidPreferredContact] = useState(true);
    const headingRef = useRef<HTMLHeadingElement>(null);

    const handleEditMode = () => {
        if (!isEditing) {
            headingRef.current?.focus();
        }
        setIsEditing(!isEditing);
    };

    const handleEmailInput = (email: string) => {
        setEmail(email);
    };

    const handlePhoneNumberInput = (phoneNumber: string) => {
        const phoneNumberWithOnlyNumbers = formatPhoneNumber(phoneNumber);
        setPhoneNumber(phoneNumberWithOnlyNumbers);
    };

    const handlePreferredContactInput = (checked: boolean, input: PreferredContact) => {
        let newPreferredContact: PreferredContact | '' = '';
        if (input === 'SMS') {
            if (checked) {
                newPreferredContact = preferredContact === 'EMAIL' ? 'SMS_EMAIL' : input;
            } else {
                newPreferredContact = preferredContact === 'SMS_EMAIL' ? 'EMAIL' : '';
            }
        } else if (input === 'EMAIL') {
            if (checked) {
                newPreferredContact = preferredContact === 'SMS' ? 'SMS_EMAIL' : input;
            } else {
                newPreferredContact = preferredContact === 'SMS_EMAIL' ? 'SMS' : '';
            }
        }

        if (newPreferredContact) {
            setIsValidPreferredContact(true);
        }

        setPreferredContact(newPreferredContact);
    };

    const isUserDataIsUpdated = () => {
        const account = sessionState?.account;
        if (account) {
            return (
                email !== account.email ||
                phoneNumber !== account.mobile ||
                preferredContact !== account.preferredContact
            );
        }
    };

    const handleSubmit = async () => {
        const isValidEmailInput = validateEmail(email) && email.length > 0;
        setIsValidEmail(isValidEmailInput);
        setEmailErrorMessage(isValidEmailInput ? '' : t('confirm.form.email_error'));

        const isValidPhoneNumberInput = validatePhone(phoneNumber) && phoneNumber.length > 0;
        setIsValidPhoneNumber(isValidPhoneNumberInput);
        setPhoneNumberErrorMessage(isValidPhoneNumberInput ? '' : t('confirm.form.phone_error'));

        if (!isUserDataIsUpdated()) {
            handleEditMode();
        }

        if (!preferredContact) {
            setIsValidPreferredContact(false);
            return;
        }

        if (sessionState?.userJwt && isValidEmailInput && isValidPhoneNumberInput) {
            updateAccount(sessionState?.userJwt, {email, mobile: phoneNumber, preferredContact});
            // TODO: Handle error if updateAccount request fails
            handleEditMode();
        }
    };

    const handleCancel = () => {
        const account = sessionState?.account;
        if (account) {
            handlePhoneNumberInput(account?.mobile);
            handleEmailInput(account?.email);
            setPreferredContact(account?.preferredContact);
            clearErrorMessages();
            handleEditMode();
        }
    };

    const clearErrorMessages = () => {
        setIsValidEmail(true);
        setIsValidPhoneNumber(true);
        setIsValidPreferredContact(true);
        setEmailErrorMessage('');
        setPhoneNumberErrorMessage('');
    };

    const getFormattedPhoneNumber = (phoneNumber: string | undefined) => {
        if (!phoneNumber) {
            return '';
        }
        const phoneNumberWithOnlyNumbers = formatPhoneNumber(phoneNumber);
        return phoneNumberWithOnlyNumbers.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4');
    };

    const getFormattedPnr = (pnr: string | undefined) => {
        if (!pnr) {
            return '';
        }
        if (pnr.length === 12) {
            return pnr.replace(/(\d{8})(\d{4})/, '$1-$2');
        }
        if (pnr.length === 10) {
            return pnr.replace(/(\d{6})(\d{4})/, '$1-$2');
        }
    };

    const getPreferredContactReadOnlyLabelText = () => {
        if (preferredContact === 'EMAIL') {
            return t('account.notification_email');
        } else if (preferredContact === 'SMS') {
            return t('account.notification_sms');
        } else {
            return t('account.notification_both');
        }
    };

    return (
        <>
            <div id='account-main-profile-container' className='account-container'>
                <div>
                    <h1
                        ref={headingRef}
                        className='account-container__heading'
                        aria-label={
                            isEditing
                                ? t('account.change_account_info_long', {
                                      givenName: sessionState.user?.givenName,
                                      familyName: sessionState.user?.familyName
                                  })
                                : `${sessionState.user?.givenName} ${sessionState.user?.familyName}`
                        }
                        tabIndex={-1}
                    >
                        <span>{sessionState.user?.givenName}</span>
                        &nbsp;
                        <span>{sessionState.user?.familyName}</span>
                    </h1>
                    <div
                        className={`account-container__inputs${isEditing ? ' account-container__inputs--editing' : ''}`}
                    >
                        {isEditing ? (
                            <>
                                <Input
                                    isValid
                                    type='number'
                                    value={sessionState?.user?.identity || ''}
                                    disabled
                                    label={t('people_select.people_form.identity_label')}
                                    ariaLabel={t('people_select.people_form.identity_label')}
                                    id='pnrLabel'
                                    onChange={() => {}}
                                />
                                <Input
                                    isValid={isValidEmail}
                                    type='email'
                                    required={false}
                                    value={email}
                                    ariaLabel={t('account.change_email')}
                                    label={t('confirm.form.email_placeholder')}
                                    id='emailInput'
                                    onChange={handleEmailInput}
                                    errorMessage={emailErrorMessage && emailErrorMessage}
                                />
                                <Input
                                    isValid={isValidPhoneNumber}
                                    type='tel'
                                    value={phoneNumber}
                                    required
                                    ariaLabel={t('account.change_mobile_number')}
                                    label={t('confirm.form.telephone')}
                                    id='phoneNumberInput'
                                    onChange={handlePhoneNumberInput}
                                    errorMessage={phoneNumberErrorMessage && phoneNumberErrorMessage}
                                />
                            </>
                        ) : (
                            <>
                                <div>
                                    <p className='account-container__inputs--read-only-label'>
                                        {t('people_select.people_form.identity_label')}
                                    </p>
                                    <p className='account-container__inputs--read-only-text'>
                                        {getFormattedPnr(sessionState.user?.identity)}
                                    </p>
                                </div>
                                <div>
                                    <p className='account-container__inputs--read-only-label'>
                                        {t('confirm.form.email_placeholder')}
                                    </p>
                                    <p className='account-container__inputs--read-only-text'>{email}</p>
                                </div>
                                <div>
                                    <p className='account-container__inputs--read-only-label'>
                                        {t('confirm.form.telephone')}
                                    </p>
                                    <p className='account-container__inputs--read-only-text'>
                                        {getFormattedPhoneNumber(phoneNumber)}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>

                    <div className='account-container__notification-options'>
                        {isEditing ? (
                            <div>
                                <h2 className='mb-1'>{t('account.notification_header')}</h2>
                                <p className='mb-3'>{t('account.notification_subtitle')}</p>
                                <Checkbox
                                    onClick={(event) => handlePreferredContactInput(event, 'SMS')}
                                    id='preferred-contact-sms-input'
                                    label={t('account.notification_sms')}
                                    checked={preferredContact === 'SMS_EMAIL' || preferredContact === 'SMS'}
                                    ariaInvalid={!isValidPreferredContact}
                                />
                                <Checkbox
                                    onClick={(event) => handlePreferredContactInput(event, 'EMAIL')}
                                    id='preferred-contact-email-input'
                                    label={t('account.notification_email')}
                                    checked={preferredContact === 'SMS_EMAIL' || preferredContact === 'EMAIL'}
                                    ariaInvalid={!isValidPreferredContact}
                                />
                                {!isValidPreferredContact && (
                                    <p className='mt-3 t-red' aria-live='assertive'>
                                        {t('account.notification_validation_error')}
                                    </p>
                                )}
                            </div>
                        ) : (
                            <div>
                                <p className='account-container__inputs--read-only-label'>
                                    {t('account.notification_header')}
                                </p>
                                <p className='account-container__inputs--read-only-text'>
                                    {getPreferredContactReadOnlyLabelText()}
                                </p>
                            </div>
                        )}
                    </div>

                    <div className='account-container__buttons'>
                        {!isEditing ? (
                            <Button
                                id='edit-profile-button-id'
                                aria-selected={isEditing}
                                ariaLabel={t('account.change_contact_info_button')}
                                onClick={handleEditMode}
                                variant='outlined'
                            >
                                {t('account.change_contact_info_button')}
                            </Button>
                        ) : (
                            <>
                                <Button
                                    className='account-container__buttons__button'
                                    ariaLabel={t('button_save_changes.text')}
                                    onClick={handleSubmit}
                                    variant='filled'
                                >
                                    {t('button_save_changes.text')}
                                </Button>
                                <Button
                                    className='account-container__buttons__button'
                                    ariaLabel={t('button_cancel.text')}
                                    onClick={handleCancel}
                                    variant='outlined'
                                >
                                    {t('button_cancel.text')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>

                <div className='account-container__bottom-links'>
                    <span className='account-container__divider' />
                    <a
                        aria-label={t('account.q_and_a_link_aria')}
                        target='_blank'
                        href='https://mittvaccin.cambio.se/fragor-svar/'
                        rel='noreferrer'
                    >
                        {t('account.q_and_a_link')}
                    </a>
                    <a aria-label={t('policy.privacy_policy_link_aria')} target='_blank' href='/personuppgiftspolicy'>
                        {t('policy.privacy_policy_short_header')}
                    </a>
                    <Button
                        className='account-container__bottom-links__remove-button'
                        variant='text'
                        ariaLabel={t('account.remove_account')}
                        onClick={() => setShowRemoveAccountModal(true)}
                    >
                        {t('account.remove_account')}
                    </Button>
                </div>

                <RemoveAccountModal open={showRemoveAccountModal} onClose={() => setShowRemoveAccountModal(false)} />
            </div>
        </>
    );
}
