import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {CSSTransition} from 'react-transition-group';
import {Spinner} from './Spinner';

import './style.scss';

interface LoadIndicatorProps {
    show: boolean;
}

export function LoadIndicator({show}: LoadIndicatorProps) {
    const nodeRef = useRef(null);
    const {t} = useTranslation();

    return (
        <>
            <CSSTransition unmountOnExit timeout={200} classNames='loading_indicator' in={show} nodeRef={nodeRef}>
                <div
                    ref={nodeRef}
                    className='loading_indicator'
                    role='status'
                    aria-label={t('aria_search_clinics.text')}
                    aria-live='polite'
                >
                    <Spinner />
                </div>
            </CSSTransition>
        </>
    );
}
