import {useTranslation} from 'react-i18next';
import {ReactComponent as IconVaccine} from 'src/components/icons/icon_vaccine.svg';
import './HistoryInfoItem.scss';

type messageType = 'EMPTY_HISTORY' | 'INCOMPLETE_HISTORY';

export function HistoryInfoItem({messageType = 'EMPTY_HISTORY'}: {messageType: messageType}) {
    const {t} = useTranslation();

    return (
        <div className='history-info-item-container ' data-testid='history-info-item'>
            <div className='history-info-item-container__icon'>
                <IconVaccine />
            </div>
            {messageType === 'INCOMPLETE_HISTORY' && (
                <div data-testid='history-info-item-incomplete-history'>
                    <h1 className='history-info-item-container__header'>
                        {t('history.missing_vaccination_item_header')}
                    </h1>
                    <p>{t('history.missing_vaccination_item_message')}</p>
                </div>
            )}
            {messageType === 'EMPTY_HISTORY' && (
                <div data-testid='history-info-item-empty-history'>
                    <h1 className='history-info-item-container__header'>
                        {t('history.no_registered_vaccinations_header')}
                    </h1>
                    <p>{t('history.missing_vaccination_item_message')}</p>
                </div>
            )}
        </div>
    );
}
