import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {MapDataProvider} from '../context/MapDataContext';
import {useClearTrackingCookies} from '../hooks/useClearTrackingCookies';
import {useCssWorkarounds} from '../hooks/useCssWorkarounds';

export function Root() {
    useCssWorkarounds();
    useClearTrackingCookies();

    useEffect(() => {
        document.body.classList.add('map-view');
        return () => document.body.classList.remove('map-view');
    }, []);

    return (
        <div className='App'>
            <MapDataProvider>
                <Outlet />
            </MapDataProvider>
        </div>
    );
}
