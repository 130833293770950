import {useEffect, useState} from 'react';
import {CheckIcon} from '../../icons/CheckIcon';
import './style.scss';
import {useTranslation} from 'react-i18next';

interface BigToggleProps {
    id: string;
    label: string;
    sublabel?: string;
    onClick(checked: boolean): void;
    tabIndex?: 0 | -1;
    checked?: boolean;
    noCheckbox?: boolean;
    disabled?: boolean;
}

export function BigToggle({
    label,
    sublabel,
    disabled,
    id,
    onClick,
    checked: checkedProp = false,
    tabIndex = 0,
    noCheckbox
}: BigToggleProps) {
    const {t} = useTranslation();
    const [checked, setChecked] = useState(checkedProp);
    useEffect(() => {
        setChecked(checkedProp);
    }, [checkedProp]);

    return (
        <>
            <div className={disabled ? 'toggle_box_container disabled' : 'toggle_box_container'}>
                <input
                    tabIndex={tabIndex}
                    disabled={disabled}
                    id={`toggle-${id}`}
                    aria-label={t('healthdeclaration.aria.person_information', {
                        person: label,
                        identity: sublabel
                    })}
                    onClick={(e) => {
                        const checked = e.currentTarget.checked;
                        onClick(checked);
                    }}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onClick(!checked);
                        }
                    }}
                    checked={checked}
                    type='checkbox'
                />
                <label htmlFor={`toggle-${id}`} aria-label={`toggle-${id}`} className='toggle_box big'>
                    <span className='with_sublabel'>
                        <span aria-hidden className='checkbox__label'>
                            {label}
                        </span>
                        <span aria-hidden className='checkbox__sub-label'>
                            {sublabel}
                        </span>
                    </span>
                    {!noCheckbox && <span className='checkbox'>{checked && <CheckIcon />}</span>}
                </label>
            </div>
        </>
    );
}
