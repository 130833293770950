import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {BookingResponse} from '../../../../services/api/types/types';
import {Button} from '../../../form/button/Button';
import {CircleCheckmark} from '../../../icons/CircleCheckmark';

import './UnbookCompleted.scss';

interface UnbookCompletedProps {
    booking: BookingResponse;
}

export function UnbookCompleted({booking}: UnbookCompletedProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <section className='unbook-completed'>
            <div className='unbook-completed__content'>
                <CircleCheckmark />
                <h1 className='header'>{t('handle_booking.unbooking_complete.heading')}</h1>
                <p className='subheader'>{t('handle_booking.unbooking_complete.subheading')}</p>
                <Button big onClick={() => navigate(`/mottagning/${booking.clinicId}`)}>
                    <span>{t('list_clinics_to_clinic.text')}</span>
                </Button>
            </div>
        </section>
    );
}
