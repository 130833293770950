import {isValidElement, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {CSSTransition} from 'react-transition-group';
import {Button} from 'src/components/form/button/Button';
import {ReactComponent as IconX} from 'src/components/icons/icon_x.svg';
import './ModalPortal.scss';

interface ModalPortalProps {
    children: React.ReactNode;
    headerIcon?: React.ReactNode;
    headerTitle?: string | React.ReactNode;
    onCloseClick?(): void;
    onPrimaryAction?(): void;
    onSecondaryAction?(): void;
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
    size?: 'sm';
    placementDesktop?: 'bottom';
    darkMode?: boolean;
    open?: boolean;
    ariaLabel?: string;
    shouldRestoreFocus?: boolean;
    shouldFadeOut?: boolean;
    shouldFadeIn?: boolean;
}

/**
 *
 * 'size' can be undefined or 'sm' which makes the modal not cover fullscreen on small screens
 * 'placementDesktop' can be undefined, then it will be centered, or 'bottom' then it will stick to bottom of screen
 *
 */
export default function ModalPortal({
    children,
    headerIcon,
    headerTitle,
    onCloseClick,
    onPrimaryAction,
    onSecondaryAction,
    primaryActionLabel,
    secondaryActionLabel,
    size,
    placementDesktop,
    darkMode,
    open,
    ariaLabel,
    shouldRestoreFocus = true,
    shouldFadeOut = true,
    shouldFadeIn = true
}: ModalPortalProps) {
    const {t} = useTranslation();

    const modalFocusRef = useRef<HTMLDivElement>(null);
    const lastFocusRef = useRef<HTMLElement>();

    const firstElementRef = useRef<HTMLElement>();
    const lastElementRef = useRef<HTMLElement>();

    const [showModal, setShowModal] = useState<boolean>();

    useEffect(() => {
        setShowModal(open === undefined ? true : open);
    }, [open]);

    const trapFocus = () => {
        if (!modalFocusRef.current) return;

        const currentlyFocusedElement = document.activeElement as HTMLElement;
        const isDialogFocused =
            currentlyFocusedElement === modalFocusRef.current ||
            modalFocusRef.current.contains(currentlyFocusedElement);

        if (!isDialogFocused) {
            lastFocusRef.current = currentlyFocusedElement;
            modalFocusRef.current.focus();
        }
    };

    const restoreFocus = () => {
        if (lastFocusRef.current) {
            lastFocusRef.current.focus();
            lastFocusRef.current = undefined;
        }
    };

    useEffect(() => {
        if (showModal) {
            document.body.classList.add('modal--open');
            document.body.style.overflow = 'hidden';
            document.querySelector('#root')?.setAttribute('aria-hidden', 'true');

            document.addEventListener('focus', trapFocus, true);
            trapFocus();

            const focusableElements = modalFocusRef.current?.querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            firstElementRef.current = focusableElements?.[0] as HTMLElement;
            lastElementRef.current = focusableElements?.[focusableElements.length - 1] as HTMLElement;
        }

        return () => {
            document.body.classList.remove('modal--open');
            document.body.style.overflow = 'auto';
            document.querySelector('#root')?.setAttribute('aria-hidden', 'false');

            document.removeEventListener('focus', trapFocus, true);

            if (shouldRestoreFocus) {
                restoreFocus();
            }
        };
    }, [showModal, shouldRestoreFocus]);

    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) => {
            if (showModal) {
                if (e.key === 'Escape') {
                    onCloseClick && onCloseClick();
                } else if (e.key === 'Tab') {
                    if (e.shiftKey && document.activeElement === firstElementRef.current) {
                        e.preventDefault();
                        lastElementRef.current?.focus();
                    } else if (!e.shiftKey && document.activeElement === lastElementRef.current) {
                        e.preventDefault();
                        firstElementRef.current?.focus();
                    }
                }
            }
        };

        document.body.addEventListener('keydown', keyDownHandler);

        return () => document.body.removeEventListener('keydown', keyDownHandler);
    }, [onCloseClick, showModal]);

    const getBackgroundFadeClass = () => {
        if (shouldFadeOut && !showModal) {
            return 'hide-fade';
        } else if (shouldFadeIn && showModal) {
            return 'show-fade';
        } else {
            return '';
        }
    };

    const getModalClass = () => {
        return placementDesktop === 'bottom' ? 'modal-portal modal-portal__placement-bottom' : 'modal-portal';
    };

    const hasHeader = !!headerIcon || !!onCloseClick || !!headerTitle;

    return createPortal(
        <CSSTransition
            in={showModal}
            nodeRef={modalFocusRef}
            timeout={300}
            classNames={{
                enter: `modal-portal${size === 'sm' ? '--small' : ''}--enter-animation`,
                exit: `modal-portal${size === 'sm' ? '--small' : ''}--exit-animation`
            }}
            unmountOnExit
        >
            <>
                <div
                    onClick={onCloseClick}
                    aria-hidden='true'
                    className={`modal-portal-background ${getBackgroundFadeClass()}`}
                ></div>
                <div
                    className={`${getModalClass()} ${size === 'sm' ? 'modal-portal--small' : ''} ${
                        darkMode ? 'modal-portal--dark' : ''
                    }
                    `}
                    role='dialog'
                    aria-label={ariaLabel ? ariaLabel : ''}
                    ref={modalFocusRef}
                    tabIndex={-1}
                    data-testid='modal-portal'
                >
                    {(headerIcon || onCloseClick || headerTitle) && (
                        <div aria-hidden='true' id='modal-portal-header' className='modal-portal__header'>
                            {headerIcon && headerIcon}
                            {headerTitle && isValidElement(headerTitle) ? (
                                headerTitle
                            ) : (
                                <h2 className='modal-portal__header__title'>{headerTitle}</h2>
                            )}
                            {!!onCloseClick && (
                                <div
                                    id='modal-portal-close-button'
                                    className='modal-portal__header-close'
                                    data-testid='modal-portal-close-button'
                                >
                                    <Button
                                        className='modal-portal__header__close-button'
                                        thin
                                        variant={darkMode ? 'outlined' : 'lighter'}
                                        color={darkMode ? 'tertiary' : 'primary'}
                                        onDark={darkMode}
                                        ariaLabel={t('button_close.text')}
                                        onClick={onCloseClick}
                                    >
                                        <IconX
                                            aria-hidden='true'
                                            className='modal-portal__header__close-button__icon'
                                        />
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}

                    <div
                        className={`modal-portal__content-scroll ${
                            hasHeader ? 'modal-portal__content-scroll--has-header' : ''
                        }`}
                    >
                        <div id='modal-portal-content' className='modal-portal__content'>
                            {children}
                        </div>
                    </div>

                    {(onSecondaryAction || onPrimaryAction) && (
                        <div id='modal-portal-action-buttons' className='modal-portal__action-buttons'>
                            {onSecondaryAction && (
                                <Button
                                    className='modal-portal__action-buttons__button'
                                    variant={darkMode ? 'outlined' : 'lighter'}
                                    color={darkMode ? 'tertiary' : 'primary'}
                                    onDark={darkMode}
                                    ariaLabel={secondaryActionLabel || t('button_close.text')}
                                    onClick={onSecondaryAction}
                                >
                                    {secondaryActionLabel || t('button_close.text')}
                                </Button>
                            )}
                            {onPrimaryAction && (
                                <Button
                                    className='modal-portal__action-buttons__button'
                                    variant='filled'
                                    ariaLabel={primaryActionLabel || t('button_try_again.text')}
                                    onClick={onPrimaryAction}
                                >
                                    {primaryActionLabel || t('button_try_again.text')}
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            </>
        </CSSTransition>,
        document.body
    );
}
