import {useTranslation} from 'react-i18next';
import {useState, useEffect, useRef, useCallback} from 'react';
import {startOfDay} from 'date-fns';
import {useUiContext} from 'src/context/UiContext';
import {Select} from 'src/components/form/select/Select';
import {DateSelect} from 'src/components/form/date-select/DateSelect';
import {VaccineCategoryResponse, VaccineCategoryReponseVaccine} from 'src/services/api/types/types';
import {Spinner} from 'src/components/layout/loader/Spinner';
import {Button} from 'src/components/form/button/Button';
import {useHistoryDataContext} from 'src/context/HistoryDataContext';
import {ReactComponent as IconInfo} from 'src/components/icons/icon_info.svg';
import {ReactComponent as IconClose} from 'src/components/icons/icon_close.svg';

import './ManualVaccinationForm.scss';

export interface VaccinationFormData {
    vaccineCategory: string;
    vaccine: string;
    dose: string;
    vaccinationDate: Date | null;
    notificationDate: Date | null;
}

export type VaccinationFormValidation = {
    [Property in keyof VaccinationFormData]: boolean;
};

export const DEFAULT_FORM_DATA: VaccinationFormData = {
    vaccineCategory: '',
    vaccine: '',
    dose: '',
    vaccinationDate: null,
    notificationDate: null
};

export const DEFAULT_FORM_VALIDATION: VaccinationFormValidation = {
    vaccineCategory: true,
    vaccine: true,
    dose: true,
    vaccinationDate: true,
    notificationDate: true
};

interface ManualVaccinationFormProps {
    prefilledForm?: VaccinationFormData;
    onSubmitForm(form: VaccinationFormData): void;
}

export function ManualVaccinationForm({prefilledForm, onSubmitForm}: ManualVaccinationFormProps) {
    const {t} = useTranslation();

    const {vaccineCategories} = useHistoryDataContext().dataState;
    const {breakPointInterval} = useUiContext().state;

    const [form, setForm] = useState<VaccinationFormData>(DEFAULT_FORM_DATA);
    const [formValidation, setFormValidation] = useState<VaccinationFormValidation>(DEFAULT_FORM_VALIDATION);
    const hasTriedToSubmit = useRef<boolean>(false);

    const [vaccineOptions, setVaccineOptions] = useState<VaccineCategoryReponseVaccine[]>([]);
    const [selectedVaccineInformationURL, setSelectedVaccineInformationURL] = useState<string>();
    const [doseOptions, setDoseOptions] = useState<string[]>([]);

    const handleUpdateForm = (newForm: VaccinationFormData) => {
        setForm(newForm);

        if (hasTriedToSubmit.current) {
            validate(newForm);
        }
    };

    const handleSubmit = () => {
        if (validate(form)) {
            onSubmitForm(form);
        } else {
            hasTriedToSubmit.current = true;
        }
    };

    const validate = (form: VaccinationFormData) => {
        const newValidation: VaccinationFormValidation = {
            vaccineCategory: !!form.vaccineCategory,
            vaccine: !!form.vaccine,
            dose: !!form.dose,
            vaccinationDate: !!form.vaccinationDate,
            notificationDate: true
        };
        setFormValidation(newValidation);

        let idOfElementToScrollTo = '';
        if (!newValidation.vaccineCategory) {
            idOfElementToScrollTo = 'vaccine-category';
        } else if (!newValidation.vaccine) {
            idOfElementToScrollTo = 'vaccine';
        } else if (!newValidation.dose) {
            idOfElementToScrollTo = 'dose';
        } else if (!newValidation.vaccinationDate) {
            idOfElementToScrollTo = 'vaccination-date';
        }

        document.getElementById(idOfElementToScrollTo)?.scrollIntoView({behavior: 'smooth'});

        return Object.values(newValidation).every((value) => value);
    };

    const getNewVaccineOptions = (vaccineCategory?: VaccineCategoryResponse) => {
        return vaccineCategory?.vaccines || [];
    };

    const getNewDoseOptions = useCallback(
        (vaccine?: VaccineCategoryReponseVaccine): string[] => {
            let doseOptions = [];
            if (vaccine) {
                doseOptions.push(t('vaccination_form.dose_option_unknown'));
                for (let i = 1; i <= vaccine.maxDoses; i++) {
                    doseOptions.push(i.toString());
                }
            }
            return doseOptions;
        },
        [t]
    );

    const handleUpdateVaccineCategory = (event: VaccinationFormData['vaccineCategory']) => {
        const newVaccineCategory = vaccineCategories.find((category) => category.name === event);
        const vaccineOptions = getNewVaccineOptions(newVaccineCategory);
        setVaccineOptions(vaccineOptions);
        setSelectedVaccineInformationURL('');

        handleUpdateForm({...form, vaccineCategory: event, vaccine: '', dose: ''});
    };

    const handleUpdateVaccine = (event: VaccinationFormData['vaccine']) => {
        const newVaccine = vaccineOptions.find((vaccine) => vaccine.name === event);
        const doseOptions = getNewDoseOptions(newVaccine);
        setDoseOptions(doseOptions);
        setSelectedVaccineInformationURL(newVaccine?.informationUrl);

        handleUpdateForm({...form, vaccine: event, dose: ''});
    };

    const handleUpdateDose = (event: VaccinationFormData['dose']) => {
        handleUpdateForm({...form, dose: event});
    };

    const handleUpdateVaccinationDate = (event: VaccinationFormData['vaccinationDate']) => {
        handleUpdateForm({...form, vaccinationDate: event});
    };

    const handleUpdateNotificationDate = (event: VaccinationFormData['notificationDate']) => {
        handleUpdateForm({...form, notificationDate: event});
    };

    useEffect(() => {
        if (prefilledForm) {
            const newVaccineCategory = vaccineCategories.find(
                (category) => category.name === prefilledForm.vaccineCategory
            );
            const newVaccineOptions = getNewVaccineOptions(newVaccineCategory);
            setVaccineOptions(newVaccineOptions);

            const newVaccine = newVaccineOptions?.find((vaccine) => vaccine.name === prefilledForm.vaccine);
            setSelectedVaccineInformationURL(newVaccine?.informationUrl);

            const newDoseOptions = getNewDoseOptions(newVaccine);
            setDoseOptions(newDoseOptions);

            const newDose = newDoseOptions.find((dose) => dose === prefilledForm.dose);

            const newForm = {...prefilledForm};
            newForm.vaccineCategory = newVaccineCategory?.name ?? '';
            newForm.vaccine = newVaccine?.name ?? '';
            newForm.dose = newDose ?? '';

            setForm(newForm);
        }
    }, [prefilledForm, vaccineCategories, getNewDoseOptions]);

    return (
        <div aria-live='polite'>
            {!vaccineCategories.length && (
                <div className='manual-vaccination-form-spinner'>
                    <Spinner />
                </div>
            )}

            <div
                className={`manual-vaccination-form${
                    !vaccineCategories.length ? ' manual-vaccination-form--hidden' : ''
                }`}
            >
                <Select
                    id='vaccine-category'
                    ariaLabel={t('vaccination_form.disease_protection')}
                    placeholder={t('vaccination_form.disease_protection')}
                    value={form.vaccineCategory}
                    options={vaccineCategories.map((category) => category.name)}
                    required
                    isValid={formValidation.vaccineCategory}
                    errorMessage={t('vaccination_form.disease_protection_validation_error')}
                    onChange={handleUpdateVaccineCategory}
                />
                <Select
                    id='vaccine'
                    ariaLabel={t('vaccination_form.vaccine')}
                    placeholder={t('vaccination_form.vaccine')}
                    value={form.vaccine}
                    options={vaccineOptions.map((vaccine) => vaccine.name)}
                    required
                    isValid={formValidation.vaccine}
                    errorMessage={t('vaccination_form.vaccine_validation_error')}
                    onChange={handleUpdateVaccine}
                />
                {selectedVaccineInformationURL && (
                    <div className='pl-4'>
                        <a
                            className='manual-vaccination-form__vaccine-info-link'
                            href={selectedVaccineInformationURL}
                            target='blank'
                            aria-label={t('vaccine.infoLinkAriaLabel')}
                        >
                            <IconInfo className='manual-vaccination-form__vaccine-info-link__icon' aria-hidden />
                            <span>
                                {t('vaccination_form.vaccine_read_more_link_text', {vaccineName: form.vaccine})}
                            </span>
                        </a>
                    </div>
                )}
                <Select
                    id='dose'
                    ariaLabel={t('vaccination_form.dose')}
                    placeholder={t('vaccination_form.dose')}
                    value={form.dose}
                    options={doseOptions}
                    required
                    isValid={formValidation.dose}
                    errorMessage={t('vaccination_form.dose_validation_error')}
                    onChange={handleUpdateDose}
                />
                <div id='vaccination-date'>
                    <DateSelect
                        selectedDate={form.vaccinationDate}
                        useNative={breakPointInterval === 'compact'}
                        max={startOfDay(Date.now())}
                        placeholder={t('vaccination_form.date_placeholder')}
                        label={t('vaccination_form.vaccination_date_label')}
                        isValid={formValidation.vaccinationDate}
                        errorMessage={t('vaccination_form.vaccination_date_validation_error')}
                        onChange={handleUpdateVaccinationDate}
                    />
                </div>
                <div>
                    <div className='manual-vaccination-form__notification-header'>
                        <h2 className='mb-0'>{t('vaccination_form.notification_date_header')}</h2>
                        &nbsp;
                        <span className='t-gray600'>{t('vaccination_form.notification_date_header_optional')}</span>
                    </div>
                    <p className='manual-vaccination-form__notification-subtitle'>
                        {t('vaccination_form.notification_date_subtitle')}{' '}
                    </p>
                    <div className='manual-vaccination-form__notification-date'>
                        <div className='manual-vaccination-form__notification-date__select'>
                            <DateSelect
                                selectedDate={form.notificationDate}
                                useNative={breakPointInterval === 'compact'}
                                min={startOfDay(Date.now() + 3600 * 1000 * 24)}
                                placeholder={t('vaccination_form.date_placeholder')}
                                label={t('vaccination_form.notification_date_label')}
                                onChange={handleUpdateNotificationDate}
                            />
                        </div>
                        {form.notificationDate && (
                            <Button
                                className='manual-vaccination-form__notification-date__reset-button'
                                variant='text'
                                onClick={() => handleUpdateNotificationDate(null)}
                                ariaLabel={t('vaccination_form.notification_date_remove_button_aria_label')}
                            >
                                <IconClose width='18px' height='18px' />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <Button
                className='w-100'
                variant='filled'
                ariaLabel={t('vaccination_form.save_button_text')}
                onClick={handleSubmit}
            >
                {t('vaccination_form.save_button_text')}
            </Button>
        </div>
    );
}
