type IconProps = {
    width?: string;
    height?: string;
};
export function PlusIcon(props: IconProps) {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width={props?.width || '24'}
                height={props?.height || '24'}
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M11.25 4C10.9739 4 10.75 4.22386 10.75 4.5V10.75H4.5C4.22386 10.75 4 10.9739 4 11.25V12.75C4 13.0261 4.22386 13.25 4.5 13.25H10.75V19.5C10.75 19.7761 10.9739 20 11.25 20H12.75C13.0261 20 13.25 19.7761 13.25 19.5V13.25H19.5C19.7761 13.25 20 13.0261 20 12.75V11.25C20 10.9739 19.7761 10.75 19.5 10.75H13.25V4.5C13.25 4.22386 13.0261 4 12.75 4H11.25Z'
                    fill='currentColor'
                />
            </svg>
        </>
    );
}
