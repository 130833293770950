import {useTranslation} from 'react-i18next';

export function PolicyLinks() {
    const {t} = useTranslation();
    return (
        <>
            <a href='/personuppgiftspolicy' target='_blank' aria-label={t('policy.privacy_policy_link_aria')}>
                {t('policy.privacy_policy_link')}
            </a>
            <a href='/cookie-policy' target='_blank' aria-label={t('policy.cookie_policy_link_aria')}>
                {t('policy.cookie_policy_link')}
            </a>
        </>
    );
}
