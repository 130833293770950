import {createContext, useContext, useEffect, useReducer} from 'react';
import {useParams} from 'react-router-dom';
import {fetchAppointmentCategories, fetchCaregivers, fetchClinicById, fetchVisitReasons} from '../services/api/api';
import {NotFoundError} from '../services/api/implementations/fetch';
import {
    AppointmentCategory,
    AppointmentType,
    CaregiverObject,
    ClinicJSON,
    ReasonFilterObject
} from '../services/api/types/types';

export enum DataActions {
    SET_LOADING = 'SET_LOADING',
    SET_ERROR = 'SET_ERROR',
    SET_CLINIC = 'SET_CLINIC',
    SET_CAREGIVERS = 'SET_CAREGIVERS',
    SET_VISIT_REASONS = 'SET_VISIT_REASONS',
    SET_APPOINTMENT_CATEGORIES = 'SET_APPOINTMENT_CATEGORIES'
}

type Action = {
    type: DataActions;
    value:
        | boolean
        | null
        | Error
        | ClinicJSON
        | Array<CaregiverObject>
        | Array<ReasonFilterObject>
        | Array<AppointmentCategory>;
};
type Dispatch = (action: Action) => void;
type State = {
    loading: boolean;
    careGivers: Array<CaregiverObject>;
    visitReasons: Array<ReasonFilterObject>;
    appointmentCategories: Array<AppointmentCategory>;
    appointmentTypeId: number | null;
    appointmentType: AppointmentType | null;
    clinic: ClinicJSON | null;
    error: Error | null;
};
type CountProviderProps = {children: React.ReactNode};

const ClinicDetailsContext = createContext<{dataState: State; dispatch: Dispatch} | undefined>(undefined);
function ClinicDetailsReducer(state: State, action: Action): State {
    switch (action.type) {
        case DataActions.SET_LOADING: {
            return {...state, loading: action.value as boolean};
        }
        case DataActions.SET_ERROR: {
            return {...state, error: action.value as Error};
        }
        case DataActions.SET_CAREGIVERS: {
            return {...state, careGivers: action.value as Array<CaregiverObject>};
        }
        case DataActions.SET_VISIT_REASONS: {
            return {...state, visitReasons: action.value as Array<ReasonFilterObject>};
        }
        case DataActions.SET_CLINIC: {
            return {...state, clinic: action.value as ClinicJSON};
        }
        case DataActions.SET_APPOINTMENT_CATEGORIES: {
            return {...state, appointmentCategories: action.value as Array<AppointmentCategory>};
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function ClinicDetailsProvider({children}: CountProviderProps) {
    const [dataState, dispatch] = useReducer(ClinicDetailsReducer, {
        careGivers: [],
        visitReasons: [],
        appointmentCategories: [],
        appointmentTypeId: null,
        appointmentType: null,
        clinic: null,
        loading: true,
        error: null
    });
    const {clinicId} = useParams<{clinicId: string}>();

    useEffect(() => {
        if (!clinicId) {
            return;
        }

        const clinicIdNum = parseInt(clinicId || '', 10);

        if (Number.isNaN(clinicIdNum)) {
            dispatch({type: DataActions.SET_LOADING, value: false});
            dispatch({type: DataActions.SET_ERROR, value: new NotFoundError('No such clinic')});
            return;
        }

        if (clinicId !== `${clinicIdNum}`) {
            window.history.replaceState(clinicIdNum, '', `${clinicIdNum}`);
        }

        const loadData = async () => {
            try {
                const [caregivers, visitReasons, clinic, appointmentCategories] = await Promise.all([
                    fetchCaregivers(),
                    fetchVisitReasons(),
                    fetchClinicById(parseInt(clinicId, 10)),
                    fetchAppointmentCategories(parseInt(clinicId, 10))
                ]);

                dispatch({type: DataActions.SET_CLINIC, value: clinic as ClinicJSON});
                dispatch({type: DataActions.SET_APPOINTMENT_CATEGORIES, value: appointmentCategories});
                dispatch({type: DataActions.SET_CAREGIVERS, value: caregivers});
                dispatch({type: DataActions.SET_VISIT_REASONS, value: visitReasons});
                dispatch({type: DataActions.SET_LOADING, value: false});
            } catch (error) {
                dispatch({type: DataActions.SET_LOADING, value: false});
                dispatch({type: DataActions.SET_ERROR, value: error as Error});
            }
        };

        loadData();
    }, [clinicId]);

    const value = {dataState, dispatch};
    return <ClinicDetailsContext.Provider value={value}>{children}</ClinicDetailsContext.Provider>;
}

function useClinicDetailsContext() {
    const context = useContext(ClinicDetailsContext);
    if (context === undefined) {
        throw new Error('useDataContext must be used within a UiProvider');
    }

    return context;
}

export {ClinicDetailsProvider, useClinicDetailsContext};
