import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {addWeeks, isBefore, isSameDay, isToday, startOfDay, addMonths, endOfDay, subDays} from 'date-fns';
import {getWeek, startOfWeek, weekInterval} from 'src/lib/dates/week';
import {format} from 'src/lib/dates/format';
import {fetchNextAvailableTimeSlotForType, fetchTimeSlotsForType} from 'src/services/api/api';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {CheckIcon} from 'src/components/icons/CheckIcon';
import {ChevronLeftIcon} from 'src/components/icons/ChevronLeftIcon';
import {TimeSlotSkeleton} from '../../../layout/skeleton/slot-skeleton/TimeSlotSkeleton';
import {DateInput} from '../../booking/booking-time/DateInput';
import './TimeSlotPicker.scss';

interface TimeSlotPickerProps {
    initialSelectedSlot: Date | null;
    clinicId: number;
    appointmentTypeId: number;
    onTimeSlotClicked(timeSlot: Date, selected: boolean): void;
    clearReserveError(): void;
    clearLoadError(): void;
    lockLoading: boolean;
    reserveError: Error | null;
}

export function TimeSlotPicker({
    initialSelectedSlot,
    clinicId,
    appointmentTypeId,
    clearReserveError,
    clearLoadError,
    onTimeSlotClicked,
    lockLoading,
    reserveError
}: TimeSlotPickerProps) {
    const {t} = useTranslation();

    const today = startOfDay(new Date());
    const [previousSlot] = useState(initialSelectedSlot);
    const [selectedDate, setSelectedDate] = useState(initialSelectedSlot || today);
    const currentWeekNum = getWeek(selectedDate);
    const previousWeekDisabled = startOfWeek(today) > startOfWeek(addWeeks(selectedDate, -1));

    const [selectedWeekInterval, setSelectedWeekInterval] = useState(weekInterval(selectedDate));
    const [weekSlots, setWeekSlots] = useState<Array<Array<Date>>>([]);
    const [nextAvailableSlot, setNextAvailableSlot] = useState<Date | null>(null);
    const [loadingSlots, setLoadingSlots] = useState(true);
    const [slotsLoadError, setSlotsLoadError] = useState<Error | null>(null);

    const [scrolled, setScrolled] = useState(false);

    const onDateChange = (newDate: Date) => {
        const newWeek = weekInterval(newDate);
        setSelectedDate(newDate);

        // Only update week if week actually changes
        if (+newWeek[0] !== +selectedWeekInterval[0]) {
            setSelectedWeekInterval(newWeek);
        }
    };

    const dayNumberClasses = (day: Date, rootClass: string) => {
        const classes = [];
        if (isBefore(day, today)) {
            classes.push(`${rootClass}--disabled`);
        }
        if (isSameDay(day, today)) {
            classes.push(`${rootClass}--selected`);
        }

        return classes.join(' ');
    };

    const loadSlots = useCallback(
        async (abortSignal: AbortController) => {
            const startOfWeek = [...selectedWeekInterval].shift();
            const endOfWeek = [...selectedWeekInterval].pop();

            if (!startOfWeek || !endOfWeek) {
                return;
            }

            setLoadingSlots(true);
            setWeekSlots([]);
            setNextAvailableSlot(null);
            setSlotsLoadError(null);

            try {
                const slots = await fetchTimeSlotsForType(
                    clinicId,
                    appointmentTypeId,
                    startOfWeek,
                    endOfWeek,
                    abortSignal.signal
                );

                if (slots.flat().length === 0) {
                    try {
                        const nextAvailableSlot = await fetchNextAvailableTimeSlotForType(
                            clinicId,
                            appointmentTypeId,
                            startOfWeek,
                            addMonths(endOfWeek, 3)
                        );
                        setNextAvailableSlot(nextAvailableSlot || null);
                    } catch (error) {
                        setLoadingSlots(false);
                        setSlotsLoadError(error as Error);
                    }
                }

                if (previousSlot) {
                    if (
                        previousSlot.getTime() > startOfWeek.getTime() &&
                        previousSlot.getTime() < endOfDay(endOfWeek).getTime()
                    ) {
                        const yesterDayIndex = subDays(previousSlot, 1).getDay();
                        if (!slots[yesterDayIndex].some((d) => previousSlot.getTime() === d.getTime())) {
                            slots[yesterDayIndex].push(previousSlot);
                            slots[yesterDayIndex].sort((a, b) => a.getTime() - b.getTime());
                        }
                    }
                }

                setWeekSlots(slots);
                setLoadingSlots(false);
            } catch (error) {
                const e = error as Error;
                if (e.name === 'AbortError') {
                    setLoadingSlots(true);
                    return;
                }

                console.error(e);
                setLoadingSlots(false);
                setSlotsLoadError(error as Error);
            }
        },
        [appointmentTypeId, clinicId, previousSlot, selectedWeekInterval]
    );

    useEffect(() => {
        const abortSignal = new AbortController();

        loadSlots(abortSignal);

        return () => abortSignal.abort();
    }, [loadSlots]);

    useEffect(() => {
        const scrollListener = (_e: Event) => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        document.addEventListener('scroll', scrollListener);

        return () => document.removeEventListener('scroll', scrollListener);
    }, []);

    return (
        <>
            {!lockLoading && reserveError !== null && (
                <ModalPortal
                    primaryActionLabel={t('error_ok_button.text')}
                    onPrimaryAction={() => {
                        setSlotsLoadError(null);
                        clearReserveError();
                        loadSlots(new AbortController());
                    }}
                >
                    <p>
                        <b>{t('timeSelect.error_time_unavailable_header')}</b>
                        <br />
                        {t('timeSelect.error_time_unavailable_text')}
                    </p>
                </ModalPortal>
            )}

            {slotsLoadError !== null && (
                <ModalPortal
                    onPrimaryAction={() => {
                        setSlotsLoadError(null);
                        clearLoadError();

                        loadSlots(new AbortController());
                    }}
                    onSecondaryAction={() => {
                        setSlotsLoadError(null);
                        clearLoadError();
                    }}
                >
                    <p>
                        <b>{t('error_generic_title.text')}</b>
                        <br />
                        {t('timeSelect.error_slots_load_error')}
                    </p>
                </ModalPortal>
            )}

            <span className='screen-reader-only' aria-atomic aria-live='polite'>
                {initialSelectedSlot && (
                    <span>
                        {t('timeSelect.aria_selected_time_slot', {timeSlot: format(initialSelectedSlot, 'Pp')})}
                    </span>
                )}
            </span>

            <section className={`time-slot-picker${scrolled ? ' time-slot-picker--scrolled' : ''}`}>
                <h1 className='main-content__header'>{t('timeSelect.select_time_slot')}</h1>
                <div className='time-slot-picker__date-row'>
                    <div className='date-picker-container'>
                        <div className='date-picker__input'>
                            <DateInput
                                min={today}
                                selectedDate={selectedDate}
                                onChange={(newDate) => {
                                    if (newDate) {
                                        const date = startOfDay(newDate);
                                        onDateChange(isBefore(date, today) ? today : date);
                                    } else {
                                        onDateChange(today);
                                    }
                                }}
                            />
                        </div>

                        <div className='day-week-container'>
                            <button className='day-week-container__today-button' onClick={() => onDateChange(today)}>
                                {t('timeSelect.today')}
                            </button>

                            <div className='week-container'>
                                <button
                                    disabled={previousWeekDisabled}
                                    aria-label={t('timeSelect.aria_week_button', {
                                        weekNum: getWeek(addWeeks(selectedDate, -1))
                                    })}
                                    className={`week-container__icon week-container__icon--left${
                                        previousWeekDisabled === false ? ' week-container__icon--available' : ''
                                    }`}
                                    onClick={() => {
                                        onDateChange(addWeeks(selectedDate, -1));
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </button>
                                <span>
                                    {t('timeSelect.week')} {currentWeekNum}
                                </span>
                                <button
                                    className={`week-container__icon week-container__icon--right week-container__icon--available`}
                                    aria-label={t('timeSelect.aria_week_button', {
                                        weekNum: getWeek(addWeeks(selectedDate, 1))
                                    })}
                                    onClick={() => {
                                        onDateChange(addWeeks(selectedDate, 1));
                                    }}
                                >
                                    <ChevronLeftIcon />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='dates-container'>
                        {selectedWeekInterval.map((day) => {
                            return (
                                <div
                                    key={day.toString()}
                                    className={`day-number ${dayNumberClasses(day, 'day-number')}`}
                                >
                                    <span className='day-number__day'>
                                        {isToday(day) ? t('timeSelect.today') : format(day, 'eee')}
                                    </span>
                                    <span className='day-number__date'>
                                        <span className={`${isBefore(day, today) ? 'day-number__date--disabled' : ''}`}>
                                            {format(day, 'd')}
                                        </span>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {loadingSlots && (
                    <div className='time-slot-picker__time-row'>
                        <TimeSlotSkeleton />
                    </div>
                )}
                {!loadingSlots && weekSlots.flat().length === 0 && (
                    <>
                        {nextAvailableSlot === null && (
                            <div className='time-slot-picker__error'>
                                <p className='time-slot-picker__error__header'>
                                    {t('timeSelect.no_future_time_slots_available_header')}
                                </p>
                                <p>{t('timeSelect.no_future_time_slots_available_text')}</p>
                            </div>
                        )}
                        {nextAvailableSlot !== null && (
                            <div className='time-slot-picker__error'>
                                <p className='time-slot-picker__error__header'>
                                    {t('timeSelect.no_time_slots_found_this_week_header')}
                                </p>
                                <p>
                                    {t('timeSelect.next_available_time_date', {
                                        nextDateFormatted: format(nextAvailableSlot, 'cccc d LLL yyyy').replace('.', '')
                                    })}
                                </p>
                                <p className='time-slot-picker__error__button'>
                                    <button
                                        onClick={() => onDateChange(nextAvailableSlot)}
                                        className='btn btn--primary'
                                    >
                                        {t('timeSelect.navigate_next_available_slot')}
                                    </button>
                                </p>
                            </div>
                        )}
                    </>
                )}

                {!loadingSlots && weekSlots.flat().length > 0 && (
                    <div className='time-slot-picker__time-row'>
                        {loadingSlots && (
                            <>
                                <TimeSlotSkeleton />
                            </>
                        )}
                        {weekSlots.map((times, index0) => {
                            const day = selectedWeekInterval[index0];
                            const dayPassed = isBefore(day, today);
                            return (
                                <div key={`p-${index0}`} className='time-slot-column-wrapper'>
                                    <p
                                        className={`time-slot-column__day ${dayNumberClasses(
                                            day,
                                            'time-slot-column__day'
                                        )}`}
                                    >
                                        <span className='time-slot-column__day__date'>{format(day, 'dd MMM')}</span>
                                        <span className='time-slot-column__day__day'>
                                            {isToday(day) ? t('timeSelect.today') : format(day, 'eee')}
                                        </span>
                                    </p>
                                    {times.length === 0 && dayPassed === false && (
                                        <p className='time-slot-column__no-free'>
                                            {t('timeSelect.no_time_slots_for_day')}
                                        </p>
                                    )}
                                    <ul className='time-slot-column' aria-label={format(day, 'P')}>
                                        {dayPassed === false && (
                                            <>
                                                {times.map((time, index) => {
                                                    const selected = +time === +(initialSelectedSlot || 0);
                                                    const previousSlotHighlighted = +time === +(previousSlot || 0);
                                                    const ariaSuffix = selected
                                                        ? t('timeSelect.aria_time_slot_date_selected_suffix')
                                                        : previousSlotHighlighted
                                                        ? t('timeSelect.aria_time_slot_date_previous_selected_suffix')
                                                        : '';
                                                    const ariaLabel = format(time, 'Pp') + ariaSuffix;
                                                    return (
                                                        <li className='time-slot-column__list-item' key={`li-${index}`}>
                                                            <button
                                                                aria-label={ariaLabel}
                                                                disabled={lockLoading}
                                                                onClick={() => onTimeSlotClicked(time, selected)}
                                                                className={`time-slot${
                                                                    selected ? ' time-slot--selected' : ''
                                                                }${
                                                                    previousSlotHighlighted
                                                                        ? ' time-slot--previous-slot'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span>
                                                                    {format(time, 'HH:mm')}
                                                                    {selected && <CheckIcon />}
                                                                </span>
                                                            </button>
                                                        </li>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </ul>
                                </div>
                            );
                        })}
                    </div>
                )}
            </section>
        </>
    );
}
