export function FilterSkeleton() {
    return (
        <div className='filter_skeleton'>
            <svg
                role='img'
                width='295'
                height='676'
                aria-labelledby='loading-aria'
                viewBox='0 0 295 676'
                preserveAspectRatio='none'
            >
                <title id='loading-aria'>Loading...</title>
                <rect
                    x='0'
                    y='0'
                    width='100%'
                    height='100%'
                    clipPath='url(#clip-path)'
                    style={{fill: 'url("#fill")'}}
                ></rect>
                <defs>
                    <clipPath id='clip-path'>
                        <rect x='0' y='0' rx='24' ry='24' width='295' height='48' />
                        <rect x='0' y='74' rx='6' ry='6' width='260' height='20' />
                        <rect x='0' y='122' rx='6' ry='6' width='114' height='20' />
                        <rect x='0' y='164' rx='6' ry='6' width='114' height='20' />
                        <rect x='0' y='206' rx='6' ry='6' width='114' height='20' />
                        <rect x='0' y='248' rx='6' ry='6' width='114' height='20' />
                        <rect x='0' y='290' rx='6' ry='6' width='114' height='20' />
                        <rect x='0' y='332' rx='6' ry='6' width='95' height='20' />
                        <rect x='0' y='406' rx='6' ry='6' width='121' height='20' />
                        <rect x='0' y='451' rx='6' ry='6' width='130' height='20' />
                        <rect x='0' y='493' rx='6' ry='6' width='130' height='20' />
                        <rect x='0' y='569' rx='6' ry='6' width='121' height='20' />
                        <rect x='0' y='614' rx='6' ry='6' width='130' height='20' />
                        <rect x='0' y='656' rx='6' ry='6' width='130' height='20' />
                    </clipPath>
                    <linearGradient id='fill'>
                        <stop offset='0.599964' stopColor='#f3f3f3' stopOpacity='1'>
                            <animate
                                attributeName='offset'
                                values='-2; -2; 1'
                                keyTimes='0; 0.25; 1'
                                dur='2s'
                                repeatCount='indefinite'
                            ></animate>
                        </stop>
                        <stop offset='1.59996' stopColor='#ecebeb' stopOpacity='1'>
                            <animate
                                attributeName='offset'
                                values='-1; -1; 2'
                                keyTimes='0; 0.25; 1'
                                dur='2s'
                                repeatCount='indefinite'
                            ></animate>
                        </stop>
                        <stop offset='2.59996' stopColor='#f3f3f3' stopOpacity='1'>
                            <animate
                                attributeName='offset'
                                values='0; 0; 3'
                                keyTimes='0; 0.25; 1'
                                dur='2s'
                                repeatCount='indefinite'
                            ></animate>
                        </stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}
