import 'leaflet/dist/leaflet.css';

import React, {forwardRef} from 'react';
import {MapContainer, TileLayer} from 'react-leaflet';

import 'leaflet-active-area';

import './style.scss';
import {MapActiveArea} from './MapActiveArea';
import {LatLngBounds} from 'leaflet';

interface LeafletMapContainerProps {
    onReady(): void;
    mapBounds?: LatLngBounds | null;
    children?: React.ReactNode;
}

const LeafletMapComponent = forwardRef<L.Map, LeafletMapContainerProps>(
    ({children, onReady, mapBounds}: LeafletMapContainerProps, ref) => {
        const mapServiceEndpoint =
            process.env.REACT_APP_CLINIC_MAP_SERVICE_ENDPOINT || 'https://maps-test.mittvaccin.se/tile';

        return (
            <MapContainer
                ref={ref}
                worldCopyJump
                className='map_container'
                whenReady={onReady}
                zoom={3}
                minZoom={3}
                zoomSnap={0.9}
                zoomControl={false}
            >
                <TileLayer
                    attribution='<span aria-hidden="true" >&copy; <a href="https://www.openstreetmap.org/copyright" tabindex=-1>OpenStreetMap</a> contributors</span>'
                    url={`${mapServiceEndpoint}/{z}/{x}/{y}.png`}
                    // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <MapActiveArea mapBounds={mapBounds} />

                {children}
            </MapContainer>
        );
    }
);

export const LeafletMapContainer = React.memo(LeafletMapComponent);
