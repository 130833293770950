export function PhoneIcon() {
    return (
        <>
            <svg
                width='24'
                height='24'
                focusable='false'
                aria-hidden='true'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M20.9994 16.4765V19.1862C21.0005 19.4377 20.9488 19.6867 20.8479 19.9172C20.7469 20.1477 20.5988 20.3546 20.4131 20.5247C20.2273 20.6947 20.008 20.8242 19.7693 20.9048C19.5305 20.9854 19.2775 21.0153 19.0265 20.9927C16.2415 20.6907 13.5664 19.7409 11.216 18.2197C9.02929 16.8329 7.17534 14.9827 5.7858 12.8003C4.25627 10.4439 3.30442 7.76107 3.00735 4.96916C2.98473 4.71938 3.01447 4.46764 3.09468 4.22996C3.17489 3.99229 3.30381 3.77389 3.47323 3.58866C3.64265 3.40343 3.84885 3.25544 4.07872 3.15411C4.30858 3.05278 4.55707 3.00032 4.80837 3.00009H7.52347C7.96269 2.99577 8.3885 3.151 8.72152 3.43683C9.05455 3.72267 9.27207 4.11961 9.33354 4.55366C9.44814 5.42084 9.66067 6.27229 9.96707 7.09177C10.0888 7.41507 10.1152 7.76642 10.043 8.1042C9.97082 8.44198 9.80313 8.75203 9.5598 8.99761L8.41041 10.1447C9.69877 12.406 11.5748 14.2784 13.8406 15.5642L14.99 14.4171C15.2361 14.1742 15.5467 14.0069 15.8852 13.9348C16.2236 13.8628 16.5757 13.8891 16.8996 14.0106C17.7207 14.3164 18.5739 14.5285 19.4428 14.6429C19.8824 14.7048 20.2839 14.9258 20.5709 15.2639C20.858 15.6019 21.0105 16.0335 20.9994 16.4765Z'
                    stroke='#E30031'
                    strokeWidth='2.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        </>
    );
}
