import {useTranslation} from 'react-i18next';
import {SearchFilterMap} from 'src/hooks/useSearchFilters';
import {Toggle} from '../toggle/Toggle';
import {GroupedResults, Status} from './Search';
import {PlacesFilterObject} from 'src/services/api/types/types';
import {Spinner} from 'src/components/layout/loader/Spinner';
import './SearchResults.scss';

interface SearchResultProps {
    id?: string;
    searchParams: SearchFilterMap;
    groupedResults: GroupedResults;
    hasPlaceLoadError: boolean;
    onAddPlaceFilter(id: string, place: PlacesFilterObject): void;
    onToggleFilter(type: keyof SearchFilterMap, id: string, active: boolean): void;
    clearQuery: () => void;
    status: Status;
    disableTabNavigation: boolean;
}

export const SearchResults = ({
    id,
    searchParams,
    groupedResults,
    hasPlaceLoadError,
    onAddPlaceFilter,
    onToggleFilter,
    clearQuery,
    status,
    disableTabNavigation
}: SearchResultProps) => {
    const {t} = useTranslation();

    const hasResults = () => {
        const {careGivers, places, visitReasons, clinics} = groupedResults;
        return (
            status === 'done' &&
            careGivers.items.length + places.items.length + visitReasons.items.length + clinics.items.length > 0
        );
    };

    return (
        <div id={id} className='search-results'>
            {status === 'loading' && (
                <div className='search-results'>
                    <div className='search-results__section search-results__section--loading'>
                        <div className='search-results__loader'>
                            <Spinner />
                        </div>
                    </div>
                </div>
            )}
            {!hasResults() && status !== 'loading' && (
                <div
                    aria-live='assertive'
                    aria-label={t('search_no_result.text')}
                    role='dialog'
                    className='search-results search-results__no-results'
                >
                    <p>{t('search_no_result.text')}</p>
                </div>
            )}
            {hasResults() && (
                <div className='search-results'>
                    {groupedResults.visitReasons.items.length > 0 && (
                        <div className='search-results__section' key={groupedResults.visitReasons.label}>
                            <h3>{groupedResults.visitReasons.label}</h3>
                            <ul>
                                {groupedResults.visitReasons.items.map(({id, label, type}) => {
                                    return (
                                        <li key={id}>
                                            <Toggle
                                                onClick={() => {
                                                    onToggleFilter('reason', id, true);
                                                    clearQuery();
                                                }}
                                                noCheckbox={true}
                                                checked={searchParams.reason.includes(id)}
                                                tabIndex={disableTabNavigation ? -1 : 0}
                                                id={id}
                                                label={label}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                    {hasPlaceLoadError && groupedResults.places.items.length === 0 && (
                        <>
                            <div className='search-results__section' key={groupedResults.places.label}>
                                <h3>{groupedResults.places.label}</h3>
                                <p>
                                    <b>{t('search_group_place_load_error.heading')}</b>
                                    <br />
                                    {t('search_group_place_load_error.text')}
                                </p>
                            </div>
                        </>
                    )}

                    {groupedResults.places.items.length > 0 && (
                        <div className='search-results__section' key={groupedResults.places.label}>
                            <h3>{groupedResults.places.label}</h3>
                            <ul>
                                {groupedResults.places.items.map(({id, label, type, place}) => {
                                    return (
                                        <li key={id}>
                                            <Toggle
                                                onClick={() => {
                                                    onAddPlaceFilter(id, place);
                                                    clearQuery();
                                                }}
                                                noCheckbox={true}
                                                checked={searchParams.place.includes(id)}
                                                tabIndex={disableTabNavigation ? -1 : 0}
                                                id={id}
                                                label={label}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                    {groupedResults.careGivers.items.length > 0 && (
                        <div className='search-results__section' key={groupedResults.careGivers.label}>
                            <h3>{groupedResults.careGivers.label}</h3>
                            <ul>
                                {groupedResults.careGivers.items.map(({id, label, type}) => {
                                    return (
                                        <li key={id}>
                                            <Toggle
                                                onClick={() => {
                                                    onToggleFilter('caregiver', id, true);
                                                    clearQuery();
                                                }}
                                                noCheckbox={true}
                                                checked={searchParams.caregiver.includes(id)}
                                                tabIndex={disableTabNavigation ? -1 : 0}
                                                id={id}
                                                label={label}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                    {groupedResults.clinics.items.length > 0 && (
                        <div className='search-results__section' key={groupedResults.clinics.label}>
                            <h3>{groupedResults.clinics.label}</h3>
                            <ul>
                                {groupedResults.clinics.items.map(({id, label, type}) => {
                                    return (
                                        <li key={id}>
                                            <Toggle
                                                onClick={() => {
                                                    onToggleFilter('clinic', id, true);
                                                    clearQuery();
                                                }}
                                                noCheckbox={true}
                                                checked={searchParams.reason.includes(id)}
                                                tabIndex={disableTabNavigation ? -1 : 0}
                                                id={id}
                                                label={label}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
