import {useTranslation} from 'react-i18next';
import {EIdPollData} from '../../../../../services/api/implementations/eidapi';
import {EidAuthenticate} from '../../../../authentication/EidAuthenticate';
import './ConsentIdentity.scss';

interface ConsentIdentifyProps {
    onLoginSuccess(result: EIdPollData): void;
}

export function ConsentIdentity({onLoginSuccess}: ConsentIdentifyProps) {
    const {t} = useTranslation();

    return (
        <div className='consent__identify'>
            <h1 className='consent-content__heading'>{t('consent.handle.header')}</h1>
            <p className='consent-content__sub-heading'>{t('consent.sub_header')}</p>
            <EidAuthenticate onLoginSuccess={onLoginSuccess} />
        </div>
    );
}
