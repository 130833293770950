import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './ConsentHeader.scss';

type LocationState = {
    clinicId: string;
};

interface Path {
    pathname: string;
    search: string;
    hash: string;
}
interface Location<State = LocationState> extends Path {
    state: State;
    key: string;
}

export function ConsentHeader() {
    const {t} = useTranslation();
    const location: Location = useLocation();
    const navigate = useNavigate();

    const getRedirectHandler = () => {
        if (location.state) {
            if (location.state.clinicId === 'map') {
                navigate(`/`);
            } else {
                navigate(`/mottagning/${location.state.clinicId}`);
            }
        } else {
            navigate(`/`);
        }
    };

    return (
        <header className='consent-header-nav'>
            <img className='logo' src='/mittvaccin_logo.svg' alt={t('logo_alt.text')} />
            <button className='consent-button' onClick={getRedirectHandler}>
                {t('button_close.text')}
            </button>
        </header>
    );
}
