import {useRef} from 'react';
import {ReactComponent as IconDropdown32} from 'src/components/icons/icon_dropdown_32.svg';
import './Select.scss';

interface SelectProps {
    id?: string | number;
    className?: string;
    ariaLabel?: string;
    placeholder?: string;
    value?: string;
    valueAriaLabel?: string;
    options?: string[];
    required?: boolean;
    isValid?: boolean;
    errorMessage?: string;
    variant?: 'default' | 'small';
    onChange(e: string): void;
}

export function Select({
    id,
    className,
    ariaLabel,
    placeholder,
    value,
    valueAriaLabel,
    options = [],
    required,
    isValid,
    errorMessage,
    onChange,
    variant = 'default'
}: SelectProps) {
    const selectRef = useRef<any>(null);
    const classNames = () => {
        const classes = ['select-container__select'];
        if (isValid === false) {
            classes.push('invalid');
        }
        if (value) {
            classes.push('select-container__select--has-selection');
        }
        if (variant === 'small') {
            classes.push('select-container__select--small');
        }
        return classes.join(' ');
    };

    const handleEnterKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (selectRef.current) {
                if ('showPicker' in HTMLSelectElement.prototype) {
                    // showPicker() is supported.
                    selectRef.current.showPicker();
                }
            }
        }
    };

    return (
        <div className={`select-container${className ? ` ${className}` : ''}`}>
            {value && id && (
                <label htmlFor={`select-${id}`} className='select-container__placeholder-label'>
                    {placeholder}
                </label>
            )}
            <select
                ref={selectRef}
                id={id ? `select-${id}` : undefined}
                aria-label={ariaLabel}
                value={value || -1}
                className={classNames()}
                required={required}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={handleEnterKeyPress}
            >
                {placeholder && (
                    <option disabled value={-1}>
                        {placeholder}
                    </option>
                )}
                {options.map((option) => {
                    return (
                        <option key={option} value={option} aria-label={valueAriaLabel}>
                            {option}
                        </option>
                    );
                })}
            </select>

            <IconDropdown32
                className={`select-container__icon${variant === 'small' ? ' select-container__icon--small' : ''} ${
                    isValid === false ? ' select-container__icon__error' : ''
                }`}
                aria-hidden
            />
            {isValid === false && <span className='select-container__error-label'>{errorMessage}</span>}
        </div>
    );
}
