import {Outlet} from 'react-router-dom';
import {ConsentHeader} from '../components/clinic/consent/components/header/ConsentHeader';
import {useCssWorkarounds} from '../hooks/useCssWorkarounds';
import {useClearTrackingCookies} from '../hooks/useClearTrackingCookies';

export function ConsentRoot() {
    useCssWorkarounds();
    useClearTrackingCookies();

    return (
        <>
            <div className='consent-container' aria-busy='false'>
                <ConsentHeader />
                <Outlet />
            </div>
        </>
    );
}
