/**
 * Enable debugging mode by running startDebug() in the console, or
 * adding ?debug to the url
 */
let debugging = false;

if (process.env.REACT_APP_ALLOW_MOCK === 'true') {
    debugging = new URLSearchParams(window.location.search).has('debug') || localStorage.getItem('ivt-debug') !== null;

    if (debugging) {
        console.log('%c Debugging mode enabled', 'font-weight: bold; font-size: 24px;color: green;');
    }

    window.startDebug = function () {
        console.log(
            '%c Debugging mode enabled, please reload page',
            'font-weight: bold; font-size: 24px;color: green;'
        );
        debugging = true;
        localStorage.setItem('ivt-debug', '1');
    };

    window.stopDebug = function () {
        console.log('%c Debugging mode disabled, please reload page', 'font-weight: bold; font-size: 24px;color: red;');
        debugging = false;
        localStorage.removeItem('ivt-debug');
    };
}

export const debug = {
    debugActive: debugging
};
