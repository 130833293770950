import {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';
import {useBookingContext, User, BookingActions} from 'src/context/BookingContext';
import {useBookingSessionContext, BookingSessionActions} from 'src/context/BookingSessionContext';
import {useClinicDetailsContext} from 'src/context/ClinicDetailsContext';
import {useSessionContext} from 'src/context/SessionContext';
import {postHealthDeclaration} from 'src/services/api/api';
import {ErrorCode, ApiErrorResponse, FinalizeDropInRequest} from 'src/services/api/types/types';
import {validatePhone, validateEmail} from 'src/services/validation/validation';
import {Button} from 'src/components/form/button/Button';
import {Checkbox} from 'src/components/form/checkbox/Checkbox';
import {Input} from 'src/components/form/input/Input';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {BookingInformation} from '../../components/BookingInformation/BookingInformation';

export function DropInConfirm() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {bookingSessionDispatch} = useBookingSessionContext();
    const {sessionState} = useSessionContext();
    const [email, setEmail] = useState(bookingState.user?.email || '');
    const [phone, setPhone] = useState(bookingState.user?.mobile || '');
    const [sms, setSms] = useState(false);
    const [personal, setPersonal] = useState(false);
    const [marketing, setMarketing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorCode, setErrorCode] = useState<ErrorCode>('ILLEGAL_DBL');
    const [isPhoneValid, setIsPhoneValid] = useState(validatePhone(phone));
    const [isEmailValid, setIsEmailValid] = useState(validateEmail(email));
    const [showError, setShowError] = useState<Error | null>(null);
    const [phoneHasBeenValid, setPhoneHasBeenValid] = useState(false);
    const [emailHasBeenValid, setEmailHasBeenValid] = useState(false);
    const [busy, setBusy] = useState(false);

    const {clinic} = dataState;

    const {bookingType, selectedPersonsIds, healthDeclarationAnswers, user, tripDepartureDate, trip} = bookingState;

    const {userJwt} = sessionState;

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        if (phoneHasBeenValid) {
            if (!validatePhone(phone)) {
                setIsPhoneValid(false);
                setPhoneHasBeenValid(false);
            }
        }
        if (validatePhone(phone)) {
            setIsPhoneValid(true);
            if (phone.length > 0) setPhoneHasBeenValid(true);
        }
    }, [phone, phoneHasBeenValid]);

    useEffect(() => {
        if (emailHasBeenValid) {
            if (!validateEmail(email)) {
                setIsEmailValid(false);
                setEmailHasBeenValid(false);
            }
        }
        if (validateEmail(email)) {
            setIsEmailValid(true);
            if (email.length > 0) setEmailHasBeenValid(true);
        }
    }, [email, emailHasBeenValid]);

    const getHealthDecl = (id: string) => {
        return Object.keys(healthDeclarationAnswers.answers[id]).map((key) => {
            return {
                questionId: parseInt(key, 10),
                answer:
                    healthDeclarationAnswers.answers[id][parseInt(key, 10)] === true
                        ? ('yes' as const)
                        : ('no' as const)
            };
        });
    };

    const mapPatient = (patient: User) => {
        return {
            identity: patient.identity,
            givenName: patient.givenName,
            familyName: patient.familyName
        };
    };

    const patients = selectedPersonsIds.map((id) => {
        if (user?.identity === id) {
            return mapPatient(user);
        }

        return mapPatient(user?.relations?.find((relation) => relation.identity === id) as User);
    });

    const handleSubmit = async () => {
        if (busy) {
            return;
        }

        setBusy(true);
        dispatch({type: BookingActions.SET_CONTACT_DETAILS, value: {email: email, mobile: phone}});

        const patientsData = patients.map((patient) => {
            const patientBookingData: FinalizeDropInRequest = {
                contactInfo: {
                    marketing: marketing,
                    preferredContact: email.length > 0 ? 'both' : 'sms',
                    mobile: phone,
                    ...(email.length > 0 && {email})
                },
                patient: patient,
                healthDecl: {
                    definitionId: parseInt(healthDeclarationAnswers.healthDeclarationId as string, 10),
                    answers: getHealthDecl(patient.identity)
                }
            };

            if (tripDepartureDate) {
                patientBookingData.healthDecl.trip = {
                    date: format(tripDepartureDate, 'yyyy-MM-dd'),
                    destinations: trip as Array<{
                        countryId: number;
                        duration: number;
                    }>
                };
            }

            return patientBookingData;
        });

        try {
            for (let i = 0; i < patientsData.length; i++) {
                const bookingData = patientsData[i];
                await postHealthDeclaration(clinic?.id as number, userJwt, bookingData);
            }

            bookingSessionDispatch({type: BookingSessionActions.CLEAR_USER_SESSION});
            dispatch({type: BookingActions.SET_BOOKING_STEP, value: 'completed'});
        } catch (error) {
            setErrorCode((error as ApiErrorResponse).errorCode);
            setShowError(error as Error);
        }
        setBusy(false);
    };

    const isValid =
        (email.length === 0 || validateEmail(email)) && validatePhone(phone) && phone.length > 0 && sms && personal;

    return (
        <main className='main-content' id='main'>
            <h1 className='main-content__header'>{t('confirm.dropin_header')}</h1>
            <p className='main-content__subheader'>{t('confirm.dropin_subheader')}</p>
            <p className='main-content__subheader'>
                {bookingType === 'healthDeclaration'
                    ? t('confirm.healthDeclaration_information')
                    : t('confirm.dropin_information')}
            </p>
            {showError && (
                <ModalPortal
                    primaryActionLabel={t('error_ok_button.text')}
                    onPrimaryAction={() => {
                        setShowError(null);
                        setErrorCode('ILLEGAL_DBL');
                    }}
                >
                    <p>
                        <b>{t('confirm.error.header')}</b>
                        <br />
                        {t(`api.errors.${errorCode}`)}
                    </p>
                </ModalPortal>
            )}

            <div className='form-container'>
                <h4 className='form-container__header'>{t('confirm.form.header')}</h4>
                <div className='form-content'>
                    <Input
                        type='tel'
                        id='phone'
                        isValid={isPhoneValid}
                        required
                        label={t('confirm.form.telephone')}
                        value={phone}
                        onBlur={(val) => setIsPhoneValid(validatePhone(val))}
                        onChange={(e) => setPhone(e)}
                    />
                    {!isPhoneValid && <p className='input-error'>{t('confirm.form.phone_error')}</p>}
                    <Input
                        type='email'
                        id='email'
                        isValid={isEmailValid}
                        label={t('confirm.form.email')}
                        value={email}
                        onBlur={(val) => setIsEmailValid(validateEmail(val))}
                        onChange={(e) => setEmail(e)}
                    />
                    {!isEmailValid && <p className='input-error'>{t('confirm.form.email_error')}</p>}
                </div>
            </div>

            <div className='mobile-only'>
                <BookingInformation />
            </div>

            <div className='policy-container'>
                <h1 className='mobile-only policy-container__mobile-only-header'>
                    {t('confirm.policy.mobile_header')}
                </h1>
                <Checkbox onClick={(bool) => setSms(bool)} id='sms' label={t('confirm.policy.sms')} checked={sms} />
                <div>
                    <Checkbox
                        onClick={(bool) => setPersonal(bool)}
                        id='personal'
                        checked={personal}
                        ariaLabel={`${t('confirm.policy.personal_data')} ${t('confirm.policy.personal_data_policy')}`}
                        jsx={
                            <>
                                <span>{t('confirm.policy.personal_data')}</span>
                                &nbsp;
                                <Button variant='text' className='in-text-link' onClick={() => setShowModal(true)}>
                                    <span>{t('confirm.policy.personal_data_policy')}</span>
                                </Button>
                            </>
                        }
                    />
                </div>
                <Checkbox
                    onClick={(bool) => setMarketing(bool)}
                    id='marketing'
                    label={t('confirm.policy.storing_data')}
                    checked={marketing}
                />
            </div>

            <div className='confirm-next'>
                <Button id='booking-confirm' big disabled={!isValid} onClick={() => handleSubmit()}>
                    <span>{t('confirm.dropin_button')}</span>
                </Button>
            </div>
            {showModal && (
                <ModalPortal onCloseClick={() => setShowModal(false)}>
                    <div className='policy-modal'>
                        <h1 className='policy-modal__header'>{t('confirm.policy.header')}</h1>
                        <p className='policy-modal__content wrap-html'>{clinic?.termsText}</p>
                    </div>
                </ModalPortal>
            )}
        </main>
    );
}
