import {useTranslation} from 'react-i18next';
import {Toggle} from '../../form/toggle/Toggle';

interface MoreFiltersProps {
    activeFilters: Array<string>;
    onToggleFilter(activeFilters: Array<string>): void;
    disableTabNavigation?: boolean;
}

export function MoreFilters({activeFilters, disableTabNavigation = false, onToggleFilter}: MoreFiltersProps) {
    const {t} = useTranslation();

    return (
        <>
            <div className='checkbox_group'>
                <Toggle
                    onClick={(checked) => {
                        let newFilters = [];
                        if (checked) {
                            newFilters = ['hasDropin', ...activeFilters];
                        } else {
                            newFilters = [...activeFilters.filter((f) => f !== 'hasDropin')];
                        }
                        onToggleFilter(newFilters);
                    }}
                    checked={activeFilters.includes('hasDropin')}
                    tabIndex={disableTabNavigation ? -1 : 0}
                    id={'hasDropin'}
                    label={t('has_dropin.text')}
                />
            </div>
        </>
    );
}
