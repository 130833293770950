import {useTranslation} from 'react-i18next';
import ModalPortal from '../layout/modal/ModalPortal';
import {Button} from '../form/button/Button';
import './AgeRestrictionWarningModal.scss';

export function AgeRestrictionWarningModal({open, handleAction}: {open: boolean; handleAction: () => void}) {
    const {t} = useTranslation();
    return (
        <>
            <ModalPortal
                size='sm'
                open={open}
                onCloseClick={handleAction}
                ariaLabel={`${t('account.age_limit_header')}`}
            >
                <div id='age-restriction-modal'>
                    <h1>{t('account.age_limit_header')}</h1>
                    <p>{t('account.age_limit_text')}</p>
                    <Button className='age-restriction-warning-modal-button' variant='outlined' onClick={handleAction}>
                        {t('error_ok_button.text')}
                    </Button>
                </div>
            </ModalPortal>
        </>
    );
}
