import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';
import './BookingInformation.scss';

export function BookingInformation() {
    const {t} = useTranslation();

    const {dispatch, bookingState} = useBookingContext();
    const {reasonState} = bookingState;

    useEffect(() => window.scrollTo(0, 0), []);

    return (
        <main className='main-content main-content--information' id='main'>
            <h1 className='main-content--information__header'>{t('clinic_information.header')}</h1>
            {reasonState.appointmentType?.information.map((info, index) => {
                return (
                    <div className='main-content--information-container' key={index}>
                        {info.for && <h3>{info.for}</h3>}
                        <p className='main-content--information-container__text'>{info.text}</p>
                        {info.url && (
                            <a
                                href={info.url}
                                className='main-content--information__link'
                                target='_blank'
                                rel='noreferrer'
                            >
                                {t('clinic_information.link')}
                            </a>
                        )}
                    </div>
                );
            })}
            <button
                className='btn btn--primary main-content--information__button'
                onClick={() => dispatch({type: BookingActions.NEXT_STEP})}
            >
                <span>{t('clinic_information.buttonText')}</span>
            </button>
        </main>
    );
}
