import {useTranslation} from 'react-i18next';
import {useBookingContext} from '../../../../context/BookingContext';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {useSessionContext} from '../../../../context/SessionContext';
import {DropInPeopleForm} from '../components/DropInPeopleForm';
import {DropInPeopleList} from '../components/DropInPeopleList';
import {EidAuthenticate} from '../../../authentication/EidAuthenticate';

export function DropInPeople() {
    const {t} = useTranslation();
    const {
        sessionState: {userJwt},
        startSession
    } = useSessionContext();

    const {dataState} = useClinicDetailsContext();
    const {
        bookingState: {bookingType}
    } = useBookingContext();

    if (Boolean(dataState.clinic?.eidDropin) === false) {
        return <DropInPeopleForm />;
    }

    return (
        <>
            {userJwt !== null ? (
                <DropInPeopleList />
            ) : (
                <main className='main-content' id='main'>
                    <h1 className='people__header'>
                        {bookingType === 'healthDeclaration'
                            ? t('people_select.healthDeclaration_identify_yourself')
                            : t('people_select.dropin_identify_yourself')}
                    </h1>

                    <p className='people__text wrap-html'>{t('people_select.dropin_subheader')}</p>
                    <p className='people__text wrap-html'>{t('people_select.additional_subheader')}</p>
                    <EidAuthenticate
                        onLoginSuccess={({jwt, sessionDuration, tokenDuration}) => {
                            startSession(jwt, sessionDuration, tokenDuration);
                        }}
                    />
                </main>
            )}
        </>
    );
}
