import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useSessionContext} from 'src/context/SessionContext';
import {EidAuthenticate} from 'src/components/authentication/EidAuthenticate';
import {ToCModal} from 'src/components/session/ToCModal';
import {Spinner} from 'src/components/layout/loader/Spinner';
import {Account} from './Account';
import {AgeRestrictionWarningModal} from '../session/AgeRestrictionWarningModal';
import './AccountMain.scss';

export function AccountMain() {
    const {sessionState, startSession} = useSessionContext();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const hasIdentity = sessionState?.hasActiveSession && !!sessionState?.user;
    const isAgeRestricted = sessionState.isAgeRestricted;

    if (sessionState.isLoadingAccount || sessionState.isLoading) {
        return (
            <div className='account-main-auth-container__spinner'>
                <div className='account-main-auth-container__spinner__icon'>
                    <Spinner />
                </div>
            </div>
        );
    }

    if (hasIdentity && isAgeRestricted && !sessionState.isLoadingAccount) {
        return <AgeRestrictionWarningModal open handleAction={() => navigate('/')} />;
    }
    return (
        <main id='main-account-container' className='account-main-account-container page-container-margin'>
            {hasIdentity && sessionState.account ? (
                <Account />
            ) : (
                <div role='region' className='account-main-auth-container'>
                    <>
                        <ToCModal
                            open={sessionState?.hasActiveSession && !sessionState.isLoading && !sessionState.account}
                        />
                        {!sessionState?.hasActiveSession && (
                            <div>
                                <h1 className='account-main-auth-container__heading'>{t('account.header')}</h1>
                                <p className='account-main-auth-container__sub-heading'>
                                    {t('account.sign_in_required')}
                                </p>
                                <h2 className='account-main-auth-container__identity-heading'>
                                    {t('people_select.choose_method')}
                                </h2>
                                <EidAuthenticate
                                    onLoginSuccess={async ({jwt, sessionDuration, tokenDuration}) => {
                                        startSession(jwt, sessionDuration, tokenDuration);
                                    }}
                                />
                            </div>
                        )}
                    </>
                </div>
            )}
        </main>
    );
}
