import React, {useEffect, useRef} from 'react';
import L from 'leaflet';

import './style.scss';

type MapControlColor = 'primary' | 'secondary' | 'tertiary';

interface MapControlProps {
    disabled?: boolean;
    ariaLabel?: string;
    icon: React.ReactNode;
    label?: string;
    color?: MapControlColor;
    filled?: boolean;
    onDark?: boolean;
    onClick?(): void;
    onDisabledClick?(): void;
}

export function MapControl({
    icon,
    label,
    ariaLabel,
    disabled = false,
    filled = false,
    color = 'primary',
    onClick = () => {},
    onDisabledClick = () => {}
}: MapControlProps) {
    const buttonElement = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (!buttonElement.current) {
            return;
        }

        L.DomEvent.disableClickPropagation(buttonElement.current);
    }, []);

    return (
        <>
            <button
                ref={buttonElement}
                className={`map_control map_control__${color}${filled ? ' map_control--filled' : ''}${
                    disabled ? ' map_control--disabled' : ''
                }${label ? ' map_control--labeled' : ''}`}
                aria-label={ariaLabel}
                onClick={() => {
                    if (disabled === false) {
                        onClick();
                    } else {
                        onDisabledClick && onDisabledClick();
                    }
                }}
            >
                {icon}
                {label && <span className='map_control__label'>{label}</span>}
            </button>
        </>
    );
}
