import './BookingInformation.scss';
import {useTranslation} from 'react-i18next';
import {BookingStep, useBookingContext} from '../../../../context/BookingContext';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {LocationIcon} from '../../../icons/LocationIcon';
import {UserIcon} from '../../../icons/UserIcon';
import {CertificateIcon} from '../../../icons/CertificateIcon';
import {TimeIcon} from '../../../icons/TimeIcon';
import {format} from '../../../../lib/dates/format';
import {EditIcon} from '../../../icons/EditIcon';
import {PhoneIcon} from '../../../icons/PhoneIcon';
import {EmailIcon} from '../../../icons/EmailIcon';
import {HealthDeclarationIcon} from '../../../icons/HealthDeclarationIcon';
import {ConsentIcon} from '../../../icons/ConsentIcon';
import {VisitCategory} from '../../../main/components/PickVisitCategory';

export function BookingInformation() {
    const {t} = useTranslation();
    const {bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {clinic, visitReasons} = dataState;

    const {
        step,
        timeSlotState,
        user,
        bookingCode,
        bookingType,
        tripDepartureDate,
        contactDetails,
        consentRequests,
        userConsentGiven,
        userConsentNeeded,
        selectedPersonsIds,
        reasonState: {appointmentType, reason}
    } = bookingState;

    const isClosedBooking =
        (bookingType === 'dropIn' && clinic?.eidDropin) || (bookingType === 'booking' && appointmentType?.eidRequired);
    const sortedSelectedIds = isClosedBooking
        ? selectedPersonsIds?.sort((a, b) => (parseInt(a, 10) || 0) - (parseInt(b, 10) || 0))
        : selectedPersonsIds;

    const selectedPeople = sortedSelectedIds.map((id) => {
        if (user?.identity === id) {
            return {name: user.givenName + ' ' + user.familyName, id: user?.identity};
        }
        const person = user?.relations?.find((p) => p.identity === id);
        return {name: person?.givenName + ' ' + person?.familyName, id: person?.identity};
    }, []);

    const getReasonLabel = () => {
        if (bookingType === 'dropIn') {
            return t('stepindicator.dropin_header');
        }
        if (bookingType === 'healthDeclaration') {
            return step === 'completed'
                ? t('stepindicator.healthDeclaration_completed_header')
                : t('stepindicator.healthDeclaration_header');
        }

        const reasonPrefix = reason?.category ? t(`reason_group.${reason.category as VisitCategory}`) : '';
        const visitReasonsJoined = appointmentType?.visitReasonIds
            .map((visitReasonId) => {
                return visitReasons.find(({id}) => parseInt(id, 10) === visitReasonId)?.label;
            })
            .join(', ');

        return `${reasonPrefix} ${visitReasonsJoined}`;
    };

    const currStepIndex = bookingState.steps.indexOf(bookingState.step);
    const setStepClasses = (bookingStep: BookingStep) => {
        const index = bookingState.steps.indexOf(bookingStep);
        if (bookingStep === bookingState.step) {
            return ' current-step';
        }
        if (index > currStepIndex) {
            return ' future-step';
        }
        return '';
    };

    const getStepIndicatorIcon = (step: string) => {
        switch (step) {
            case 'timeSelect':
                return <TimeIcon />;
            case 'peopleSelect':
                return <UserIcon ariaHidden />;
            case 'confirm':
                return <CertificateIcon />;
        }
    };
    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const getConsentinfo = () => {
        let info = '';
        consentRequests
            ?.filter((request) => request.digital)
            .forEach((request) => {
                if (request.mobile) {
                    if (info.length > 0) {
                        info += ', ';
                    }
                    info += request.mobile;
                }
                if (request.email) {
                    info += ', ' + request.email;
                }
            });
        return info;
    };

    const showConsentRequest = () => {
        return consentRequests?.some((request) => request.digital);
    };

    const BookingStepItem = ({bookingStep}: {bookingStep: BookingStep}) => {
        if (!bookingState.steps.includes(bookingStep)) {
            return null;
        }

        if (bookingStep === 'completed') {
            return (
                <li key={bookingStep} className='stepindicator__list__item'>
                    <EditIcon />
                    {t('booking.information.bookingcode')}
                    <br />
                    <span>{bookingCode}</span>
                </li>
            );
        }
        if (bookingStep === 'initial') {
            return (
                <li key={bookingStep} className='stepindicator__list__item'>
                    <LocationIcon />
                    {clinic?.name}
                    <br />
                    <span>{clinic?.address}</span>
                </li>
            );
        }
        if (bookingStep === 'timeSelect') {
            return (
                <li key={bookingStep} className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                    <TimeIcon />
                    <span>
                        {timeSlotState.selectedSlot
                            ? capitalizeFirstLetter(
                                  format(timeSlotState.selectedSlot, 'ccc dd MMM, HH:mm').replace('.', '')
                              )
                            : t('stepindicator.timeSelect')}
                    </span>
                </li>
            );
        }

        if (bookingStep === 'peopleSelect') {
            return (
                <li key={bookingStep} className={'people_list stepindicator__list__item' + setStepClasses(bookingStep)}>
                    <UserIcon ariaHidden />
                    <span className='people'>
                        {selectedPeople.map((person) => (
                            <span className='name' key={person.id}>
                                {person.name}
                                <br />
                                <span>{person.id}</span>
                            </span>
                        ))}
                    </span>
                </li>
            );
        }

        if (bookingStep === 'consent') {
            return (
                <li className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                    <ConsentIcon />
                    {t('stepindicator.consent')}
                    {userConsentNeeded && userConsentGiven && (
                        <>
                            <br />
                            <span>
                                {t('stepindicator.x_consent_given', {
                                    personName: `${user?.givenName} ${user?.familyName}`
                                })}
                            </span>
                        </>
                    )}
                    {showConsentRequest() && (
                        <>
                            <br />
                            <span>{t('stepindicator.consent-requests', {contactInfo: getConsentinfo()})}</span>
                        </>
                    )}
                </li>
            );
        }

        if (bookingStep === 'healthDeclaration') {
            return (
                <li key={bookingStep} className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                    <HealthDeclarationIcon />
                    {t('stepindicator.healthDeclaration')}
                    <br />
                    <span>{t('completed.filled_out')}</span>
                    {tripDepartureDate && <span>{t('completed.travel_documents_added')}</span>}
                </li>
            );
        }

        return (
            <li key={bookingStep} className={'stepindicator__list__item' + setStepClasses(bookingStep)}>
                {getStepIndicatorIcon(bookingStep)} {t(`stepindicator.${bookingStep}`)}
            </li>
        );
    };

    return (
        <aside className={`aside-content booking-information`}>
            <div className='aside-content__container step-width'>
                <h2 className='aside-padding'>
                    {getReasonLabel()}
                    <br />
                    {bookingType !== 'healthDeclaration' && (
                        <span>
                            {t('stepindicator.people_count', {count: bookingState.selectedPersonsIds.length || 0})}
                        </span>
                    )}
                </h2>
                <ul className='stepindicator__list'>
                    {bookingType === 'booking' && step === 'completed' && <BookingStepItem bookingStep='completed' />}
                    {bookingType === 'healthDeclaration' && (
                        <li className='stepindicator__list__item'>
                            <LocationIcon />
                            {clinic?.name}
                            <br />
                            <span>{clinic?.address}</span>
                        </li>
                    )}
                    <BookingStepItem bookingStep='initial' />
                    <BookingStepItem bookingStep='timeSelect' />
                    <BookingStepItem bookingStep='peopleSelect' />
                    <BookingStepItem bookingStep='consent' />
                    <BookingStepItem bookingStep='healthDeclaration' />
                    {step === 'completed' && (
                        <>
                            <li key='phone' className='stepindicator__list__item'>
                                <PhoneIcon />
                                <span>{contactDetails?.mobile}</span>
                            </li>
                            {contactDetails?.email && (
                                <li key='email' className='stepindicator__list__item'>
                                    <EmailIcon />
                                    <span>{contactDetails?.email}</span>
                                </li>
                            )}
                        </>
                    )}
                </ul>
            </div>
        </aside>
    );
}
