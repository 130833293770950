import React from 'react';
import ModalPortal from '../layout/modal/ModalPortal';

interface LoadingErrorProps {
    primaryAction?(): void;
    secondaryAction?(): void;
}

export function LoadingError({primaryAction}: LoadingErrorProps) {
    return (
        <ModalPortal onPrimaryAction={primaryAction}>
            <p>
                <b>Ett fel inträffade</b>
                <br />
                Sidan kunde inte laddas korrekt.
            </p>
        </ModalPortal>
    );
}
