import './BookingSkeleton.scss';
import {SkeletonAnimation} from '../../../icons/SkeletonAnimation';

export function BookingSkeleton() {
    return (
        <div className='booking_skeleton'>
            <div className='booking_skeleton__title'>
                <SkeletonAnimation />
            </div>
            {[...Array(4)].map((e, i) => (
                <div key={i} className='booking_skeleton__row'>
                    <div className='booking_skeleton__row__icon'>
                        <SkeletonAnimation />
                    </div>
                    <div className='booking_skeleton__row__text-container'>
                        <div className='booking_skeleton__row__text-container__text'>
                            <SkeletonAnimation />
                        </div>
                        {i !== 2 && (
                            <div className='booking_skeleton__row__text-container__text'>
                                <SkeletonAnimation />
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className='booking_skeleton__footer'>
                <div className='booking_skeleton__row'>
                    <div className='booking_skeleton__row__text-container'>
                        <div className='booking_skeleton__row__text-container__text'>
                            <SkeletonAnimation />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
