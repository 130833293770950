import './ConsentMain.scss';
import {useSessionContext} from '../../../context/SessionContext';
import {ConsentIdentity} from './components/eid/ConsentIdentity';
import {HandleConsent} from './components/handle/HandleConsent';

export function ConsentMain() {
    const {sessionState, startSession} = useSessionContext();

    const hasIdentity = sessionState.hasActiveSession;

    return (
        <>
            <main className='consent' id='main'>
                {sessionState.hasActiveSession && <HandleConsent />}

                {!hasIdentity && (
                    <ConsentIdentity
                        onLoginSuccess={({jwt, sessionDuration, tokenDuration}) => {
                            startSession(jwt, sessionDuration, tokenDuration);
                        }}
                    />
                )}
            </main>
        </>
    );
}
