import {User} from '../../../../../context/BookingContext';
import {BigToggle} from '../../../../form/toggle/BigToggle';
import {PeopleSkeleton} from '../../../../layout/skeleton/people-skeleton/PeopleSkeleton';

interface AuthenticatedPeopleListProps {
    loading: boolean;
    user: User | null;
    selfBookingOnly: boolean;
    selectedPersonsIds: Array<string>;
    selectPerson(identity: string): void;
    validateUser?(user: User): boolean;
}

export function AuthenticatedPeopleList({
    loading,
    user,
    selfBookingOnly,
    selectedPersonsIds,
    selectPerson,
    validateUser
}: AuthenticatedPeopleListProps) {
    return (
        <>
            {loading && <PeopleSkeleton />}
            {!loading && user !== null && (
                <div className='people__list-section__list'>
                    <BigToggle
                        label={user.givenName + ' ' + user.familyName}
                        sublabel={user.identity}
                        checked={selectedPersonsIds.includes(user.identity)}
                        onClick={() => selectPerson(user.identity)}
                        id={user.identity}
                        key={user.identity}
                        disabled={validateUser ? validateUser(user) : false}
                    />

                    {user.relations &&
                        !selfBookingOnly &&
                        user.relations.map((r) => {
                            return (
                                r.relationType === 'child' && (
                                    <BigToggle
                                        label={r.givenName + ' ' + r.familyName}
                                        sublabel={r.identity}
                                        checked={selectedPersonsIds.includes(r.identity)}
                                        onClick={() => selectPerson(r.identity)}
                                        id={r.identity}
                                        key={r.identity}
                                        disabled={validateUser ? validateUser(r) : false}
                                    />
                                )
                            );
                        })}
                </div>
            )}
        </>
    );
}
