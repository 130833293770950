import {useEffect, useRef, useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as IconCheck} from 'src/components/icons/icon_check.svg';
import './SnackBar.scss';

interface SnackBarProps {
    content: string;
    onExited?(): void;
    duration?: number;
    type?: 'success' | 'error';
}

export function SnackBar({content, onExited, duration, type}: SnackBarProps) {
    const snackBarRef = useRef<HTMLDivElement | null>(null);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, duration || 4000);
    }, [duration]);

    return (
        <CSSTransition in={visible} onExited={onExited} nodeRef={snackBarRef} unmountOnExit timeout={500}>
            <div aria-live='polite' role='alert' className='snack-bar' ref={snackBarRef}>
                {type === 'success' && (
                    <div className='snack-bar__icon-container'>
                        <IconCheck className='snack-bar__icon' />
                    </div>
                )}
                <p>{content}</p>
            </div>
        </CSSTransition>
    );
}
