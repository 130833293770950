import {useSessionContext} from '../context/SessionContext';
import {useHistoryDataContext} from '../context/HistoryDataContext';
import {useHandleBookingContext} from '../context/HandleBookingContext';

/**
 * Hook to sign out the user and clear the session, history and booking data from parent contexts.
 */
export function useAppState() {
    const {clearSession} = useSessionContext();
    const {clearHistory} = useHistoryDataContext();
    const {clearBookingContext} = useHandleBookingContext();

    const signOut = () => {
        clearSession({redirectToHome: true});
        clearHistory();
        clearBookingContext();
    };

    const clearAppData = () => {
        clearSession({redirectToHome: false});
        clearHistory();
        clearBookingContext();
    };
    return {signOut, clearAppData};
}
