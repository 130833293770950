import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMap} from 'react-leaflet';
import {GpsIcon} from '../../icons/GpsIcon';
import {MinusIcon} from '../../icons/MinusIcon';
import {PlusIcon} from '../../icons/PlusIcon';
import {MapControl} from '../map-control/MapControl';
import {MapControlGroup} from '../map-control/MapControlGroup';

import L, {LocationEvent} from 'leaflet';

import './style.scss';
import {XIcon} from '../../icons/XIcon';
import {ListIcon} from '../../icons/ListIcon';
import {ListStatus, UiStringActions, useUiContext} from '../../../context/UiContext';

interface MapControlsProps {
    onLocationSearch(): void;
    onLocationFound(e: LocationEvent): void;
    onLocationError(e: L.ErrorEvent, retryFunction?: () => void): void;
}

export enum LocationErrorCodes {
    PERMISSION_DENIED = 1,
    TIMEOUT = 3
}

export function MapControls({onLocationFound, onLocationError, onLocationSearch}: MapControlsProps) {
    const {state, dispatch} = useUiContext();
    const {listStatus} = state;

    const {t} = useTranslation();
    const map = useMap();

    const [geoPermission, setGeoPermission] = useState<PermissionState>('prompt');
    const [showToast, setShowToast] = useState(true);

    const showList = listStatus === ListStatus.VISIBLE || listStatus === ListStatus.FORCED_VISIBLE;

    const findMyLocation = useCallback(() => {
        setShowToast(false);
        onLocationSearch();
        map.locate();
    }, [map, onLocationSearch]);

    useEffect(() => {
        if ('permissions' in navigator) {
            const geoPermissions = async () => {
                const permissions = await navigator.permissions.query({name: 'geolocation'});
                setGeoPermission(permissions.state === 'denied' ? 'denied' : 'prompt');
            };

            geoPermissions();
        }
    }, []);

    useEffect(() => {
        map.on('locationfound', function (e) {
            map.flyTo(e.latlng, 11);

            setGeoPermission('granted');
            onLocationFound(e);
        }).on('locationerror', (e) => {
            if (e.code === LocationErrorCodes.PERMISSION_DENIED) {
                setGeoPermission('denied');
            }
            console.log(e);
            onLocationError(e, findMyLocation);
        });

        if (showToast) {
            map.whenReady(() => {
                map.once('dragstart', () => setShowToast(false));
                map.once('zoomstart', () => setShowToast(false));
            });
        }

        return () => {
            map.off('locationerror').off('locationfound').off('dragstart');
        };
    }, [findMyLocation, map, onLocationError, onLocationFound, setShowToast, showToast]);

    return (
        <>
            <div
                className={`map_controls${showList ? ' map_controls--shifted' : ''}`}
                role='region'
                aria-label={t('map_controls.region_label')}
            >
                <div className='map_control_toast_wrapper'>
                    <MapControl
                        color='primary'
                        filled={geoPermission === 'granted'}
                        icon={<GpsIcon />}
                        ariaLabel={t('find_position.text')}
                        disabled={geoPermission === 'denied'}
                        onDisabledClick={() => {
                            const locationError = {
                                code: 1,
                                message: 'Geolocation error: User denied Geolocation',
                                type: 'locationerror'
                            } as L.ErrorEvent;
                            setShowToast(false);
                            onLocationError(locationError, findMyLocation);
                        }}
                        onClick={() => {
                            findMyLocation();
                        }}
                    />
                    {showToast && (
                        <div className='map_control_toast'>
                            <p tabIndex={0}>
                                {t('clinics_near_you.text')}
                                <button aria-label={t('button_close.text')} onClick={() => setShowToast(false)}>
                                    <XIcon />
                                </button>
                            </p>
                        </div>
                    )}
                </div>
                <MapControlGroup>
                    <MapControl
                        icon={<PlusIcon />}
                        ariaLabel={t('zoom_in.text')}
                        color='primary'
                        onClick={() => void map.zoomIn()}
                    />
                    <MapControl
                        icon={<MinusIcon />}
                        ariaLabel={t('zoom_out.text')}
                        color='primary'
                        onClick={() => void map.zoomOut()}
                    />
                </MapControlGroup>

                {!showList && (
                    <MapControl
                        icon={<ListIcon />}
                        ariaLabel={t('map_clinics_list_button.label')}
                        label={t('map_clinics_list_button.text')}
                        color='primary'
                        onClick={() => {
                            dispatch({type: UiStringActions.SET_LIST_STATUS, value: ListStatus.VISIBLE});
                        }}
                    />
                )}
            </div>
        </>
    );
}
