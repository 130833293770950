import {createContext, useContext, useEffect, useReducer} from 'react';
import {LoadStatus} from '../components/main/Main';
import {ClinicObjectSlim} from '../services/api/types/types';

export enum UiActions {
    SET_EXTEND_SEARCH = 'SET_EXTEND_SEARCH',
    SET_SMALL_DEVICE = 'SET_SMALL_DEVICE'
}

export enum UiBreakpointActions {
    SET_BREAKPOINT_INTERVAL = 'SET_BREAKPOINT_INTERVAL'
}

export enum UiNumberActions {}

export enum UiStringActions {
    SET_HOVERED_CLINIC_ID = 'SET_HOVERED_CLINIC_ID',
    SET_STICKY_CLINIC_ID = 'SET_STICKY_CLINIC_ID',
    SET_POPUP_OPEN_ID = 'SET_POPUP_OPEN_ID',
    SET_LIST_STATUS = 'SET_LIST_STATUS'
}

export enum UiClinicActions {
    SET_VISIBLE_CLINICS = 'SET_VISIBLE_CLINICS'
}

export enum UiNoValueActions {
    CLEAR_STICKY_CLINIC_ID = 'CLEAR_STICKY_CLINIC_ID'
}

export enum UiLoadStatusActions {
    SET_CLINIC_LOAD_STATUS = 'SET_CLINIC_LOAD_STATUS',
    SET_FILTERS_LOAD_STATUS = 'SET_FILTERS_LOAD_STATUS'
}

export enum ListStatus {
    HIDDEN = 'HIDDEN',
    HIDDEN_BUT_SHOWN = 'HIDDEN_BUT_SHOWN',
    VISIBLE = 'VISIBLE',
    FORCED_VISIBLE = 'FORCED_VISIBLE'
}
type Action =
    | BooleanAction
    | StringAction
    | NumberAction
    | ClinicAction
    | NoValueAction
    | LoadStatusAction
    | BreakpointIntervalAction;

type BooleanAction = {type: UiActions; value: boolean};
type NoValueAction = {type: UiNoValueActions};
type NumberAction = {type: UiNumberActions; value: number};
type StringAction = {type: UiStringActions; value: string | ListStatus | null};
type ClinicAction = {type: UiClinicActions; value: Array<ClinicObjectSlim>};
type LoadStatusAction = {type: UiLoadStatusActions; value: LoadStatus};
type BreakpointIntervalAction = {type: UiBreakpointActions; value: BreakpointInterval};

type BreakpointInterval = 'compact' | 'medium' | 'expanded' | 'large';

type Dispatch = (action: Action) => void;
type State = {
    listStatus: ListStatus;
    popupOpenId: string | null;
    searchExtended: boolean;
    smallDevice: boolean;
    breakPointInterval: BreakpointInterval;
    visibleClinics: Array<ClinicObjectSlim>;
    hoveredClinicId: string | null;
    stickyClinicId: string | null;
    clinicLoadStatus: LoadStatus;
    filtersLoadStatus: LoadStatus;
};
type CountProviderProps = {children: React.ReactNode};

const UiContext = createContext<{state: State; dispatch: Dispatch} | undefined>(undefined);

function uiReducer(state: State, action: Action): State {
    switch (action.type) {
        case UiStringActions.SET_POPUP_OPEN_ID: {
            return {...state, popupOpenId: action.value};
        }
        case UiActions.SET_EXTEND_SEARCH: {
            return {...state, searchExtended: action.value};
        }
        case UiActions.SET_SMALL_DEVICE: {
            return {...state, smallDevice: action.value};
        }
        case UiBreakpointActions.SET_BREAKPOINT_INTERVAL: {
            return {...state, breakPointInterval: action.value};
        }
        case UiStringActions.SET_HOVERED_CLINIC_ID: {
            return {...state, hoveredClinicId: action.value};
        }
        case UiStringActions.SET_STICKY_CLINIC_ID: {
            return {...state, stickyClinicId: action.value};
        }
        case UiStringActions.SET_LIST_STATUS: {
            return {...state, listStatus: action.value as ListStatus};
        }
        case UiNoValueActions.CLEAR_STICKY_CLINIC_ID: {
            return {...state, stickyClinicId: null};
        }
        case UiClinicActions.SET_VISIBLE_CLINICS: {
            return {...state, visibleClinics: action.value};
        }
        case UiLoadStatusActions.SET_CLINIC_LOAD_STATUS: {
            return {...state, clinicLoadStatus: action.value};
        }
        case UiLoadStatusActions.SET_FILTERS_LOAD_STATUS: {
            return {...state, filtersLoadStatus: action.value};
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function UiProvider({children}: CountProviderProps) {
    const smallDevice = window.innerWidth <= 640; // needs to be migrated to the useEffect below
    const [state, dispatch] = useReducer(uiReducer, {
        listStatus: ListStatus.HIDDEN,
        popupOpenId: null,
        searchExtended: false,
        smallDevice,
        breakPointInterval: 'large',
        visibleClinics: [],
        hoveredClinicId: null,
        stickyClinicId: null,
        clinicLoadStatus: 'hold',
        filtersLoadStatus: 'hold'
    });

    /**
     * This effect listens to the window resize event and sets the breakpoint span
     */
    useEffect(() => {
        const compact = window.matchMedia('screen and (max-width: 600px)');
        const medium = window.matchMedia('screen and (min-width: 601px) and (max-width: 840px)');
        const expanded = window.matchMedia('screen and (min-width: 841px) and (max-width: 1000px)');
        const large = window.matchMedia('screen and (min-width: 1001px)');
        updateMediaSize(); // run once to set the initial size

        function updateMediaSize() {
            if (compact.matches) {
                dispatch({type: UiBreakpointActions.SET_BREAKPOINT_INTERVAL, value: 'compact'});
            } else if (medium.matches) {
                dispatch({type: UiBreakpointActions.SET_BREAKPOINT_INTERVAL, value: 'medium'});
            } else if (expanded.matches) {
                dispatch({type: UiBreakpointActions.SET_BREAKPOINT_INTERVAL, value: 'expanded'});
            } else if (large.matches) {
                dispatch({type: UiBreakpointActions.SET_BREAKPOINT_INTERVAL, value: 'large'});
            }
        }
        compact.addEventListener('change', updateMediaSize);
        medium.addEventListener('change', updateMediaSize);
        expanded.addEventListener('change', updateMediaSize);
        large.addEventListener('change', updateMediaSize);

        return () => {
            compact.removeEventListener('change', updateMediaSize);
            medium.removeEventListener('change', updateMediaSize);
            expanded.removeEventListener('change', updateMediaSize);
            large.removeEventListener('change', updateMediaSize);
        };
    }, []);

    const value = {state, dispatch};
    return <UiContext.Provider value={value}>{children}</UiContext.Provider>;
}

function useUiContext() {
    const context = useContext(UiContext);
    if (context === undefined) {
        throw new Error('useUiContext must be used within a UiProvider');
    }

    return context;
}

export {UiProvider, useUiContext};
