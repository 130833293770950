import {fetchApi} from './implementations/fetch';
import {mockApi} from './implementations/mock';
import {
    ClinicObjectSlim,
    ReasonFilterObject,
    PlacesFilterObject,
    CaregiverObject,
    CapacityResponse,
    FinalizeBookingRequest,
    BookingPatchRequest,
    ConsentRequestAnswer,
    FinalizeDropInRequest,
    AccountJSON,
    ManualVaccinationPayload
} from './types/types';
import {debug} from '../debug/debug';
import {LatLng, LatLngBounds} from 'leaflet';
import {weekInterval} from '../../lib/dates/week';
import {isSameDay} from 'date-fns';
import {capacityCache} from '../cache/capacityCache';

const {
    getAccount,
    createOrUpdateAccount,
    deleteAccount,
    getCaregivers,
    caregiverCookiePolicyLink,
    getClinics,
    getVisitReasons,
    getPlaces,
    getPlace,
    getCounties,
    getCountries,
    getClinic,
    getAppointmentCategories,
    getAppointmentType,
    getTimeSlots,
    getNextAvailableTimeSlot,
    getCapacity,
    reserveTimeSlot,
    unreserveTimeSlot,
    refreshTimeSlot,
    getHealthDeclaration,
    getDefaultHealthDeclaration,
    getPerson,
    getPersonMinimal,
    finalizeBooking,
    submitHealthDeclaration,
    validatePnr,
    getSingleBooking,
    getBookings,
    getConsentRequests,
    patchConsentRequests,
    patchBooking,
    getHistory,
    addManualVaccination,
    editManualVaccination,
    deleteManuallyAddedVaccination,
    getVaccineCategories
} = debug.debugActive || process.env.NODE_ENV === 'test' ? mockApi : fetchApi;

const fetchAccount = async (userToken: string) => {
    return await getAccount(userToken);
};

const postAccount = async (userToken: string, data: AccountJSON) => {
    return await createOrUpdateAccount(userToken, data);
};

const removeAccount = async (userToken: string) => {
    return await deleteAccount(userToken);
};

const fetchVisitReasons = async (clientLocale: string = 'sv-se') => {
    const visitReasons = await getVisitReasons();

    return visitReasons.map(({id, name, category}) => {
        const localeName = name.find(({locale}) => locale === clientLocale) || name[0];

        return {
            id: id.toString(),
            category,
            label: localeName.text
        } satisfies ReasonFilterObject;
    });
};

const fetchCaregivers = async () => {
    const careGivers = await getCaregivers();

    return careGivers.map(({id, name, cookiePolicyVersion, hsaid}) => {
        return {
            id,
            label: name,
            cookiePolicyVersion,
            hsaid
        } satisfies CaregiverObject;
    });
};

const getCaregiverCookiePolicyLink = (caregiverId: number) => {
    return caregiverCookiePolicyLink(caregiverId);
};

const fetchClinics = async () => {
    let clinics = await getClinics();

    // Removing clinics without position
    clinics = clinics.filter((clinic) => clinic.position);

    return clinics.map((clinic) => {
        return {
            id: clinic.id.toString(),
            name: clinic.name,
            availableVisitReasonIds: clinic.availableVisitReasons.map((id) => id.toString()),
            principalCareGiverId: clinic.principalCareGiver,
            logoUrl: clinic.logoUrl,
            careGiverId: clinic.careGiver,
            address: clinic.address,
            city: clinic.city,
            zip: clinic.zip,
            hasDropin: clinic.hasDropin || false,
            hasTimebook: clinic.hasTimebook || false,
            location: {
                lat: clinic.position.lat,
                lng: clinic.position.lon
            },
            externalBookingUrl: clinic.externalBookingUrl,
            externalBookingUrlType: clinic.externalBookingUrlType
        } satisfies ClinicObjectSlim;
    });
};

const fetchClinicById = async (id: number) => {
    return await getClinic(id);
};

const fetchSearch = async (query: string, abortSignal: AbortSignal) => {
    const search = await getPlaces(query, abortSignal);

    if ('throwIfAborted' in abortSignal) {
        // This might not be needed when using actual API endpoint
        abortSignal.throwIfAborted();
    }

    return search.map(({id, name, bounds, category}) => {
        return {
            id,
            name,
            category,
            boundingBox: new LatLngBounds(new LatLng(bounds.bottom, bounds.left), new LatLng(bounds.top, bounds.right))
        } satisfies PlacesFilterObject;
    });
};

const fetchPlace = async (placeId: string) => {
    const placeData = await getPlace(placeId);

    if (placeData === null) {
        throw new Error('Place not found');
    }

    const {id, name, category, bounds} = placeData;

    return {
        id,
        name,
        category,
        boundingBox: new LatLngBounds(new LatLng(bounds.bottom, bounds.left), new LatLng(bounds.top, bounds.right))
    } satisfies PlacesFilterObject;
};

const fetchCounties = async () => {
    const countiesData = await getCounties();

    return countiesData.map(({id, name, bounds, category}) => {
        return {
            id,
            name,
            category,
            boundingBox: new LatLngBounds(new LatLng(bounds.bottom, bounds.left), new LatLng(bounds.top, bounds.right))
        } satisfies PlacesFilterObject;
    });
};

const fetchCountries = async () => {
    const countiesData = await getCountries();

    return countiesData;
};

const fetchHistory = async (userToken: string, userId?: string) => {
    return getHistory(userToken, userId);
};

const addVaccination = async (userToken: string, userId: string, payload: ManualVaccinationPayload) => {
    return await addManualVaccination(userToken, userId, payload);
};

const editVaccination = async (
    userToken: string,
    userId: string,
    vaccinationId: string,
    payload: ManualVaccinationPayload
) => {
    return await editManualVaccination(userToken, userId, vaccinationId, payload);
};

const removeManuallyAddedVaccination = async (userToken: string, vaccinationId: string, userId?: string) => {
    return await deleteManuallyAddedVaccination(userToken, vaccinationId, userId);
};

const fetchVaccineCategories = async () => {
    return await getVaccineCategories();
};

const fetchAppointmentCategories = async (clinicId: number) => {
    return await getAppointmentCategories(clinicId);
};
const fetchAppointmentType = async (clinicId: number, appointmentTypeId: number) => {
    return await getAppointmentType(clinicId, appointmentTypeId);
};

const fetchTimeSlotsForType = async (
    clinicId: number,
    appointmentTypeId: number,
    from: Date,
    to: Date,
    abortSignal: AbortSignal
) => {
    const stringTimeSlots = await getTimeSlots(clinicId, appointmentTypeId, from, to, abortSignal);

    if ('throwIfAborted' in abortSignal) {
        // This might not be needed when using actual API endpoint
        abortSignal.throwIfAborted();
    }

    const weekDates = weekInterval(from);
    const timeSlotsList = stringTimeSlots.map((dateString) => new Date(dateString));
    const timeSlots = weekDates.reduce(
        (acc, date) => {
            acc.push(timeSlotsList.filter((val) => isSameDay(date, val)));

            return acc;
        },
        [] as Array<Array<Date>>
    );

    return timeSlots;
};

const fetchNextAvailableTimeSlotForType = async (clinicId: number, appointmentTypeId: number, from: Date, to: Date) => {
    const dateString = await getNextAvailableTimeSlot(clinicId, appointmentTypeId, from, to);

    if (!dateString) {
        return null;
    }

    return new Date(dateString);
};

const fetchCapacity = async (clinicId: number, abortSignal: AbortSignal, reasonIds: Array<string> = []) => {
    const capacityCacheKey = `${[clinicId, ...reasonIds.sort()]?.join(',')}`;

    if (capacityCache.has(capacityCacheKey)) {
        return capacityCache.get(capacityCacheKey) as CapacityResponse;
    }

    const capacity = await getCapacity(clinicId, abortSignal, reasonIds);

    if ('throwIfAborted' in abortSignal) {
        // This might not be needed when using actual API endpoint
        abortSignal.throwIfAborted();
    }

    if (!capacity) {
        return null;
    }

    capacityCache.set(capacityCacheKey, capacity);

    return capacity;
};

const fetchReserveTimeSlot = async (clinicId: number, appointmentTypeId: number, timeSlot: Date) => {
    const bookingId = await reserveTimeSlot(clinicId, appointmentTypeId, timeSlot);

    return bookingId;
};

const fetchRefreshTimeSlot = async (jwt: string, clinicId: number, bookingId: string) => {
    return await refreshTimeSlot(jwt, clinicId, bookingId);
};

const fetchUnreserveTimeSlot = async (clinicId: number, bookingId: string, userJwt: string | null = null) => {
    return await unreserveTimeSlot(clinicId, bookingId, userJwt);
};
const fetchPerson = async (userToken: string) => {
    return await getPerson(userToken);
};
const fetchPersonMinimal = async (userToken: string) => {
    return await getPersonMinimal(userToken);
};

const fetchHealthDeclaration = async (healthDeclarationId: number) => {
    return await getHealthDeclaration(healthDeclarationId);
};

const fetchDefaultHealthDeclaration = async (clinicId: number) => {
    return await getDefaultHealthDeclaration(clinicId);
};

const postFinalizeBooking = async (
    clinicId: number,
    bookingId: string,
    userToken: string | null,
    data: FinalizeBookingRequest
) => {
    return await finalizeBooking(clinicId, bookingId, userToken, data);
};

const postHealthDeclaration = async (clinicId: number, userToken: string | null, data: FinalizeDropInRequest) => {
    return await submitHealthDeclaration(clinicId, userToken, data);
};

const postValidatePnr = async (pnr: string) => {
    return await validatePnr(pnr);
};

const fetchSingleBooking = async (bookingCode: string, phoneNumber: string) => {
    return await getSingleBooking(bookingCode, phoneNumber);
};

const fetchBookings = async (userJwt: string) => {
    return await getBookings(userJwt);
};

const fetchConsentRequests = async (userJwt: string) => {
    return await getConsentRequests(userJwt);
};

const updateConsentRequests = async (userJwt: string, consentRequestAnswer: ConsentRequestAnswer) => {
    return await patchConsentRequests(userJwt, consentRequestAnswer);
};

const updateBooking = async (
    bookingId: string,
    clinicId: number,
    bookingData: Partial<BookingPatchRequest>,
    userJwt: string | null
) => {
    return await patchBooking(bookingId, clinicId, bookingData, userJwt);
};

export {
    fetchAccount,
    postAccount,
    removeAccount,
    fetchVisitReasons,
    fetchCaregivers,
    getCaregiverCookiePolicyLink,
    fetchClinics,
    fetchSearch,
    fetchPlace,
    fetchClinicById,
    fetchCounties,
    fetchCountries,
    fetchHistory,
    addVaccination,
    editVaccination,
    removeManuallyAddedVaccination,
    fetchVaccineCategories,
    fetchAppointmentCategories,
    fetchAppointmentType,
    fetchTimeSlotsForType,
    fetchNextAvailableTimeSlotForType,
    fetchCapacity,
    fetchReserveTimeSlot,
    fetchUnreserveTimeSlot,
    fetchRefreshTimeSlot,
    fetchHealthDeclaration,
    fetchDefaultHealthDeclaration,
    fetchPerson,
    fetchPersonMinimal,
    postFinalizeBooking,
    postHealthDeclaration,
    postValidatePnr,
    fetchSingleBooking,
    fetchBookings,
    fetchConsentRequests,
    updateConsentRequests,
    updateBooking
};
