import {CookiesProvider} from 'react-cookie';
import {Outlet} from 'react-router-dom';
import {BookingProvider} from '../context/BookingContext';
import {BookingSessionProvider} from '../context/BookingSessionContext';
import {ClinicDetailsProvider} from '../context/ClinicDetailsContext';
import {useClearTrackingCookies} from '../hooks/useClearTrackingCookies';
import {useCssWorkarounds} from '../hooks/useCssWorkarounds';

export function ClinicRoot() {
    useCssWorkarounds();
    useClearTrackingCookies();

    return (
        <CookiesProvider>
            <ClinicDetailsProvider>
                <BookingProvider>
                    <BookingSessionProvider>
                        <Outlet />
                    </BookingSessionProvider>
                </BookingProvider>
            </ClinicDetailsProvider>
        </CookiesProvider>
    );
}
