import {useTranslation} from 'react-i18next';

import './PolicyHeader.scss';

export function PolicyHeader() {
    const {t} = useTranslation();

    return (
        <header className='policy-header-nav'>
            <img className='logo' src='/mittvaccin_logo.svg' alt={t('logo_alt.text')} />
        </header>
    );
}
