import {useTranslation} from 'react-i18next';
import {HistoryResponse} from 'src/services/api/types/types';
import {ReactComponent as IconTrash} from 'src/components/icons/icon_trash.svg';
import {ReactComponent as IconEdit} from 'src/components/icons/icon_edit.svg';
import {ReactComponent as IconInfo} from 'src/components/icons/icon_info.svg';
import {ReactComponent as IconVaccine} from 'src/components/icons/icon_vaccine.svg';
import {ReactComponent as CalendarIcon} from 'src/components/icons/icon_calendar.svg';
import {ReactComponent as IconAlarmOn} from 'src/components/icons/icon_alarm_on.svg';
import {ReactComponent as IconLocation} from 'src/components/icons/icon_location.svg';
import {ReactComponent as IconHeart} from 'src/components/icons/icon_heart.svg';
import {ReactComponent as IconClinicMap} from 'src/components/icons/icon_clinic_map.svg';

import './VaccinationItemDetailsCard.scss';
import {useUiContext} from '../../../../context/UiContext';
import {useMemo} from 'react';

type VaccinationItemDetailsCardProps = {
    className?: string;
    vaccination: HistoryResponse;
    onRemoveVaccination(): void;
    onEditVaccination(): void;
};

export function VaccinationItemDetailsCard({
    className,
    vaccination,
    onRemoveVaccination,
    onEditVaccination
}: VaccinationItemDetailsCardProps) {
    const {t} = useTranslation();
    const {breakPointInterval} = useUiContext().state;

    const isMobile = ['compact', 'medium'].includes(breakPointInterval);

    const dateOptions: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    };

    const isUserAddedVaccination = vaccination.source === 'USER_ADDED';
    const isExternallyAddedVaccination = vaccination.source === 'REGISTERED_HISTORY';

    const doseNumberText = useMemo(() => {
        return vaccination.vaccine.dose && vaccination.vaccine.dose !== -1
            ? t('vaccine.doseNr', {doseNr: vaccination.vaccine.dose})
            : t('vaccine.doseNrNotSpecified');
    }, [t, vaccination.vaccine.dose]);

    const handleRemoveVaccination = () => {
        if (onRemoveVaccination) {
            onRemoveVaccination();
        }
    };

    const handleEditVaccination = () => {
        if (onEditVaccination) {
            onEditVaccination();
        }
    };

    const getVaccineHeader = () => {
        if (isMobile) {
            return doseNumberText;
        } else {
            if (vaccination.vaccine.categoryName === '-' && vaccination.vaccine.name) {
                return vaccination.vaccine.name;
            }
            return vaccination.vaccine.categoryName || vaccination.vaccine.name;
        }
    };

    return (
        <>
            <div
                id={`vaccine-details-${vaccination.id}`}
                className={`vaccination-item-details-card${className ? ` ${className}` : ''}`}
                data-testid='vaccine-test-component'
            >
                <div className='vaccination-item-details-card__container'>
                    <div className='vaccination-item-details-card__container__column'>
                        <h1
                            className={`vaccination-item-details-card__container__column__header${
                                isMobile ? '--small' : ''
                            }`}
                        >
                            {getVaccineHeader()}
                        </h1>
                        {!isMobile && <p>{doseNumberText}</p>}
                        {isUserAddedVaccination && <p className='t-gray600'>{t('vaccine.isUserAddedText')}</p>}
                        {isExternallyAddedVaccination && (
                            <p className='t-gray600'>
                                {t('vaccine.isRegisteredHistoryText', {clinicName: vaccination.clinic.name})}
                            </p>
                        )}
                    </div>
                </div>
                {vaccination.notification && (
                    <div className='vaccination-item-details-card__notification'>
                        <div>
                            <p>{t('vaccine.nextDoseNotificationText')}</p>
                            <h4>{vaccination.notification.when}</h4>
                        </div>
                        <div className='vaccination-item-details-card__notification__right'>
                            <div className='vaccination-item-details-card__notification__right__icon'>
                                <IconAlarmOn width='1.75rem' height='1.75rem' aria-hidden />
                            </div>
                            {!isUserAddedVaccination && (
                                <div>
                                    <p>{t('vaccine.fromCaregiverText')}</p>
                                    <h4 className='vaccination-item-details-card__notification__right__subheader'>
                                        {vaccination.clinic.name}
                                    </h4>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className='vaccination-item-details-card__container'>
                    <div className='vaccination-item-details-card__container__row underline'>
                        <div className='vaccination-item-details-card__container__row__left'>
                            <CalendarIcon aria-hidden />
                            {t('time.date')}
                        </div>
                        <h4 className='vaccination-item-details-card__container__row__right'>
                            {new Date(vaccination.when).toLocaleDateString('sv-SE', dateOptions)}
                        </h4>
                    </div>
                    <div className='vaccination-item-details-card__container__row underline'>
                        <div className='vaccination-item-details-card__container__row__left'>
                            <div className='vaccination-item-details-card__container__row__left__icon'>
                                <IconVaccine width='1rem' height='1rem' aria-hidden />
                            </div>
                            {t('vaccine.vaccine')}
                        </div>
                        <div className='vaccination-item-details-card__container__row__right'>
                            <h4>{vaccination.vaccine.name}</h4>
                            {vaccination.vaccine?.informationUrl && (
                                <a
                                    className='vaccination-item-details-card__container__row__right__icon'
                                    href={vaccination.vaccine.informationUrl}
                                    target='blank'
                                    aria-label={t('vaccine.infoLinkAriaLabel')}
                                >
                                    <IconInfo aria-hidden />
                                </a>
                            )}
                        </div>
                    </div>
                    {!isUserAddedVaccination && (
                        <>
                            <div className='vaccination-item-details-card__container__row underline'>
                                <div className='vaccination-item-details-card__container__row__left'>
                                    <div className='vaccination-item-details-card__container__row__left__icon'>
                                        <IconClinicMap aria-hidden />
                                    </div>
                                    {t('vaccine.careGiverUnit')}
                                </div>
                                <h4 className='vaccination-item-details-card__container__row__right'>
                                    {vaccination.clinic.name}
                                </h4>
                            </div>
                            {vaccination.careGiverName && (
                                <div className='vaccination-item-details-card__container__row underline'>
                                    <div className='vaccination-item-details-card__container__row__left'>
                                        <div className='vaccination-item-details-card__container__row__left__icon'>
                                            <IconHeart aria-hidden />
                                        </div>
                                        {t('search_group_caregiver.text')}
                                    </div>
                                    <h4 className='vaccination-item-details-card__container__row__right'>
                                        {vaccination.careGiverName}
                                    </h4>
                                </div>
                            )}
                            {vaccination.clinic.address && vaccination.clinic.city && (
                                <div className='vaccination-item-details-card__container__row underline'>
                                    <div className='vaccination-item-details-card__container__row__left'>
                                        <div className='vaccination-item-details-card__container__row__left__icon'>
                                            <IconLocation aria-hidden />
                                        </div>
                                        {t('vaccine.address')}
                                    </div>
                                    <h4 className='vaccination-item-details-card__container__row__right'>{`${vaccination.clinic.address}, ${vaccination.clinic.city}`}</h4>
                                </div>
                            )}
                        </>
                    )}
                    {isUserAddedVaccination && (
                        <div className='vaccination-item-details-card__container__row__actions'>
                            <button
                                className='vaccination-item-details-card__container__row__actions__button'
                                onClick={handleRemoveVaccination}
                            >
                                <IconTrash aria-hidden />
                                {t('button_remove.text')}
                            </button>
                            <button
                                className='vaccination-item-details-card__container__row__actions__button'
                                onClick={handleEditVaccination}
                            >
                                <IconEdit aria-hidden />
                                {t('button_change.text')}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
