export function CircleCheckmark() {
    return (
        <>
            <svg
                width='60'
                aria-hidden='true'
                height='60'
                viewBox='0 0 60 60'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <rect width='60' height='60' rx='30' fill='#4B9D57' />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M39.6234 22.8147C39.3631 22.5544 38.941 22.5544 38.6806 22.8147L27.8384 33.657L22.6529 28.4715C22.3925 28.2111 21.9704 28.2111 21.7101 28.4715L19.8244 30.3571C19.5641 30.6174 19.5641 31.0395 19.8244 31.2999L25.4731 36.9486C25.4758 36.9514 25.4785 36.9541 25.4813 36.9569L27.3669 38.8425C27.6272 39.1028 28.0494 39.1028 28.3097 38.8425L41.509 25.6431C41.7694 25.3828 41.7694 24.9607 41.509 24.7003L39.6234 22.8147Z'
                    fill='white'
                />
            </svg>
        </>
    );
}
