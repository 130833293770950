import {useEffect} from 'react';

export function useCssWorkarounds() {
    // "Hack" to make browsers with no dynamic height value support (e.g. Safari iOS <= 15.2)
    // get a viewport height set which takes the navigation bar into account
    useEffect(() => {
        if (CSS.supports('height: 100svh')) {
            return;
        }
        const updateVhFallback = () => {
            // Set the value in the --vhfallback custom css property to the root of the document
            document.documentElement.style.setProperty('--vhfallback', `${window.innerHeight * 0.01}px`);
        };

        window.addEventListener('resize', updateVhFallback);
        updateVhFallback();

        return () => window.removeEventListener('resize', updateVhFallback);
    }, []);
}
