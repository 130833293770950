import React, {createContext, useCallback, useContext, useEffect, useReducer} from 'react';
import {fetchCaregivers, fetchClinics, fetchCounties, fetchVisitReasons} from '../services/api/api';
import {
    CaregiverObject,
    ClinicObjectSlim,
    ClinicObjectSlimWithCapacity,
    CountyFilterObject,
    ReasonFilterObject
} from '../services/api/types/types';
import {UiLoadStatusActions, useUiContext} from './UiContext';
import {LatLng} from 'leaflet';

export enum MapDataActions {
    SET_CAREGIVERS,
    SET_CLINICS,
    SET_VISIT_REASONS,
    SET_COUNTIES
}

export enum MapLocationActions {
    SET_USER_LOCATION = 'SET_USER_LOCATION'
}

export enum MapBooleanActions {
    SET_HAS_GEO_PERMISSION = 'SET_HAS_GEO_PERMISSION'
}

type DataAction = {
    type: MapDataActions;
    value: Array<CaregiverObject> | Array<ClinicObjectSlim> | Array<ReasonFilterObject> | Array<CountyFilterObject>;
};
type LocationAction = {type: MapLocationActions; value: LatLng | null};
type BooleanAction = {type: MapBooleanActions; value: boolean};

type Action = DataAction | LocationAction | BooleanAction;

type Dispatch = (action: Action) => void;
type State = {
    careGivers: Array<CaregiverObject>;
    clinics: Array<ClinicObjectSlim>;
    visitReasons: Array<ReasonFilterObject>;
    counties: Array<CountyFilterObject>;
    userLocation: LatLng | null;
    hasGeoPermission: boolean;
};
type MapDataProviderProps = {children: React.ReactNode};

const MapDataContext = createContext<
    | {
          state: State;
          getDistanceToClinic(clinic: ClinicObjectSlimWithCapacity): {value: number; unit: string} | undefined;
          dispatch: Dispatch;
      }
    | undefined
>(undefined);

function MapDataReducer(state: State, action: Action): State {
    switch (action.type) {
        case MapDataActions.SET_CAREGIVERS: {
            return {...state, careGivers: action.value as Array<CaregiverObject>};
        }
        case MapDataActions.SET_CLINICS: {
            return {...state, clinics: action.value as Array<ClinicObjectSlim>};
        }
        case MapDataActions.SET_VISIT_REASONS: {
            return {...state, visitReasons: action.value as Array<ReasonFilterObject>};
        }
        case MapDataActions.SET_COUNTIES: {
            return {...state, counties: action.value as Array<CountyFilterObject>};
        }
        case MapLocationActions.SET_USER_LOCATION: {
            return {...state, userLocation: action.value};
        }
        case MapBooleanActions.SET_HAS_GEO_PERMISSION: {
            return {...state, hasGeoPermission: action.value};
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function MapDataProvider({children}: MapDataProviderProps) {
    const [state, dispatch] = useReducer(MapDataReducer, {
        careGivers: [],
        clinics: [],
        visitReasons: [],
        counties: [],
        userLocation: null,
        hasGeoPermission: false
    });

    const {state: uiState, dispatch: uiDispatch} = useUiContext();

    const getDistanceToClinic = useCallback(
        (clinic: ClinicObjectSlimWithCapacity) => {
            if (!!state.userLocation && state.hasGeoPermission) {
                let distance = state.userLocation.distanceTo([clinic.location.lat, clinic.location.lng]);
                let unit = 'm';
                let fractionDigits = 0;

                if (distance >= 1000 && distance < 5000) {
                    distance = distance / 1000;
                    unit = 'km';
                    fractionDigits = 1;
                } else if (distance >= 5000) {
                    distance = distance / 1000;
                    unit = 'km';
                }

                return {
                    value: +distance.toFixed(fractionDigits),
                    unit: unit
                };
            }
        },
        [state.hasGeoPermission, state.userLocation]
    );

    useEffect(() => {
        fetchClinics()
            .then((clinics) => {
                dispatch({type: MapDataActions.SET_CLINICS, value: clinics});
                uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'done'});
            })
            .catch(() => {
                uiDispatch({type: UiLoadStatusActions.SET_CLINIC_LOAD_STATUS, value: 'error'});
            });
    }, [uiDispatch]);

    useEffect(() => {
        if (uiState.filtersLoadStatus === 'done' || uiState.filtersLoadStatus === 'error') {
            return;
        }

        Promise.all([fetchCaregivers(), fetchVisitReasons(), fetchCounties()])
            .then(([caregivers, visitReasons, counties]) => {
                dispatch({type: MapDataActions.SET_CAREGIVERS, value: caregivers});
                dispatch({type: MapDataActions.SET_VISIT_REASONS, value: visitReasons});
                dispatch({type: MapDataActions.SET_COUNTIES, value: counties as Array<CountyFilterObject>});
                uiDispatch({type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS, value: 'done'});
            })
            .catch(() => {
                uiDispatch({type: UiLoadStatusActions.SET_FILTERS_LOAD_STATUS, value: 'error'});
            });
    }, [uiDispatch, uiState.filtersLoadStatus]);

    const value = {state, getDistanceToClinic, dispatch};
    return <MapDataContext.Provider value={value}>{children}</MapDataContext.Provider>;
}

function useMapDataContext() {
    const context = useContext(MapDataContext);
    if (context === undefined) {
        throw new Error('useDataContext must be used within a UiProvider');
    }

    return context;
}

export {MapDataProvider, useMapDataContext};
