import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSessionContext} from 'src/context/SessionContext';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {Spinner} from 'src/components/layout/loader/Spinner';
import {Button} from 'src/components/form/button/Button';
import {ConfirmRemoveAccount} from './ConfirmRemoveAccount';
import './RemoveAccountModal.scss';

export function RemoveAccountModal({open, onClose}: {open: boolean; onClose: () => void}) {
    const {t} = useTranslation();
    const {sessionState, removeUserAccount} = useSessionContext();
    const [showConfirmRemoveAccount, setShowConfirmRemoveAccount] = useState(false);

    const handleRemoveAccount = () => {
        if (sessionState?.userJwt) {
            removeUserAccount(sessionState?.userJwt);
        }
    };

    useEffect(() => {
        if (open) {
            setShowConfirmRemoveAccount(false);
        }
    }, [open]);

    return (
        <ModalPortal
            open={open || sessionState.isRemovingAccount}
            size='sm'
            ariaLabel={
                showConfirmRemoveAccount
                    ? t('account.remove_account_confirmation_header')
                    : t(t('account.remove_account'))
            }
            onCloseClick={!showConfirmRemoveAccount ? onClose : undefined}
            headerTitle={
                showConfirmRemoveAccount && !sessionState.isRemovingAccount
                    ? t('account.remove_account_confirmation_header')
                    : ''
            }
        >
            {sessionState.isRemovingAccount && (
                <div className='remove-modal__loader-container'>
                    <div className='remove-modal__loader-container__spinner'>
                        <Spinner />
                    </div>
                    <h4>{t('account.removing-account')}</h4>
                </div>
            )}
            {!sessionState.isRemovingAccount && !showConfirmRemoveAccount && (
                <ConfirmRemoveAccount onConfirmCloseAccount={() => setShowConfirmRemoveAccount(true)} />
            )}
            {showConfirmRemoveAccount && !sessionState.isRemovingAccount && (
                <div aria-live='assertive' aria-label={t('account.remove_account_confirmation_header')}>
                    <p className='remove-modal__remove-text'>{t('account.remove_account_confirmation_text')}</p>
                    <Button
                        className='remove-modal__remove-button'
                        onClick={handleRemoveAccount}
                        variant='filled'
                        disabled={sessionState.isRemovingAccount}
                        ariaLabel={t('account.remove_account')}
                    >
                        {t('account.remove_account')}
                    </Button>
                    <Button
                        className='remove-modal__remove-button'
                        onClick={onClose}
                        variant='outlined'
                        disabled={sessionState.isRemovingAccount}
                        ariaLabel={t('account.no_keep_account')}
                    >
                        {t('account.no_keep_account')}
                    </Button>
                </div>
            )}
        </ModalPortal>
    );
}
