import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useBookingContext, BookingActions} from 'src/context/BookingContext';
import {generateOpenUserKey, makeNewOpenUser, OpenUser} from 'src/lib/user/openUser';
import ModalPortal from 'src/components/layout/modal/ModalPortal';
import {Button} from 'src/components/form/button/Button';
import {PlusIcon} from 'src/components/icons/PlusIcon';
import {PatientForm} from '../../booking/people-select/views/PatientForm';
import './DropInPeopleForm.scss';

export function DropInPeopleForm() {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {selectedPersonsIds, user, healthDeclarationAnswers, bookingType} = bookingState;

    useEffect(() => window.scrollTo(0, 0), []);

    const [patients, setPatients] = useState<Array<OpenUser>>(() => {
        const users = Array.from(Array(selectedPersonsIds.length || 1)).map((_, index): OpenUser => {
            const selectedUser = index === 0 ? user : (user?.relations || [])[index - 1];

            if (selectedUser === undefined || selectedUser === null) {
                return makeNewOpenUser();
            }

            return {
                uniqueKey: generateOpenUserKey(),
                identity: selectedUser.identity,
                identityValid: true,
                age: selectedUser.age,
                givenName: selectedUser.givenName,
                familyName: selectedUser.familyName,
                gender: selectedUser.gender as 'male' | 'female'
            };
        });

        return users;
    });
    const [showNetworkError, setShowNetworkError] = useState(false);

    const focusElement = useRef<HTMLDivElement | null>(null);
    const patientFormsContainer = useRef<HTMLDivElement>(null);
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current || patientFormsContainer.current === null) {
            initialRender.current = false;
            return;
        }

        if (focusElement.current) {
            focusElement.current.focus();
            focusElement.current = null;
        } else {
            const lastItem = patientFormsContainer.current.querySelector(
                '.people-form__patient-form:last-child'
            ) as HTMLDivElement;
            lastItem?.focus();
            lastItem?.scrollIntoView({
                block: 'center'
            });
        }
    }, [patients.length]);

    const peopleValid =
        patients
            .map((user) => {
                return user.familyName !== '' && user.givenName !== '' && user.identityValid;
            })
            .includes(false) === false;

    const identities = patients.length > 0 ? patients.map(({identity}) => identity) : [];
    const hasDuplicatePatients = peopleValid && new Set(identities).size !== identities.length;

    return (
        <>
            <main className='main-content people-form dropin-people-form' id='main'>
                {showNetworkError && (
                    <ModalPortal
                        primaryActionLabel={t('error_ok_button.text')}
                        onPrimaryAction={() => setShowNetworkError(false)}
                    >
                        <p>
                            <b>{t('error_network_title.text')}</b>
                            <br />
                            {t('error_network_message.text')}
                        </p>
                    </ModalPortal>
                )}
                <h1 className='people__header'>
                    {bookingType === 'healthDeclaration'
                        ? t('people_select.healthDeclaration_identify_yourself')
                        : t('people_select.people_form.booking_header')}
                </h1>

                <p className='people__text wrap-html'>{t('people_select.people_list.consent_info')}</p>

                <p className='people__text wrap-html'>{t('people_select.people_form.dropin_subheader')}</p>

                <div className='dropin-people-form__patient-form-list' ref={patientFormsContainer}>
                    {patients.map((patient, index) => {
                        // Prevent removal of first patient
                        const onRemovePatient =
                            index === 0
                                ? undefined
                                : (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                      const updatedPatients = patients.filter((_, i) => i !== index);
                                      setPatients(updatedPatients);

                                      const closestPatientElement = e.currentTarget.closest(
                                          '.people-form__patient-form'
                                      ) as HTMLDivElement;
                                      focusElement.current = closestPatientElement?.previousSibling as HTMLDivElement;
                                  };

                        return (
                            <div tabIndex={-1} key={patient.uniqueKey} className='people-form__patient-form'>
                                <PatientForm
                                    onNetworkError={() => setShowNetworkError(true)}
                                    identities={identities}
                                    patient={patient}
                                    index={index}
                                    onRemoveClick={onRemovePatient}
                                    onChange={(updatedPatient) => {
                                        const updatedPatients = [...patients];
                                        updatedPatients[index] = updatedPatient;
                                        setPatients(updatedPatients);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className='dropin-people-form__people-footer'>
                    <h2 className='dropin-people-form__sub-heading'>
                        {bookingType === 'healthDeclaration'
                            ? t('people_select.people_form.healthDeclaration_add_person_header')
                            : t('people_select.people_form.dropin_add_person_header')}
                    </h2>
                    <p>
                        <Button
                            onClick={() => {
                                const updatedPatients = [...patients];
                                updatedPatients.push(makeNewOpenUser());

                                setPatients(updatedPatients);
                            }}
                            variant='text'
                            className='dropin-people-form__add-person-button'
                        >
                            <PlusIcon />
                            {t('people_select.people_form.dropin_add_person')}
                        </Button>
                    </p>
                </div>

                <div className='people-form__footer'>
                    <Button
                        disabled={peopleValid === false || hasDuplicatePatients}
                        big
                        onClick={() => {
                            const [firstPatient, ...otherPatients] = patients;

                            const answerKeys = Object.keys(healthDeclarationAnswers.answers);

                            answerKeys.forEach((key) => {
                                if (identities.includes(key) === false) {
                                    dispatch({type: BookingActions.REMOVE_HEALTH_DECLARATION, value: key});
                                }
                            });

                            dispatch({
                                type: BookingActions.SET_USER,
                                value: {
                                    identity: firstPatient.identity,
                                    givenName: firstPatient.givenName,
                                    familyName: firstPatient.familyName,
                                    gender: firstPatient.gender,
                                    age: firstPatient.age,
                                    relations: otherPatients.map((patient) => {
                                        return {
                                            relationType: 'unknown',
                                            identity: patient.identity,
                                            givenName: patient.givenName,
                                            familyName: patient.familyName,
                                            gender: patient.gender,
                                            age: patient.age
                                        };
                                    })
                                }
                            });
                            dispatch({type: BookingActions.SET_SELECTED_IDS, value: identities});
                            dispatch({type: BookingActions.NEXT_STEP});
                        }}
                    >
                        <span>{t('button_next.text')}</span>
                    </Button>
                </div>
            </main>
        </>
    );
}
