import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Destinations} from '../../../../context/BookingContext';
import {useMatchMedia} from '../../../../hooks/useMatchMedia';
import {Country} from '../../../../services/api/types/types';
import {Button} from '../../../form/button/Button';
import {DepartureDateInput} from './components/DepartureDateInput';
import {useTravelDurations} from './hooks/useTravelDurations';

import './TravelModal.scss';

interface TravelModalProps {
    countries: Array<Country>;
    initialDestinations?: Destinations;
    initialDepartureDate?: Date | null;
    onChange(dirty: boolean): void;
    onSaveClick({departureDate, destinations}: {departureDate: Date; destinations: Destinations}): void;
}

export function TravelModal({
    countries,
    initialDestinations = [
        {
            countryId: null,
            duration: null
        }
    ],
    initialDepartureDate = null,
    onChange,
    onSaveClick
}: TravelModalProps) {
    const {t} = useTranslation();
    const {travelDurations} = useTravelDurations();

    const [departureDate, setDepartureDate] = useState<Date | null>(initialDepartureDate);
    const [destinations, setDestinations] = useState<Destinations>(initialDestinations);

    const useNativeDate = useMatchMedia('(max-width: 51.25rem)');

    const isChanged =
        departureDate !== initialDepartureDate ||
        JSON.stringify(Object.values(destinations.flatMap(({countryId, duration}) => [countryId, duration]))) !==
            JSON.stringify(
                Object.values(initialDestinations.flatMap(({countryId, duration}) => [countryId, duration]))
            );

    // Allow save when all fields have value OR no fields have value and no additional destinations are added
    const isNull = (val: unknown) => val === null;
    const formValid =
        (departureDate !== null &&
            Object.values(destinations)
                .flatMap(({countryId, duration}) => [countryId, duration])
                .includes(null) === false) ||
        (departureDate === null &&
            destinations.length === 1 &&
            Object.values(destinations)
                .flatMap(({countryId, duration}) => [countryId, duration])
                .every(isNull));

    useEffect(() => {
        onChange(isChanged);
    }, [onChange, isChanged]);

    const initialRender = useRef(true);
    const focusElement = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (initialRender.current || travelItemsContainer.current === null) {
            initialRender.current = false;
            return;
        }

        if (focusElement.current) {
            focusElement.current.focus();
            focusElement.current = null;
        } else {
            const lastItem = travelItemsContainer.current.querySelector(
                '.travel-modal__travel-item:last-child'
            ) as HTMLDivElement;
            lastItem?.focus();
            lastItem?.scrollIntoView();
        }
    }, [destinations.length]);

    const travelItemsContainer = useRef<HTMLDivElement>(null);

    return (
        <>
            <div className='travel-modal'>
                <h1 className='health-declaration__header'>{t('travelModal.header')}</h1>
                <p>{t('travelModal.information_text')}</p>
                <div className='travel-modal__departure-section'>
                    <DepartureDateInput
                        selectedDate={departureDate}
                        useNative={useNativeDate}
                        min={new Date()}
                        onChange={(date) => setDepartureDate(date)}
                    />
                </div>
                <div className='travel-modal__travel-section' ref={travelItemsContainer}>
                    {destinations.map((destination, index) => {
                        return (
                            <div tabIndex={-1} key={index} className='travel-modal__travel-item'>
                                {index > 0 && (
                                    <div className='travel-modal__travel-item-header'>
                                        <b>{t('travelModal.location_iteration', {index: index + 1})}</b>
                                    </div>
                                )}
                                <span
                                    className={`travel-modal__select-container${
                                        destination.countryId !== null
                                            ? ' travel-modal__select-container--with-value'
                                            : ''
                                    }`}
                                >
                                    {destination.countryId !== null && (
                                        <span aria-hidden className='travel-modal__select-label'>
                                            {t('travelModal.select_location')}
                                        </span>
                                    )}
                                    <select
                                        aria-label={`${t('travelModal.location_iteration', {index: index + 1})} ${t(
                                            'travelModal.select_location'
                                        )}`}
                                        value={destination.countryId || -1}
                                        className={`travel-modal__select`}
                                        onChange={(e) => {
                                            const items = [...destinations];
                                            const newDestination = {...items[index]};
                                            newDestination.countryId = parseInt(e.target.value, 10);

                                            items[index] = newDestination;
                                            setDestinations(items);
                                        }}
                                    >
                                        <option disabled value={-1}>
                                            {t('travelModal.select_location')}
                                        </option>
                                        {countries.map((country) => {
                                            return (
                                                <option key={`${country.id}`} value={country.id}>
                                                    {country.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </span>
                                <span
                                    className={`travel-modal__select-container${
                                        destination.duration !== null
                                            ? ' travel-modal__select-container--with-value'
                                            : ''
                                    }`}
                                >
                                    {destination.duration !== null && (
                                        <span aria-hidden className='travel-modal__select-label'>
                                            {t('travelModal.select_duration')}
                                        </span>
                                    )}
                                    <select
                                        aria-label={`${t('travelModal.location_iteration', {index: index + 1})} ${t(
                                            'travelModal.select_duration'
                                        )}`}
                                        value={destination.duration || -1}
                                        className={`travel-modal__select`}
                                        onChange={(e) => {
                                            const items = [...destinations];
                                            const newDestination = {...items[index]};
                                            newDestination.duration = parseInt(e.target.value, 10);

                                            items[index] = newDestination;
                                            setDestinations(items);
                                        }}
                                    >
                                        <>
                                            <option disabled value={-1}>
                                                {t('travelModal.select_duration')}
                                            </option>
                                            {travelDurations.map(({id, label}) => (
                                                <option key={`duration-${id}`} value={id}>
                                                    {label}
                                                </option>
                                            ))}
                                        </>
                                    </select>
                                </span>
                                <span className='travel-modal__remove-destination-link'>
                                    {index === 0 ? (
                                        <a
                                            href='/'
                                            aria-label={t('travelModal.aria.clear_date_and_first_destination')}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const newDestinations = [...destinations];
                                                newDestinations[index] = {
                                                    countryId: null,
                                                    duration: null
                                                };
                                                setDestinations(newDestinations);
                                                setDepartureDate(null);
                                            }}
                                        >
                                            {t('button_clear.text')}
                                        </a>
                                    ) : (
                                        <a
                                            href='/'
                                            aria-label={t('travelModal.aria.remove_destination', {index: index + 1})}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                const newDestinations = [...destinations];
                                                newDestinations.splice(index, 1);
                                                setDestinations(newDestinations);

                                                const closestTravelItem = e.currentTarget.closest(
                                                    '.travel-modal__travel-item'
                                                ) as HTMLDivElement;
                                                focusElement.current =
                                                    closestTravelItem?.previousSibling as HTMLDivElement;
                                            }}
                                        >
                                            {t('button_remove.text')}
                                        </a>
                                    )}
                                </span>
                            </div>
                        );
                    })}
                </div>

                <div className='travel-modal__travel-section-footer'>
                    <Button
                        onClick={() => {
                            setDestinations([
                                ...destinations,
                                {
                                    countryId: null,
                                    duration: null
                                }
                            ]);
                        }}
                        big
                        variant='outlined'
                        className='btn--wide travel-modal__add-travel-button'
                    >
                        <span>{t('travelModal.add_additional_location')}</span>
                    </Button>
                    <Button
                        disabled={!formValid}
                        onClick={() => {
                            onSaveClick({
                                departureDate: departureDate as Date,
                                destinations
                            });
                        }}
                        big
                        className='btn--wide travel-modal__add-travel-button'
                    >
                        <span>{t('button_save.text')}</span>
                    </Button>
                </div>
            </div>
        </>
    );
}
