import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../form/button/Button';
import {BookingType} from '../../booking/booking-initial/BookingInitial';

interface ClinicErrorProps {
    error: Error;
    bookingType: BookingType;
}

export function ClinicError({error, bookingType}: ClinicErrorProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const ErrorContent = () => {
        if (bookingType === 'healthDeclaration') {
            return error.name === 'NoHealthDeclarationError' ? (
                <>
                    <h1 className='main-content__header'>
                        {t('clinic_error.clinic_healthDeclaration_not_available_header')}
                    </h1>
                    <p className='main-content__content'>
                        {t('clinic_error.clinic_healthDeclaration_not_available_content')}
                    </p>
                </>
            ) : (
                <>
                    <h1 className='main-content__header'>
                        {t('clinic_error.healthDeclaration_clinic_not_found_header')}
                    </h1>
                    <p className='main-content__content'>
                        {t('clinic_error.healthDeclaration_clinic_not_found_content')}
                    </p>
                </>
            );
        }

        return error.name === 'NotFoundError' ? (
            <>
                <h1 className='main-content__header'>{t('clinic_error.no_clinic_found')}</h1>
                <Button onClick={() => navigate('/')}>{t('clinic_error.find_other_clinic')}</Button>
            </>
        ) : (
            <>
                <h1 className='main-content__header'>{t('clinic_error.generic_error_header')}</h1>
                <h2 className='main-content__header__error'>{t('clinic_error.try_again_later')}</h2>
            </>
        );
    };

    return (
        <main className='main-content main-content--error' id='main'>
            <section className='main-content__section'>
                <ErrorContent />
            </section>
        </main>
    );
}
