import {useTranslation} from 'react-i18next';
import {BookingActions, useBookingContext} from '../../../../context/BookingContext';
import {useBookingSessionContext} from '../../../../context/BookingSessionContext';
import {useClinicDetailsContext} from '../../../../context/ClinicDetailsContext';
import {fetchReserveTimeSlot, fetchUnreserveTimeSlot} from '../../../../services/api/api';

export function TimeSelectNextButton({className}: {className?: string}) {
    const {t} = useTranslation();
    const {dispatch, bookingState} = useBookingContext();
    const {dataState} = useClinicDetailsContext();
    const {refreshBookingSession, startOpenSession} = useBookingSessionContext();
    const {clinic} = dataState;

    const {timeSlotState, bookingId} = bookingState;

    const onNextClick = async () => {
        if (!clinic?.id || !bookingState.reasonState.appointmentType?.id || !bookingState.timeSlotState.selectedSlot) {
            return;
        }

        dispatch({
            type: BookingActions.SET_TIMESLOT_DATA,
            value: {
                lockLoading: true,
                lockError: null
            }
        });

        if (bookingId) {
            dispatch({type: BookingActions.CLEAR_BOOKING_ID});

            try {
                await fetchUnreserveTimeSlot(clinic.id, bookingId);
            } catch (error) {
                // Probably fine, the time might already be cleared
                console.error(error);
            }
        }

        await refreshBookingSession();

        try {
            const {bookingId, sessionDuration} = await fetchReserveTimeSlot(
                clinic?.id,
                bookingState.reasonState.appointmentType?.id,
                bookingState.timeSlotState.selectedSlot
            );

            if (bookingState.reasonState.appointmentType.eidRequired === false) {
                startOpenSession(sessionDuration);
            }

            dispatch({type: BookingActions.SET_BOOKING_ID, value: bookingId});
            dispatch({
                type: BookingActions.SET_TIMESLOT_DATA,
                value: {
                    lockLoading: false,
                    lockError: null
                }
            });
            dispatch({type: BookingActions.NEXT_STEP});
        } catch (error) {
            dispatch({
                type: BookingActions.SET_TIMESLOT_DATA,
                value: {
                    lockLoading: false,
                    lockError: error as Error
                }
            });
        }
    };

    return (
        <button
            disabled={!timeSlotState.selectedSlot}
            className={`btn btn--primary btn--big${className ? ` ${className}` : ''}`}
            onClick={() => onNextClick()}
        >
            <span>{t('clinic_form.next')}</span>
        </button>
    );
}
