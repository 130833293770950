export const validateEmail = (email: string) => {
    return new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(email) || email.length === 0;
};

export const validatePhone = (phone: string) => {
    return new RegExp(/^07\d{8}$/).test(phone) || phone.length === 0;
};

export function validateBookingCode(bookingCode: string) {
    return new RegExp(/^\w+-\w+$/).test(bookingCode);
}
