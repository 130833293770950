import './TimeSlotSkeleton.scss';

export function TimeSlotSkeleton() {
    const days = [1, 2, 3, 4, 5, 6, 7];
    const slots = [1, 2, 3, 4, 5, 6];
    return (
        <>
            {days.map((dayIndex) => {
                return (
                    <div key={dayIndex} className='time-slot-column time-slot-column--skeleton'>
                        <div className={`time-slot-column__day time-slot-column__day--skeleton`}>
                            <span className='time-slot-column__day__date'></span>
                            <span className='time-slot-column__day__day'></span>
                        </div>
                        {slots.map((slotIndex) => {
                            return <div key={slotIndex} className='time-slot-column__slot--skeleton'></div>;
                        })}
                    </div>
                );
            })}
        </>
    );
}
