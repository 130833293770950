export function SearchIcon() {
    return (
        <>
            <svg
                focusable='false'
                aria-hidden='true'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <g clipPath='url(#clip0_1038_121705)'>
                    <circle cx='6.49982' cy='6.5' r='5.25' stroke='#121212' strokeWidth='2.5' />
                    <rect
                        x='10.512'
                        y='9.33325'
                        width='7'
                        height='2.5'
                        rx='0.5'
                        transform='rotate(45 10.512 9.33325)'
                        fill='#121212'
                    />
                </g>
                <defs>
                    <clipPath id='clip0_1038_121705'>
                        <rect width='16' height='16' fill='white' />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
}
