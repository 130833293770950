import {Outlet} from 'react-router-dom';
import {PolicyFooter} from '../components/policy/footer/PolicyFooter';
import {PolicyHeader} from '../components/policy/header/PolicyHeader';

import '../components/policy/PolicyRoot.scss';

export function PolicyRoot() {
    return (
        <div className='policy-container' aria-busy='false'>
            <div className='page-container-margin'>
                <PolicyHeader />
                <main id='main' className='policy-container__content'>
                    <Outlet />
                </main>
                <PolicyFooter />
            </div>
        </div>
    );
}
